<template>
    <div class="container mx-auto px-4">
        <font-awesome-icon @click="$router.push('/')" icon="fas fa-arrow-left" class="mt-5 p-5 -ml-5 cursor-pointer text-2xl" />
        <h1 class="text-3xl font-bold my-6">Terms of Service</h1>
        <p class="mb-4">Welcome to CreatorXone, a project by MSB Media, s.r.o., VAT number: SK2121581044 ("we," "us," "our"). CreatorXone offers a comprehensive dashboard for managing and enhancing your presence on platforms like OnlyFans, Fansly, and Reddit ("Service").</p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">1. Introduction</h2>
        <p class="mb-4">This document outlines the Terms of Service ("Terms") for the use of CreatorXone. By accessing or using our Service, you agree to be bound by these Terms and our Privacy Policy. If you disagree with any part of the terms, you must not use our Service.</p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">2. Service Description</h2>
        <p class="mb-4">CreatorXone provides marketing and software solutions aimed at agencies managing social media accounts. Our services include but are not limited to:</p>
        <ul class="list-disc pl-5 mb-4">
            <li>Maximizing online presence across multiple platforms.</li>
            <li>Offering advanced privacy features with proxy handling.</li>
            <li>Facilitating seamless model-fan interactions through ChatterMate.</li>
            <li>Providing automated Share4Share and Likes4Likes to boost user profiles.</li>
            <li>Delivering insightful metrics and analytics for strategic decision-making.</li>
        </ul>

        <h2 class="text-2xl font-semibold mt-8 mb-4">3. User Obligations</h2>
        <p class="mb-4">Users are responsible for maintaining the confidentiality of their account information and for all activities under their account. You agree to use our Service for lawful purposes only and comply with all applicable laws and regulations in your use of the Service.</p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">4. Payment and Invoicing</h2>
        <p class="mb-4">Services are invoiced at the end of each month. Payment is due upon receipt of the invoice. Failure to make timely payments may result in termination of services. We offer various pricing plans to suit your needs. Details of these plans are available on our website.</p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">5. Data Security and Credential Management</h2>
        <p class="mb-4">We implement multiple layers of encryption for the storage and handling of your credentials. Access to your encrypted credentials is strictly limited and controlled through User Access Control (UAC) mechanisms.</p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">6. Intellectual Property Rights</h2>
        <p class="mb-4">All intellectual property rights in the Service and its content, features, and functionality are owned by us, our licensors, or other providers of such material and are protected by copyright and intellectual property laws.</p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">7. Termination</h2>
        <p class="mb-4">We may terminate or suspend your access to our Service immediately, without prior notice or liability, for any reason, including non-payment of invoices.</p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">8. Changes to Terms</h2>
        <p class="mb-4">We reserve the right to modify or replace these Terms at any time. We will provide notice of any changes by posting the new Terms on our website.</p>

        <h2 class="text-2xl font-semibold mt-8 mb-4">9. Contact Information</h2>
        <p class="mb-4">If you have any questions about these Terms, please contact us at info@creatorxone.com.</p>

        <p class="text-center text-sm mt-8 mb-4">© 2023 MSB Media, s.r.o. All rights reserved.</p>
    </div>
</template>

<script>
    export default {
        
    }
</script>