<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full mb-4 relative">
      <router-link :to="'../Settings'">
        <font-awesome-icon icon="fas fa-arrow-left" class="absolute float-left text-lg p-2 text-button -mt-2" />
      </router-link>
      <h2 class="mt-2 text-xl text-contentText font-semibold text-center">Archived models</h2>
    </div>

    <div>
        <table class="text-tableText bg-tableBG" id="modelTable">
            <tr>
                <th class="w-18">ID</th>
                <th class="cursor-pointer" @click="sortTable('code','ASC')" >Code &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'code'}" /></th>
                <th class="w-24 cursor-pointer" @click="sortTable('platform','ASC')">Platform <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'platform'}" /></th>
                <th class="cursor-pointer w-1/6" @click="sortTable('identifier','ASC')" >Group &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'identifier'}" /></th>
                <th class="cursor-pointer w-1/4" @click="sortTable('free.name','ASC')" >Name &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'free.name'}" /></th>
                <th class="">Return from archive</th>
            </tr>
            <slot v-for="(model, index) in archivedModels" :key="index">
                <tr v-if="model.free" class="cursor-pointer hover:bg-sepBorder" v-bind:class="{archived:model.free.active == 1,'bg-tableRow1':index%2==0}">
                    <td @click="navigateToModelForm(model)">{{ model.free.id }}</td>
                    <td @click="navigateToModelForm(model)">
                        <img v-if="model.platform==1" src="@/assets/images/of_logo.png" title="Onlyfans" alt="Onlyfans" class="h-5 mx-auto"/>
                        <img v-else-if="model.platform==2" src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="h-5 mx-auto"/>
                    </td>
                    <td @click="navigateToModelForm(model)">{{ model.code }}</td>
                    <td @click="navigateToModelForm(model)">{{ model.identifier }}</td>
                    <td @click="navigateToModelForm(model)">{{ model.free.name }}</td>
                    <td class="cursor-pointer" @click="modelDeArchive(index)" v-if="model.free.active == 0">Return from archive <font-awesome-icon icon="fas fa-undo" /></td>
                    <td class="cursor-pointer" @click="modelArchive(index)" v-else>Archive <font-awesome-icon icon="fas fa-archive" /></td>
                </tr>
            </slot>
        </table>
    </div>
</template>

<script>
    import { get_models,archive_model } from "@/api"
    export default {
        data() {
            return {
                archivedModels: null,
                emplID: null,
                sortColumn: null,
                loading: false
            }
        },
        methods: {
            navigateToModelForm(model) {
                this.$router.push({ name: 'modelsForm', query: {routedFrom: 'archived', e:true, id: model.pair_id} });
            },
            sortTable(column,order) {
                this.sortColumn = column
                if (this.$cookies.get('orderColumnM') != column) {
                    this.$cookies.set('orderColumnM',column);
                }
                const properties = column.split('.');

                this.modelData.sort((a, b) => {
                    const valueA = properties.reduce((obj, key) => obj[key], a);
                    const valueB = properties.reduce((obj, key) => obj[key], b);

                    if (typeof valueA === 'number' && typeof valueB === 'number') {
                        // Both values are numbers. Do numerical comparison.
                        return order === 'ASC'
                            ? valueA - valueB
                            : valueB - valueA;
                    } else {
                        // Convert the values to strings and do string comparison.
                        const stringA = String(valueA);
                        const stringB = String(valueB);
                        return order === 'ASC'
                            ? stringA.localeCompare(stringB, undefined, { sensitivity: 'base' })
                            : stringB.localeCompare(stringA, undefined, { sensitivity: 'base' });
                    }
                });
            },
            async modelArchive(index) {
                if (confirm("Are you sure you want to archive this model?")) { 
                    await archive_model(this.$cookies.get('userToken'),this.$cookies.get('c_id'),0,this.archivedModels[index].pair_id);
                    this.archivedModels[index].free.active=0 
                }
            },
            async modelDeArchive(index) {
                if (confirm("Are you sure you want to return this model from archive?")) { 
                    await archive_model(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,this.archivedModels[index].pair_id);
                    this.archivedModels[index].free.active=1;
                }
            }
        },
        async created() {
            this.loading = true;
            this.archivedModels = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),0);
            this.loading = false;
        },
    }
</script>

<style scoped>
    td,th {
        padding:5px;
        border:1px solid #D1D5DB;
        text-align:center;
    }
</style>