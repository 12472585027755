<template>
    <div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 left-1/2" v-if="saved">{{ $t('saved') }}</div>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>

    <div class="w-full pb-10 relative">
        <router-link :to="'../../Settings'" v-if="!noarrow">
            <font-awesome-icon icon="fas fa-arrow-left" class="text-lg p-2 text-button -mt-1" />
            <br />
        </router-link>
        <h1 class="text-xl md:text-2xl text-contentText font-semibold">Setup your shift schedule</h1>
    
        <section class="w-full sm:max-w-3xl grid md:grid-cols-2 gap-5 sm:gap-10">
            <div class="pt-5 sm:pt-10">
                <h2 class="text-sm md:text-lg font-semibold mb-2">Efficient Shift Management for Social Sales Teams</h2>
                <p class="max-w-xl text-xs sm:text-sm">Our innovative shift scheduling dashboard is designed to accommodate the dynamic needs of sales teams managing social media accounts across various time zones. </p>
                <ul class="text-sm md:text-base list-decimal ml-5 mt-5 leading-loose max-w-xl">
                    <li>Ensure your time zone is accurate.</li>
                    <li>Add your shifts sequentially by clicking "Add another shift" for additional time slots.</li>
                    <li>Once your schedule is set, click "Save" to finalize your shifts.</li>
                </ul>
            </div>
            <div class="md:mt-5 mb-6 ml-0 max-w-md">
                <div class="mb-3">
                    <label class="mr-3 text-sm font-bold p-2">Time zone: <span class="font-normal">UTC {{selectedTimeZone}}</span></label>
                </div>

                <div v-for="(shift, index) in shiftsData" :key="index" class="flex items-center mb-3">
                    <label class="mr-3 text-sm font-bold p-2.5">Shift {{index+1}}</label>
                    <VueDatePicker v-model="shift.time" class="flex-1" time-picker disable-time-range-validation range placeholder="Select Time" />
                    <font-awesome-icon v-if="index === shiftsData.length-1" @click="removeShift" :icon="['fas', 'trash-can']" class="ml-3 cursor-pointer p-2 transition ease-in-out hover:scale-105 text-decline" />
                </div>
                <button @click="addShift" class="mt-4 block sm:content transition ease-in-out rounded-xl p-1.5 px-6 font-semibold shadow hover:shadow-lg hover:scale-105 text-sm sm:text-base border-growth border-2 text-growth">Add another shift</button>    
            </div>
        </section>

        <button class="p-2 px-4 bg-growth rounded text-buttonText absolute right-0 font-semibold" @click="saveChange()"><font-awesome-icon v-if="loading" icon="fas fa-spinner" class="animate-spin"/> Save shifts schedule</button>
    </div>
</template>

<script>
    export default {
        props: ['noarrow'],
        emits: ['close'],
        data() {
            return { 
                shiftsData: [],
                loading: false,
                saved: false,
                selectedTimeZone: this.getClientTimeZoneOffset(),
            } 
        },
        methods: {
            timezoneOptions() {
                return this.$moment.tz.names().map(timezone => ({
                    value: this.$moment.tz(timezone).utcOffset() / 60,
                    label: `UTC ${timezone.split('/').pop().replace(/_/g, ' ')} (${this.$moment.tz(timezone).format('Z')})`
                })).sort((a, b) => a.value - b.value);
            },
            getClientTimeZoneOffset() {
                return this.$moment().utcOffset() / 60;
            },
            processShiftsData(shiftsData) {
                return shiftsData.map(shift => ({
                    time: [shift.time.from, shift.time.to].map(this.convertUTCToTimeObject),
                }));
            },
            convertUTCToTimeObject(timeString) {
                const localTime = this.$moment.utc(timeString, "HH:mm:ss").local();
                return { hours: localTime.hour(), minutes: localTime.minute(), seconds: localTime.second() };
            },
            saveChange() {
                console.log(this.shiftsData);
                const shiftsDataUTC = this.shiftsData.map(shift => ({
                time: shift.time.map(time => {
                    // Assuming time is a Date object or can create one directly
                    const date = new Date();
                    date.setHours(time.hours, time.minutes, time.seconds, 0);

                    // Convert to UTC string and format
                    const utcTime = date.toISOString().split('T')[1].substring(0, 8);
                    return utcTime;
                }),
                }));

                console.log(shiftsDataUTC);

                this.loading = true;
                this.$axios.post(baseURL + "shifts/", {
                    shiftData: shiftsDataUTC,
                    timeZone: 2,
                    action: 'saveShiftsSettings',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then(() => {
                    this.saved = true;
                    setTimeout(() => this.saved = false, 1000);
                }).catch(console.log)
                .finally(() => this.loading = false, setTimeout(() => this.$emit('close'), 1500));
            },
            addShift() {
                this.shiftsData.push({ time: [{ hours: 0, minutes: 0, seconds: 0 }, { hours: 0, minutes: 0, seconds: 0 }] });
            },
            removeShift() {
                if (this.shiftsData.length > 1) {
                    this.shiftsData.pop();
                }
            }
        },
        created() {
            this.$axios.get(baseURL + "shifts/", {
                params: {
                    build: 'getShiftsSettings',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then(result => {
                if (result.data && result.data.shiftsData) {
                    // Process each shift's time from UTC to local time
                    this.shiftsData = this.processShiftsData(result.data.shiftsData);
                    console.log(this.shiftsData);
                } 
            }).catch(error => {
                console.log(error);
            });
            //this.shiftsData = [ {time: [{ "hours": 19, "minutes": 40, "seconds": 0 }, { "hours": 19, "minutes": 50, "seconds": 0 }]}, {time: [{ "hours": 19, "minutes": 50, "seconds": 0 }, { "hours": 19, "minutes": 50, "seconds": 0 }]} ]
        }
    }
</script>


