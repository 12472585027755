<template>
    <div>
        <div class="bg-green-300 rounded-full text-black p-2 fixed z-50 top-5 px-6 left-1/2" v-if="saved">{{ $t('saved') }}</div>

        <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   
        <div class="w-full mb-4 relative">
            <h2 class="mt-2 text-xl text-contentText font-semibold text-center">{{ $t('session_management') }}</h2>
        </div>
        
        <div v-if="Array.isArray(session)" class="overflow-auto">
            <slot v-for="(account,i2) in session[0]?.accounts" :key="i2">
                <table class="text-tableText bg-tableBG w-full">
                    <thead>
                        <tr class="bg-tableRow1">
                            <th class="px-3">{{ $t('type') }}</th>
                            <th class="w-24">{{ $t('status') }}</th>
                            <th colspan="2" class="w-10">{{ $t('actions') }}</th>
                            <th class="px-3">{{ $t('on_session') }}</th>
                        </tr>
                    </thead>
                    <tr v-for="(as,asI) in account.sessions" :key="asI">
                        <td class="p-0.5 px-3">{{as.gologin_note}}</td>
                        <td class="p-0.5 px-3 cursor-pointer">
                            <tooltip @click="UnlockSession(0,i2,asI)" v-if="as.locked" :msg="$t('unlock_this_session')" icon="fas fa-lock" iconClass="text-yellow-500" />
                            <tooltip v-else :msg="$t('unused_session')" icon="fas fa-lock-open" classBand="cursor-default" iconClass="text-green-500" />
                        </td>
                        <td class="p-0.5 px-2 cursor-pointer" @click="refreshSession(0,i2,asI)">
                            <tooltip :msg="$t('refresh_session')" icon="fas fa-arrows-rotate" />
                        </td>
                        <td v-if="account.sessions.length<4 && !as.locked" class="p-0.5 px-3 cursor-pointer" @click="switchSession(0,i2,asI)">
                            <tooltip :msg="$t('switch_session')" icon="fas fa-shuffle" />
                        </td>
                        <td v-else></td>
                        <td class="w-96"><span v-if="as.userEmail">{{as.userEmail}}</span><span v-else class="text-green-600">{{ $t('not_used') }}</span></td>
                    </tr>
                </table>
            </slot>
        </div>
    </div>
</template>
<script setup>
    import Tooltip from '@/components/toolTip.vue';
    import { get_sessions } from '@/api.js';
</script>
<script>
    export default {
        components: {
            Tooltip
        },
        props: ['model_pair_id'],
        emits: ['close'],
        data() {
            return {
                loading:false,
                session: [],
                saved:false
            }
        },
        methods: {
            switchSession(i1,i2,i3) {
                let tag = prompt(this.$t('switch_session_confirm'), this.$t('sexter'));
                if (tag == 'sexter' || tag == 'droper' || tag == 'admin' || tag == 'backup') {
                    this.$axios.post(baseURL+"settingspost/", {
                        action: 'switchSession',
                        sessionID: this.session[i1].accounts[i2].sessions[i3].ID,
                        newNote: tag,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        this.refreshSession(0,i2,i3);
                    }).finally(() => this.loading = false);
                } else {
                    alert(this.$t('session_unrecognized'));
                }
            },
            UnlockSession(i1,i2,i3) {
                if (confirm(this.$t('session_unlock_warning'))) {
                    this.$axios.post(baseURL+"settingspost/", {
                        action: 'unlockSession',
                        sessionID: this.session[i1].accounts[i2].sessions[i3].ID,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        this.refreshSession(0,i2,i3);
                    }).finally(() => this.loading = false);
                }
            },
            refreshSession(i1,i2,i3) {
                this.loading = true;
                this.$axios.get(baseURL+"settingsget/", {
                    params: {
                        action: 'refreshSession',
                        sessionID: this.session[i1].accounts[i2].sessions[i3].ID,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.session[i1].accounts[i2].sessions[i3] = result.data
                }).finally(() => setTimeout(() => {this.loading = false;}, 500));
            }
        },
        async created() {
            this.loading = true
            this.session = await get_sessions(this.$cookies.get('userToken'), this.$cookies.get('c_id'), this.model_pair_id);
            this.loading = false;
        }
    }
</script>

<style scoped>
  td, th {
    padding:5px;
    border:1px solid rgba(80, 80, 80, 0.2);
    text-align:center;
  }
</style>