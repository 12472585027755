<template>
    <h3 class="text-lg mb-5 font-bold sticky top-0 bg-white z-20">Claimed Subscribers</h3>
    <div class="overflow-auto max-h-[calc(100vh-12rem)]">
        <table v-if="claims_list.length>0" class="text-tableText text-center whitespace-nowrap rounded font-normal border border-sepBorder table-auto w-full">
            <thead class="sticky top-0 bg-white z-10 border border-sepBorder">
                <tr class="border-b border-sepBorder">
                    <th></th>
                    <th class="p-2">Name</th>
                    <th class="p-2">Subscription Date</th>
                    <th class="p-2">Purchases</th>
                    <th class="p-2">Last Purchase</th>
                    <th class="p-2">Avg. Spent</th>
                    <th class="p-2">Max. Spent</th>
                    <th class="p-2">Total Spent</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(claim,index) in claims_list" :key="index" ref="claims" class="border-b">
                    <td class="text-center w-10 px-2 py-1"><div  class="rounded-full h-10 w-10" :style="{ backgroundImage: `url(${claim.avatar})`,backgroundColor:'#d6d6d6', backgroundSize: 'cover', backgroundPosition: 'center' }"></div></td>
                    <td class="text-left pl-1">
                        <div class="text-sm font-bold ellipses truncate w-28">{{ claim.display_name }}</div><p class="text-xs">{{ claim.username }}</p>
                    </td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>$ {{ claim.total_spent }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="text-center mt-5 h-12">
        <slot v-if="band==true">
            <div class=" cursor-pointer animate-bounce text-white bg-white p-3 px-4 border-2 border-growth rounded-full absolute left-1/2 -ml-6"  @click="loadMore()">
                <font-awesome-icon icon="fa-solid fa-angles-down" class="text-growth" />
            </div>
        </slot>
        <slot v-else-if="band=='processing'">
            <font-awesome-icon :icon="'fa-solid fa-spinner'" class="animate-spin"/>
        </slot>
        <slot v-else-if="band=='nothing'">
            <div class="text-center">
                <h3>{{claims_list?.length>0 ? 'End of results' : 'No claimed subscribers found'}}</h3>
            </div>
        </slot>
    </div>
    <div class="float-right text-textOnBkg -mt-8">
        <button class="bg-button text-white ml-5 border border-sepBorder rounded p-1 px-3 -mt-1 cursor-pointer" @click="exportToCSV"><span ref="exportLoading" class="hidden"><font-awesome-icon icon="fas fa-spinner" class="animate-spin mr-2" /></span>Export to CSV</button>
      </div>
</template>
<script setup> 
    import { getUserClaims } from "@/api";
</script>
<script>
    export default {
        props: ['linkID','modelID','companyID'],
        data(){
            return{
                claims_list: [],
                band: 'processing',
                noItems: false,
                offset: 0,
                limit: 10
            }
        },
        methods:{
            async setUserClaims(the_ID,offset=0,limit=10){
                //let claims = await getUserClaims(this.$cookies.get('userToken'),7,3326,524869,offset,limit);
                let claims = await getUserClaims(this.$cookies.get('userToken'),this.companyID,this.modelID,the_ID,offset,limit);

                if(claims.data.length==0){
                    this.band = 'nothing';
                    return;
                }

                this.claims_list = this.claims_list.concat(claims.data);
                this.band = claims.hasMore;
            },
            async loadMore(){
                this.band = 'processing';
                this.offset +=10;
                this.limit +=10;
                
                await this.setUserClaims(this.linkID,this.offset,this.limit);
            },
            exportToCSV(){
                this.$refs.exportLoading.classList.remove('hidden');
                let csv = 'Name,Username,Subscription Date,Purchases,Last Purchase,Avg. Spent,Max. Spent,Total Spent\n';
                this.claims_list.forEach((row) => {
                    csv += `${row.display_name},${row.username},-,-,-,-,-,${row.total_spent}\n`;
                });
                let hiddenElement = document.createElement('a');
                hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
                hiddenElement.target = '_blank';
                hiddenElement.download = 'claimed_subscribers.csv';
                hiddenElement.click();
                this.$refs.exportLoading.classList.add('hidden');
            
            }
        },
        async mounted(){
            this.claims_list = [];
            await this.setUserClaims(this.linkID);
            console.log(this.claims_list);
        }
    }
</script>