<template>
    <div v-show="sent" class="fixed w-48 left-1/2 -ml-24 top-5 z-10 bg-growth text-center p-2 rounded-full text-white font-semibold shadow">{{ $t('successfully_sent') }}</div>
    <div class="grid sm:grid-cols-3 gap-5 gap-x-10 relative mt-6 sm:mb-10">
        <section class="bg-button sm:fixed p-5 sm:p-10 sm:py-14 sm:-mt-7 sm:-ml-32 rounded text-white" style="box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 10px 3px, inset rgba(0, 0, 0, 0.1) 2px 2px 10px 3px" >
            <div class="flex items-center">
                <div class="mr-4 w-8 text-center">
                    <font-awesome-icon icon="fa-solid fa-location-dot" class="text-2xl text-white" />
                </div>
                <div>
                    MSB Media, Ltd.<br />
                    Puškinova 8, Košice<br />
                    Slovakia
                </div>
            </div>
            <div class="flex items-center my-4 sm:my-8">
                <div class="mr-4 w-8 text-center">
                    <font-awesome-icon icon="fa-solid fa-phone" class="text-2xl text-white" />
                </div>
                <a href="tel:+421904040616" class="text-white no-underline">+421 904 040 616</a>
            </div>
            <div class="flex items-center my-4 sm:my-8">
                <div class="mr-4 w-8 text-center">
                    <font-awesome-icon icon="fa-regular fa-envelope" class="text-3xl text-white" />
                </div>
                <div>
                    <a href="mailto:info@creatorxone.com" class="text-white no-underline">info@creatorxone.com</a>
                </div>
            </div>
            <div class="mt-5 sm:mt-10 flex gap-5 w-max mx-auto">
                <a target="_blank" href="https://www.instagram.com/creatorxone/" class="text-white no-underline"><font-awesome-icon icon="fa-brands fa-instagram" class="text-3xl" /></a>
                <a target="_blank" href="https://www.facebook.com/profile.php?id=61557008110530" class="text-white no-underline"><font-awesome-icon icon="fa-brands fa-square-facebook" class="text-3xl" /></a>
                <a target="_blank" href="https://twitter.com/creatorxone" class="text-white no-underline"><font-awesome-icon icon="fa-brands fa-x-twitter" class="text-3xl" /></a>
            </div>
        </section>
        <div class="sm:col-start-2 sm:col-span-2">
            <h2 class="text-button text-xl sm:text-2xl font-semibold mb-2">{{ $t('send_a_message') }}</h2>
            <input type="text" v-model="emailForm.name" class="my-2 w-full border-b border-sepBorder p-1 rounded" :placeholder="$t('name')" />
            <input type="text" v-model="emailForm.subject" class="my-2 w-full border-b border-sepBorder p-1 rounded" :placeholder="$t('subject')" />
            <input type="email" v-model="emailForm.email" class="my-2 w-full border-b border-sepBorder p-1 rounded" :placeholder="$t('reg_form.email')" />
            <textarea v-model="emailForm.message" class="my-2 mt-3 w-full border-b border-sepBorder shadow p-1 px-2 rounded outline-0" style="max-height:70px;min-height:70px" :placeholder="$t('message')" />
            <button @click="sendMail" v-bind:disabled="isDisabled" class="bg-button font-semibold text-white py-1 px-4 text-lg rounded shadow" :class="{'opacity-50 cursor-not-allowed':isDisabled}">{{ $t('send') }}</button>
        </div>
    </div>
</template>

<script>
    import { sendMail } from "@/api.js";
    export default {
        emits: ['close'],
        data() {
            return {
                emailForm:{
                    name:null,
                    subject:null,
                    email:null,
                    message:null,
                },
                sent: false
            }
        },
        computed: {
            isDisabled(){
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return this.emailForm.name === null || this.emailForm.subject === null || this.emailForm.email === null || this.emailForm.message === null || !emailRegex.test(this.emailForm.email);
            }
        },
        methods: {
            async sendMail(){
                if (this.isDisabled) {
                    return;
                }
                console.log(this.emailForm);
                const mail = await sendMail(this.emailForm);
                if (mail?.success === true) {
                    this.sent = true;
                    setTimeout(() => { this.sent = false; }, 1500);
                    this.$emit('close');
                } else {
                    this.sent = true;
                }
            }
        }
    }
</script>