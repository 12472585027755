<template>
    <div v-if="!visible" @click="visible=true" class="cursor-pointer bg-button text-buttonText px-3 rounded w-max text-center mt-3"><font-awesome-icon icon="fas fa-plus" /> Add a {{label}}</div>
    <div v-else class="cursor-text mt-0 border border-sepBorder pb-1 px-3 rounded w-max mt-3">
        <label class="text-xs">{{label}}</label><br />
        <input type="text" class="border-b border-sepBorder pb-1" v-model="value" ref="new" @keydown.enter="add()" placeholder="Name" autofocus /><br />
        <span class="text-sm float-right">*Press enter to save</span>
        <div class="clear-both"></div>
    </div>
</template>

<script>
    export default {
        props: ['label','type','groupID','index'],
        data() {
            return {
                value: null,
                visible:false
            }
        },
        methods: {
            add() {
                if (this.value) {
                    this.$axios.post(baseURL+"groups/", {
                        groupID: this.groupID,
                        name: this.value,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then(result => {
                        this.$emit('newGroup', this.value, result.data.newID, this.groupID);
                        this.value = null;
                    }, (error) => {
                        console.log(error);
                    })
                }
            },
        }
    }
</script>