<template>
    <div v-if="toolDetail.show">
        <font-awesome-icon @click="toolDetail.show=false" icon="fas fa-arrow-left" class="cursor-pointer float-left text-lg p-2 text-button -mt-2" />
        <tool-repost v-if="toolDetail.id == 7" :model="model" />
        <searchforadults v-else-if="toolDetail.id == 6" :model="model" />
    </div>
    <div v-else>
        <div class="text-xl text-contentText font-bold text-center text-button">{{ $t('tools_text.main_h1') }}</div>
        <section class="mt-3 grid grid-cols-1 md:grid-cols-2 gap-10">
            <div class="hidden md:block">
                <slot v-if="model.platform == 1">
                    <h3 class="text-xl font-bold leading-relaxed mt-2">{{ $t('tools_text.h1') }}</h3>
                    <p class="text-sm">{{ $t('tools_text.p1') }}</p>
                    <h3 class="text-xl font-bold leading-relaxed mt-2">{{ $t('tools_text.h2') }}</h3>
                    <p class="text-sm">{{ $t('tools_text.p2') }}</p>
                    <h3 class="text-xl font-bold leading-relaxed mt-2">{{ $t('tools_text.h3') }}</h3>
                    <p class="text-sm">{{ $t('tools_text.p3') }}</p>
                    <h3 class="text-xl font-bold leading-relaxed mt-2">{{ $t('tools_text.h4') }}</h3>
                    <p class="text-sm">{{ $t('tools_text.p4') }}</p>
                    <h3 class="text-xl font-bold leading-relaxed mt-2">{{ $t('tools_text.h5') }}</h3>
                    <p class="text-sm">{{ $t('tools_text.p5') }}</p>
                </slot>
                <slot v-if="model.platform == 2">
                    <h3 class="text-xl font-bold leading-relaxed mt-2">{{ $t('tools_text.h3') }}</h3>
                    <p class="text-sm">{{ $t('tools_text.p3') }}</p>
                    <h3 class="text-xl font-bold leading-relaxed mt-2">{{ $t('tools_text.h6') }}</h3>
                    <p class="text-sm">{{ $t('tools_text.p6') }}</p>
                </slot>
            </div>
            <div class="w-full md:w-max mt-3">
                <div class="grid grid-cols-3 items-center w-full p-2 px-3 rounded bg-gray-100">
                    <div class="rounded-full h-16 w-16 bg-gray-200" :style="{ backgroundImage: `url(${model.avatar})`, backgroundSize: 'cover', backgroundPosition: 'center' }"></div>
                    <div class="ml-6 col-span-2">
                        <h2 class="font-bold truncate text-sm">{{model.username ? '@' + model.username : ''}}</h2>
                        <div class="my-1">
                            <slot v-if="model.platform==1"><span class="hidden">O</span><img src="@/assets/images/of_logo.png" title="Onlyfans" alt="Onlyfans" class="h-5"/></slot>
                            <slot v-else-if="model.platform==2"><span class="hidden">F</span><img src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="h-5"/></slot>
                        </div>
                    </div>
                </div>
                <slot v-for="(data,index) in model.free.tools" :key="index">
                    <div class="justify-between items-center my-1.5 leading-loose border border-sepBorder p-1 px-5 rounded flex" @click="openToolSettings(data.id)" :class="{'cursor-pointer hover:bg-sepBorder':data.id == 7 || data.id == 6}">
                        <div class="leading-normal">
                            <strong class="font-semibold">{{data.name}}</strong><br />
                            <small class="font-lighter" :class="{'text-growth font-semibold' : data.price == 0}">
                                {{data.id == 6 ? data.price + $t('sfa_conditions') : data.price>0 ? '$ ' + data.price + '/' + $t('month') : $t('included')}}
                            </small>
                        </div>
                        <font-awesome-icon @click.stop="changeToolsValue(index)" :icon="squareIcon(data.active)" class="text-2xl p-1 ml-5 cursor-pointer" :class="{'text-growth': data.active}" />
                    </div>
                </slot>
            </div>
        </section>
    </div>
</template>

<script setup>
    import toolRepost from '@/components/settings/tools/repost.vue';
    import searchforadults from '@/components/settings/tools/searchforadults.vue';
</script>
<script>
    import { api_get_call, update_tools } from '@/api.js'; 
    export default {
        props: ['model'],
        components: { toolRepost, searchforadults},
        data() {
            return {
                toolDetail: {show:false, id:0},
            }
        },
        methods: {
            openToolSettings(id) {
                if (id == 7 || id == 6) {
                    this.toolDetail.show = true;
                    this.toolDetail.id = id;
                }
            },
            squareIcon(condition) {
                return condition==0 ? 'fas fa-toggle-off' : 'fas fa-toggle-on';
            },
            async changeToolsValue(index) {
                this.loading = true;

                if (this.model.free.tools[index].id == 6) {
                    let SFA_link = await api_get_call(`${baseURL}models/?action=sfa_link&model_id=${this.model.free.id}&userToken=${this.$cookies.get('userToken')}&companyID=${this.$cookies.get('c_id')}`);
                    if (SFA_link == false) {
                        console.log(this.$t('no_tracking_links'));
                        this.openToolSettings(6);
                        return;
                    }
                }

                let toolData = {
                    model_id: this.model.free.id,
                    id: this.model.free.tools[index].id,
                    value: Number(!this.model.free.tools[index].active)
                };

                let tool_result = await update_tools(this.$cookies.get('userToken'), this.$cookies.get('c_id'), toolData);
                if (tool_result.success != false) {
                    this.model.free.tools[index].active = Number(!this.model.free.tools[index].active);
                } else {
                    alert(tool_result.message + "\n" + this.$t('try_again'));
                }
            },
        }
    }
</script>