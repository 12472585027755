<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div v-if="modelData.length>0" class="grid grid-cols-3 text-center px-5 border border-sepBorder py-1 rounded-t gap-y-3">
        <div class="border-b border-sepBorder py-2 text-left">Name</div>
        <div class="border-b border-sepBorder py-2">Platform</div>
        <div class="border-b border-sepBorder py-2">ID</div>
        <div v-for="(model, index) in modelData" :key="index" @click="open_model(index)" class="col-span-3 grid grid-cols-3 cursor-pointer p-1 px-4" :class="{'border-t border-sepBorder': index>0}">
            <div class="flex items-center -mt-1 py-2">
                <template v-if="model.avatar">
                    <div class="rounded-full h-8 w-8" :style="{ backgroundImage: `url(${model.avatar})`, backgroundSize: 'cover', backgroundPosition: 'center' }"></div>
                </template>
                <template v-else>
                    <div class="bg-gray-200 rounded-full h-8 w-8"></div>
                </template>
                <span class="pl-3">{{ model.free.name }}</span>
            </div>
            <div class="items-center py-2">
                <slot v-if="model.platform==1"><span class="hidden">O</span><img src="@/assets/images/of_logo.png" title="Onlyfans" alt="Onlyfans" class="h-5 mx-auto"/></slot>
                <slot v-else-if="model.platform==2"><span class="hidden">F</span><img src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="h-5 mx-auto"/></slot>
            </div>
            <div class="py-2">{{model.free.id}}</div>
        </div>
    </div>
    <div v-else class="text-center text-contentText text-xl font-bold">No creator accounts<br /><span class="text-base font-normal">To access this section, add a creator account.</span></div>

    <slot v-if="dialogWindow">
        <div class="fixed w-full h-full left-0 top-0 bg-black bg-opacity-60 block z-40" @click="dialogWindow = false"></div>
        <section class="absolute sm:inset-x-1/4 block p-10 bg-tooltipBG text-tooltipText z-50 rounded-lg w-full sm:w-144 mx-auto h-auto top-0">
            <font-awesome-icon icon="fas fa-xmark" @click="dialogWindow = false" class="cursor-pointer absolute right-3 top-3 p-2" />
            <slot v-if="dialogData">
                <h2 class="text-center mb-2">Willingness list for <strong>{{dialogData.free.name}}</strong></h2>
                <section class="overflow-auto">
                    <slot v-for="(v,i) in willingnessList" :key="i">
                        <div @click="dialogWillingness[i] = (parseInt(dialogWillingness[i])-1)*(-1)" class="leading-looser cursor-pointer">
                            <font-awesome-icon v-if="dialogWillingness && dialogWillingness[i] == 0" class="mr-2" icon="fas fa-square" /><font-awesome-icon v-else class="mr-2 text-green-600" icon="fas fa-square-check" /> {{v.name}}
                        </div>
                    </slot>
                </section>
                <button @click="saveWillingness(dialogData.pair_id)" class="mt-5 delay-75 duration-75 transition-all bg-button rounded cursor-pointer p-2 px-7 text-buttonText float-right text-center font-semibold uppercase">
                    <font-awesome-icon icon="fas fa-spinner" class="animate-spin" v-if="loading" /> Save
                </button>
            </slot>
        </section>
    </slot>

    <transition name="zoom-fade">
        <section v-if="modal.display" @click="modal.display = false" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
            <section @click.stop class="bg-white rounded-lg w-full max-w-md sm:max-w-5xl mx-auto my-auto p-8 sm:p-12 z-50 relative">
                <font-awesome-icon @click="modal.display = false" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
                <tools-modal v-if="modal.type == 'tools' && selectedModelData" :model="selectedModelData" />
            </section>
        </section>
    </transition>
</template>

<script setup>
    import toolsModal from '@/components/settings/models/tools.vue';
</script>

<script>
    import { get_models } from '@/api';
    export default {
        components: { toolsModal },
        data() {
            return { 
                modelData: [],
                selectedModelData: null,
                sortColumn: null,
                loading:false,
                dialogWindow:false,
                dK:null,
                dialogData:null,
                willingnessList:[],
                dialogWillingness: [],
                modal: {display:false, type:''}
            }
        },        
        methods: {
            async open_model(index) {
                this.modal = {display:true, type:'tools'};
                this.selectedModelData = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),null,this.modelData[index].pair_id);
                console.log("model clicked", this.modelData[index]);
            },
        },
        async created() {
            this.loading = true;
            this.modelData = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1);
            this.loading = false;
            if (this.$cookies.get('orderColumnE')) {
                this.sortTable(this.$cookies.get('orderColumnM'),'ASC')
            }
        }
    }
</script>