<template>
    <section class="text-contentText py-2 sm:py-10 my-2 border border-white">
        <section class="container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-5 px-5 sm:px-2 mt-5">
            <div class="pr-2 pb-5 w-full">
                <h2 class="font-bold text-2xl sm:text-4xl lg:text-4xl" v-html="$t('pricing_section.headline')"></h2>
                <h3 class="text-base sm:text-xl mt-3">{{ $t('pricing_section.headline_sub') }}</h3>
                <img alt="analytics and growth" class="h-36 xl:w-4/5" src="@/assets/images/undraw_visual_data_re_mxxo.svg" />
            </div>
            <div>
                <div v-for="(pricingOption,index) in pricing" :key="index" class="grid grid-cols-3 sm:grid-cols-4 w-max mb-5 sm:mb-8 gap-1 gap-x-3 sm:gap-2">
                    <h3 class="text-lg sm:text-2xl font-semibold col-span-3 sm:col-span-full">{{pricingOption.name}}</h3>
                    <button v-for="(button,bIndex) in pricingOption.options" :key="bIndex" @click="selectedPricing = [index,bIndex]" class="block sm:content transition ease-in-out rounded-xl p-1.5 px-6 font-semibold shadow hover:shadow-lg hover:scale-105 text-sm sm:text-base border-growth border-2" :class="isSelected(index, bIndex) ? 'text-growth bg-transparent' : 'text-white bg-growth'">{{button.name}}</button>
                </div>
            </div>

            <section class="bg-white md:col-span-2 xl:col-span-1 rounded-xl shadow-lg text-black p-4 sm:p-6 px-5 sm:px-8 min-w-lg">
                <h3 class="text-base sm:text-lg font-light">{{ pricing[selectedPricing[0]]?.name }}</h3>
                <h3 class="text-xl sm:text-2xl font-regular">{{ pricing[selectedPricing[0]]?.options[selectedPricing[1]]?.name }}</h3>
                <h2 class="text-2xl sm:text-3xl font-bold mt-2">$ {{ pricing[selectedPricing[0]]?.options[selectedPricing[1]]?.price }} <span class="font-light text-gray-700"> {{ $t('pricing_section.per_month') }}</span></h2>
                <ul class="list-disc ml-5 mt-3 leading-8">
                    <li v-for="(feature,fIndex) in pricing[selectedPricing[0]]?.options[selectedPricing[1]]?.features" :key="fIndex">{{feature}}</li>
                </ul>
                <button @click="$router.push({ path: '/', query: { open: 'register' } });" class="block sm:content transition ease-in-out rounded-xl p-1.5 px-6 font-semibold shadow hover:shadow-lg hover:scale-105 text-sm sm:text-base border-growth border-2 text-white bg-growth mx-auto mt-2">{{ $t('pricing_section.start_free_trial') }}</button>
            </section>
            <div class="bg-white rounded-xl shadow-lg w-full py-0.5 sm:col-span-1 md:col-span-2 lg:col-span-3 mt-5 text-center sm:mt-7 text-gray-700 text-base sm:text-lg">{{ $t('pricing_section.custom') }} <span @click="sendMail()" class="underline cursor-pointer">{{ $t('click_here') }}</span>.</div>
        </section>
    </section>
</template>

<script>
export default {
  name: 'Pricing',
  data() {
    return {
        selectedPricing:[0,0],
        pricing: [
            {
                name:"Onlyfans",
                options:[
                    {
                        name: this.$t('pricing_section.packages[0].name'),
                        price: 29.99,
                        features: [
                            this.$t('pricing_section.packages[0].features[0]'),
                            this.$t('pricing_section.packages[0].features[1]'),
                            this.$t('pricing_section.packages[0].features[2]'),
                            this.$t('pricing_section.packages[0].features[3]'),
                            this.$t('pricing_section.packages[0].features[4]'),
                            this.$t('pricing_section.packages[0].features[5]'),
                            this.$t('pricing_section.packages[0].features[6]')
                        ]
                    },
                    {
                        name: this.$t('pricing_section.packages[1].name'),
                        price: 49.99,
                        features: [
                            this.$t('pricing_section.packages[1].features[0]'),
                            this.$t('pricing_section.packages[1].features[1]'),
                            this.$t('pricing_section.packages[1].features[2]'),
                            this.$t('pricing_section.packages[1].features[3]'),
                            this.$t('pricing_section.packages[1].features[4]'),
                            this.$t('pricing_section.packages[1].features[5]')
                        ]
                    },
                    {
                        name: this.$t('pricing_section.packages[2].name'),
                        price: 59.99,
                        features: [
                            this.$t('pricing_section.packages[2].features[0]'),
                            this.$t('pricing_section.packages[2].features[1]'),
                            this.$t('pricing_section.packages[2].features[2]'),
                            this.$t('pricing_section.packages[2].features[3]'),
                            this.$t('pricing_section.packages[2].features[4]'),
                            this.$t('pricing_section.packages[2].features[5]')
                        ]
                    }
                ]
            },
            {
                name:"Fansly",
                options:[
                {
                    name: this.$t('pricing_section.packages[3].name'),
                    price: 29.99,
                    features: [
                        this.$t('pricing_section.packages[3].features[0]'),
                        this.$t('pricing_section.packages[3].features[1]'),
                        this.$t('pricing_section.packages[3].features[2]'),
                        this.$t('pricing_section.packages[3].features[3]'),
                        this.$t('pricing_section.packages[3].features[4]'),
                        this.$t('pricing_section.packages[3].features[5]'),
                        this.$t('pricing_section.packages[3].features[5]')
                    ]
                },
                {
                    name: this.$t('pricing_section.packages[4].name'),
                    price: 44.99,
                    features: [
                        this.$t('pricing_section.packages[4].features[0]'),
                        this.$t('pricing_section.packages[4].features[1]'),
                        this.$t('pricing_section.packages[4].features[2]'),
                        this.$t('pricing_section.packages[4].features[3]'),
                        this.$t('pricing_section.packages[4].features[4]'),
                        this.$t('pricing_section.packages[4].features[5]')
                    ]
                }
                ]
            },
            {
                name:"Reddit",
                options:[
                {
                    name: this.$t('pricing_section.packages[5].name'),
                    price: 15,
                    features: [
                        this.$t('pricing_section.packages[5].features[0]'),
                        this.$t('pricing_section.packages[5].features[1]'),
                        this.$t('pricing_section.packages[5].features[2]'),
                        this.$t('pricing_section.packages[5].features[3]')
                    ]
                }
                ]
            }
        ]
    }
  },
  methods: {
    isSelected(rowIndex, colIndex) {
        return this.selectedPricing[0] === rowIndex && this.selectedPricing[1] === colIndex;
    },
    sendMail() {
      const email = 'info@creatorxone.com';
      window.location.href = `mailto:${email}`;
    }
  }
}
</script>