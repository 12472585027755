<template>
    <div v-if="team" class="border-sepBorder px-3 bg-white relative" :class="{'border rounded my-1.5 ml-8': is_group != 1, 'border-b': is_group == 1}">
        <article class="text-light pb-0" :class="{'font-bold':is_group==1,'w-24 truncate':is_group!=1}" v-if="!showInput" @click="openTeam(team.id, team.name)">{{ team.name }}</article>
        <input type="text" v-if="showInput" @blur="showInput = false" autofocus v-model="newName" @keydown.enter="showInput = false, edit(team.id)"/>

        <div class="absolute right-4 -top-0.5 grid grid-cols-3 gap-3 w-16 mt-0.5">
            <div v-if="!showInput" class="text-center cursor-pointer ml-2" @click="showInput = true">
                <font-awesome-icon icon="fas fa-pen" class="text-sm" />
            </div>
            <div v-if="showDelete" class="text-center cursor-pointer ml-2" @click="removeGroup()">
                <font-awesome-icon icon="fas fa-trash-can" class="text-sm" />
            </div>
            <div v-if="is_group!=1" class="text-center cursor-pointer ml-1.5"><font-awesome-icon icon="fa-solid fa-grip" class="text-sm" /></div>
        </div>
    </div>
</template>

<script>
    import { remove_group } from '@/api.js';
    export default {
        props: ['team', 'is_group','showDelete'],
        emits: ['remove-prop'],
        data() {
            return {
                showInput:false,
                newName:null,
                visible:true
            }
        },
        methods: {
            edit(groupID) {
                this.$axios.put(baseURL+"groups/", {
                    newValue: this.newName,
                    groupID: groupID,
                    is_group: parseInt(this.is_group??false),
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id'),
                }).then(result => {
                    this.team.name = this.newName;
                }, (error) => {
                    console.log(error);
                })
            },
            async removeGroup() {
                if (confirm("Are you sure you want to delete this group?")) {
                    await remove_group(this.$cookies.get('userToken'), this.$cookies.get('c_id'), this.team.id, this.is_group).then(() => {
                        this.$emit('remove-prop');
                    });
                }
            },
        },
        created() {
            this.newName = this.team.name
        }
    }
</script>