<template>
    <div class="fixed top-0 w-full z-40" style="box-shadow: 0px 5px 5px rgba(100,100,100,0.25);">
        <div class="bg-header text-panelsText shadow-lg border-b-2 border-sepBorder w-full sm:pl-16 pb-4">
            <section class="p-4 pb-0 pt-4.5">
                <h1 class="float-left mr-5 text-base sm:text-xl font-bold mt-1">{{ $t(pageTitle) }}</h1>
                <div v-if="pageTitle != 'Download' && pageTitle != 'CreatorX Billing'" @click="mobileFilter=!mobileFilter" class="sm:hidden mt-1 absolute right-5"><font-awesome-icon icon="fas fa-filter" /></div>
                <div class="metrics-bar" v-if="pageTitle != 'Settings'">
                    <span v-bind:class="{'hidden sm:contents':!mobileFilter}">
                        <slot v-if="pageTitle != 'Promo' && pageTitle != 'Payrolls' && pageTitle != 'Download' && pageTitle != 'CreatorX Billing' && pageTitle!= 'Tracking Links' && pageTitle!= 'Creators'">
                            <select class="ml-2 sm:ml-0 float-left w-min mr-4 border-b py-1 pb-1.5 border-sepBorder outline-0" @change="changedGroup($event.target.selectedIndex)" v-model="selected.group" v-if="groupList && groupList.length>1">
                                <option :value="null">{{ $t('all_groups') }}</option>
                                <option v-for="(group,index) in groupList" :key="index" :value="group.id">{{group.name}}</option>
                            </select>
                            <span v-else class="float-left mr-5 text-lg mt-1">{{singleGroup}}</span>

                            <slot v-if="(pageTitle=='Sexting' || pageTitle=='Analytics' || pageTitle=='Sexting costs' || pageTitle=='Payrolls' || pageTitle=='Shifts') && groupList && (((groupList.length>1) && (selected.group != null)) || ((selected.group != null && groupList[groupIndex] && groupList[groupIndex].hasChildren) || (singleGroup && groupList[groupIndex].hasChildren)))">
                                <select class="float-left w-min mr-4 border-b py-1 pb-1.5 border-sepBorder outline-0" @change="changedTeam()" v-if="groupList[groupIndex] && groupList[groupIndex].hasChildren && groupList[groupIndex].children.length>1" v-model="selected.team">
                                    <option value="null">{{ $t('all_teams') }}</option>
                                    <option v-for="(team,index) in groupList[groupIndex].children" :key="index" :value="team.id">{{team.name}}</option>
                                </select>
                                <span v-else-if="groupList[groupIndex] && groupList[groupIndex].hasChildren" class="text-panelsText float-left mr-5 text-lg mt-1">{{groupList[groupIndex].children[0].name}}</span>
                            </slot>
                        </slot>
                    
                        <slot v-if="(pageTitle=='Metrics' && enable>1) || pageTitle == 'Transactions' || pageTitle == 'Promo'">
                            <span v-if="modelsList.length > 0">
                                <input type="search" v-model="inputModelName" class="float-left w-min mr-4 border-b py-1 border-sepBorder" :placeholder="$t('search')" list="models" @input="updateSelectedModelName" @click="clearModelName" />
                                <datalist id="models">
                                    <option v-for="(model, index) in filteredModelsList" :key="index" :value="model.free.name + (model.platform == 1 ? ' | OF' : ' | Fansly') + ' | ' + model.code"></option>
                                </datalist>
                            </span>
                            
                            <span v-else class="text-panelsText float-left mr-5 text-base mt-2">{{ $t('no_models') }}</span>
                            <div class="float-left mr-3">
                                <font-awesome-icon icon="fas fa-question-circle" class="text-lg text-panelsText cursor-pointer" @mouseenter="displayLegend = true"  @mouseleave="displayLegend = false" />
                                <div v-bind:class="{'hidden':displayLegend == false}" class="bg-button text-buttonText absolute mt-5 p-2 rounded border border-black" v-html="$t('models_search_notice')"></div>
                            </div>
                        </slot>
                        <slot v-else-if="pageTitle=='Metrics' && enable<=1">
                            <div class="float-left mr-5 mt-2"><font-awesome-icon icon="fa-lg fas fa-spinner" class="animate-spin text-gray-300" /></div>
                        </slot>
                        <slot v-else-if="pageTitle=='Sexting' || pageTitle=='Sexting costs'">
                            <slot v-if="employeeList && employeeList.length>0">
                                <input type="search" class="float-left w-min mr-4 border-b py-1 border-sepBorder" :placeholder="$t('search')" v-model="inputEmployeeName" list="sexters" @input="updateSelectedEmpl" />
                                <datalist id="sexters">
                                    <option v-for="(employee, index) in filteredEmployeeList" :key="index" :value="employee.name" :class="`employee-${employee.id}`"></option>
                                </datalist>
                            </slot>
                            <span v-else class="text-panelsText float-left mr-5 text-base mt-2">{{ $t('no_employees') }}</span>
                        </slot>

                        <!-- Clear filters -->
                        <div v-if="pageTitle=='Metrics' || pageTitle=='Analytics' || pageTitle=='Transactions' || pageTitle == 'Promo' || pageTitle=='Sexting' || pageTitle=='Sexting costs' || pageTitle=='Payrolls' || (pageTitle=='Shifts' && groupList.length>1)" class="whitespace-nowrap cursor-pointer w-min float-left p-1 px-1.5 rounded bg-button text-buttonText mr-3" @click="clearFilters()" :title="$t('clear_filters')">
                            <font-awesome-icon icon="fas fa-filter-circle-xmark" />
                        </div>

                        <div v-if="pageTitle=='Sexting costs' || pageTitle=='Payrolls' || pageTitle == 'Models'" class="absolute right-5 w-36">
                            <VueDatePicker :model-value="selected.month" @update:model-value="handleMonth" month-picker></VueDatePicker>
                        </div>

                        <div v-else-if="pageTitle == 'Metrics' || pageTitle == 'Shifts' || pageTitle == 'Analytics' || pageTitle == 'Transactions'" class="sm:absolute right-5 clear-both sm:float-left">
                            <VueDatePicker :model-value="dRange" @update:model-value="handledRange" :max-date="dRangaMax" range multi-calendars :enable-time-picker="false"></VueDatePicker>
                        </div>
                    </span>
                    <div class="hidden absolute right-7 mt-0 sm:mt-8 text-panelsText float-left">{{ $t('timezone') }}: <strong class="text-button text-sm">UTC (+0)</strong></div>
                </div>
                <div class="clear-both"></div>
            </section>
        </div>
    </div>
</template>

<script setup>
    import logoComponent from "@/components/logo.vue"
</script>

<script>
    import { get_list_data, get_groups, get_models, get_users, verify_token } from "@/api"
    export default {
        props: ['pageTitle','lrIndex','lrData','enable'],
        data() {
            return {
                theme:false,
                groupList: [],
                mTeamsList: [],
                modelsList: [],
                displayLegend: false,
                employeeList: [],
                unselectGroups: false,
                dRange: [null, null],
                dRangaMax:null,
                dateFrom:null,
                dateTo:null,
                timeNow: null,
                timeZone: null,
                selected: {group: null,team:null,model:null,month:null},
                inputModelName: '',
                selectedModelName: '',
                selectedModel: null,
                inputEmployeeName: '',
                selectedEmpl: '',
                selectedmTeam:null,
                singleGroup:null,
                singleMarkTeam:null,
                singleGroupID:null,
                groupIndex:null,
                mobileFilter:false,
                myself:null
            }
        },
        components() {
            logoComponent
        },
        computed: {
            randomIconClass() {
                if (this.theme) {
                    const icons = [
                        "fa-spider",
                        "fa-ghost",
                        "fa-skull-crossbones",
                        "fa-broom",
                        "fa-crow",
                        "fa-hat-wizard",
                        "fa-book-skull",
                        "fa-skull",
                        "fa-cat"
                    ];
                    
                    // Get a random index from the icons array
                    const randomIndex = Math.floor(Math.random() * icons.length);
                    return `fas ${icons[randomIndex]} mr-1`;
                }
            },
            filteredModelsList() {
                if (!this.modelsList) return [];
                return this.modelsList.filter((model) =>
                    model?.free?.name?.toLowerCase().includes(this.inputModelName.toLowerCase())
                );
            },
            filteredEmployeeList() {
                if (!this.employeeList) return [];
                return this.employeeList.filter((employee) =>
                    employee?.name?.toLowerCase().includes(this.inputEmployeeName.toLowerCase())
                );
            },
        },
        watch: {            
            lrIndex: async function (nVal,oVal) {
                if (!oVal) {oVal = 0;}
                if (this.lrData == 'model') {
                    var modelIndex = this.getModelIndex(oVal,nVal);
                    this.selectedModelName = this.modelsList[modelIndex].free.name + (this.modelsList[modelIndex].platform == 1 ? ' | OF' : ' | Fansly') + ' | ' + this.modelsList[modelIndex].code;
                    if (this.selectedModelName != null) {
                        this.$emit('selectedModel', this.modelsList[modelIndex].pair_id);
                        this.$emit('selectedModelN', this.modelsList[modelIndex].free.name + (this.modelsList[modelIndex].platform == 1 ? ' | OF' : ' | Fansly') + ' | ' + this.modelsList[modelIndex].code);
                    }
                } else {
                    var groupIndex = this.getGroupIndex(oVal,nVal);
                    this.selected.group = this.groupList[groupIndex].id;                    
                    if (this.selected.group != null) {
                        this.$emit('selectedGroup', this.groupList[groupIndex].id);
                        this.$emit('selectedGroupN', this.groupList[groupIndex].name);
                    }

                    if (this.selected.group != null) {
                        if (this.pageTitle == 'Metrics' || this.pageTitle=='Transactions') {
                            this.modelsList = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,this.selected.group);
                        } else if (this.pageTitle == 'Sexting'  || this.pageTitle=='Sexting costs' || pageTitle=='Payrolls') {
                            this.employeeList = await get_users(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,this.selected.group,this.selected.team,1);
                        }
                    }
                }
            }
        },
        methods: {
            updateSelectedEmpl(event) {
                const value = event.target.value;
                const employee = this.employeeList.find(
                    (emp) => emp.name === value
                );
                
                if (employee) {
                    this.selectedEmpl = employee.id;  // storing employee ID or another unique identifier
                    this.$emit('selectedEmpl', employee.id);  // emitting the selected employee ID
                }
            },
            updateSelectedModelName(event) {
                const value = event.target.value;
                const model = this.modelsList.find(
                    (b) => b.free.name + (b.platform==1? ' | OF' : ' | Fansly') + ' | ' + b.code === value
                );

                if (model) {
                    this.selectedModelName = value; // or some other identifier from the model
                    this.$emit('selectedModel', model.pair_id);
                    this.$emit('selectedModelN', value);
                }
            },
            clearModelName() {
                this.selectedModelName = null; // or '' for an empty string
            },
            handledRange(modelData) {
                this.dRange = modelData;
                this.$emit('dateFrom', this.dRange[0]);
                this.$emit('dateTo', this.dRange[1]);
            },
            handleMonth(modelData) {
                this.selected.month = modelData;
                let m = parseInt(modelData.month)+1;
                this.$emit('selectedMonth', modelData.year + '-' + m + '-01');
            },
            getGroupIndex(oVal, nVal) {
                return this.groupList ? (this.groupList.findIndex(b => b.id === this.selected.group) + (oVal < nVal ? 1 : this.groupList.length - 1)) % this.groupList.length : null;
            },
            getModelIndex(oVal, nVal) {
                const selectedModel = this.modelsList.find((b) => b.free.name + (b.platform==1? ' | OF' : ' | Fansly') + ' | ' + b.code === this.selectedModelName)
                return (this.modelsList.indexOf(selectedModel) + (oVal < nVal ? 1 : this.modelsList.length - 1)) % this.modelsList.length
            },
            async clearFilters() {                
                this.unselectGroups = true;
                this.selectedBranch = null;
                this.selectedModel = null;
                this.selectedEmpl = null;
                this.selected.team = null;
                this.selectedModelName = null;
                this.selected.group = null;
                this.$emit('selectedModel', null);
                this.$emit('selectedBranch', null);
                this.$emit('selectedTeam', null);
                this.$emit('selectedEmpl', null);
                this.$emit('selectedGroup', 0);
                this.modelsList = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,null);
            },
            async changedGroup(index) {
                this.groupIndex = index-1;
                this.selected.team = null;
                this.selectedModel = null;
                this.selectedEmpl = null;
                this.unselectGroups = false;
                this.$emit('selectedGroup', this.selected.group);
                this.$emit('selectedTeam', null);
                
                if (this.selected.group != null) {
                    this.$emit('selectedGroupN', this.groupList[this.groupIndex].name);
                    if (this.pageTitle == 'Metrics' || this.pageTitle=='Transactions' || this.pageTitle == 'Metrics summary') {
                        this.modelsList = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,this.selected.group);
                    } else if (this.pageTitle == 'Sexting' || this.pageTitle=='Sexting costs') {
                        this.employeeList = await get_users(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,this.selected.group,this.selected.team,1);
                    }
                }
            },
            changedBranch() {
                this.selectedModel = null;
                this.selectedEmpl = null;
                this.$emit('selectedBranch', this.selectedBranch);
            },
            async changedTeam() {
                this.selectedModel = null;
                this.selectedEmpl = null;
                this.employeeList = await get_users(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,this.selected.group,this.selected.team,1);
                this.$emit('selectedTeam', this.selected.team);
                this.$emit('selectedEmpl', null);
            },
            async changedmTeam() {
                this.$emit('selectedGroup', this.selectedmTeam);
            }
        },
        async mounted() {
            const user = await verify_token(this.$cookies.get('userToken'),this.$cookies.get('c_id'),'header');
            if (user && user.myself) {
                this.myself = user.myself;
            }

            if (this.myself && this.myself.platform>0) {
                this.selectedPlatform = this.myself.platform;
            }
            
            this.groupList = await get_groups(this.$cookies.get("userToken"),this.$cookies.get('c_id'));
            if (this.groupList && this.groupList.length == 1) {
                this.singleGroup = this.groupList[0].name;
                this.selected.group = this.groupList[0].id;
            }
            this.groupIndex = 0;

            if (this.pageTitle == 'Metrics summary') {
                this.branchList = await get_list_data(this.$cookies.get('userToken'),this.$cookies.get('c_id'),'branches',null,null,1,this.pageTitle);
            }
            if (this.pageTitle == 'Metrics' || this.pageTitle == 'Transactions' || this.pageTitle == 'Metrics summary') {
                this.modelsList = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,this.selected.group);
                if (this.modelsList[0] && this.pageTitle == 'Transactions') {
                    this.inputModelName = this.modelsList[0].free?.name + " | " + this.modelsList[0].platformName + " | " + this.modelsList[0].code;
                    setTimeout(() => {
                        this.$emit('selectedModel', this.modelsList[0].pair_id);
                    }, 500);
                }
            }
            if (this.pageTitle == 'Sexting' || this.pageTitle == 'Sexting costs' || this.pageTitle == 'Payrolls') {
                this.employeeList = await get_users(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,this.selected.group,this.selected.team,1);
            }

            let pageTitles = ['Metrics', 'Analytics', 'Drop Shifts', 'Transactions', 'Promo', 'Metrics summary'];

            if (pageTitles.includes(this.pageTitle)) {
                let endDate = new Date();
                let startDate = new Date(new Date().setDate(endDate.getDate() - 9));
                
                this.dRange = [
                    startDate.toISOString(),
                    endDate.toISOString()
                ];
                this.dRangaMax = new Date();

                this.$emit('dateFrom', this.dRange[0]);
                this.$emit('dateTo', this.dRange[1]);
            } else if (this.pageTitle === 'Shifts') {
                let startDate = new Date(new Date().setDate(new Date().getDate() - 1));
                let endDate = new Date(new Date().setDate(startDate.getDate() + 5));
                
                this.dRange = [
                    startDate.toISOString(),
                    endDate.toISOString()
                ];

                this.$emit('dateFrom', this.dRange[0]);
                this.$emit('dateTo', this.dRange[1]);
            } else if (['Sexting', 'Models'].includes(this.pageTitle)) {
                this.selected.month = {
                    year: this.$moment.utc().startOf('month').format('YYYY'),
                    month: this.$moment.utc().startOf('month').format('M')-1
                };
                let m = parseInt(this.selected.month.month)+1;
                this.$emit('selectedMonth', this.selected.month.year + '-' + m + '-01');
            } else if (['Payrolls', 'Sexting costs'].includes(this.pageTitle)) {
                this.selected.month = {
                    year: this.$moment.utc().startOf('month').format('YYYY'),
                    month: this.$moment.utc().startOf('month').format('M')-2
                };
                let m = parseInt(this.selected.month.month)+1;
                this.$emit('selectedMonth', this.selected.month.year + '-' + m + '-01');
            }
            this.$emit('selectedModel', null);
            this.$emit('selectedPlatform', this.selectedPlatform);
        },
    }
</script>