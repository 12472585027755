<template>
  <footer class="bg-bkg bg-opacity-20 text-contentText bottom-0 w-full py-4 sm:py-6">
    <section class="container mx-auto px-5 flex justify-between">
      <logo-component />
      <div class="text-center text-sm" v-html="$t('not_affiliated')"></div>
      <div class="text-right">
        <p>{{$t('copyright')}}</p>
        <strong @click="sendMail" class="cursor-pointer">info@creatorxone.com</strong>
        <!-- Other footer info -->
      </div>
    </section>
  </footer>
</template>

<script setup>
    import logoComponent from "@/components/logo.vue"
</script>

<script>
export default {
  name: "FooterPanel",
  components() {
    logoComponent
  },
  methods: {
    sendMail() {
      const email = 'info@creatorxone.com';
      window.location.href = `mailto:${email}`;
    }
  }
};
</script>

