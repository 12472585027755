<template>
    <div class="overflow-x-hidden">
        <header-panel />

        <div class="px-2">
            <section class="container mx-auto bg-white rounded-lg shadow-lg p-5 sm:p-10 mt-22">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
                    <div class="max-w-base">
                        <font-awesome-icon icon="fa-solid fa-arrow-left" class="ml-2 text-2xl cursor-pointer" @click="$router.push('/')" />
                        <h1 class="mt-5 text-3xl font-bold" v-html="$t('multi_platform.h1')"></h1>
                        <p class="max-w-md mt-3 text-lg" v-html="$t('multi_platform.h2')"></p>
                    </div>
                    <div>
                        <img :src="mutliPlatform" class="hidden sm:block" alt="Elevate Your OnlyFans, Fansly, and Reddit Strategy" title="Elevate Your OnlyFans, Fansly, and Reddit Strategy" />
                    </div>
                    <div @click="$router.push({ path: '/', query: { open: 'register' } });" class="col-span-full bg-growth w-full mx-auto rounded shadow-lg text-center text-white p-3 font-semibold text-lg cursor-pointer">
                        <font-awesome-icon icon="fa-solid fa-seedling" class="mr-3" /> {{ $t('Try_now') }}
                    </div>
                    <div class="col-span-full mx-auto">
                        <div class="flex items-center">
                            <font-awesome-icon icon="fa-solid fa-handshake-simple" class="hidden sm:block text-5xl pr-16 text-growth" />
                            <div class="max-w-2xl">
                                <h3 class="leading-relaxed font-bold text-2xl">{{ $t('multi_platform.h3') }}</h3>
                                <p>{{ $t('multi_platform.p1') }}</p>
                            </div>
                        </div>
                        <div class="flex items-center mt-8">
                            <font-awesome-icon icon="fa-solid fa-user-shield" class="hidden sm:block text-5xl pr-16 text-growth" />
                            <div class="max-w-2xl">
                                <h3 class="leading-relaxed font-bold text-xl">{{ $t('multi_platform.h4') }}</h3>
                                <p>{{ $t('multi_platform.p2') }}</p>
                            </div>
                        </div>
                        <div class="flex items-center mt-8">
                            <font-awesome-icon icon="fa-solid fa-arrow-up-right-dots" class="hidden sm:block text-5xl pr-16 text-growth" />
                            <div class="max-w-2xl">
                                <h3 class="leading-relaxed font-bold text-xl">{{ $t('multi_platform.h5') }}</h3>
                                <p>{{ $t('multi_platform.p3') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <footer-panel />
    </div>
</template>

<script setup>
    import headerPanel from "@/components/landing/HeaderLanding.vue";
    import mutliPlatform from '@/assets/images/multi_platform_access.svg';
    import FooterPanel from "@/components/landing/FooterLanding.vue"
</script>

<script>
    export default {
        components: {FooterPanel, headerPanel},
        data() {
            return {
                menuOpen:false,
            }
        },
    }
</script>