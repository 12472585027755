<template>
    <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
    <header-filter :pageTitle="'Analytics'" @selectedModelN="selectedModelN=$event" @selectedModel="selectedModel = $event" @selectedGroupN="selectedGroupN=$event" @selectedGroup="selectedGroup = $event" @selectedTeam="selectedTeam = $event" @dateFrom="dateFrom = $event" @dateTo="dateTo = $event" />
    <section v-if="1==2" class="grid grid-cols-4 my-3 sm:ml-22 sm:mt-24 text-center">
        <section class=" bg-bkg p-8">
            <modelOfMonth :month="month" v-if="month!==null && month.value!==null" />
        </section>
        <section class=" bg-bkg p-8">
            <employeeOfMonth :month="month" v-if="month!==null && month.value!==null" />
        </section>
        <section class=" bg-bkg p-8">
            <bestTeam :month="month" v-if="month!==null && month.value!==null" />
        </section>
        <section class=" bg-bkg p-8">
            <bestManager :month="month" v-if="month!==null && month.value!==null" />
        </section>
    </section>
    <div class="text-black sm:ml-22 sm:mt-20">
        <div class="whitespace-nowrap cursor-pointer p-1.5 px-5 rounded bg-button text-buttonText font-semibold w-max border border-sepBorder" @click="refresh()" :title="$t('refresh')"><font-awesome-icon icon="fas fa-redo" /> {{ $t('refresh') }}</div>
        <section class="w-full bg-bkg p-8 mt-3 rounded border border-sepBorder">
            <section v-if="analytics_squares && analytics_squares.length>0" class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 gap-x-16 text-left">
                <slot v-if="analytics_squares.length>1">
                    <div class="w-full rounded p-1 px-5 rounded" v-for="(v,i) in analytics_squares" :key="i">
                        <div class="float-left mr-5 mt-2 text-center w-10 h-10 pt-1.5 rounded-full border border-leftPanelBG"><font-awesome-icon class="text-leftPanelBG" :icon="v.icon" /></div>
                        <div class="float-left">
                            <h2 class="text-2xl font-bold">{{addSpace(v.amount)}}</h2>
                            <span class="font-light">{{v.label}}</span>
                        </div>
                        <div v-if="v.compare" class="float-left text-lg ml-3 mt-3" :class="{'text-growth':v.compare>=0,'text-decline':v.compare<0}"><font-awesome-icon :icon="trendIcon(v.compare)" class="mr-2" />{{v.compare}}</div>
                    </div>
                </slot>
            </section>
            <section v-else-if="loading" class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 gap-x-16 text-left">
                <div class="w-full rounded p-1 px-5 rounded animate-pulse mt-1" v-for="(v,i) in 4" :key="i">
                    <div class="float-left mr-5 mt-2 text-center w-10 h-10 pt-1.5 rounded-full bg-panels"></div>
                    <div class="float-left">
                        <h2 class="bg-panels w-32 h-5 mt-2 block rounded-full"></h2>
                        <span class="bg-panels font-light w-20 h-4 mt-1 block rounded-full"> </span>
                    </div>
                </div>
            </section>
            <div v-else class="text-center font-semibold">
                {{ $t('no_data') }}
            </div>

            <div class="w-full mt-10 grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div class="h-96">
                    <chart :column="'activeFans'" :chartType="'line'" :graphLabel="$t('active_fans')" :colors="['#70C1B2','#FFFFFF']" :dateFrom="dateFrom" :dateTo="dateTo" :selectedGroup="selectedGroup" :selectedTeam="selectedTeam" :selectedModel="selectedModel" />
                </div>
                <div class="h-96">
                    <chart :column="'transactionsValue'" :chartType="'bar'" :graphLabel="$t('earnings')" :colors="['#70C1B2','#E4E5E4']" :dateFrom="dateFrom" :dateTo="dateTo" :selectedGroup="selectedGroup" :selectedTeam="selectedTeam" :selectedModel="selectedModel" />
                </div>
                <div v-if="1==2" class="h-96">
                    <chart_comp :column="'Top 10 Employees'" :chartType="'bar'" :graphLabel="$t('sales')" :colors="['#70C1B2','#E4E5E4']" :g_data="employees_graph_data" />
                </div>
                <div v-if="1==2" class="h-96">
                    <chart_comp :column="'Top 10 Models'" :chartType="'bar'" :graphLabel="$t('earnings')" :colors="['#70C1B2','#E4E5E4']" :g_data="models_graph_data" />
                </div>
            </div>
            <div class="clear-both"></div>
        </section>
    </div>
</template>

<script setup>
    import headerFilter from "@/components/headerFilter.vue"
    import chart from "@/components/charts/analytics_chart.vue";
    import chart_comp from "@/components/charts/chart_comp.vue";
    import leftMenu from "@/components/LeftMenu.vue";
    import modelOfMonth from "@/components/analytics/modelOfMonth.vue";
    import employeeOfMonth from "@/components/analytics/employeeOfMonth.vue";
    import bestTeam from "@/components/analytics/bestTeam.vue";
    import bestManager from "@/components/analytics/bestManager.vue";
</script>

<script>
    import { analytics_data, get_top_employee,get_top_models } from "@/api.js";    

    export default {
        props:['lrIndex','lrData','selectedModel', 'selectedModelN','selectedTeam','selectedGroupN','selectedEmpl','selectedGroup','dateFrom','dateTo'],
        components() {
            headerFilter,
            chart,
            leftMenu
        },
        data() {
            return {
                mounted:false,
                analytics_squares: [],
                loading:true,
                permissions: null,
                month : { name: null, value: null },
                employees: [],
                employees_graph_data:{ data:[], labels:[]},
                topModels: [],
                models_graph_data:{ data:[], labels:[]},
            }
        },
        watch: { 
            selectedModel: 'handleUpdate',
            dateFrom: 'handleUpdate',
            dateTo: 'handleUpdate',
            selectedGroup: 'handleUpdate',
            selectedTeam: 'handleUpdate',
        },
        methods: {
            trendIcon(condition) {
                return condition>=0 ? 'fas fa-arrow-trend-up' : 'fas fa-arrow-trend-down';
            },
            refresh() {
                this.analytics_squares = [];
                this.loading = true;
                this.handleUpdate();
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            },
            handleUpdate() {
                // Avoid running on the initial null-to-undefined transition
                if (!this.mounted) {
                    return;
                }

                // Prevent updateChart from running multiple times in quick succession
                if (!this.updateChartPending) {
                    this.updateChartPending = true;
                    // Use this.$nextTick to delay the chart update until after the DOM has been updated
                    this.$nextTick(() => {
                        this.getAnalytics();
                        this.updateChartPending = false; // Reset the flag
                    });
                }
            },
            addSpace(nStr) {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + " " + '$2');
                }
                return x1 + x2;
            },
            async getAnalytics() {
                this.loading = true;
                //analytics summary
                const analytics = await analytics_data(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.dateFrom,this.dateTo,"summary",this.selectedGroup, this.selectedTeam, this.selectedModel, this.selectedEmpl, this.column);
                
                var analytics_squares = [];
                if (analytics.active_fans && analytics.active_fans.length>0) {
                    analytics_squares.push({
                        label:this.$t('active_fans'),
                        amount: analytics.active_fans[analytics.active_fans.length-1],
                        icon:"fas fa-users",
                        compare: analytics.active_fans[analytics.active_fans.length-1] - analytics.active_fans[0]
                    });
                }
                if (analytics.expired_fans && analytics.expired_fans.length>0) {
                    analytics_squares.push({
                        label:this.$t('expired_fans'),
                        amount: analytics.expired_fans[analytics.expired_fans.length-1],
                        icon:"fas fa-users",
                        compare: analytics.expired_fans[analytics.expired_fans.length-1] - analytics.expired_fans[0]
                    });
                }
                if (analytics.active_fans && analytics.active_fans.length>0) {
                    analytics_squares.push({label:this.$t('earnings'),amount:"$"+parseFloat(analytics.earnings).toFixed(2),icon:"fas fa-dollar-sign"});
                    analytics_squares.push({label:this.$t('subscriptions'),amount:"$"+parseFloat(analytics.subscriptions).toFixed(2),icon:"fas fa-dollar-sign"});
                }
                this.analytics_squares = analytics_squares;
                this.loading = false;
            }
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', query: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.myself) {this.myself = result.data.myself;}
                    if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                    if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
                }, (error) => {
                console.log(error);
            })
        },
        async mounted() {
            this.mounted = true;

            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() - 1); // Subtract 1 month from the current date
            const month = currentDate.getMonth() + 1; // Adding 1 to get month in range 1-12
            const year = currentDate.getFullYear();
            const formattedMonthYear = currentDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });

            const formattedValue = `${month.toString().padStart(2, '0')}-${year}`;
            this.month = { name: formattedMonthYear, value: formattedValue };

            this.employees = await get_top_employee(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.dateFrom,this.dateTo, 10);
            this.topModels = await get_top_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.dateFrom,this.dateTo, 10);

            console.log(this.employees);
            console.log(this.topModels);

            if(this.employees.length > 0) {
                this.employees_graph_data = {
                    data: this.employees.map(e => e.total_sales),
                    labels: this.employees.map(e => e.name)
                };
            }
            if(this.topModels.length > 0) {
                this.models_graph_data = {
                    data: this.topModels.map(e => e.earnings),
                    labels: this.topModels.map(e => e.name)
                };
            }
            //console.log(this.employees_graph_data);
            //console.log(this.models_graph_data);
        }
    }
</script>