<template>
  <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
  <header-filter :pageTitle="'Tracking Links'" />
  <div class="mx-4 mb-24 sm:ml-16 px-4 mt-24">
    <slot v-if="models_band=='loading'">
      <section class="text-center py-40">
        <font-awesome-icon :icon="'fa-solid fa-spinner'" class="animate-spin text-4xl"/>
      </section>
    </slot>
    <slot v-else-if="models_band==true">
      <div class="bg-tableBG p-5 drop-shadow-lg rounded-lg">
        <div class="flex flex-col sm:flex-row justify-between items-center">
          <div class="flex items-center mb-4 sm:mb-0">
            <span class="text-base sm:text-xl font-bold mr-5">Select creator:</span>
            <span v-if="modelsList.length > 0">
              <input type="search" v-model="inputModelName" class="w-full sm:w-max border-b py-1 border-sepBorder" placeholder="Search" list="models" @input="updateSelectedModelName" @click="clearModelName" />
              <datalist id="models">
                <option v-for="(model, index) in modelsList" :key="index" :value="model.free.name + (model.platform == 1 ? ' | OF' : ' | Fansly') + ' | ' + model.code"></option>
              </datalist>
            </span>
          </div>
          <p v-if="lastSynced!=null" class="text-base sm:text-right">*Last Synced: {{ lastSynced }}</p>
        </div>
      </div>

      <div class="gap-3 sm:gap-7 mt-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4" v-if="tracking_links.length>0">
        <div class="flex flex-row p-5 items-center items-start space-y-0 space-x-4" v-for="(summary,i) in summary_list" :key="i" ref="summaries">
          <div class="flex-shrink-0 self-center">
            <font-awesome-icon :icon="summary.icon" :class="summary.class"/>
          </div>
          <div>
            <p class="text-xl sm:text-2xl lg:text-3xl font-bold">
              <span v-if="summary.nomenclature=='$'">$</span>{{ summary.value }}<span v-if="summary.nomenclature=='%'">%</span>
            </p>
            <h3 class="font-lighter text-base sm:text-base lg:text-lg whitespace-nowrap">
              {{ summary.name }}
              <tooltip v-if="summary.info" :msg="summary.msg??'missing tooltip'" icon="fas fa-question-circle" :classBand="classBand" />
            </h3>
          </div>
        </div>
      </div>
      
      <div class="bg-tableBG p-6 drop-shadow-lg rounded-lg mt-5 w-full">
          <div class="sm:flex justify-between items-start mx-2 ">
            <h3 class="text-textOnBkg font-bold text-lg sm:text-2xl mb-3 sm:mb-0">
              Tracking Links 
              <button v-if="modelsList.length>0 && modelsList.filter((model) => model?.free?.id == selectedModel)[0]?.platform == 1" class="ml-2 border-2 border-growth text-growth text-sm cursor-pointer font-semibold rounded p-1 px-2" @click="showModal('newLink',null)">
                Add New
              </button>
            </h3>
            <div>
              <div class="sm:flex justify-between gap-3 mb-2">
                <div>
                  <button type="button" @click="showHidetag=!showHidetag" class="rounded bg-white py-1 px-2 outline-0 border-b border-sepBorder" id="menu-button" aria-expanded="true" aria-haspopup="true">
                      Show/Hide Columns
                      <font-awesome-icon icon="fa-solid fa-chevron-down" class="text-xs" />
                    </button>
                    <div v-show="showHidetag" class="absolute z-10 mt-2 w-56 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                      <div class="py-1" role="none">
                        <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                        <label class="text-gray-700 block px-4 py-2 text-sm show-columns-checkbox" role="menuitem" tabindex="-1" id="menu-item-0" v-for="(col,index) in columns" :key="index" v-show="col.active">
                          <input type="checkbox" class="mr-0.5" v-model="col.show" @click="updateColumnsPreset(col.name)">
                          {{ col.name }}
                        </label>
                      </div>
                    </div>
                </div>
                <div>
      
                  <select v-on:change="orderLinks" v-model="order_by" name="order_by" id="order_by" class="border-b py-1 border-sepBorder w-max outline-0">
                    <option value="">Sort by</option>
                    <option value="alphabetical_asc">Alphabetical: A to Z</option>
                    <option value="alphabetical_desc">Alphabetical: Z to A</option>
                    <option value="clicks_desc">Clicks: high to low</option>
                    <option value="clicks_asc">Clicks: low to high</option>
                    <option value="fans_profit">Fans profit</option>
                    <option value="date_asc">Date: new to old</option>
                    <option value="date_desc">Date: old to new</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          
          <slot v-if="links_band=='loading'">
            <section class="text-center py-40">
              <font-awesome-icon :icon="'fa-solid fa-spinner'" class="animate-spin text-4xl"/>
            </section>
          </slot>
          <slot v-else-if="tracking_links.length>0">
            <div class="overflow-auto mt-5" style="max-height:600px;">
              <table class="text-tableText whitespace-nowrap rounded font-normal border border-sepBorder table-auto w-full relative" ref="tableRef">
                <thead class="sticky top-0 bg-tableBG">
                  <tr class="text-button">
                    <th class="p-2 px-4 text-lg text-left">Links</th>
                    <th v-for="(col,index) in columns" :key="index" v-show="(col.active && col.show)" class="p-2 px-4 text-lg text-center">
                      {{ col.name }}
                      <tooltip v-if="col.info" :msg="col.msg??'missing tooltip'" :classBand="classBand" icon="fas fa-question-circle" />
                    </th>
                    <th class="p-2 px-4 text-lg text-center">Detailed View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(link_info,i) in tracking_links" :key="i" class="bg-tableBG text-tableText border text-center">
                    <td class="text-left p-2 py-3 px-5 font-bold">
                      <span class="w-full md:w-36 ellipses truncate mr-2">{{ link_info.name }}</span>
                      <tooltip @click="copyToClipboard(link_info.url)" :msg="'Copy Link'" :classBand="classBand" icon="fa-regular fa-copy" />
                      <span class="block text-sm font-normal">Created: <strong>{{ link_info.created_at }}</strong></span>
                    </td>
                    <td v-show="columns[0].show">{{ link_info.clicks_count }}</td>
                    <td v-show="columns[1].show">{{ link_info.subscribeCount }}</td>
                    <td v-show="columns[3].show">${{ link_info.earnings }}</td>
                    <td v-show="columns[4].show">{{ link_info.buyers }}</td>
                    <td v-show="columns[5].show">{{ link_info.subscribeCount === 0 || link_info.clicks_count === 0 ? 0 : parseFloat((link_info.subscribeCount / link_info.clicks_count) * 100).toFixed(2) }}%</td>
                    <td>
                      <button type="button" class="text-growth text-buttonText ml-5 p-1 px-3 -mt-1 cursor-pointer" @click="showModal(null,null)">
                        <font-awesome-icon icon="fa-solid fa-chart-column" class="text-growth text-lg cursor-pointer" />
                      </button>
                      <button type="button" class="text-growth text-buttonText ml-5 p-1 px-3 -mt-1 cursor-pointer" @click="showModal('claims',link_info)">
                        <font-awesome-icon icon="fa-solid fa-user-group" class="text-growth text-lg cursor-pointer" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center p-5 my-5">
              <slot v-if="hasMoreLinks==true">
                <div class="cursor-pointer animate-bounce text-white bg-white p-3 px-4 border-2 border-growth rounded-full absolute left-1/2 -ml-6"  @click="loadMore()">
                    <font-awesome-icon icon="fa-solid fa-angles-down" class="text-growth" />
                </div>
              </slot>
              <slot v-else-if="hasMoreLinks=='processing'">
                  <font-awesome-icon :icon="'fa-solid fa-spinner'" class="animate-spin"/>
              </slot>
              <div class="float-right text-textOnBkg mr-2">
                <button class="bg-button text-buttonText ml-5 border border-sepBorder rounded p-1 px-3 -mt-1 cursor-pointer" @click="exportToCSV"><span ref="exportLoading" class="hidden"><font-awesome-icon icon="fas fa-spinner" class="animate-spin mr-2" /></span>Export to CSV</button>
              </div>
            </div>
          </slot>
          <slot v-else>
            <section>
                <div class="w-full bg-bkg rounded text-center py-60 border border-sepBorder ml-1">No tracking links yet. <span v-if="modelsList.length>0 && modelsList.filter((model) => model?.free?.id == selectedModel)[0]?.platform == 1" class="text-growth cursor-pointer" @click="showModal('newLink',null)">Add your first tracking link</span></div>
            </section>
          </slot>
      </div>
    </slot>

    <slot v-else-if="models_band==false">
      <section>
        <div class="w-full bg-bkg rounded text-center py-60 border border-sepBorder ml-1">
          No Creators found.
          <router-link to="/Creators" class="text-growth cursor-pointer">Please add a Creator</router-link>
        </div>
      </section>
    </slot>
  </div>
  
  <transition name="zoom-fade">
    <section v-if="modal.display" @click="modal.display = false" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
        <section @click.stop class="bg-white rounded-lg w-full max-h-full max-w-md sm:max-w-5xl mx-auto my-auto p-8 sm:p-12 z-50 overflow-auto relative">
            <font-awesome-icon @click="modal.display = false" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
            <detailedClaims v-if="modal.type == 'claims'" :linkID="linkID" :modelID="selectedModel" :companyID="companyID" ref="claims" />
            <newLinkModal v-else-if="modal.type == 'newLink'" :companyID="companyID" :modelID="selectedModel" @newLink="addnewLink"/>
            <p v-else class="text-center text-xl">Comming Soon!</p>
        </section>
    </section>
  </transition>

</template>
<script setup>
  import headerFilter from "@/components/headerFilter.vue";
  import detailedClaims from "@/components/trackingLinks/detailedClaimsComponent.vue";
  import { getTrackingLinks,get_models } from "@/api";
  import leftMenu from "@/components/LeftMenu.vue";
  import newLinkModal from "@/components/trackingLinks/addNewModalComponent.vue";
  import Tooltip from '@/components/toolTip.vue';
</script>
<script>
  export default {
    components: {
      headerFilter,
      detailedClaims,
      leftMenu,
      Tooltip,
      newLinkModal
    },
    data(){
      return{
        loaded: false,
        modal: {display: false, type: null},
        myself: null,
        companyID: null,
        order_by: '',
        modelsList: [],
        inputModelName: '',
        selectedModelName: '',
        selectedModel: null,
        lastModel: null,
        lastSynced: null,
        linkID: null,
        permissions: null,
        hasMoreLinks: false,
        showHidetag: false,
        band: false,
        models_band: 'loading',
        links_band: '',
        offset: 0,
        limit: 10,
        classBand: 'bg-button',

        summary_list: [
          {name: 'Total Clicks', value: 0, nomenclature: '', info: false, displayLegend: false, msg: null, icon:'fa-solid fa-computer-mouse', class:'text-button text-4xl cursor-pointer' },
          {name: 'Total Subscribers', value: 0, nomenclature: '', info: false, displayLegend: false, msg: null, icon:'fa-solid fa-user-group', class:'text-button text-3xl cursor-pointer' },
          {name: 'Total Earnings', value: 0, nomenclature: '$', info: false, displayLegend: false, msg: null, icon:'fa-solid fa-dollar-sign', class:'text-button text-4xl cursor-pointer' },
          {name: 'Conversion Rate', value: 0, nomenclature: '%', info: false, displayLegend: false, msg: null, icon:'fa-solid fa-percent', class:'text-button text-4xl cursor-pointer'}
        ],
        columns:[
          {name: 'Clicks',info:false,msg:'',show:true,active:true},
          {name: 'Subscribers',info:false,msg:'',show:true,active:true},
          {name: 'Cost', info:true,msg:'',show:false,active:false},
          {name: 'Earnings',info:false,msg:'',show:true,active:true},
          {name: 'Buyers', info:false,msg:'',show:true,active:true},
          {name: 'Conversion Rate', info:false,msg:'',show:true,active:true},
          {name: 'Profit', info:false,msg:'',show:false,active:false},
          {name: 'CPC', info:false,msg:'',show:false,active:false},
          {name: 'EPC', info:false,msg:'',show:false,active:false},
          {name: 'CPF', info:false,msg:'',show:false,active:false},
          {name: 'ARPF', info:false,msg:'',show:false,active:false},
          
          {name: 'ROI', info:false,msg:'',show:false,active:false},
        ],
        tracking_links:[]
      }
    },
    methods:{
      copyToClipboard(url) {
          navigator.clipboard.writeText(url);
          this.msg = "Copied to clipboard!";
          this.classBand = 'bg-growth';
          setTimeout(() => {
              this.msg = this.textMessage;
              this.classBand = 'bg-button';
          }, 3000);
      },
      updateColumnsPreset(name){
        let columns_preset = this.columns.reduce((acc, col) => {
          acc[col.name] = col.show;
          if(name == col.name)
            acc[col.name] = !acc[col.name];
          return acc;
        }, {});
        this.$cookies.set('columns_preset', JSON.stringify(columns_preset));
      },
      async getColumnPreset(){
        let columns_preset = await this.$cookies.get('columns_preset');
        if(columns_preset){
          this.columns = this.columns.map(col => {
            col.show = columns_preset[col.name];
            return col;
          });
        }
      },
      async loadMore(){
        this.hasMoreLinks = 'processing';

        this.offset +=10;
        this.limit +=10;

        await this.setTrackingLinks();

        this.orderLinks();
      },
      async setTrackingLinks(){
        this.links_band = 'loading';
        let modelID = this.selectedModel;//3326
        let links = await getTrackingLinks(this.$cookies.get('userToken'), this.companyID, modelID,this.offset,this.limit);
        if(links == false){return;}
        this.hasMoreLinks = links.hasMore;
        if(this.lastModel != modelID){this.tracking_links = [];}
        this.tracking_links = this.tracking_links.concat(links.data);
        this.lastModel = modelID;
        this.links_band = 'loaded';
      },
      calculateTotals(){
        const totals = this.tracking_links.reduce((acc, link) => {
          acc.clicks += link.clicks_count;
          acc.subscribers += link.subscribeCount;
          acc.earnings += parseFloat(link.earnings);
          return acc;
        }, { clicks: 0, subscribers: 0, earnings: 0 });

        this.summary_list[0].value = totals.clicks;
        this.summary_list[1].value = totals.subscribers;
        this.summary_list[2].value = totals.earnings;
        let conversion_rate = totals.subscribers === 0 || totals.clicks === 0 ? 0 : parseFloat((totals.subscribers / totals.clicks) * 100).toFixed(2);
        this.summary_list[3].value = conversion_rate;
        return this.summary_list;
      },
      filteredModelsList() {
        if (!this.modelsList) return [];
        return this.modelsList.filter((model) =>
            model?.free?.name?.toLowerCase().includes(this.inputModelName.toLowerCase())
        );
      },
      clearModelName() {
          this.selectedModelName = null; 
      },
      async updateSelectedModelName(event) {
        const value = event.target.value;
        const model = this.modelsList.find(
            (b) => b.free.name + (b.platform==1? ' | OF' : ' | Fansly') + ' | ' + b.code === value
        );

        if (model) {
          this.selectedModelName = value;
          this.selectedModel = model?.free?.id;
          await this.setTrackingLinks();

          if(this.tracking_links.length>0){
            this.summary_list = this.calculateTotals();
            this.orderLinks();
          }
        }
      },
      orderLinks() {
        if (this.order_by == 'Order' || this.tracking_links == null) {
          return;
        }

        const sortFunctions = {
          fans_profit: (a, b) => b.earnings - a.earnings,
          clicks_desc: (a, b) => b.clicks_count - a.clicks_count,
          clicks_asc: (a, b) => a.clicks_count - b.clicks_count,
          date_desc: (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
          date_asc: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
          alphabetical_asc: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
          alphabetical_desc: (a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase())
        };

        this.tracking_links.sort(sortFunctions[this.order_by]);

        if (this.order_by == 'date_asc') {
          this.lastSynced = this.tracking_links[this.tracking_links.length - 1]?.last_checked_at ? this.$moment(this.tracking_links[this.tracking_links.length - 1].last_checked_at).format('MMMM Do YYYY, h:mm a') : null;
        }
      },
      showModal(type,link_info = null){
        this.modal.display = true;
        this.modal.type = type;
        if (type == 'claims') {
          this.linkID = link_info.link_id;
        }
      },
      async addnewLink(newLink){
        this.tracking_links.unshift(newLink);
        this.summary_list = this.calculateTotals();
        this.modal.display = false;
      },
      exportToCSV(){
        this.$refs.exportLoading.classList.remove('hidden');
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += "Links,Clicks,Subscribers,Earnings,Buyers,Conversion Rate,\n";
        this.tracking_links.forEach((link) => {
          csvContent += `${link.name},${link.clicks_count},${link.subscribeCount},${link.earnings},${link.buyers},${link.subscribeCount === 0 || link.clicks_count === 0 ? 0 : parseFloat((link.subscribeCount / link.clicks_count) * 100).toFixed(2)}%\n`;
        });
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "tracking_links.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
        this.$refs.exportLoading.classList.add('hidden');
      }
    },
    created(){
      if (this.$cookies.get('c_id')) {
        this.companyID = this.$cookies.get('c_id');
      }
      this.$axios.get(baseURL+"opravnenia/userToken.php", {
        params: {
          userToken: this.$cookies.get('userToken'),
          companyID: this.companyID,
          place: 'header'
        }
        }).then((result) => {
          if (result.data.selectBranch) {
            let prev = this.$route.path;
            this.$router.push({name:'Branches', query: { prevRoute: prev } });
          } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
            this.$cookies.set('userToken','');
            window.location.href = '/';
          }
          if (result.data.myself) {this.myself = result.data.myself;}
          if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
          setTimeout(() => {
              this.loaded = true;
          }, 500); 
        }, (error) => {
          console.log(error);
        });
    },
    async mounted(){
      this.modelsList = await get_models(this.$cookies.get('userToken'),this.companyID,1,null,null);
      if(this.modelsList.length==0){
        this.models_band = false;
        return;
      }
      this.models_band = true;
      let b = this.modelsList[0];
      this.inputModelName = b.free.name + (b.platform==1? ' | OF' : ' | Fansly') + ' | ' + b.code;
      await this.getColumnPreset();
      this.selectedModelName = this.inputModelName;
      this.selectedModel = b.free.id;
      this.lastModel = this.selectedModel;
      await this.setTrackingLinks();
      if(this.tracking_links.length>0){
        this.summary_list = this.calculateTotals();
        this.orderLinks();
      }
    }
  }
</script>
<style scoped>
.zoom-fade-enter-active {
  transition: all .3s ease;
}
.zoom-fade-leave-active {
  transition: all .3s ease;
}
.zoom-fade-enter-from, .zoom-fade-leave-to {
  transform: scale(0.9);
  opacity: 0;
}
.show-columns-checkbox{
  margin: 0.5rem 0;
  cursor: pointer;  
}
.show-columns-checkbox input{
  cursor: pointer;
  max-width: 1rem;
  box-shadow: none;
  margin-top: 5px;
}
</style>