<template>
    <div>
        <section v-if="dialog.show" @click="dialog.show = false" class="fixed w-full h-full left-0 top-0 bg-black bg-opacity-60 block z-40">
    </section>
    <section v-if="dialog.show" class="text-black fixed bg-gray-200 p-5 rounded-xl top-1/3 mx-auto sm:inset-1/3 z-50 w-4/5 sm:w-1/3 ">
        <font-awesome-icon @click="dialog.show = false" class="absolute p-5 right-0 top-2 cursor-pointer" icon="fas fa-xmark fa-lg" />
        <div>
            <div class="text-center mb-2">
                <span class="text-lg font-semibold">{{dialog.title}}</span><br />
                <span class="text-sm">Give models access to see their earnings in real time</span>
            </div>
            <label class="text-sm">Email adress or Username</label><br />
            <input type="text" placeholder="Email or Username" v-model="modelCred.email"><font-awesome-icon icon="fas fa-exclamation" class="text-red-500 absolute -ml-4 mt-2" v-if="submitted && !modelData.free.email" /><br />
            <label class="text-sm">Password</label><br />
            <div class="relative w-max">
                <input v-bind:type="displayPass ? 'text' : 'password'" placeholder="Password" v-model="modelCred.password">
                <font-awesome-icon :icon="eyeIcon(displayPass)" class="absolute text-gray-700 right-2 mt-1.5 text-center p-1 cursor-pointer" @click="displayPass = !displayPass"  /><br /> 
            </div>
            <button v-if="$route.query.routedFrom != 'archived'" @click="saveCredentials()" class="delay-75 duration-75 transition-all absolute right-5 mt-16 bg-button rounded cursor-pointer p-2 mb-4 px-5 text-panelsText text-center font-semibold uppercase">
                <font-awesome-icon icon="fas fa-spinner" class="animate-spin" v-if="loading" /><font-awesome-icon v-else icon="fas fa-floppy-disk" /> Save
            </button>
        </div>
    </section>
    </div>
</template>

<script>
    export default {
        props: ['dialog'],
        data() {
            return {
                displayPass:false,
                loading:false
            }
        },
        methods: {
            eyeIcon(condition) {
                return !condition ? 'fas fa-eye' : 'fas fa-eye-slash';
            },
            squareIcon(condition) {
                return condition==0 ? 'fas fa-square' : 'fas fa-square-check';
            },
        }
    }
</script>