<template>
    <div class="w-full z-40 top-0 fixed h-screen mb-10 bg-bkg bg-opacity-90">
        <h1 class="text-contentText text-center mt-5 font-semibold text-3xl">{{ $t('please_select_a_company') }}</h1><br />
        <div class="w-96 mx-auto z-50 relative h-full overflow-y-auto">
            <ol class="h-max w-full mt-0 pb-20">
                <li v-for="(branch, index) in branches" :key="index" 
                    @click="selectedCompany(branch.companyID)"
                    class="bg-white text-contentText hover:bg-button hover:text-white p-1 px-4 rounded border border-sepBorder shaddow-md cursor-pointer font-semibold text-center whitespace-nowrap m-1 mt-2 ">
                    {{branch.companyName}}
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
    import { api_get_call } from "@/api.js";
    export default {
        data() {
            return {
                branch:null,
                branches:null
            }
        },
        methods: {
            async should_go_to_profile(token = this.$cookies.get('userToken'),c_id = this.$cookies.get('c_id')) {
                this.agency_status = await api_get_call(`${baseURL}users/?action=agency_profile_status&companyID=${c_id}&userToken=${token}`);
                //condition if exact tasks id=1 and id=2 are done. If not, redirect to profile page
                let profile_done_status = (this.agency_status.to_do.filter(task => task.done).length / this.agency_status.to_do.length * 100).toFixed(0);
                return parseInt(profile_done_status) < 100;
            },
            async selectedCompany(compID) {
                this.$cookies.set('c_id',compID, '14d');
                let go_to_profile = await this.should_go_to_profile(this.$cookies.get('userToken'),compID);
                console.log('should got to profile?', go_to_profile);
                if (go_to_profile) {
                    location.replace("/profile");
                } else if (this.$route.query && (this.$route.query.prevRoute == 'Login')) {
                    location.replace("/metrics");
                } else if (this.$route.query.prevRoute) {
                    if (this.$route.query.prevRoute == "/settings") {
                        location.replace("/metrics");
                    } else {
                        console.log(this.$route.query.prevRoute);
                        if (this.$route.query.prevRoute == "/profile") {
                            location.replace("/metrics");
                        } else {
                            location.replace(this.$route.query.prevRoute)
                        }
                    }
                } else {
                    location.replace("/metrics");
                }
            }
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        this.branches = result.data.branches;
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    } else if (parseInt(result.data.companyID_global)>0) {
                        let p = this.$route.query.prevRoute??'/metrics';
                        location.replace(p);
                    }
                    if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                    this.permissions = result.data.new_permissions??null;
                }, (error) => {
                console.log(error);
            })
        }
    }
</script>