<template>
    <div class="cursor-pointer" @click="$router.push('/')">
        <div class="text-2xl font-semibold -mb-3" style="font-size:23px;font-family: 'Exo', sans-serif;">Creator<span class="font-bold px-1" style="color:#10B981;font-size:28px;">X</span>one</div>
        <span class="font-lighter sm:contents" style="font-size:8px;">{{ $t('motto') }}</span>
    </div>
</template>

<script>
    export default {
        
    }
</script>