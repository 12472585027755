<template>
    <div class="">
        <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
        <headerfilter pageTitle="Sexting costs" @selectedMonth="selectedMonth = $event" @selectedPlatform="selectedPlatform = $event" @selectedTeam="selectedTeam = $event" @selectedGroup="selectedGroup = $event" @selectedEmpl="selectedEmpl = $event" @dateFrom="dateFrom = $event"/>

        <section class="sm:ml-24 mt-24 ml-5 mb-20 bg-squares text-panelsText p-1 px-7 rounded-xl">
            <h1 class="m-5 ml-0 font-bold text-xl">Sexting costs per model</h1>
            <div class="mt-5 pb-5">
                <table class="bg-tableBG border border-sepBorder" v-if="sextingCosts.length>0">
                    <thead>
                        <tr class="text-tableText border border-sepBorder">
                            <td class="text-left p-1 pl-4 rounded-l-lg border border-sepBorder">Name</td>
                            <td class="text-left p-1 border border-sepBorder">Platform</td>
                            <td class="text-left p-1 border border-sepBorder">Team</td>
                            <td class="text-left p-1 border border-sepBorder">Cost</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(costData,index) in sextingCosts" :key="index" class="border border-sepBorder" :class="index % 2 == 0 ? 'bg-tableRow1' : ''">
                            <td class="text-left p-2 pl-4 border border-sepBorder">{{costData.modelName}}</td>
                            <td class="px-2">
                                <slot v-if="costData.platform==1"><span class="hidden">O</span><img src="@/assets/images/of_logo.png" title="Onlyfans" alt="Onlyfans" class="h-5 mx-auto"/></slot>
                                <slot v-else-if="costData.platform==2"><span class="hidden">F</span><img src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="h-5 mx-auto"/></slot>
                            </td>
                            <td class="text-left p-1 border border-sepBorder">{{costData.teamName}}</td>
                            <td class="text-left p-1 font-bold border border-sepBorder">$ {{parseFloat(costData.sum_cost).toFixed(2)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue";
    import leftMenu from "@/components/LeftMenu.vue";
</script>

<script>
    export default {
        props: ['dateFrom','selectedEmpl','selectedGroup','selectedMonth','selectedTeam','selectedPlatform'],
        data() {
            return {
                sextarSalaries:[],
                sextingCosts: [],
                mounted:false,
                permissions: null,
            }
        },
        components() {
            headerfilter,
            leftMenu
        },
        watch: {
            selectedMonth: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    
                    this.getSextingCosts();
                }
            },
            selectedGroup: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    
                    this.getSextingCosts();
                }
            },
            selectedEmpl: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.loading = true;
                    
                    this.getSextingCosts();
                }
            },
            selectedTeam: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.loading = true;
                    
                    this.getSextingCosts();
                }
            },
            selectedPlatform: function(n,v) {
                if (this.mounted) {
                    this.loading = true;
                    
                    this.getSextingCosts();
                }
            }
        },
        methods: {
            xcheckIcon(condition) {
                return condition ? 'fas fa-check' : 'fas fa-xmark';
            },
            markAsPaid(salary_id,salary_index) {
                if (confirm("Mark as paid?")) {
                    this.$axios.patch(baseURL+"finance/", {
                        userToken: this.$cookies.get('userToken'),
                        action:'markAsPaid',
                        salaryID: salary_id,
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        this.sextarSalaries[salary_index].paid = true;
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            getSextingCosts() {
                this.$axios.get(baseURL+"finance/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        build:'sextingCosts',
                        selectedMonth: this.selectedMonth,
                        companyID: this.$cookies.get('c_id'),
                        platform: this.selectedPlatform??1
                    }
                }).then((result) => {
                    console.log(result);
                    if (result.status == 200) {
                        this.sextingCosts = result.data;
                    }
                }, (error) => {
                    console.log(error);
                });
            }
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
            }).then((result) => {
                if (result.data.selectBranch) {
                    let prev = this.$route.path;
                    this.$router.push({name:'Branches', query: { prevRoute: prev } });
                } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                    this.$cookies.set('userToken','');
                    window.location.href = '/';
                }
                if (result.data.myself) {this.myself = result.data.myself;}
                if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
            }, (error) => {
                console.log(error);
            });

            
            this.getSextingCosts();
        }, 
        mounted() {
            this.mounted = true;
        }
    }
</script>