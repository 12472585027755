<template>
    <div class="" v-if="settingsList.length>0">
        <div class="mb-3" v-for="(setting,index) in settingsList" :key="index">
            <strong class="text-base sm:text-xl font-semibold text-black">{{ setting.name }}</strong>
            <slot v-for="(settingOption,indexChild) in setting.children" :key="indexChild">
              <div class="text-sm sm:text-base m-4 ml-10 hover:text-black hover:font-semibold">
                <router-link
                  :to="{ name: settingOption.path}"
                  :aria-current-value="settingOption.path"
                  @click="openSettingOption(index,indexChild,settingOption.name)">
                  <font-awesome-icon :icon="settingOption.icon" class="mr-2 text-sm" />
                  <span> {{ settingOption.name }}</span>
                </router-link>
              </div>
            </slot>
        </div>
    </div>
    <div v-else class="w-full h-screen sm:w-80 sm:h-96 flex items-center justify-center">
      <font-awesome-icon icon="fas fa-spinner" class="animate-spin" />
    </div>
</template>

<script>
  export default {
    component() {
      groups,
      employees,
      logs,
      models,
      privileges,
      shifts
    },
    data() {
      return {
        settingsList: [],
        settingID: 0,
        headerName: '',
      }
    },
    methods: {
      openSettingOption(index,indexChild,name) {        
        this.settingID = this.settingsList[index].children[indexChild].id;
        this.headerName = name;
      },
      goBack() {
        this.settingID = 0;
      }
    },
    created() {
      if (!(this.$cookies.get('c_id'))) {
          var companyID = null;
      } else {
          var companyID = this.$cookies.get('c_id');
      }
      this.$axios.get(baseURL+"settingsget/", {
        params: {
          action: 'settingsOptions',
          userToken: this.$cookies.get('userToken'),
          companyID: companyID
        }
      }).then(result => {
        this.settingsList = result.data;
      }, (error) => {
        console.log(error);
      })
    }
  }
</script>