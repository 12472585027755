<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full mb-4 relative">
      <router-link :to="'../Settings'">
        <font-awesome-icon icon="fas fa-arrow-left" class="float-left text-lg p-2 text-button -mt-2" />
      </router-link>
      <h2 class="mt-2 text-xl text-contentText font-semibold text-center">Move models between branches</h2>
    </div>

    <section class="w-full sm:max-w-4xl">
        <label class="text-sm">Select a model</label><br />
        <input type="search" class="w-min mb-5 border-b border-sepBorder outline-0 py-1.5" placeholder="Search" v-model="selectedModelName" list="models" @change="loadModelGroups()" @click="selectedModelName = null" />
        <datalist id="models">
            <option v-for="(model,index) in allModels" :key="index" :value="model.code + ' (' + model.name + ')'" :class="model.pair_id"></option>
        </datalist>

        <slot v-if="activeGroups && activeGroups.length>0">
            <h2 class="text-lg font-semibold mb-5">History</h2>
            <div class="overflow-auto">
                <table class="text-tableText bg-tableBG whitespace-nowrap">
                    <tr class="text-center border-b border-sepBorder">
                        <th class="cursor-pointer text-center" >Group id &nbsp;</th>
                        <th class="cursor-pointer text-center" >Model code &nbsp;</th>
                        <th class="cursor-pointer">Company &nbsp; </th>
                        <th class="cursor-pointer">Group &nbsp; </th>
                        <th class="cursor-pointer">Date from &nbsp; </th>
                        <th class="w-1/5">Date to</th>
                        <th class="w-1/5">Action</th>
                    </tr>
                    <tr v-for="(group, index) in activeGroups" :key="index" class="border-b border-sepBorder">
                        <td>{{ group.groupID }}</td>
                        <td>{{ group.modelCode }}</td>
                        <td>{{ group.companyName }}</td>
                        <td>{{ group.groupName }}</td>
                        <td>{{ group.dateFrom }}</td>
                        <td>{{ group.dateTo }}</td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </slot>

        <section v-if="sModel">
            <h2 class="mt-7 text-lg font-semibold mb-3">Move to another company: </h2>
            
            <div class="w-max mb-5">
                <label class="text-sm">Moving date (date when the model is starting in the new branch)</label>
                <VueDatePicker :model-value="dateTo" @update:model-value="handleDateTo" :min-date="new Date()"></VueDatePicker>
            </div>

            <label class="text-sm">Company</label><br />
            <select v-model="moveTo.company" @change="loadGroups()" class="mt-1 mb-3 border-b border-sepBorder outline-0 py-1.5">
                <option value="0">Select company</option>
                <option v-for="(company,index) in companiesList" :key="index" :value="company.id">{{company.name}}</option>
            </select>
            <div v-if="moveTo.company && groupsList">
                <label>Group</label><br />
                <select v-model="moveTo.group"  @change="switchView($event, $event.target.selectedIndex)" class="mt-1 mb-3 border-b border-sepBorder outline-0 py-1.5">
                    <option v-for="(group,index) in groupsList" :key="index" :value="group.id">{{group.name}}</option>
                </select>
            </div>
            <div v-else-if="moveTo.company && !groupsList">
                No groups in this company
            </div>
            <slot class="mt-2 float-left" v-if="moveTo.company && groupsList && (groupsList.length>0 && groupIndex!=null) || moveTo.team">
                <div class="mt-1" v-if="(groupIndex!=-1 && groupsList[groupIndex].hasChildren == true) || moveTo.team">
                    <label>Team</label><br />
                    <select class="mt-1 clear-both border-b border-sepBorder outline-0 py-1.5" v-model="moveTo.team">
                        <option value="0">No team</option>
                        <option v-for="(team,index) in groupsList[groupIndex].children" :key="index" :value="team.id">{{ team.name }}</option>
                    </select>
                </div>
            </slot>

            <button v-if="moveTo.company && groupsList" @click="moveModel()" class="delay-75  duration-75 transition-all absolute right-20 bottom-10 p-2 px-5 text-lg rounded text-white font-medium bg-button">
                <font-awesome-icon icon="fas fa-spinner" v-if="loading" class="animate-spin" /> {{buttonValue}}
            </button>
        </section>
    </section>

</template>

<script>
    import { get_groups, verify_token } from '@/api.js'
    export default {
        data() {
            return {
                loading:false,
                modelData:null,
                sortColumn: null,
                allModels:null,
                sModel:null,
                activeGroups: null,
                companiesList:null,
                groupsList:null,
                teamsList:null,
                groupIndex:null,
                dateTo:new Date(),
                buttonValue: "Save changes",
                moveTo: {company:0,group:null,team:0},
                selectedModelName:null,
                myself:null
            }
        },
        methods: {
            handleDateTo(data) {
                this.dateTo = data;
            },
            moveModel() {
                this.loading = true;
                this.$axios.post(baseURL+"settingspost/", {                    
                    action: "moveModel",
                    moveTo: this.moveTo,
                    moveDate: this.$moment(this.dateTo).format('YYYY-MM-DD'),
                    m_pair_id: this.sModel,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    this.buttonValue = this.$t('saved');
                    let b_l = this.$t('save_changes');
                    setTimeout(() => this.loadModelGroups(), this.moveTo = {company:null,group:null,team:0}, this.buttonValue = b_l, 1000);
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            listAllModels() {
                this.loading = true;
                this.$axios.get(baseURL+"lists/", {
                    params: {
                        build: 'getAllModels',
                        access: 'd4sa6dd489asd7as84d9as8fas',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.allModels = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            listCompanies() {
                this.loading = true;
                this.$axios.get(baseURL+"lists/", {
                    params: {
                        build: 'getAllCompanies',
                        access: 'd4sa6dd489asd7as84d9as8fas',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.companiesList = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            loadModelGroups() {
                const model = this.allModels.find(b => b.code + ' (' + b.name + ')' === this.selectedModelName)
                if (model) {
                    this.sModel = model.pair_id;
                }
                this.loading = true;
                this.$axios.get(baseURL+"settingsget/", {
                    params: {
                        action: 'getActiveGroups',
                        modelID: this.sModel,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.activeGroups = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            async loadGroups() {
                if (this.moveTo.company) {
                    this.loading = true;
                    this.groupsList = await get_groups(this.$cookies.get("userToken"),this.moveTo.company);
                    this.loading = false;
                }
            },
            switchView(event, selectedIndex) {
                this.groupIndex = selectedIndex;
                this.moveTo.team = null;
            },
        },
        async created() {
            const user = await verify_token(this.$cookies.get('userToken'),this.$cookies.get('c_id'),'header');
            if (user && user.myself) {
                this.myself = user.myself;
            }

            if (this.myself.position != 1) {
                this.$router.push({name: 'Settings'});
            }

            this.listAllModels();
            this.listCompanies();
        }
    }
</script>

<style scoped>
    td {
        padding:5px 10px;
        text-align:left
    }
</style>