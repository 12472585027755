<template>
    <div class="w-full overflow-auto">
        <table class="text-tableText bg-tableBG">
            <tr>
                <th class="px-3">Level Name</th>
                <th class="px-3">Earnings From</th>
                <th class="px-3">Earnings To</th>
                <th class="px-3" v-if="salaryType == 1">Hourly wage</th>
                <th class="px-3">Bonus</th>
                <th class="px-3" v-if="salaryType == 2">$ / %</th>
                <th class="px-3">Edit</th>
                <th class="px-4">Remove</th>
            </tr>
            <tr v-for="(level, index) in salaryLevels" :key="index" class="px-3" :class="{'bg-tableRow1 text-tableRow1Text':index%2==0}">
                <td class="p-1" v-if="editIndex == index"><input class="w-36" v-model="level.levelName" placeholder="Name" /></td><td class="p-0.5 px-3" v-else>{{ level.levelName }}</td> 
                <td class="p-1" v-if="editIndex == index"><input class="w-36" v-model="level.limitFrom" /></td><td class="px-3" v-else>{{ level.limitFrom }}</td>
                <td class="p-1" v-if="editIndex == index"><input class="w-36" v-model="level.limitTo" /></td><td class="px-3" v-else>{{ level.limitTo }}</td>
                
                <slot v-if="salaryType ==1">
                    <td class="p-1" v-if="editIndex == index"><input class="w-24" type="number" v-model="level.wage" />$ </td><td class="px-3" v-else>$ {{ level.wage }}</td>
                    <td class="p-1" v-if="editIndex == index"><input class="w-16" type="number" v-model="level.percentageBonus" /> %</td><td class="px-3" v-else>{{ level.percentageBonus }} %</td>
                </slot>
                <slot v-else>
                    <td class="p-1" v-if="editIndex == index"><input class="w-24" type="number" v-model="level.bonus" /></td><td class="px-3" v-else>{{ level.bonus }}</td>
                    <td class="p-1" v-if="editIndex == index">
                        <select v-model="level.bonusType" class="w-16 mx-auto">
                            <option value="0">$</option>
                            <option value="1">%</option>
                        </select>
                    </td>
                    <td class="px-3" v-else>{{ level.bonusType == 0 ? '$':'%' }}</td>
                </slot>

                <td v-if="editIndex == index" class="cursor-pointer p-1 px-3" @click="saveSalary(index,level.id)">Save <font-awesome-icon icon="fas fa-floppy-disk" /></td>
                <td v-else class="cursor-pointer px-3" @click="editSalary(index)">
                    Edit <font-awesome-icon icon="fas fa-up-right-from-square" /></td>
                <td class="cursor-pointer text-center" v-if="editIndex != index" @click="deleteSalary(level.id, index)"><font-awesome-icon icon="fas fa-trash-can" /></td>
                <td v-else></td>
            </tr>
        </table>
        <div v-if="!addingNewLine && (salaryType == 1 || salaryType == 2)" @click="addLine()" class="bg-button rounded cursor-pointer p-2 mb-4 px-3 text-white text-center font-semibold mt-2 mr-4 float-left"><font-awesome-icon icon="fas fa-plus" /> &nbsp;Add a new record</div>
    </div>
</template>

<script>
    export default {
        props: ['salaryType','salary'],
        data() {
            return {
                addingNewLine:false,
                editIndex:null,
                salaryLevels:null
            }
        },
        methods: {
            addLine(){
                if (this.salaryLevels && this.salaryLevels.length>0) {
                    this.salaryLevels.push({id:null, levelName:null, limitFrom:0, limitTo:0, wage:0, percentageBonus:0,bonus:0,bonusType:0})
                } else {
                    this.salaryLevels = [{id:null, levelName:null, limitFrom:0, limitTo:0, wage:0, percentageBonus:0,bonus:0,bonusType:0}]
                }
                this.editIndex = this.salaryLevels.length-1
                this.addingNewLine = true;
            },
            editSalary(index) {
                this.editIndex = index
                this.addingNewLine = true;
            },
            saveSalary(index,salaryID) {
                if (parseFloat(this.salaryLevels[index].limitTo) > parseFloat(this.salaryLevels[index].limitFrom)) { 
                    this.rozdielOK = true;
                }
                if (this.salaryLevels[index].levelName == null || this.salaryLevels[index].limitTo == 0 ) {
                    alert('Please fill out required fields!')
                } else if (!this.rozdielOK) {
                    alert ("\"To\" must be a higher than \"From\"!")
                } else {
                    this.addingNewLine = false;
                    this.editIndex = null;
                    this.$axios.post(baseURL+"settingspost/", {
                        salary: this.salaryLevels[index],
                        action: 'saveSalary',
                        type: this.salaryType == 1 ? 'basic' : 'monthly',
                        salaryID: salaryID,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        if (salaryID == null) {
                            this.salaryLevels[index].id = result.data
                            console.log(this.salaryLevels[index]);
                        }
                    }, (error) => {
                        console.log(error);
                    }).finally(() => this.loading = false);

                    this.loading = true;
                }
            },
            deleteSalary(levelID, index) {
                if (confirm("Are you sure?")) {
                    this.$axios.post(baseURL+"settingspost/", {
                        action: 'removeSalaryLine',
                        type: this.salaryType == 1 ? 'basic' : 'monthly',
                        salaryID: levelID,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        console.log(result.data);
                        this.salary.splice(index,1)
                    }, (error) => {
                        console.log(error);
                    })
                }
            },
        },
        mounted() {
            this.salaryLevels = this.salary
        }
    }
</script>