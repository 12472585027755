<template>
    <div class="overflow-x-hidden">
        <header-panel />

        <div class="container mx-auto bg-white rounded-lg shadow-lg p-5 sm:p-10 mt-22">
            <h1 class="text-4xl font-bold text-left text-button">{{article.headline}}</h1>
            <div class="block xl:hidden mx-auto w-full bg-cover bg-center h-96 rounded mt-5" :style="`background-image:url('${article.image_url}')`">
            </div>
            <div class="flex items-center mt-5">
                <img :src="article.image_url" class="hidden xl:block h-156 rounded mr-8 mt-5 shadow-xl" :alt="article.keywords" />
                <div v-html="article.content"></div>
            </div>
        </div>
        <footer-panel />
    </div>
</template>

<script setup>
    import headerPanel from "@/components/landing/HeaderLanding.vue";
    import FooterPanel from "@/components/landing/FooterLanding.vue"
</script>

<script>
    import { api_get_call } from "@/api.js";
    export default {
        data() {
            return {
                article: {}
            }
        },
        components: {headerPanel, FooterPanel},
        async created() {
            //get article ID from url ../:articleID
            let articleID = this.$route.params.articleID;
            this.article = await api_get_call(`${baseURL}articles/?article_id=${articleID}`);
        }
    }
</script>