<template>
    <div class="container text-center mx-auto px-5">
        <h1 class="text-2xl font-bold mt-6 sm:mt-10">Sorry, we couldn't find the page you're looking for</h1>
        <img src="@/assets/images/404.svg" alt="404" class="h-96 mx-auto my-10" />
        <button @click.prevent="$router.go(-1)" class="border-2 border-growth text-growth font-bold p-1 px-5 text-lg rounded">Go back</button>
    </div>
</template>

<script>
    export default {
        
    }
</script>