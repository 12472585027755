<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full mb-4 relative">
      <router-link :to="'../Settings'">
        <font-awesome-icon icon="fas fa-arrow-left" class="absolute float-left text-lg p-2 text-button -mt-2" />
      </router-link>
      <h2 class="mt-2 text-xl text-contentText font-semibold text-center">Archived employees</h2>
    </div>

    <div>
        <table class="text-tableText bg-tableBG" id="employeeTable">
            <tr>
                <th onclick="sortTable(0,'employeeTable')" class="cursor-pointer"  @click="sortColumn=0">Code &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 0}" /></th>
                <th onclick="sortTable(1,'employeeTable')" class="w-1/3 cursor-pointer" @click="sortColumn=1">Name &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 1}" /></th>
                <th onclick="sortTable(2,'employeeTable')" class="cursor-pointer" @click="sortColumn=2">Assignment &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 2}" /></th>
                <th onclick="sortTable(3,'employeeTable')" class="cursor-pointer" @click="sortColumn=3">Position &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 3}" /></th>
                <th class="w-1/5">Return from archive</th>
            </tr>
            <tr class="cursor-pointer hover:bg-sepBorder" v-for="(empl, index) in archivedEmpl" :key="index" :class="{archived:empl.active== 0,'bg-tableRow1':index%2==0}">
                <td>{{ empl.id }}</td>
                <td><span class="w-4 h-4 rounded-full mt-1 float-left border border-sepBorder" :style="'background:'+empl.color"></span> {{ empl.name }}</td>
                <td v-if="empl.groupName == ''">All</td>
                <td v-else>{{ empl.groupName }}</td>
                <td>{{ empl.positionName }}</td>            
                <td class="cursor-pointer" @click="emplDeArchive(index)" v-if="empl.active == 0">Return from archive <font-awesome-icon icon="fas fa-undo" /></td>
                <td class="cursor-pointer" @click="emplArchive(index)" v-else>Archive <font-awesome-icon icon="fas fa-archive" /></td>
            </tr>
        </table>
    </div>
</template>

<script>
    import { archive_user, get_users } from "@/api"
    export default {
        data() {
            return {
                archivedEmpl: null,
                emplID: null,
                sortColumn: null,
                loading: false
            }
        },
        methods: {
            async emplArchive(index) {
                if (confirm("Are you sure you want to archive this user?")) { 
                    this.archivedEmpl[index].active=0 
                    await archive_user(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.archivedEmpl[index].active,this.archivedEmpl[index].id);
                }
            },
            async emplDeArchive(index) {
                if (confirm("Are you sure you want to return this user from archive?")) { 
                    this.archivedEmpl[index].active=1 
                    await archive_user(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.archivedEmpl[index].active,this.archivedEmpl[index].id);
                }
            }
        },
        async created() {
            this.loading = true;
            this.archivedEmpl = await get_users(this.$cookies.get('userToken'),this.$cookies.get('c_id'),0,null,null,null);
            this.loading = false;
        },
    }
</script>

<style scoped>
  td {
    padding:5px;
    border:1px solid #D1D5DB;
    text-align:center;
  }
</style>