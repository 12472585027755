<template>
    <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />

    <section v-if="agency_status" class="w-full relative py-3 bg-white shadow-lg flex justify-end items-center">
        <div class="flex" @click="modal.display = true, modal.type = 'profile_details'">
            <div class="mr-2 text-right cursor-pointer">
                <strong class="text-lg">{{branchName}}</strong> - {{myself.username}}<br />
                <span v-if="agency_status.trial" class="text-growth text-sm font-semibold">
                    Free Trial {{agency_status.trial_till ? 'ends ' + ($moment(agency_status.trial_till).format("DD.MM.YYYY") == $moment().format("DD.MM.YYYY") ? 'Today' : $moment(agency_status.trial_till).format("DD.MM.YYYY")) : null}}
                </span>
                <span v-else-if="agency_status.subscription_active" class="text-growth font-bold text-sm">
                    <font-awesome-icon icon="fas fa-circle-check" /> Active subscription
                </span>
                <span v-else class="text-decline font-bold text-sm">
                    <font-awesome-icon icon="fa-solid fa-triangle-exclamation" /> Expired subscription
                </span>
            </div>
            <div class="p-4 mr-1 cursor-pointer">
                <font-awesome-icon icon="fas fa-angle-down" />
            </div>
        </div>
    </section>

    <div class="w-full text-center top-1/2 absolute" v-if="loading">
        <font-awesome-icon icon="fas fa-spinner" class="animate-spin text-2xl" />
    </div>
    <slot v-else>
        <section v-if="profile_done_status<100" class="sm:ml-24 mt-10 px-2 sm:px-0 max-w-2xl">
            <h2 class="mb-1">Profile setup finished up to <strong>{{profile_done_status}}%</strong></h2>
            <div class="container h-3 mb-5 rounded bg-gray-200">
                <div class="h-3 bg-growth rounded" :style="'width:' + profile_done_status + '%'"></div>
            </div>
            <slot v-for="(task,index) in agency_status.to_do" :key="index">
                <div class="p-3 px-4 rounded border border-sepborder max-w-2xl mt-2 mb-4 relative" :class="task.done ? 'bg-gray-200 opacity-80': 'cursor-pointer'" @click="openModal(task)">
                    <h2 :class="task.done ? 'font-semibold text-base' : 'font-bold text-lg'">{{index+1}}. {{task.headline}}</h2>
                    <p class="mt-1" v-if="task.done == false">{{task.text}}</p>
                    <font-awesome-icon v-if="task.done" icon="fas fa-check fa-2xl" class="text-2xl absolute right-3 top-3 text-growth"/>
                </div>
            </slot>
        </section>
        <section v-else class="sm:ml-24 mt-10 px-2 sm:px-0 max-w-2xl">In this section you'll be able to update your account details, change password and much more.<br />Page in development.</section>
    </slot>

    <transition name="zoom-fade">
        <section v-if="modal.display" @click="modal.display = false" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
            <section @click.stop class="bg-white rounded-lg w-full max-w-md sm:max-w-5xl mx-auto my-auto p-8 sm:p-12 z-50 relative">
                <font-awesome-icon @click="modal.display = false" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
                <privileges-panel v-if="modal.type == 'permissions'" :noarrow="true" :position_id="3" />
                <shifts-settings v-if="modal.type == 'shifts'" :noarrow="true" @close="handleClose" />
                <employees-form v-if="modal.type == 'employee'" :noarrow="true" @close="handleClose" />
                <models-form v-if="modal.type == 'creator'" :noarrow="true" @close="handleClose" />
                <models-list v-if="modal.type == 'creator_tools'"/>
                <section v-if="modal.type == 'profile_details'" class="grid grid-cols-2 gap-10">
                    <div v-if="[1,2].includes(myself.position)" class="border-r border-sepBorder pr-5">
                        <h2 class="text-xl mb-3 font-semibold text-button">Subscription</h2>
                        <slot v-if="cancel_subscription_block == false">
                            <div v-if="agency_status.trial" class="mb-3">
                                <strong>Your Free Trial will end on {{agency_status.trial_till ? 'till ' + ($moment(agency_status.trial_till).format("DD.MM.YYYY") == $moment().format("DD.MM.YYYY") ? ' Today' : $moment(agency_status.trial_till).format("DD.MM.YYYY")) : null}}</strong><br />
                                <span class="text-sm">After your free trial ends, you'll need to pay a small fee to keep using our services.<br />
                                At the end of your billing month, you'll be charged based on how much you used our services this month.</span>
                            </div>
                            <div v-else-if="agency_status.subscription_active" class="mb-3">
                                <strong>{{agency_status.next_charge_date ? 'You will be billed next on ' + $moment(agency_status.next_charge_date).format("DD.MM.YYYY") : null}}</strong><br />
                            </div>
                            <div v-else class="mb-3">
                                <strong>Your subscription has expired</strong><br />
                                <span class="text-sm">You can renew your subscription at any time. Your subscription will be renewed for the next month.</span>
                            </div>
                            <button v-if="agency_status.subscription_active" @click="cancel_subscription_block = true" class="border opacity-70 border-decline text-decline p-1 px-2 rounded">Cancel subscription</button>
                            <button v-else @click="renew_subscription()" class="bg-growth text-white font-semibold p-1.5 px-2.5 rounded">Renew subscription</button>
                        </slot>
                        <slot v-else>
                            <select v-model="reason_to_cancel" class="outline-0 border border-sepBorder p-1 px-2 w-max rounded">
                                <option value="0">Select reason</option>
                                <option v-for="(reason, index) in cancelation_reasons" :key="index" :value="reason.value">{{reason.text}}</option>
                            </select><br />
                            <div class="mt-3" v-if="reason_to_cancel == 9">
                                <label class="mb-0.5 text-sm text-gray-500">Please state your reason</label>
                                <input type="text" v-model="reason_to_cancel_text" class="border border-sepBorder rounded p-1 mt-0.5 w-full sm:w-72" />
                            </div>
                            <button @click="cancel_subscription()" class="border border-decline text-decline rounded p-1.5 px-2.5 mt-4">Confirm cancellation</button>
                        </slot>
                        <router-link to="/billing">
                            <button class="block mt-5 border border-button text-button font-semibold rounded p-1.5 px-2.5" @click="modal.display = false"><font-awesome-icon class="mr-1" icon="fa-solid fa-arrow-up-right-from-square" /> Open Billing</button>
                        </router-link>
                    </div>
                    <div>
                        <h2 class="text-xl mb-3 font-semibold text-button">Profile details</h2>
                        <div v-if="[1,2].includes(myself.position)" class="">
                            <label class="text-sm text-gray-500">Company name</label><br />
                            <input type="text" v-model="branchName" class="border border-sepBorder rounded p-1 mt-0.5 w-full sm:w-72" />
                        </div>
                        <div class="mt-2">
                            <label class="text-sm text-gray-500">User name</label><br />
                            <input type="text" v-model="myself.username" class="border border-sepBorder rounded p-1 mt-0.5 w-full sm:w-72" />
                        </div>
                        <div class="mt-2">
                            <label class="text-sm text-gray-500">Email</label><br />
                            <input type="text" v-model="myself.email" class="border border-sepBorder rounded p-1 mt-0.5 w-full sm:w-72" />
                            <br />
                            <small class="text-gray-800"><font-awesome-icon icon="fa-solid fa-info" class="mx-1 text-black" /> By changing your email address here, it will also become your new login email address.</small>
                        </div>
                        <button @click="save_profile_changes()" class="bg-button text-white rounded p-1.5 px-2.5 mt-4">
                            <font-awesome-icon v-if="button_loading" icon="fas fa-spinner" class="animate-spin mr-2" />Save changes
                        </button>
                    </div>
                </section>
                <div v-if="modal.type == 'resend'" class="text-center">
                    <div class="mb-4">
                        Verification email has been sent to your email address.<br />Please check your inbox and follow the instructions.
                        <div class="italic mt-2">If you don't see the email, check your spam folder or click <strong>"Resend email"</strong>.</div>
                    </div>
                    <div class="flex w-max mx-auto">
                        <div v-if="!edit_email" class="bg-gray-100 border border-sepBorder rounded p-1.5 px-4 mr-3 text-center w-80">{{myself.email}}</div>
                        <input v-else type="email" class="border border-sepBorder rounded p-1.5 px-4 mr-3 text-center w-80" v-model="myself.email" />
                        <font-awesome-icon v-if="!edit_email" @click="edit_email = true" icon="fas fa-pen-to-square" class="mt-2.5 cursor-pointer" />
                        <font-awesome-icon v-else @click="edit_email = false" icon="fa-solid fa-check" class="mt-2.5 cursor-pointer" />
                    </div>
                    {{resend_message}}
                    <button @click="resendEmail(myself.email)" class="bg-button text-white rounded p-1.5 px-2.5 mt-4">
                        <font-awesome-icon v-if="button_loading" icon="fas fa-spinner" class="animate-spin mr-2" />Resend email
                    </button>
                </div>
            </section>
        </section>
    </transition>
</template>

<script setup>
    import leftMenu from "@/components/LeftMenu.vue"
    import privilegesPanel from "@/components/settings/privileges.vue"
    import shiftsSettings from "@/components/settings/shifts.vue"
    import employeesForm from "@/components/settings/employeesForm.vue"
    import modelsForm from "@/components/settings/modelsForm.vue"
    import modelsList from "@/components/settings/tools/models_on_tools.vue"
</script>

<script>
    import { api_get_call, api_post_call } from "@/api.js"
    export default {
        data() {
            return {
                permissions: null,
                modal: {display:false, type: null},
                myself: null,
                agency_status: null,
                profile_done_status: 100,
                edit_email: false,
                resend_message:null,
                button_loading:false,
                branchName: null,
                loading:true,
                cancel_subscription_block: false,
                cancelation_reasons: [
                    { value: 1, text: 'The price is too high.' },
                    { value: 2, text: 'I no longer need this product.' },
                    { value: 3, text: 'I am not satisfied with the product.' },
                    { value: 4, text: 'I switched to another product.' },
                    { value: 5, text: "It isn't a priority right now." },
                    { value: 6, text: 'I subscribed by mistake.' },
                    { value: 8, text: 'I am switching to another subscription plan.' },
                    { value: 9, text: 'Other' }
                ],
                reason_to_cancel: 0,
                reason_to_cancel_text: null
            }
        },
        components() {
            leftMenu,
            privilegesPanel,
            shiftsSettings,
            employeesForm,
            modelsForm,
            modelsList
        },
        methods: {
            async save_profile_changes() {
                this.button_loading = true;
                let response = await api_post_call(`${baseURL}users/`, {
                    action: 'update_profile',
                    companyID: this.$cookies.get('c_id'),
                    userToken: this.$cookies.get('userToken'),
                    branchName: this.branchName,
                    email: this.myself.email,
                    username: this.myself.username
                });
                if (response.success) {
                    this.modal.display = false;
                }
                setTimeout(() => {
                    this.button_loading = false;
                }, 500);
            },
            async cancel_subscription() {
                if ((this.reason_to_cancel == 0) || (this.reason_to_cancel == 9 && !this.reason_to_cancel_text)) {
                    alert('Please select a reason for cancellation');
                    return;
                }
                if (confirm('Are you sure you want to cancel your subscription?')) {
                    this.cancel_subscription_block = false;
                    this.agency_status.subscription_active = false;
                    await api_post_call(`${baseURL}billing/`, {
                        action: "cancel_subscription", 
                        companyID: this.$cookies.get('c_id'), 
                        userToken: this.$cookies.get('userToken'), 
                        reason: this.reason_to_cancel, 
                        reason_text: this.reason_to_cancel_text
                    });
                }
            },
            async renew_subscription() {
                this.modal.display = false;
                this.agency_status.subscription_active = true;
                await api_post_call(`${baseURL}billing/`, {
                    action: "renew_subscription", 
                    companyID: this.$cookies.get('c_id'), 
                    userToken: this.$cookies.get('userToken')
                });
                alert('Subscription renewal started. We will try to charge your card again. If the payment fails, we will send you an email with a link to update your payment method.');
            },
            handleClose() {
                this.modal.display = false;
            },
            async resendEmail() {
                this.button_loading = true;
                const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
                if (!emailRegex.test(this.myself.email)) {
                    alert('Invalid email format');
                    return;
                }
                let response = await api_post_call(`${baseURL}users/`, {
                    action: 'resend_verification_email',
                    email : this.myself.email,
                    companyID: this.$cookies.get('c_id'),
                    userToken: this.$cookies.get('userToken')
                });
                if (!response.success) {
                    this.resend_message = response.message;
                } else {
                    this.modal.display = false;
                }
                setTimeout(() => {
                    this.button_loading = false;
                }, 500);
            },
            async openModal(task) {
                if (!task.done) {
                    if (task.action == 'payment') {
                        let response = await api_get_call(`${baseURL}billing/?action=generate_purchase_link&companyID=${this.$cookies.get('c_id')}&userToken=${this.$cookies.get('userToken')}`);
                        if (response.success) {
                            window.open(response.purchase_link, '_blank');
                        }
                        return;
                    }
                    this.modal.type = task.action;
                    this.modal.display = true;
                }
            }
        },
        async created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
            }).then((result) => {
                if (result.data.selectBranch) {
                    let prev = this.$route.path;
                    this.$router.push({name:'Branches', query: { prevRoute: prev } });
                } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                    this.$cookies.set('userToken','');
                    window.location.href = '/';
                }
                if (result.data.myself) {this.myself = result.data.myself; this.branchName = result.data.branchName;}
                if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
            }, (error) => {
                console.log(error);
            });
        },
        async mounted() {
            setTimeout(async() => {
                this.agency_status = await api_get_call(`${baseURL}users/?action=agency_profile_status&companyID=${this.$cookies.get('c_id')}&userToken=${this.$cookies.get('userToken')}`);
                this.profile_done_status = (this.agency_status.to_do.filter(task => task.done).length / this.agency_status.to_do.length * 100).toFixed(0);
                this.loading = false;
            }, 1000);
        }
    }
</script>