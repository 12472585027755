<template>
    <span>
        <font-awesome-icon :icon="icon" class="text-button cursor-pointer" :class="iconClass" @mouseenter="displayLegend = true"  @mouseleave="displayLegend = false" />
        <div v-show="displayLegend==true" class="bg-button text-buttonText absolute mt-0.5 p-1 rounded text-sm whitespace-nowrap" :class="classBand">{{ message }}</div>
    </span>
</template>
<script>
    export default {
        props: ['msg','icon','classBand','iconClass'],
        watch: {
            classBand(){
                if (this.icon == 'fa-regular fa-copy') {
                    this.message = "Copied to clipboard!";
                    setTimeout(() => {
                        this.message = "Copy link";
                    }, 3000);
                }
            }
        },
        data() {
            return {
                displayLegend:false,
                message: this.msg
            }
        }
    }
</script>