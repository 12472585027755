<template>
  <section class="bg-bkg bg-opacity-20 text-contentText pt-12 border border-white mt-10" id="benefits">
    <div class="max-w-7xl mx-auto px-5 sm:px-2">
      <h1 class="hidden text-xl sm:text-3xl font-bold mb-8">{{ $t('benefits_headline') }}</h1>
      
      <section v-for="(benefit, index) in new_benefits" :key="index" class="grid sm:grid-cols-2 items-center gap-x-10 mb-10">
        <img class="hidden sm:block" :alt="benefit.h3" :title="benefit.h3" v-if="index%2==0" :src="benefit.image" @click="detail = true, selectedImage = index" />
        <div>
          <h3 class="font-bold text-3xl" v-html="benefit.h2"></h3>
          <h4 class="font-light text-lg mb-3">{{benefit.h3}}</h4>
          <img class="sm:hidden my-2 cursor-pointer w-full" :alt="benefit.h3" :title="benefit.h3" :src="benefit.image" @click="detail = true, selectedImage = index" />
          <span v-for="(p,i) in benefit.p" :key="i" class="flex">
            <font-awesome-icon icon="fas fa-check" class="text-growth mr-1.5 mt-1" /> <p v-html="p"></p><br />
          </span>
          <router-link class="bg-growth w-max p-1.5 px-3 text-white font-semibold rounded-lg mt-5 block border border-growth hover:bg-transparent hover:text-growth shadow-lg" v-if="benefit.link" :to="benefit.link">{{ $t('show_more') }}</router-link>
        </div>
        <img class="hidden sm:block" v-if="index%2==1" :alt="benefit.h3" :title="benefit.h3" :src="benefit.image" @click="detail = true, selectedImage = index" />
        <hr class="mt-5 sm:hidden" noshade="noshade" />
      </section>
    </div>
  </section>

  <transition name="fade-in">
    <section class="w-screen h-screen fixed z-50 top-0 left-0 bg-gray-500 bg-opacity-50 flex items-center justify-center backdrop-blur-md" v-if="detail" @click="detail=false">
      <font-awesome-icon icon="fas fa-xmark" class="text-4xl cursor-pointer absolute text-white right-0 top-0 p-10" />
      <img :alt="new_benefits[selectedImage]?.h3" :title="new_benefits[selectedImage]?.h3" :src="new_benefits[selectedImage]?.image" class="container max-h-screen w-full mx-auto" />
    </section>
  </transition>
</template>

<script>
import mutliPlatform from '@/assets/images/multi_platform_access.svg';
import trackingLinks from '@/assets/images/tracking_links.svg';
import s4s from '@/assets/images/s4s.svg';
import chatterMate from '@/assets/images/fanpad.svg';
import shifts from '@/assets/images/shifts.svg';
import metrics from '@/assets/images/metrics.svg';

export default {
  data() {
    return {
      theme:false,
      selectedImage: null,
      detail:false,
      new_benefits: [
        {
          image: mutliPlatform, 
          h2: this.$t('all_benefits[0].headline'),
          h3: this.$t('all_benefits[0].headline_sub'),
          p: [this.$t('all_benefits[0].p[0]'),
            this.$t('all_benefits[0].p[1]'),
            this.$t('all_benefits[0].p[2]'),
          ],
          link: 'multi_platform'
        },
        {
          image: s4s,
          h2: this.$t('all_benefits[1].headline'),
          h3: this.$t('all_benefits[1].headline_sub'),
          p: [this.$t('all_benefits[1].p[0]'),
            this.$t('all_benefits[1].p[1]'),
            this.$t('all_benefits[1].p[2]'),
          ],
          link: 'reposting'
        },
        {
          image: trackingLinks,
          h2: this.$t('all_benefits[2].headline'),
          h3: this.$t('all_benefits[2].headline_sub'),
          p: [this.$t('all_benefits[2].p[0]'),
            this.$t('all_benefits[2].p[1]'),
            this.$t('all_benefits[2].p[2]'),
          ],
          link: 'traffic_tracking'
        },
        {
          image: chatterMate, 
          h2: this.$t('all_benefits[3].headline'),
          h3: this.$t('all_benefits[3].headline_sub'),
          p: [this.$t('all_benefits[3].p[0]'),
            this.$t('all_benefits[3].p[1]'),
            this.$t('all_benefits[3].p[2]'),
          ],
          link: 'chattermate'
        },
        {
          image: shifts, 
          h2: this.$t('all_benefits[4].headline'),
          h3: this.$t('all_benefits[4].headline_sub'),
          p: [this.$t('all_benefits[4].p[0]'),
            this.$t('all_benefits[4].p[1]'),
            this.$t('all_benefits[4].p[2]'),
          ],
          link: 'employees_shifts'
        },
        {
          image: metrics, 
          h2: this.$t('all_benefits[5].headline'),
          h3: this.$t('all_benefits[5].headline_sub'),
          p: [this.$t('all_benefits[5].p[0]'),
            this.$t('all_benefits[5].p[1]'),
            this.$t('all_benefits[5].p[2]'),
          ],
        },
      ],
    };
  },
  computed: {
    randomIconClass() {
      const icons = [
          "fa-spider",
          "fa-ghost",
          "fa-skull-crossbones",
          "fa-broom",
          "fa-crow",
          "fa-hat-wizard",
          "fa-book-skull",
          "fa-skull"
      ];
      
      // Get a random index from the icons array
      const randomIndex = Math.floor(Math.random() * icons.length);
      return `fas ${icons[randomIndex]} mr-1`;
      }
  },
};
</script>
