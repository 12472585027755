<template>
    <div>
        <h3 class="text-2xl font-bold text-center mb-4">Add new Trial Link</h3>
        <form>
            <div class="grid grid-cols-1 gap-4">
                <div>
                    <label for="trialLinkName" class="block text-sm font-medium text-gray-700">Trial Link Name</label>
                    <input type="text" id="trialLinkName" name="trialLinkName" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                
            </div>
            <div class="text-center mt-5">
                <button type="submit" class="bg-button text-buttonText ml-5 border border-sepBorder rounded p-1 px-3 -mt-1 cursor-pointer">Submit</button>
            </div>
            
        </form>
    </div>
</template>
<script>
export default {
    props: ['modelID','companyID'],
    data() {
        return {
        };
    },
    methods: {
        // Your component's methods go here
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
};
</script>