<template>
    <div class="overflow-x-hidden">
        <header-panel />

        <div class="container mx-auto bg-white rounded-lg shadow-lg p-5 sm:p-10 mt-22 grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5">
            <div @click="$router.push({ name: 'ArticleDetail', params: { articleID: article.headline.replace(/\s+/g, '-') } });" class="border border-sepBorder shadow p-6 rounded cursor-pointer" v-for="(article,index) in articles" :key="index">
                <div class="bg-cover bg-center w-full h-36 mb-3 rounded" :style="`background-image:url(${article.image_url})`"></div>
                <h2 class="text-lg font-bold">{{article.headline}}</h2>
            </div>
        </div>
        <footer-panel />
    </div>
</template>

<script setup>
    import headerPanel from "@/components/landing/HeaderLanding.vue";
    import FooterPanel from "@/components/landing/FooterLanding.vue"
</script>

<script>
    import { api_get_call } from "@/api.js";

    export default {
        data() {
            return {
                articles: [],
            }
        },
        components: {headerPanel, FooterPanel},
        methods: {  
            async fetchArticles(limit,offset) {
                this.articles = await api_get_call(`${baseURL}articles/?action=get_all_articles&limit=${limit}&offset=${offset}`);
            }
        },
        async created() {
            this.fetchArticles(10,0);
        }
    }
</script>