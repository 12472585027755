<template>
    <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
    <headerfilter :pageTitle="'Shifts'" @selectedModel="selectedModel = $event" @selectedTeam="selectedTeam = $event" @selectedGroup="selectedGroup = $event" @dateFrom="dateFrom = $event" @dateTo="dateTo = $event" />
    <div class="mt-20" v-if="permissions && $getPermission(permissions,4).is_granted">
        <button @click="shiftSettingsModal = true" class="hover:scale-105 transition ease-in-out z-10 bg-button text-white rounded p-1.5 px-5 ml-3 sm:ml-20 cursor-pointer" v-if="permissions && $getPermission(permissions,5).is_granted"><font-awesome-icon icon="fa-solid fa-gears" /> {{ $t('Settings') }}</button>
        <shiftsTable :selectedModel="selectedModel" :myself="myself" :permissions="permissions" :selectedTeam="selectedTeam" :selectedGroup="selectedGroup" :dateFrom="dateFrom" :dateTo="dateTo"/>
    </div>
    <div v-if="permissions != null && (!$getPermission(permissions,4).is_granted)" class="text-center mt-52 text-textOnBkg">{{ $t("no_permission") }}</div>

    <transition v-if="permissions && $getPermission(permissions,5).is_granted" name="zoom-fade">
        <section v-if="shiftSettingsModal" @click="shiftSettingsModal = false" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
            <section @click.stop class="bg-white rounded-lg w-full max-h-screen max-w-md sm:max-w-5xl mx-auto my-auto p-8 sm:p-12 z-50 overflow-auto relative">
                <font-awesome-icon @click="shiftSettingsModal = false" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
                <shifts-settings :noarrow="true" @close="handleClose" />
            </section>
        </section>
    </transition>
</template>

<script setup>
    import shiftsTable from "@/components/shifts/shiftsTable.vue"
    import headerfilter from "@/components/headerFilter.vue"
    import leftMenu from "@/components/LeftMenu.vue"
    import shiftsSettings from "@/components/settings/shifts.vue"
</script>

<script>
    export default {
        props: ['selectedModel','selectedGroup','dateFrom','dateTo','selectedTeam'],
        components() {
            shiftsTable,
            headerfilter,
            leftMenu,
            shiftsSettings
        },
        data() {
            return {
                groupList: [],
                modelsList: [],
                unselectGroups: false,
                permissions:null,
                myself:null,
                shiftSettingsModal:false,
            }
        },
        methods: {
            handleClose() {
                this.shiftSettingsModal = false;
            }
        },
        async mounted() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
            }).then((result) => {
                if (result.data.selectBranch) {
                    let prev = this.$route.path;
                    this.$router.push({name:'Branches', query: { prevRoute: prev } });
                } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                    this.$cookies.set('userToken','');
                    window.location.href = '/';
                }
                if (result.data.myself) {this.myself = result.data.myself;}
                if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
            }, (error) => {
                console.log(error);
            });
        }
    }
</script>