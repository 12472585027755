<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      user: {}
    }
  },
    mounted() {
        function initFreshChat() {
            let init = {
                token: "494daac6-2106-4ec2-8521-63e67e0b6e61",
                host: "https://creatorxone.freshchat.com"
            };
            if (this.user != null) {
                init.externalId = this.user.myself.id;
                init.firstName = this.user.myself.username;
                init.email = this.user.myself.email;
                init.properties = {
                    company: this.user.branchName,
                    companyID: this.user.companyID_global,
                    position: this.user.myself.position,
                    permissions: this.user.permissions,
                };
            }

            window.fcWidget.init(init);
        }

        if (this.$cookies.get('userToken') != null) {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            //setup user details for Freshchat
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    this.user = result.data;
                    if (window.addEventListener) {
                        window.addEventListener("load", initiateCall, false);
                    } else if (window.attachEvent) {
                        window.attachEvent("onload", initiateCall);
                    }
                }, (error) => {
                console.log(error);
            });
        } else {
            if (window.addEventListener) {
                window.addEventListener("load", initiateCall, false);
            } else if (window.attachEvent) {
                window.attachEvent("onload", initiateCall);
            }
        }
        
        function initialize(i, t) {
            var e;
            if (i.getElementById(t)) {
                initFreshChat();
            } else {
                (e = i.createElement("script")).id = t;
                e.async = true;
                e.src = "https://creatorxone.freshchat.com/js/widget.js";
                e.onload = initFreshChat;
                i.head.appendChild(e);
            }
        }

        function initiateCall() {
            initialize(document, "Freshchat-js-sdk");
        }
    }
}
</script>

<style>
#app {  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  color: #2c3e50;  
}
</style>