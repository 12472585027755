<template>
    <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
    <headerfilter :pageTitle="'Settings'" />
    <div class="mx-auto w-full px-2 sm:mx-0 sm:w-max sm:ml-22 mt-24">
      <section v-if="loaded" class="border-2 border-sepBorder text-panelsText bg-squares text-base rounded-xl mt-6 mb-10 p-4 sm:p-8 px-2 sm:px-14 relative">
        <router-view :key="$route.path"></router-view>
      </section>
    </div>
</template>

<script setup>  
  import headerfilter from "@/components/headerFilter.vue"
  import leftMenu from "@/components/LeftMenu.vue"
</script>

<script>
import {verify_token} from "@/api.js"
export default {
  components() {
    headerfilter,
    leftMenu
  },
  data() {
    return {
      showHeader: false,
      loaded:false,
      permissions:null,
      myself:null,
    }
  },
  async created() {
    if (!(this.$cookies.get('c_id'))) {
        var companyID = null;
    } else {
        var companyID = this.$cookies.get('c_id');
    }
    this.$axios.get(baseURL+"opravnenia/userToken.php", {
        params: {
            userToken: this.$cookies.get('userToken'),
            companyID: companyID,
            place: 'header'
        }
    }).then((result) => {
        if (result.data.selectBranch) {
            let prev = this.$route.path;
            this.$router.push({name:'Branches', query: { prevRoute: prev } });
        } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
            this.$cookies.set('userToken','');
            window.location.href = '/';
        }
        if (result.data.myself) {this.myself = result.data.myself;}
        if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
        if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
    }, (error) => {
    console.log(error);
    });
    this.loaded = true;
  },
}
</script>