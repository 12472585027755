<template>
    <div class="whitespace-nowrap absolute w-max z-20 bg-gray-200 -ml-4 text-left mt-1 p-2 px-5 rounded text-black">
        <slot v-for="(dataObj,index) in data[0]" :key="index">
            <span v-if="action == 0" v-html="dataObj.value" class="float-left"></span>
            <input v-else v-model="dataObj.value" v-on:keyup.enter="saveComment(dataObj.commentID)" ref="commentInput" />
            <br />
        </slot>
    </div>
</template>

<script>
    export default {
        props:['data','incommingAction'],
        data() {
            return {
                hoverCommentIndex: null,
                action:0
            }
        },
        methods: {
            edit() {
                this.action = 1;
            },
            removeComment(comment_id) {
                if (confirm("Are you sure?")) {
                    this.$axios.post(baseURL+"metricsUpdate/", {                    
                        action: "deleteMetricsComment",
                        commentID: comment_id,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        console.log(result.data);
                    });
                }
            },
            saveComment(comment_id) {
                this.action = 0;
                console.log(this.dataObj.value);
                this.$axios.post(baseURL+"metricsUpdate/", {                    
                    action: "saveMetricsComment",
                    comment: this.dataObj.value,
                    commentID: comment_id,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    console.log(result.data);
                });
            }
        },created() {
            if (this.incommingAction) {
                this.action = this.incommingAction;
            }
        }
    }
</script>