import { createRouter,createWebHistory } from 'vue-router'
import SextingCosts from '/src/views/SextingCosts.vue'
import Metrics from '/src/views/Metrics.vue'
import Stexting from '/src/views/Stexting.vue'
import Shifts from '/src/views/Shifts.vue'
import Settings from '/src/views/Settings.vue'
import Login from '/src/views/Login.vue'
import Analytics from '/src/views/Analytics.vue'
import Transactions from '/src/views/Transactions.vue'
import models from '/src/components/settings/models.vue'
import modelsForm from '/src/components/settings/modelsForm.vue'
import settingsPanel from '/src/components/settings/settingsPanel.vue'
import groups from '/src/components/settings/groupsSettings.vue'
import employees from '/src/components/settings/employees.vue'
import employeesForm from '/src/components/settings/employeesForm.vue'
import salary from '/src/components/settings/salary.vue'
import logs from '/src/components/settings/logs.vue'  
import privileges from '/src/components/settings/privileges.vue'
import shifts from '/src/components/settings/shifts.vue'
import marketingSetting from "/src/components/settings/marketingSetting.vue"
import companySettings from '/src/components/settings/companySettings.vue'
import employeesArch from '/src/components/settings/employeesArch.vue'
import moveModels from '/src/components/settings/moveModels.vue'
import sessionManagement from '/src/components/settings/sessionManagement.vue'
import taskScheduler from '/src/components/settings/scheduler.vue'
import archivedModels from "/src/components/settings/archivedModels.vue"
import addSocial from "/src/components/settings/socialsForm.vue"
import socials from "/src/components/settings/socials.vue"
import Marketing from '/src/views/Marketing.vue'
import BranchPicker from '/src/views/BranchPicker.vue'
import Tools from '/src/views/Tools.vue'
import MetricsSummary from '/src/views/MetricsSummary.vue'
import Payrolls from '/src/views/Payrolls.vue'
import Verify from '/src/views/Verify.vue'
import Tos from '/src/views/Tos.vue'
import TrackingLinks from '/src/views/TrackingLinks.vue'
import TrialLinks from '@/views/TrialLinks.vue'
import Creators from '/src/views/Creators.vue'
import Employees from '@/views/Employees.vue'
import Profile from '/src/views/Profile.vue'
import NotFound from '/src/views/NotFound.vue'
import ChattingScripts from '/src/views/ChattingScripts.vue'
import Reposting from '/src/views/Features/Reposting.vue'
import Multi_platform from '/src/views/Features/Multi_platform.vue'
import Traffic_tracking from '/src/views/Features/Traffic_tracking.vue'
import ChatterMate from '/src/views/Features/Chattermate.vue'
import EmployeesLanding from '/src/views/Features/Employees.vue'
import Billing from '/src/views/Billing.vue'
import Articles from '@/views/Articles.vue'
import ArticleDetail from '@/views/ArticleDetail.vue'

const routes = [
    {path: '/billing', name: 'Billing', component: Billing},
    {path: '/reposting', name: 'Reposting', component: Reposting},
    {path: '/multi_platform', name: 'Multi_platform', component: Multi_platform},
    {path: '/traffic_tracking', name: 'Traffic_tracking', component: Traffic_tracking},
    {path: '/chattermate', name: 'ChatterMate', component: ChatterMate},
    {path: '/employees_shifts', name: 'EmployeesLanding', component: EmployeesLanding},
    {path: '/sextingcosts', name: 'SextingCosts', component: SextingCosts},
    {path: '/payrolls', name: 'Payrolls', component: Payrolls},
    {path: '/tools', name: 'Tools', component: Tools},
    {path: '/metrics', name: 'Metrics', component: Metrics},
    {path: '/metricssummary', name: 'MetricsSummary', component: MetricsSummary},
    {path: '/analytics', name: 'Analytics', component: Analytics},
    {path: '/sexting', name: 'Stexting', component: Stexting},
    {path: '/shifts', name: 'Shifts', component: Shifts, props:true},
    {path: '/transactions', name: 'Transactions', component: Transactions},
    {path: '/branch', name: 'Branches', component: BranchPicker},
    {path: '/creators', name: 'Creators', component: Creators},
    {path: '/profile', name: 'Profile', component: Profile},
    {path: '/trackingLinks', name: 'TrackingLinks', component: TrackingLinks},
    {path: '/trialLinks', name: 'TrialLinks', component: TrialLinks},
    {path: '/employees', name: 'Employees', component: Employees},
    {path: '/chattingScripts', name: 'ChattingScripts', component: ChattingScripts},
    {path: '/articles', name: 'ArticlesList', component: Articles},
    {path: '/articles/:articleID', name: 'ArticleDetail', component: ArticleDetail},
    {path: '/settings', name: 'Settings', component: Settings,
        children: [
            {
                path: '',
                name: 'settingsPanel',
                component: settingsPanel
            },
            {
                name: 'menu',
                path: 'menu',
                component: settingsPanel
            },
            {   
                name: 'settingsGroups',
                path: 'groups',
                component: groups
            },
            {
                name: 'taskScheduler',
                path: 'taskScheduler',
                component: taskScheduler
            },
            {
                name: 'sessionManagement',
                path: 'sessionManagement',
                component: sessionManagement
            },
            {
                name: 'marketingSetting',
                path:'marketingSetting',
                component: marketingSetting
            },
            {
                name: 'models',
                path:'models',
                component: models
            },
            {
                name: 'addSocial',
                path:'socials/form',
                component: addSocial,
                props:true
            },
            {
                name: 'socials',
                path:'socials',
                component: socials
            },
            {
                name: 'archivedModels',
                path: 'archivedModels',
                component: archivedModels
            },
            {
                name: 'moveModels',
                path:'moveModels',
                component: moveModels
            },
            {
                name: 'salary',
                path:'salary',
                component: salary
            },
            {
                name: 'employees',
                path:'employees',
                component: employees,
                props:true
            },
            {
                name: 'employeesArchive',
                path:'archivedEmployees',
                component: employeesArch
            },
            {
                name:'employeesForm',
                path:'employees/form',
                component: employeesForm,
                props:true
            },
            {
                name:'modelsForm',
                path:'models/form',
                component: modelsForm,
                props:true
            },
            {
                name: 'logs',
                path:'logs',
                component: logs
            },
            {
                name: 'shifts',
                path:'shifts',
                component: shifts,
                props:true,
            },
            {
                name:'companySettings',
                path:'companySettings',
                component: companySettings
            },
            {
                name: 'privileges', 
                path:'privileges',
                component: privileges,
                props:true
            }
        ]
    },
    {path: '/', name: 'Login', component: Login},
    {path: '/verify', name: 'Verify', component: Verify},
    {path: '/tos', name: 'Tos', component: Tos},
    {path: '/not_found', name: 'NotFound', component: NotFound },
    { path: '/:pathMatch(.*)*', redirect: { name: 'NotFound' } },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else if (to.hash) {
          return new Promise((resolve) => {
            // Ensure the element is in the DOM before trying to scroll
            setTimeout(() => {
              const element = document.querySelector(to.hash);
              if (element) {
                // Calculate position with offset
                const top = element.getBoundingClientRect().top + window.pageYOffset - 50;
                resolve({ left: 0, top, behavior: 'smooth' });
              } else {
                resolve({ left: 0, top: 0, behavior: 'smooth' });
              }
            }, 0); // setTimeout with 0 delay ensures the DOM is updated
          });
        } else {
          return { left: 0, top: 0 };
        }
    }      
});

export default router