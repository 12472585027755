<template>
    <div>
        <font-awesome-icon :icon="icon" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                icon: null
            }
        },
        props: ['t'],
        created() {
            if (this.t == "Subscription") {
                this.icon = "fas fa-heart text-red-300";
            } else if (this.t == "Message") {
                this.icon = "fas fa-comments";
            } else if (this.t == "Tip") {
                this.icon = "fas fa-dollar-sign text-green-300";
            } else {
                this.icon = "fas fa-table-columns text-purple-300";
            }
        }
    }
</script>