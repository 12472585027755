<template>
    <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
    <header-filter :pageTitle="'Employee Profiles'" @selectedemployee="selectedemployee = $event" @selectedTeam="selectedTeam = $event" @selectedGroup="selectedGroup = $event" />

    <slot v-if="permissions && $getPermission(permissions,10).is_granted">
        <section v-if="topEmployees && topEmployees.length > 0 && !loading" class="hidden sm:block sm:ml-22 fixed w-60 text-center border border-sepBorder rounded h-full mt-2 py-5 pb-36 overflow-auto">
            <slot v-if="topEmployees && topEmployees.length > 0">
                <h2 class="font-bold text-2xl">{{ $t('top_employees') }}</h2>
                <span class="font-light">{{topEmployees_month.name}} <font-awesome-icon v-if="1==2" icon="fas fa-angle-down" /></span>
                <select class="bg-transparent font-light cursor-pointer outline-0 text-center" @change="getTopEmployees()" v-model="selectedTopMonth">
                    <option v-for="(month, index) in lastSixMonths" :key="index" :value="month.value">{{ month.label }} <font-awesome-icon icon="fas fa-angle-down" /></option>
                </select>
                <div class="grid grid-rows-3 gap-6 w-full mt-5">
                    <div v-for="(t_empl,t_i) in topEmployees" :key="t_i" class="text-center rounded text-center relative">
                        <div class="rounded-full h-36 w-36 border-6 mx-auto" :class="{'border-gold':t_i == 0, 'border-silver':t_i == 1, 'border-bronze':t_i == 2}" :style="'box-shadow: inset 0 0 5px rgba(0,0,0,0.3);'"><font-awesome-icon icon="fa-solid fa-trophy" class="text-7xl pt-8" :class="{'text-gold':t_i == 0, 'text-silver':t_i == 1, 'text-bronze':t_i == 2}" /></div>
                        <div class="p-1 px-3 w-max top-11 text-black absolute left-24 ml-1.5 font-bold text-xl text-center">{{t_i+1}}</div>
                        <h4 class="mt-1.5 w-56 mx-auto truncate">{{t_empl.name}}</h4>
                        <h3 class="font-bold text-2xl">$ {{parseFloat(t_empl.total_sales).toFixed(2)}}</h3>
                    </div>
                </div>
            </slot>
            <font-awesome-icon v-else-if="loading" icon="fas fa-spinner" class="animate-spin absolute top-1/3" />
        </section>
        <div class="mt-22" :class="topEmployees && topEmployees.length > 0 && !loading ? 'sm:ml-86' : 'sm:ml-22'">
            <button @click="employeeModal = true, employee_pair_id = null" class="mt-2.5 bg-button rounded cursor-pointer p-1.5 px-7 text-buttonText text-center font-semibold">
                <font-awesome-icon icon="fa-solid fa-plus" /> {{ $t('add_employee') }}
            </button>
            <button v-if="employeeDataFiltered.length>0 && permissions && $getPermission(permissions,11).is_granted" @click="showActive = !showActive" class="block ml-3 mt-2 rounded outline-0 cursor-pointer text-button text-center text-sm">
                <font-awesome-icon :icon="showActive ? 'fas fa-check-square' : 'far fa-square'" /> {{ $t('show_active_employees') }}
            </button>

            <div class="overflow-auto pr-3" style="height: calc(100vh - 155px);">
                <table v-if="employeeDataFiltered.length>0 && !loading" class="bg-transparent mt-3 mx-auto whitespace-nowrap rounded font-normal border border-sepBorder table-auto w-full">
                    <thead>
                        <tr>
                            <th class="w-28 pb-2 px-1 py-3">{{ $t('id') }}</th>
                            <th class="w-28 pb-2 px-2 pl-2 text-left py-3">{{ $t('tag') }}</th>
                            <th class="w-42 pb-2 text-left pl-2 py-3">{{ $t('name') }}</th>
                            <th class="pb-2 px-2 w-42 text-left py-3">{{ $t('designation') }}</th>
                            <th class="w-42 pb-2 text-left px-2.5 py-3 text-left">{{ $t('assignment') }}</th>
                            <th class="pb-2 px-4 text-left py-3">{{ $t('sales') }}</th>
                            <th class="pb-2 px-4 text-left py-3">{{ $t('hours') }}</th>
                            <th class="pb-2 px-4 text-left py-3">{{ $t('shifts') }}</th>
                            <th class="pb-2 px-4 text-left py-3">{{ $t('avg_sales_shift') }}</th>
                            <th class="pb-2 px-4 text-center py-3">{{ $t('action') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(employee,index) in employeeDataFiltered" :key="index" class="cursor-pointer hover:bg-gray-200" @click="editEmployee(employee.id)">
                            <td class="border-t border-l border-sepBorder px-3 text-center">{{employee.id}}</td>
                            <td class="p-2 border-t border-b"><div class="rounded h-5 w-12 shadow bg-gray-200" :style="{ backgroundColor: employee.color, backgroundSize: 'cover', backgroundPosition: 'center' }"></div></td>
                            <td class="p-2.5 border-t border-b border-sepBorder border-l-0 leading-5">
                                <div class="font-semibold whitespace-nowrap">{{employee.name}}</div>
                            </td>
                            <td class="px-2.5 border-t border-b text-left">{{ employee.positionName }}</td>
                            <td class="px-2.5 border-t border-b text-left">{{ employee.groupName }} {{ employee.teamName ? ' - ' + employee.teamName : null }}</td>
                            <td class="px-2.5 border-t border-b text-left">{{ employee.total_sales ? '$ ' + employee.total_sales.toFixed(2) : "-"}}</td>
                            <td class="px-2.5 border-t border-b text-center">{{ employee.hours ?? "-"}}</td>
                            <td class="px-2.5 border-t border-b text-center">{{ employee.shifts ?? "-"}}</td>
                            <td class="px-2.5 border-t border-b text-center">{{ employee.total_sales ? '$ ' + (parseFloat(employee.total_sales) / parseFloat(employee.shifts)).toFixed(2) : "-"}}</td>
                            <td class="px-2.5 border-t border-b border-sepBorder text-center text-button">
                                <slot v-if="((parseInt(employee.orderNo) > parseInt(myself?.orderNo)) || (employee.id == myself?.id)) && employee.active == 1">
                                    <span class=" px-2 cursor-pointer text-center" @click.stop="emplSuspend(0,index)" v-if="employee.suspended == 1"><font-awesome-icon icon="fas fa-circle-play" class="text-red-500" /></span>
                                    <span class=" px-2 cursor-pointer text-center" @click.stop="emplSuspend(1,index)" v-else><font-awesome-icon icon="fas fa-circle-pause" class="text-green-500" /></span>
                                </slot>
                                <slot v-if="((parseInt(employee.orderNo) > parseInt(myself?.orderNo)) || (employee.id == myself?.id)) && employee.suspended == 1 || employee.active == 0">
                                    <span class=" px-2 cursor-pointer text-center text-sm" @click.stop="emplArchive(1,index)" v-if="employee.active == 0">Restore <font-awesome-icon icon="fas fa-undo" /></span>
                                    <span class=" px-2 cursor-pointer text-center" @click.stop="emplArchive(0,index)" v-else><font-awesome-icon icon="fas fa-archive" /></span>
                                </slot>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-else-if="loading" class="text-center mt-10">
                    <font-awesome-icon icon="fas fa-spinner" class="animate-spin text-xl" />
                </div>
                <div v-else>
                    <h1 class="text-center text-2xl font-semibold text-contentText mt-10">{{ $t('no_employees_yet') }}</h1>
                </div>
            </div>

            <transition name="zoom-fade">
                <section v-if="employeeModal" @click="employeeModal = false; $router.replace({'query': null});" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
                    <section @click.stop class="bg-white rounded-lg w-full max-h-screen max-w-md sm:max-w-5xl mx-auto my-auto p-8 sm:p-12 z-50 overflow-auto relative">
                        <font-awesome-icon @click="employeeModal = false; $router.replace({'query': null});" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
                        <addEmployee :noarrow="false" :employee_id_prop="employee_pair_id" @close="handleClose" />
                    </section>
                </section>
            </transition>
        </div>
    </slot>
    <div v-else class="mt-36 text-center">{{ $t('no_permission') }}</div>
</template>

<script setup>
    import headerFilter from "@/components/headerFilter.vue"
    import addEmployee from "@/components/settings/employeesForm.vue"
    import leftMenu from '@/components/LeftMenu.vue'
</script>

<script>
    import { get_users, suspend_user, archive_user, get_top_employee } from '@/api';
    export default {
        props: ['selectedGroup'],
        components() {
            leftMenu, headerFilter, addEmployee
        },
        data() {
            return {
                employeeModal: false,
                employeeData: [],
                permissions:null,
                myself:null,
                employee_pair_id: null,
                loading:true,
                topEmployees:[],
                showActive:true,
                selectedTopMonth: null,
                topEmployees_month: {name: null, value: null},
            }
        },
        watch: {
            selectedGroup: function() {
                this.getEmployeeData();
            }
        },
        computed: {
            lastSixMonths() {
                let months = [];
                for (let i = 0; i < 6; i++) {
                    let month = this.$moment().subtract(i, 'months');
                    months.push({
                    value: month.format('MM-YYYY'),
                    label: month.format('MMMM YYYY')
                    });
                }
                return months;
            },
            employeeDataFiltered() {
                if (this.employeeData) {
                    if (this.showActive == true) {
                        return this.employeeData.filter(employee => employee.active == 1)
                    } else {
                        return this.employeeData;
                    }
                } else {
                    return [];
                }
            }
        },
        methods: {
            handleClose() {
                this.employeeModal = false;
                this.getEmployeeData();
            },
            async emplArchive(action, index) {
                if (confirm(this.$t('you_sure') + (action == 1 ? this.$t('archive_user') : this.$t('unarchive_user')))) { 
                    this.employeeData[index].active = action;
                    await archive_user(this.$cookies.get('userToken'),this.$cookies.get('c_id'),action,this.employeeData[index].id);
                }
            },
            async emplSuspend(action,index) {
                if (confirm(this.$t('you_sure') + (action == 1 ? this.$t('suspend') : this.$t('unsuspend')) + " " + this.$t('this_user') + "?")) {
                    this.employeeData[index].suspended = action;
                    await suspend_user(this.$cookies.get('userToken'), this.$cookies.get('c_id'), action, this.employeeData[index].id);
                }
            },
            editEmployee(pair_id = null) {
                if (this.permissions && !this.$getPermission(this.permissions,10).is_granted) {return;}
                this.$router.push({name: 'Employees', query: {'id': pair_id,'e':true} }), 
                this.employee_pair_id = pair_id;
                this.employeeModal = true;
            },
            async getEmployeeData() {
                console.log(this.selectedGroup);
                const activeEmployees = await get_users(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,this.selectedGroup,null,"0",null,null,1);
                let archivedEmployees = [];
                if (this.permissions && this.$getPermission(this.permissions,11).is_granted) {
                    archivedEmployees = await get_users(this.$cookies.get('userToken'),this.$cookies.get('c_id'),0,null,this.selectedGroup,null,"0",null,null,1);
                }
                this.employeeData = [...(Array.isArray(activeEmployees) ? activeEmployees : []), ...(Array.isArray(archivedEmployees) ? archivedEmployees : [])];
                this.loading = false;
            },
            async getTopEmployees() {
                let date = this.$moment(this.selectedTopMonth, "MM-YYYY");
                let startOfMonth = date.startOf('month').format('YYYY-MM-DD');
                let endOfMonth = date.endOf('month').format('YYYY-MM-DD');
                this.topEmployees = await get_top_employee(this.$cookies.get('userToken'),this.$cookies.get('c_id'), startOfMonth, endOfMonth,3);
            },
        },
        async created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
            }).then((result) => {
                if (result.data.selectBranch) {
                    let prev = this.$route.path;
                    this.$router.push({name:'Branches', query: { prevRoute: prev } });
                } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                    this.$cookies.set('userToken','');
                    window.location.href = '/';
                }
                if (result.data.myself) {this.myself = result.data.myself;}
                if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
            }, (error) => {
                console.log(error);
            });
        },
        async mounted() {
            if (this.permissions && (!this.$getPermission(this.permissions,9).is_granted || !this.$getPermission(this.permissions,9).is_granted)) { this.$router.go(-1); }
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() - 1); // Subtract 1 month from the current date
            const month = currentDate.getMonth() + 1; // Adding 1 to get month in range 1-12

            this.selectedTopMonth = `${month.toString().padStart(2, '0')}-${currentDate.getFullYear()}`;

            this.getEmployeeData();
            if (this.selectedTopMonth) {
                this.getTopEmployees();
            }
        }
    }
</script>

<style scoped>
    .zoom-fade-enter-active {
        transition: all .3s ease;
    }
    .zoom-fade-leave-active {
        transition: all .3s ease;
    }
    .zoom-fade-enter-from, .zoom-fade-leave-to {
        transform: scale(0.9);
        opacity: 0;
    }
</style>