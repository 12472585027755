<template>
    <div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 left-1/2" v-if="saved">{{ $t('saved') }}</div>

    <div class="w-full mb-4 relative">
      <router-link v-if="!noarrow" :to="'../../Settings'">
        <font-awesome-icon icon="fas fa-arrow-left" class="float-left text-lg p-2 text-button -mt-2" />
      </router-link>
      <h2 class="mt-3 text-xl text-panelText font-semibold text-center">Permission settings</h2>
    </div>

    <ol class="float-left mt-4 pl-6 ml-2 w-80 leading-8">
        <li v-for="(userType, index) in userTypesData" class="cursor-pointer" :key="index" v-bind:class="{'text-black font-bold': selectedOption == index}" @click="permissions(index,userType.id)">
            {{ userType.name }}
        </li>
    </ol>

    <div class="float-right rounded shadow-2xl bg-panels m-4 p-6 mt-1 text-panelsText opacity-90 h-128" v-if="permiss_open">
        <div class="h-full w-full overflow-y-scroll pr-10">
            <table class="w-full text-left border-collapse">
                <thead>
                    <tr class="border-b-2 border-panelsText">
                        <th class="pr-5 pb-0.5">Permission</th>
                        <th class="pr-5 pb-0.5 text-center">Granted</th>
                        <th class="pb-0.5 text-center">Scope</th>
                    </tr>
                </thead>
                <tbody>
                    <slot v-for="(permiss, indexP) in userTypesData[selectedOption].permissions" :key="indexP">
                        <tr v-if="indexP == 0 || (indexP>0 && userTypesData[selectedOption].permissions[indexP-1].category != permiss.category)" class="text-button font-bold">
                            <td class="py-2 pl-2" colspan="3">{{permiss.category}}</td>
                        </tr>
                        <tr class="hover:bg-gray-300">
                            <!-- Permission Name -->
                            <td class="pl-1 py-1.5 border-b border-panelsText"> {{ permiss.name }}</td>
                            
                            <!-- Checkbox for Permission -->
                            <td class="py-1.5 border-b border-panelsText text-center">
                                <font-awesome-icon :icon="checkIcon(permiss.is_granted)" @click="changeTF(indexP)" />
                            </td>
                            
                            <!-- Toggle Button for Scope -->
                            <td class="py-0.5 border-b border-panelsText text-center text-sm">
                                <button class="p-0" v-if="permiss.is_granted == 1 && permiss.scoping" 
                                        v-bind:class="scopeClass(permiss.scope)" 
                                        @click="toggleScope(permiss,indexP)">
                                    {{ permiss.scope }}
                                </button>
                                <div v-else class="block w-28"></div>
                            </td>
                        </tr>
                    </slot>
                </tbody>
            </table>
        </div>
    </div>

    <div class="clear-both"></div>
</template>

<script>
    export default {
        props: ['noarrow','position_id'],
        data() {
            return { 
                userTypesData: [],
                permissionsData: [],
                permiss_field: [],
                permiss_open: false,
                selectedOption: null,
                userTypeId: null,
                saved:false
            }
        },
        methods: {
            checkIcon(condition) {
                return condition==1 ? 'fas fa-check-square' : 'fas fa-square';
            },
            toggleScope(permiss,indexP) {
                if (permiss.scope === "individual") {
                    permiss.scope = "team";
                } else if (permiss.scope === "team") {
                    permiss.scope = "full";
                } else {
                    permiss.scope = "individual";
                }
                this.sendNewPermissions(indexP);
            },
            scopeClass(scope) {
                if (scope === "individual") {
                    return "bg-blue-500 text-white px-4 py-0.5 rounded";
                } else if (scope === "team") {
                    return "bg-green-500 text-white px-4 py-0.5 rounded";
                } else {
                    return "bg-gray-500 text-white px-4 py-0.5 rounded";
                }
            },
            permissions(index,typeId) {
                this.permiss_field = this.userTypesData[index].permissions;
                this.selectedOption = index;
                this.userTypeId = typeId;
                this.permiss_open = true; 
            },
            replaceAt(original, index, replacement) {
                return original.substr(0, index) + replacement + original.substr(index + 1);
            },
            sendNewPermissions(indexP) {
                this.userTypesData[this.selectedOption].permissions = this.permiss_field;
                this.$axios.post(baseURL+ "settingspost/", {                    
                    action: "saveNewPermissions",
                    permissionData: this.userTypesData[this.selectedOption].permissions[indexP],
                    positionID: this.userTypeId,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    this.saved = true;
                    setTimeout(() => (this.saved = false), 500)
                }, (error) => {
                    console.log(error);
                });
            },
            changeTF(indexP) {
                this.userTypesData[this.selectedOption].permissions[indexP].is_granted = !this.userTypesData[this.selectedOption].permissions[indexP].is_granted;
                this.sendNewPermissions(indexP);
            },
            findIndexById(userTypesData, position_id) {
                return userTypesData.findIndex(userType => userType.id === position_id);
            }
        },
        created() {
            this.$axios.get(baseURL+"settingsget/", {
                params: {
                    action: 'permissions',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.userTypesData = result.data
                if (this.position_id && this.userTypesData.length > 0) {
                    this.permissions(this.findIndexById(this.userTypesData, this.position_id),this.position_id);
                }
            }, (error) => {
                console.log(error);
            });
        }
    }
</script>