<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   
    <div class="bg-green-300 rounded-full text-black p-2 fixed top-3 px-6 left-1/2" v-if="saved">{{ $t('saved') }}</div>

    <div class="w-96 mb-4 relative">
      <router-link :to="'../Settings'">
        <font-awesome-icon icon="fas fa-arrow-left" class="absolute float-left text-lg p-2 text-button -mt-2" />
      </router-link>
      <h2 class="mt-2 text-xl text-contentText font-semibold text-center mb-10">Company settings</h2>

        <div v-for="(checkbox, chI) in checkboxes" :key="chI" class="mt-2 text-panelsText">
            <slot v-if="chI == 'timezone_shift'">
                <hr class="py-2"/>
                <label class="text-sm">{{checkbox.title}} for metrics</label><br />
                GTM <input class="ml-2 border border-gray-500 rounded p-1 px-2" type="number" v-model="checkbox.value" :min="-12" :max="12" /><br />
                <small class="text-xs">*Enter the offset from GMT (UTC) in hours<br />We strongly suggest you keep metric summaries in GTM +0.<br />For more information, contact support</small>
                <br />
                <small class="font-bold">*This only affects Fansly</small>
            </slot>
            <slot v-else>
                <font-awesome-icon class="cursor-pointer" :icon="checkIcon(checkbox.value)" /> &nbsp;{{checkbox.title}}
            </slot>
        </div>
        <button class=" mt-5 p-1.5 px-5 cursor-pointer rounded bg-button text-white font-semibold w-max" @click="saveSettings()">Save</button>
    </div>
    
</template>

<script>
    export default {
        data() {
            return {
                saved: false,
                loading: false,
                checkboxes: {}
            }
        },
        methods: {
            checkIcon(condition) {
                return condition == 1 ? 'fas fa-check-square' : 'fas fa-square';
            },
            saveSettings() {
                this.$axios.post(baseURL+"settingspost/", {                    
                    action: "companySettings",
                    checkboxes: this.checkboxes,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    console.log(result.data);
                    this.saved = true;
                    setTimeout(() => (this.saved = false), 700)
                }, (error) => {
                    console.log(error);
                })
           },
        },
        created() {
            this.$axios.get(baseURL+"settingsget/", {
                params: {                 
                    action: "companySettings",
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.checkboxes = result.data;
                console.log(this.checkboxes)
            }, (error) => {
                console.log(error);
            })
        }
    }
</script>

renew = !renew