div<template>
    <div class="overflow-x-hidden">
        <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-40"></div>
        
        <!-- header -->
        <header class="bg-bkg text-black px-6 sm:px-8 py-1 sm:py-2 fixed w-full top-0 z-30 shadow-lg">
            <div class="container mx-auto">
                <logo-component class="float-left" />
                <div class="relative float-right mt-2 sm:mt-0.5">
                    <font-awesome-icon icon="fas fa-bars" class="lg:hidden cursor-pointer" @click="menuOpen = !menuOpen" />
                    <!-- Dropdown Menu -->
                    <nav :class="{'absolute right-0 top-full mt-2 md:mt-0 bg-header border border-sepBorder lg:border-0 rounded shadow-lg': menuOpen, 'hidden': !menuOpen}" class="lg:static lg:block lg:shadow-none">
                        <div class="grid lg:grid-cols-6 gap-x-4 px-5 lg:px-0 leading-loose lg:leading-none whitespace-nowrap">
                            <button @click="scrollToSection('#benefits')" class="py-3 hover:text-growth border-0 cursor-pointer text-center">{{ $t('benefits') }}</button>
                            <button @click="scrollToSection('#pricing')" class="py-3 hover:text-growth border-0 cursor-pointer text-center">{{ $t('pricing') }}</button>
                            <button @click="contacModal = true" class="py-3 hover:text-growth border-0 cursor-pointer text-center">{{ $t('contact_us') }}</button>
                            <button @mouseover="openSignupOptions = true" @mouseleave="openSignupOptions = false" class="py-3 hover:text-growth font-bold cursor-pointer text-center">{{ $t('sign_up') }}
                                <div class="z-50 absolute mt-3" v-show="openSignupOptions == true">
                                    <div class="mt-3 bg-bkg shadow-lg rounded-b p-3 pt-1 text-left">
                                        <small class="text-left font-light text-black">{{ $t('account_for') }}</small>
                                        <div class="my-3 mb-4 text-black hover:text-growth" @click="dialogWindow = 'signup'">{{$t('agency')}}</div>
                                        <div class="text-black hover:text-growth" @click="dialogWindow = 'signup_creator'">{{$t('creator')}}</div>
                                    </div>
                                </div>
                            </button>
                            <button @click="open_login_modal()" class="py-3 hover:text-growth font-bold cursor-pointer text-center">{{ $t('log_in') }}</button>
                            <language-picker />
                        </div>
                    </nav>
                </div>
            </div>
        </header>
        <!-- header -->

        <div class="grid sm:grid-cols-2 container mx-auto px-5 sm:px-4 text-black pt-8 gap-5 mt-10 md:mt-28">
            <div class="mt-3 md:mt-10">
                <h1 style="line-height: 121.2%; font-weight:900" class="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl"><div>{{ $t('main_headline_landing') }}</div> <div style="font-family: Exo, sans-serif;">Creator<span class="text-growth">X</span>one</div></h1>
                <h2 class="leading-loose mt-5 text-lg sm:text-xl lg:text-2xl xl:text-3xl">{{ $t('main_headline_landing_2') }}</h2>
                <div class="grid grid-cols-2 gap-4 w-max mt-5">
                    <button @click="dialogWindow = 'signup'" class="transition ease-in-out bg-growth rounded-xl p-2 px-6 text-white font-semibold shadow hover:shadow-lg hover:scale-105">{{ $t('for_agencies') }}</button>
                    <button @click="dialogWindow = 'signup_creator'" class="transition ease-in-out border border-growth rounded-xl p-2 px-4 text-growth font-semibold hover:shadow-lg hover:scale-105">{{ $t('for_creators') }}</button>
                </div>
            </div>
            <div class="px-5 md:px-0">
                <img alt="social media illustration" src="@/assets/images/social_media_illustration.svg" class="w-full"/>
            </div>
        </div>

        <div id="benefits" class="container mx-auto items-center pt-24 text-center relative">
            <hr class="w-full border-0 border-t border-gray-300" noshade="noshade" />
            <div @click="scrollToSection('#benefits')" class="w-max cursor-pointer">
                <div class="animate-bounce text-white bg-white p-3 px-4 border-2 border-growth rounded-full absolute left-1/2 -ml-6 -mt-5">
                    <font-awesome-icon icon="fas fa-angles-down" class="text-xl text-growth" />
                </div>
            </div><br /><br />
            <span class="text-xl font-bold italic">{{ $t('step_into_our_realm') }}</span>
        </div>

        <transition name="zoom-fade">
            <section v-if="contacModal" @click="contacModal = false" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
                <section @click.stop class="bg-white rounded-lg w-full max-h-screen max-w-md sm:max-w-3xl mx-auto my-auto p-8 px-6 sm:p-10 z-50 overflow-auto relative">
                    <font-awesome-icon @click="contacModal = false" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
                    <contact-modal @close="handleClose" />
                </section>
            </section>
        </transition>
        
        <transition name="zoom-fade">
            <section v-if="dialogWindow" @click="dialogWindow = false" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
                <section @click.stop class="rounded-lg w-full min-h-lg max-w-md sm:max-w-5xl mx-auto my-auto p-8 sm:p-12 z-50 overflow-auto relative bg-white" :class="dialogWindow == 'signup' ? '' : 'border-4 border-growth'">
                    <font-awesome-icon @click="dialogWindow = false, $router.push({ path: '/', query: null });" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
                    <div v-if="(dialogWindow == 'signup' || dialogWindow == 'signup_creator') && signedUp == true" class="text-center">
                        <p class="text-xl font-semibold text-xl">{{ $t('reg_success') }}</p>
                        <p class="mt-7 text-lg font-semibold">{{ $t('reg_request_1') }}</p>
                        <p class="mt-3 italic text-lg">{{ $t('reg_request_2') }}</p>
                    </div>
                    <slot v-else>
                        <div class="grid md:grid-cols-2 gap-x-10">
                            <div class="hidden md:block">
                                <h2 class="text-2xl font-black mb-5">{{dialogText.h1}}</h2>
                                <p class="leading-relaxed">{{dialogText.p1}}</p>
                                <br />
                                <h2 class="text-2xl font-black mb-5 mt-5">{{dialogText.h2}}</h2>
                                <ul class="list-disc ml-6 leading-relaxed">
                                    <li>{{ $t('reg_form.reg_benefit_1') }}</li>
                                    <li>{{ $t('reg_form.reg_benefit_2') }}</li>
                                    <li>{{ $t('reg_form.reg_benefit_3') }}</li>
                                    <li>{{ $t('reg_form.reg_benefit_4') }}</li>
                                </ul>
                                <div v-if="dialogWindow == 'signup' || dialogWindow == 'signup_creator'" class="mt-5">
                                    <strong class="text-lg" v-html="$t('reg_form.begin_trial')"></strong>
                                    <br />{{ $t('reg_form.begin_trial_desc') }}
                                </div>
                            </div>
                            <div>
                                <h2 v-if="dialogWindow == 'signup'" class="font-black text-xl mb-8 leading-none">{{ $t('sign_up') }} <br /><span class="text-sm font-light">{{ $t('for_an') }} {{ $t('agency_account') }}</span></h2>
                                <h2 v-if="dialogWindow == 'signup_creator'" class="font-black text-xl mb-8 leading-none">{{ $t('sign_up') }} <br /><span class="text-sm font-light">{{ $t('for_an') }} {{ $t('creator_account') }}</span></h2>
                                <h2 v-if="dialogWindow == 'signin'" class="font-black text-xl mb-8 leading-none">{{ $t('sign_in') }} <br /><span class="text-sm font-light">{{ $t('to_your') }} {{ $t('agency_account') }}</span></h2>
                                <form @submit.prevent="handleSubmit">
                                    <transition-group name="fade-transition" tag="div" class="relative">
                                        <div class="mt-7" key="signin-form">
                                            <label v-if="!fData.email" for="email" class="cursor-text text-gray-700 absolute ml-2">{{ $t('reg_form.email') }}*</label>
                                            <input type="email" id="email" autocomplete="email" v-model="fData.email" @input="fillEmail" class="w-full font-regular z-10 cursor-text border-0 shadow-none rounded-none border-b border-sepBorder focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out px-2 py-1 text-gray-700" />
                                        </div>
                                        <div class="mt-6" key="signin-form">
                                            <label v-if="!fData.password" for="password" class="cursor-text text-gray-700 font-normal absolute ml-2">{{ $t('reg_form.password') }}*</label>
                                            <input v-bind:type="displayPass ? 'text' : 'password'" id="password" autocomplete="current-password" v-model="fData.password" class="w-full cursor-text z-10 border-0 shadow-none rounded-none border-b border-sepBorder focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out px-2 py-1 text-gray-700" />
                                            <font-awesome-icon :icon="eyeIcon(displayPass)" class="absolute text-gray-700 right-2 text-center p-1 cursor-pointer" @click="displayPass = !displayPass" />
                                        </div>
                                        <div class="mt-6" v-if="dialogWindow == 'signup' || dialogWindow == 'signup_creator'" key="signup-form">
                                            <label v-if="!fData.password_again" for="password_again" class="cursor-text text-gray-700 font-normal absolute ml-2">{{ $t('reg_form.confirm_password') }}*</label>
                                            <input v-bind:type="displayPass ? 'text' : 'password'" id="password_again" autocomplete="new-password" v-model="fData.password_again" class="w-full cursor-text z-10 border-0 shadow-none rounded-none border-b border-sepBorder focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out px-2 py-1 text-gray-700" />
                                        </div>
                                        
                                        <div class="mt-6" v-if="dialogWindow == 'signup' || dialogWindow == 'signup_creator'" key="signup-form">
                                            <label v-if="!fData.name" for="name" class="cursor-text text-gray-700 absolute ml-2">{{ dialogWindow == 'signup_creator' ? $t('reg_form.creator_name') : $t('reg_form.agency_name') }}*</label>
                                            <input type="name" id="name" autocomplete="organization" v-model="fData.name" class="bg-transparent w-full font-regular z-10 cursor-text border-0 shadow-none rounded-none border-b border-sepBorder focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out px-2 py-1 text-gray-700" />
                                        </div>
                                        <div class="mt-6" v-if="dialogWindow == 'signup'" key="signup-form">
                                            <label v-if="!fData.url" for="url" class="cursor-text text-gray-700 absolute ml-2">{{ $t('reg_form.agency_url') }}</label>
                                            <input type="text" id="url" autocomplete="url" v-model="fData.url" class="bg-transparent w-full font-regular z-10 cursor-text border-0 shadow-none rounded-none border-b border-sepBorder focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out px-2 py-1 text-gray-700" />
                                        </div>
                                        <div class="mt-5" v-if="dialogWindow == 'signup' || dialogWindow == 'signup_creator'" key="signup-form">
                                            <label for="url" class="cursor-text text-gray-700 text-sm font-semibold">{{ $t('reg_form.preffered_contact') }}</label><br />
                                            <div class="grid grid-cols-3 gap-x-2">
                                                <div>
                                                    <select class="bg-none mr-3 w-full outline-0 border-b border-sepBorder py-2" v-model="fData.contact">
                                                        <option value="0">Email</option>
                                                        <option value="1">WhatsApp</option>
                                                        <option value="2">Telegram</option>
                                                    </select>
                                                </div>
                                                <div class="mt-2 col-span-2">
                                                    <label v-if="!fData.contactD" for="contactD" class="cursor-text text-gray-700 absolute ml-2">{{fData.contact == 0 ? $t('reg_form.email') : $t('reg_form.phone_number')}}</label>
                                                    <input type="text" id="contactD" v-model="fData.contactD" class="bg-transparent w-full font-regular z-10 cursor-text border-0 shadow-none rounded-none border-b border-sepBorder focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out px-2 pb-1 text-gray-700" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5 cursor-pointer" v-if="dialogWindow == 'signup' || dialogWindow == 'signup_creator'" @click="fData.tos = !fData.tos" key="signup-form">
                                            <font-awesome-icon :icon="fData.tos == true ? 'fas fa-check-square' : 'far fa-square'" class="text-growth text-lg" /> &nbsp;{{ $t('reg_form.terms_1') }} <a href="/tos" target="_blank" class="underline">{{ $t('reg_form.terms_2') }}</a>
                                        </div>
                                    </transition-group>
                                    <div v-html="errorMessageContent" class="mt-4 text-red-400 text-sm"></div>
                                    <button type="submit" @click="submitForm()" class="mt-4 transition ease-in-out bg-growth rounded-xl p-2 px-6 text-white font-semibold shadow hover:shadow-lg hover:scale-105">
                                        <font-awesome-icon v-if="buttonLoading" icon="fas fa-spinner" class="animate-spin mr-2" /> 
                                        {{dialogWindow == 'signup' || dialogWindow == 'signup_creator' ? $t('submit') : $t('log_in') }}
                                    </button>
                                </form>
                                <div v-if="dialogWindow == 'signup'" class="mt-5 text-sm font-light">{{ $t('reg_form.question_1') }} <u class="font-normal cursor-pointer" @click="dialogWindow = 'signin'">{{ $t('log_in') }}</u></div>
                                <div v-if="dialogWindow == 'signin'" class="mt-5 text-sm font-light">{{ $t('reg_form.question_2') }} <u class="font-normal cursor-pointer" @click="dialogWindow = 'signup'">{{ $t('sign_up') }}</u></div>
                            </div>
                        </div>
                    </slot>
                </section>
            </section>
        </transition>
        
        <benefits-panel />
        <div id="pricing">
            <pricing />
        </div>
        <div id="reviews">
            <reviews />
        </div>
        <footer-panel />

        <transition name="fade">
            <button v-if="isVisible" class="animate-bounce fixed bottom-6 left-1/2 -ml-6 bg-growth bg-opacity-10 hover:bg-opacity-30 border border-growth rounded-full text-growth h-12 w-12 z-30" @click="scrollToTop">
                <font-awesome-icon icon="fas fa-arrow-up" />
            </button>
        </transition>
    </div>
</template>

<script setup>
    import logoComponent from "@/components/logo.vue"
    import pricing from "@/components/landing/pricing.vue"
    import reviews from "@/components/landing/reviews.vue"
    import BenefitsPanel from "@/components/landing/BenefitsLanding.vue"
    import FooterPanel from "@/components/landing/FooterLanding.vue"
    import contactModal from "@/components/landing/ContactModal.vue"
    import languagePicker from "@/components/languagePicker.vue"
</script>
<script>
    import { signup, api_get_call } from "@/api"
    export default {
        components: {
            BenefitsPanel,FooterPanel,pricing,reviews,logoComponent,contactModal, languagePicker
        },
        data() {
            return {
                openSignupOptions:false,
                displayPass:false,
                contacModal:false,
                menuOpen:false,
                theme:false,
                showPassword:false,
                warning:false,
                fData: {
                    email: null,
                    password: null,
                    password_again: null,
                    name: null,
                    url: null,
                    contact: 0,
                    contactD: null,
                    tos: false
                },
                loading: false,
                buttonLoading:false,
                errorMessage: false,
                errorMessageContent: null,
                permissions:null,
                isVisible: false,
                dialogWindow: false,
                signedUp: this.$route.query.signedUp == 'true' ? true : false,
            }
        },
        watch: {
            '$route.query.open': function(newVal) {
                if (newVal === 'register') {
                    this.dialogWindow = 'signup';
                }
            }
        },
        computed: {
            dialogText() {
                if (this.dialogWindow == 'signup' || this.dialogWindow == 'signup_creator') {
                    return {
                        h1: this.$t('reg_form.headline_1'),
                        p1: this.$t('reg_form.p1'),
                        h2: this.$t('reg_form.headline_2')
                    }
                } else {
                    return {
                        h1: this.$t('reg_form.headline_3'),
                        p1: this.$t('reg_form.p2'),
                        h2: this.$t('reg_form.headline_4')
                    }
                }
            },
            randomIconClass() {
                const icons = [
                    "fa-spider",
                    "fa-ghost",
                    "fa-skull-crossbones",
                    "fa-broom",
                    "fa-crow",
                    "fa-hat-wizard",
                    "fa-book-skull",
                    "fa-skull",
                    "fa-cat"
                ];
                
                // Get a random index from the icons array
                const randomIndex = Math.floor(Math.random() * icons.length);
                return `fas ${icons[randomIndex]} mr-3`;
            }
        },
        methods: {
            async open_login_modal() {
                if (this.$cookies.get('userToken')) {
                    if (!(this.$cookies.get('c_id'))) {
                    var companyID = null;
                    } else {
                        var companyID = this.$cookies.get('c_id');
                    }
                    
                    this.$axios.get(baseURL+"opravnenia/userToken.php", {
                        params: {
                            userToken: this.$cookies.get('userToken'),
                            companyID: companyID
                        }
                        }).then(async (result) => {
                            if (result.data.selectBranch) {
                                let prev = this.$route.path;
                                this.$router.push({name:'Branches', query: { prevRoute: prev } });
                            } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                                this.$cookies.set('userToken','');
                            }
                            this.permissions = result.data.new_permissions??null;
                            if (await this.should_go_to_profile()) {
                                this.$router.push({name: 'Profile'})
                            } else if (this.permissions && this.$getPermission(this.permissions,1) && this.$getPermission(this.permissions,1).is_granted) {
                                window.location.href = 'Metrics';
                            } else {
                                window.location.href = 'Shifts';
                            }
                        }, (error) => {
                        console.log(error);
                    })
                }
                this.dialogWindow = 'signin';
            },
            handleClose() {
                this.contacModal = false;
            },
            scrollToSection(target) {
                this.$router.push(target).catch(err => {
                    if (err.name != "NavigationDuplicated" && !err.message.includes("Avoided redundant navigation to current location")) {
                        console.log(err);
                    }
                });
            },
            eyeIcon(condition) {
                return !condition ? 'fas fa-eye' : 'fas fa-eye-slash';
            },
            async signupLocal() {
                this.errorMessageContent = "";

                // Email format regex
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                // Validation checks
                if (!this.fData.email || !emailRegex.test(this.fData.email)) {
                    this.errorMessageContent = this.$t('reg_form.warning_1');
                    this.buttonLoading = false;
                } else if (!this.fData.password || this.fData.password.length < 8) {
                    // Checks if password is not null, undefined, or less than 8 characters
                    this.errorMessageContent = this.$t('reg_form.warning_2');
                    this.buttonLoading = false;
                } else if (this.fData.password !== this.fData.password_again || !this.fData.password_again) {
                    // Checks if both passwords do not match or if password_again is not entered
                    this.errorMessageContent = this.$t('reg_form.warning_3');
                    this.buttonLoading = false;
                } else if (!this.fData.name) {
                    // Checks if the agency name is not entered
                    this.errorMessageContent = this.$t('reg_form.warning_4');
                    this.buttonLoading = false;
                } else if (!this.fData.tos) {
                    // Checks if the Terms of Service is not agreed to
                    this.errorMessageContent = this.$t('reg_form.warning_5');
                    this.buttonLoading = false;
                }

                // If there are any error messages, don't proceed with signup
                if (this.errorMessageContent) {
                    this.warning = true; // You might want to use this flag to show the error messages in your template
                    return; // Stop the function if any validations fail
                }

                const signupResult = await signup(this.fData);
                if (!signupResult.success) {
                    this.errorMessageContent = signupResult.message;
                    setTimeout(() => {
                        this.buttonLoading = false;
                    }, 200);
                } else {
                    //generate the purchase link here with trial period
                    const purchaseLink = await api_get_call(`${baseURL}billing/?action=generate_purchase_link&userToken=${signupResult.token}`);
                    if (purchaseLink.success) {
                        purchaseLink.purchase_link = purchaseLink.purchase_link;
                        
                        this.$cookies.set('userToken', signupResult.token, '14d');
                        this.buttonLoading = true;
                        window.location.href = purchaseLink.purchase_link;
                    } else {
                        alert(this.$t('reg_form.purchase_link_alert'));
                        this.errorMessageContent = purchaseLink.error;
                    }
                    setTimeout(() => {
                        this.buttonLoading = false;
                    }, 200);
                }
            },
            submitForm() {
                this.buttonLoading = true;
                if (this.dialogWindow == 'signin') { 
                    this.login();
                    setTimeout(() => {
                        this.buttonLoading = false;
                    }, 200);
                }
                if (this.dialogWindow == 'signup') { this.signupLocal();}
                if (this.dialogWindow == 'signup_creator') { this.signupLocal();}
            },
            eyeIcon(condition) {
                return !condition ? 'fas fa-eye' : 'fas fa-eye-slash';
            },
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            },
                // Method to check the scroll position and update the `isVisible` property
            checkScroll() {
                this.isVisible = window.scrollY > 100;
            },
            async should_go_to_profile(token = this.$cookies.get('userToken')) {
                this.agency_status = await api_get_call(`${baseURL}users/?action=agency_profile_status&companyID=${this.$cookies.get('c_id')}&userToken=${token}`);
                //condition if exact tasks id=1 and id=2 are done. If not, redirect to profile page
                let profile_done_status = (this.agency_status.to_do.filter(task => task.done).length / this.agency_status.to_do.length * 100).toFixed(0);
                return profile_done_status < 100;
            },
            async login() {
                this.loading = true;
                this.$axios.post(baseURL+"login/", {
                    email: this.fData.email,
                    password: this.fData.password
                }).then(async (result) => {
                    this.permissions = result.data.new_permissions??null;

                    if (result.data.success == true) {
                        this.$cookies.set('userToken', result.data.token, '14d');
                        if (this.permissions) {
                            let go_to_profile = await this.should_go_to_profile(result.data.token);
                            console.log(go_to_profile);
                            if (go_to_profile) {
                                this.$router.push({name: 'Profile'})
                            } else if (this.$getPermission(this.permissions,20).is_granted) {
                                this.$router.push({name:'Creators'});
                            } else if (this.$getPermission(this.permissions,1).is_granted) {
                                this.$router.push({name:'Metrics'});
                            } else {
                                this.$router.push({name:'Shifts'});
                            }
                            
                        }
                    } else {
                        this.errorMessage = true;
                        if (result.data.error == 'account-deactivated') {
                            this.errorMessageContent = this.$t('reg_form.error_1');
                        } else {
                            this.errorMessageContent = this.$t('reg_form.error_2');
                        }
                    }
                }, (error) => {
                    console.log(error);
                    this.errorMessage = true;
                })
                .finally(() => this.loading = false)
            },
        },
        mounted() {
            if (this.$route.query.open == 'register') {this.dialogWindow = 'signup';}
            if (this.$route.query.open == 'login') {this.dialogWindow = 'signin';}
            if (this.$route.query.open == 'contact') {this.contacModal = true;}
            if (this.$route.query.open == 'pricing') {this.scrollToSection('#pricing');}
            if (this.$route.query.open == 'benefits') {this.scrollToSection('#benefits');}

            // Add the scroll event listener when the component is mounted
            window.addEventListener('scroll', this.checkScroll);
        },
        beforeDestroy() {
            // Remove the scroll event listener before the component is destroyed
            window.removeEventListener('scroll', this.checkScroll);
        },
    }
</script>

<style>
    .zoom-fade-enter-active {
        transition: all .3s ease;
    }
    .zoom-fade-leave-active {
        transition: all .3s ease;
    }
    .zoom-fade-enter-from, .zoom-fade-leave-to {
        transform: scale(0.9);
        opacity: 0;
    }
    .fade-transition-enter-active, .fade-transition-leave-active {
        position: relative;
        transition: opacity 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955);
    }
    .fade-transition-enter, .fade-transition-leave-to /* starting and ending state for enter/leave */ {
        position: absolute;
        opacity: 0;
    }
</style>
