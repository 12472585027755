<template>
    <button class="hover:scale-105 transition ease-in-out ml-2 z-10 whitespace-nowrap cursor-pointer p-1.5 px-5 rounded bg-button text-buttonText font-semibold w-max border border-sepBorder" @click="refresh()" :title="$t('refresh')"><font-awesome-icon icon="fas fa-redo" /> {{ $t('refresh') }}</button>
    <slot v-if="shiftsData && shiftsData.length>0">
        <transition name="fade">
            <section id="parent" class="mx-2 sm:mx-0 sm:pl-20 w-full pr-2 pt-2">
                <table v-if="shiftsData && shiftsData.length>0" style="table-layout: fixed;" class="bg-bkg p-0.5 rounded">
                    <thead id="myhead">
                        <tr class="border border-sepBorder">
                            <th class="text-panelsText bg-tableBG border-r-2 border-sepBorder">
                                <div class="justify-center flex mt-0.5" style="width:8rem">
                                    Models
                                </div>
                            </th>
                            <slot v-if="shiftsData.length>0 && shiftsData[0].models[0]">
                                <slot v-for="(sh,index) in shiftsData[0].models[0].data" :key="index">
                                    <th class="bg-tableBG text-panelsText border-r border-sepBorder" v-for="(shiftColumn,shiftCIndex) in sh.dayData" :key="shiftCIndex" :class="{'border-r border-2': shiftCIndex == borderCount}">
                                        <div :style="'width:'+parseFloat(shiftColumn.shiftH)+'rem'">
                                            <span class="font-semibold cursor-default" :class="{'text-xs':parseFloat(shiftColumn.shiftH)<5}" :title="sh.dayName">{{formatDate(sh.date)}}<br />
                                            <span class="font-normal">{{formatTime(sh.date + " " + shiftColumn.timeFrom,"H") + ':00-' + formatTime(sh.date + " " + shiftColumn.timeTo,"H")}}:00</span></span>
                                        </div>
                                    </th>
                                </slot>
                            </slot>
                        </tr>
                    </thead>
                    <tbody id="mybody" onscroll="fixscroll()" v-if="shiftsData.length>0">
                        <slot v-for="(group,gInd) in shiftsData" :key="gInd">
                            <span v-if="group.models.length>0" class="sticky bg-button text-buttonText rounded font-semibold top-0 w-max z-13 p-0.5 px-2 left-1">{{group.groupName}}</span>
                            <slot v-for="(model,ind) in group.models" :key="ind">
                                <div class="w-full h-3" :class="{'hidden': ind>0 && model.teamName == group.models[parseInt(ind)-1].teamName || model.teamName == group.groupName}"></div>
                                <tr class="border border-sepBorder">
                                    <td class="border border-sepBorder text-panelsText bg-tableBG z-12">
                                        <div class="w-32 pl-0.5" :title="model.name">
                                            <strong class="-mt-1 text-sm truncate w-30 block">{{model.name}}</strong>
                                            <div class="flex" style="width:8rem">
                                                <img v-if="model.platform==1" src="@/assets/images/of_logo.png" class="h-4"/>
                                                <img v-else-if="model.platform==2" src="@/assets/images/fansly_logo.png" class="h-4"/>
                                            </div>
                                            <div class="text-xs">{{model.code}}</div>
                                            <span v-if="model.teamName" class="absolute left-20 -top-4 bg-button text-sm text-buttonText z-50 p-0.5 px-1 rounded whitespace-nowrap" :class="{'hidden': ind>0 && model.teamName == group.models[parseInt(ind)-1].teamName || model.teamName == group.groupName}">{{model.teamName}}</span>
                                        </div>
                                    </td>
                                    <slot v-if="shiftsData.length>0">
                                        <slot v-for="(sh,index) in model.data" :key="index">
                                            <slot v-if="(permissions && $getPermission(permissions,5).is_granted)">
                                                <slot v-for="(shift,i) in sh.dayData" :key="i">
                                                    <td class="cursor-pointer relative border bg-tableRow1 border-sepBorder text-white h-16 flex-none" v-bind:class="{'rightBorder': i == borderCount}">
                                                        <div :style="'width:'+parseFloat(shift.shiftH)+'rem'">
                                                            <section class="absolute w-full h-16 top-0" @click="showModal(gInd,ind,index,i,0,true)">
                                                            </section>
                                                            <slot v-for="(field,fI) in shift.shiftField" :key="fI">
                                                                <section @click="showModal(gInd,ind,index,i,fI,false)" 
                                                                    class="absolute top-1 z-2 align-text-bottom text-sm text-black bg-white border border-gray-400 h-14 rounded-r-lg text-left pl-1" 
                                                                    :class="{'bg-red-200': shift.shiftField.length > 1}" 
                                                                    :style="calculateMarginLeft(field.timeFrom, shift.timeFrom, sh.date, sh.date, field.employeeColor, field.hours)">
                                                                    <font-awesome-icon icon="fas fa-xmark" class="absolute right-0 p-2 -mt-1 text-red-600 text-xs" v-on:click.stop="openDialog(gInd,field.shiftID,ind,index,i,fI,model.team_id)" />
                                                                    <span class="inline-block whitespace-nowrap w-4/5 overflow-ellipsis font-semibold overflow-hidden"> {{field.employeeName}}</span><br />
                                                                    <span class="inline-block whitespace-nowrap w-2/3 overflow-ellipsis overflow-hidden text-xs"> {{field.employeeGroupName}}</span>
                                                                    <span v-if="field.note" class="bg-gray-700 absolute rounded whitespace-nowrap mt-1 right-1 text-xs text-white font-semibold px-1">Note <font-awesome-icon icon="far fa-sticky-note" /></span>
                                                                    <span v-if="field.hours != shift.shiftH" class="absolute rounded whitespace-nowrap mt-1 right-1 text-xs bg-button text-buttonText font-semibold px-1">{{field.hours}}h</span>
                                                                </section>
                                                            </slot>
                                                        </div>
                                                    </td>
                                                </slot>
                                            </slot>
                                            <slot v-else>
                                                <td class="cursor-pointer relative border bg-tableRow1 border-sepBorder text-white h-16"
                                                    v-bind:class="{'rightBorder': i == borderCount}" 
                                                    v-for="(shift,i) in sh.dayData" :key="i">
                                                    <div :style="'width:'+parseFloat(shift.shiftH)+'rem'">
                                                        <section class="absolute w-full h-16 top-0">
                                                        </section>
                                                        <slot v-for="(field,fI) in shift.shiftField" :key="fI">
                                                            <section class="absolute top-1 z-2 align-text-bottom text-sm text-black bg-white border border-gray-400 h-14 rounded-r-lg text-left pl-1" :class="{'bg-red-200':shift.shiftField.length>1}" 
                                                                :style="[parseInt(field.timeFrom)-parseInt(shiftsData[0].models[0].data[index].dayData[i].timeFrom)<-1 ? {'border-top':'8px solid ' + field.employeeColor , 'width': + parseFloat(field.hours)+'rem','margin-left': + 100/8*(parseInt(shiftsData[0].models[0].data[index].dayData[i].timeFrom)-parseInt(field.timeFrom)-24)*-1 + '%'} : {'border-top':'8px solid ' + field.employeeColor , 'width': + parseFloat(field.hours)+'rem','margin-left': + 100/8*(parseInt(field.timeFrom)-parseInt(shiftsData[0].models[0].data[index].dayData[i].timeFrom)) + '%'}]">
                                                                <span class="inline-block whitespace-nowrap w-4/5 overflow-ellipsis font-semibold overflow-hidden"> {{field.employeeName}}</span><br />
                                                                <span class="inline-block whitespace-nowrap w-2/3 overflow-ellipsis overflow-hidden text-xs"> {{field.employeeGroupName}}</span>
                                                                <span v-if="field.note" class="absolute rounded whitespace-nowrap mt-1 right-8 text-xs bg-gray-700 text-white font-semibold px-1">Note <font-awesome-icon icon="far fa-sticky-note" /></span>
                                                                <span v-if="field.hours != shift.shiftH" class="absolute rounded whitespace-nowrap mt-1 right-1 text-xs bg-button text-black font-semibold px-1">{{field.hours}}h</span>
                                                            </section>
                                                        </slot>
                                                    </div>
                                                </td>
                                            </slot>
                                        </slot>
                                    </slot>
                                </tr>
                            </slot>
                        </slot>
                    </tbody>
                </table>
                <span v-if="!loading && mounted && (!shiftsData || shiftsData.length == 0)" class=" ml-4 text-textOnBkg text-center text-2xl font-bold">No models</span> 
                <div style="clear:both"></div>
            </section>
        </transition>
    </slot>
    <slot v-else-if="loading">
        <transition name="fade">
            <section class="mb-36 mx-2 sm:mx-0 sm:pl-24 w-full pr-2 h-full pt-40 sm:pt-24 -mt-24 sm:-mt-4">
                <div class="w-full bg-bkg rounded text-center h-full mx-2 py-96"><font-awesome-icon icon="fas fa-spinner" class="animate-spin" /> Loading</div>
            </section>
        </transition>
    </slot>
    <slot v-else>
        <transition name="fade">
            <section class="mb-36 mx-2 sm:mx-0 sm:pl-24 w-full pr-2 h-full pt-40 sm:pt-24 -mt-24 sm:-mt-4">
                <div class="w-full bg-bkg rounded text-center h-full mx-2 py-96">
                    <span v-html="$t('finish_setup')"></span><br /><br />
                    <router-link to="/profile" class="bg-button text-white p-1 px-3 rounded">Finish agency setup</router-link>
                </div>
            </section>
        </transition>
    </slot>

    <div class="bg-green-300 rounded-full text-black p-2 fixed top-0 px-6 left-1/2" v-if="saved">{{ $t('saved') }}</div>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <transition name="zoom-fade">
        <section v-if="showModalkey && permissions && $getPermission(permissions,5).is_granted" @click="showModalkey = false" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
            <section @click.stop class="bg-white rounded-lg w-full max-h-screen max-w-md sm:max-w-5xl mx-auto my-auto p-8 sm:p-12 z-50 overflow-auto relative">
                <font-awesome-icon @click="showModalkey = false" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
                <shift-modal @result="handleSaveShift($event)" :modalField="modalField" :employeeList="employeeList" :helpFrom="helpFrom" :helpTo="helpTo" :errorMessage="errorMessage" :timeZone="timeZone" :myself="myself" />
            </section>
        </section>
    </transition>

    <slot v-if="dialogWindow">
        <div class="fixed w-full h-full left-0 top-0 bg-black bg-opacity-60 block z-40" @click="dialogWindow = false"></div>
        <section class="absolute sm:inset-1/4 block mt-2 p-10 bg-gray-300 z-50 rounded-lg w-full sm:w-96 mx-auto h-48">
            <font-awesome-icon icon="fas fa-xmark" @click="dialogWindow = false" class="cursor-pointer absolute right-3 top-3 p-2" />
            Do you want to remove shift on one model or on the whole team?<br />
            <section class="text-center w-full grid grid-cols-2 mt-5 gap-3">
                <div @click="removeShift(false)" class="p-1 rounded border-2 border-button text-button font-semibold cursor-pointer transition-all duration-500 hover:scale-105">One model</div>
                <div v-if="modalButtons == 1" @click="removeShift(true)" class="bg-button text-buttonText rounded p-1 font-semibold cursor-pointer transition-all duration-500 hover:scale-105">Whole team</div>
            </section>
        </section>
    </slot>
</template>

<script setup>
    import shiftModal from "@/components/shifts/shiftModal.vue";
</script>

<script>
    import {get_all_shifts,get_models,get_users} from "@/api.js";
    export default {
        props: ['selectedModel','selectedGroup','dateFrom','dateTo','selectedTeam','permissions','myself'],
        components: {
            shiftModal
        },
        data() {
            return {
                employeeList: [],
                modelsList: [],
                shiftTimes: [],
                helpFrom: null,
                helpTo: null,
                forAll:true,
                borderCount: 0,
                days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                mounted: false,
                showModalkey:false,
                shiftsData: {model_pairID: null,name: '',code: '',
                    data:{date:null,dayName:null,timeFrom:null, timeTo:null, hours: 0, shiftNo: null, shiftField:{employeeID:null,employeeName:null,note:null,salary:true}}
                },
                shiftID_delete:null,
                isLoadingShifts: false,
                loading:true,
                recalcLoading:false,
                errorMessage: null,
                saved:false,
                dialogWindow: false,
                modalButtons:1,
                indexesHelp:[],
                timeZone: this.$moment().utcOffset(),
                modalField: {split:false,modelPairID:null,shiftID:null,assignedAt:null,assignedBy:null,timeFrom:null,timeFromMinutes:0,timeTo:null,timeToMinutes:0,modelName:null,den:null,date:null,hours:0,empl:0,note:null,indexes:null,shiftNo:null,teamID:null,salary:null},
            }
        },
        methods: {
            handleSaveShift(payload) {
                this.showModalkey = false;

                let { groupIndex, columnIndex, shiftIndex, result, teamID } = payload;
                let shiftCounter = 0;
                this.shiftsData[groupIndex].models.forEach((values, index) => {
                    if (values.team_id == teamID) {
                        if (result.action == 'insert' && shiftCounter < result.shifts.length) {
                            let mI = this.forAll==true ? index : this.modalField.indexes['ind'];
                            this.shiftsData[groupIndex].models[mI].data[columnIndex].dayData[shiftIndex].shiftField.push({
                                'timeFrom': this.modalField.timeFrom,
                                'timeTo': this.modalField.timeTo,
                                'shiftID': result.shifts[shiftCounter].id,
                                'employeeID': this.modalField.empl,
                                'employeeGroupName': result.shifts[shiftCounter].teamName,
                                'employeeName': result.shifts[shiftCounter].name,
                                'note': this.modalField.note,
                                'employeeColor': result.shifts[shiftCounter].color,
                                'hours': parseFloat(this.modalField.hours),
                                'giveSalary': this.modalField.salary,
                                'assignedBy': result.shifts[shiftCounter].assignedBy
                            });
                            this.shiftsData[groupIndex].models[mI].data[columnIndex].dayData[shiftIndex].hours += parseFloat(this.modalField.hours); 
                            shiftCounter++;
                        }
                    }
                });
            },
            refresh() {
                console.log("refreshing");
                this.loadShifts(this.selectedTeam==null?0:this.selectedTeam);
            },
            calculateTimeDifferenceInRem(fieldTime, shiftTime, fieldDate, shiftDate) {
                // Parse dates and times
                const parseDateTime = (date, time) => new Date(`${date}T${time}`);
                
                // Create Date objects for both field and shift times
                const fieldDateTime = parseDateTime(fieldDate, fieldTime);
                const shiftDateTime = parseDateTime(shiftDate, shiftTime);

                // Calculate the difference in milliseconds
                const timeDifferenceMs = fieldDateTime - shiftDateTime;

                // Convert milliseconds to hours (1 hour = 3600000 milliseconds)
                const timeDifferenceHours = timeDifferenceMs / 3600000;

                // The difference in hours is equivalent to rem if 1 hour = 1rem
                return `${timeDifferenceHours}rem`;
            },
            calculateMarginLeft(fieldTimeFrom, shiftTimeFrom, shiftDate, fieldDate, employeeColor, hours) {
                // Convert times to minutes
                let marginLeftRem = this.calculateTimeDifferenceInRem(fieldTimeFrom, shiftTimeFrom, shiftDate, fieldDate);
                return {
                    'margin-left': `${marginLeftRem}`,
                    'width': `${hours}rem`, // Adding 1px to ensure visibility if width is too small
                    'border-top': `6px solid ${employeeColor}`,
                    // Include other styles as necessary
                };
            },
            openDialog(gIndex,shift_id,ind,index,i,fI,teamid) {
                this.dialogWindow = true;
                this.indexesHelp = [gIndex,ind,index,i,fI,teamid,shift_id];
            },
            removeShift(whole) {
                if (whole) {
                    const teamID = this.shiftsData[this.indexesHelp[0]].models[this.indexesHelp[1]].team_id;
                    var ids_to_delete = [];
                    this.shiftsData[this.indexesHelp[0]].models.forEach((values,indexL) => {
                        if (values.team_id == teamID) {
                            if (this.shiftsData[this.indexesHelp[0]].models[indexL].data[this.indexesHelp[2]].dayData[this.indexesHelp[3]].shiftField[this.indexesHelp[4]]) {
                                ids_to_delete.push(this.shiftsData[this.indexesHelp[0]].models[indexL].data[this.indexesHelp[2]].dayData[this.indexesHelp[3]].shiftField[this.indexesHelp[4]].shiftID);
                            }
                        }
                    })
                } else {
                    var ids_to_delete = [this.indexesHelp[6]];
                }

                if (ids_to_delete.length>0) {
                    this.$axios.post(baseURL+"shifts/", {                    
                        action: "deleteShift",
                        shift_ids: ids_to_delete,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        if (result.data && result.data == 'OK') {
                            if (whole == true) {
                                this.shiftsData[this.indexesHelp[0]].models.forEach((values,index) => {
                                    if(values.team_id == this.indexesHelp[5]) {
                                        this.shiftsData[this.indexesHelp[0]].models[index].data[this.indexesHelp[2]].dayData[this.indexesHelp[3]].shiftField.splice(this.indexesHelp[4],1);
                                    }
                                });
                            } else {
                                this.shiftsData[this.indexesHelp[0]].models[this.indexesHelp[1]].data[this.indexesHelp[2]].dayData[this.indexesHelp[3]].shiftField.splice(this.indexesHelp[4],1);
                            }
                        } else {
                            alert("Server error, shift not deleted. Please refresh the page");
                        }
                    }, (error) => {
                        console.log(error);
                    });
                    this.dialogWindow = false;
                }
            },
            showModal(gIndex,ind,index,i,fI,shiftEmpty) {
                this.showModalkey = true;
                this.modalField = {modelPairID:null,group:null,modelName:null,den:null,date:null,hours:0,empl:0,note:null,indexes:null,shiftNo:null}
                this.modalField = {
                    teamID: this.shiftsData[gIndex].models[ind].team_id,
                    modelName: this.shiftsData[gIndex].models[ind].name,
                    group: this.shiftsData[gIndex].models[ind].group_id,
                    modelPairID: this.shiftsData[gIndex].models[ind].pair_id
                }
                this.modalField.den = this.shiftsData[gIndex].models[ind].data[index].dayName
                this.modalField.date = this.$moment(this.shiftsData[gIndex].models[ind].data[index].date).format("DD.MM.YYYY")
                this.modalField.shiftNo = this.shiftsData[gIndex].models[ind].data[index].dayData[i].shiftNo
                this.modalField.timeFromMinutes = 0;
                this.modalField.timeToMinutes = 0;
                var shiftField = this.shiftsData[gIndex].models[ind].data[index].dayData[i].shiftField ? this.shiftsData[gIndex].models[ind].data[index].dayData[i].shiftField[fI] : null;
                if (shiftField && shiftEmpty == false) {
                    this.modalField.shiftID = shiftField.shiftID ? shiftField.shiftID : null;
                    this.modalField.assignedBy = !shiftField ? '' : shiftField.assignedBy;
                    this.modalField.assignedAt = !shiftField ? '' : shiftField.assignedAt;
                    this.modalField.salary = Boolean(shiftField.giveSalary)
                    this.helpFrom = this.$moment(shiftField.timeFrom, "HH:mm:ss").format("HH:mm")
                    this.modalField.timeFromMinutes = parseInt(this.$moment(shiftField.timeFrom, "HH:mm:ss").format("mm"));
                    this.modalField.timeToMinutes = parseInt(this.$moment(shiftField.timeTo, "HH:mm:ss").format("mm"));
                    this.helpTo = shiftField.timeTo
                    this.modalField.timeFrom = this.formatTime(this.modalField.date + ' ' + shiftField.timeFrom,"H");
                    this.modalField.timeTo = this.formatTime(this.modalField.date + ' ' + shiftField.timeTo,"H");
                    if (this.modalField.timeFrom == 0) {this.modalField.timeFrom = 24} if (this.modalField.timeTo == 0) {this.modalField.timeTo = 24}
                    this.forAll = false;
                } else {
                    this.modalField.salary = true
                    this.helpFrom = this.shiftsData[gIndex].models[ind].data[index].dayData[i].timeFrom;
                    this.helpTo = this.shiftsData[gIndex].models[ind].data[index].dayData[i].timeTo
                    this.modalField.timeFrom = this.formatTime(this.modalField.date + ' ' + this.shiftsData[gIndex].models[ind].data[index].dayData[i].timeFrom,"H");
                    this.modalField.timeTo = this.formatTime(this.modalField.date + ' ' + this.shiftsData[gIndex].models[ind].data[index].dayData[i].timeTo,"H");
                    if (this.modalField.timeFrom == 0) {this.modalField.timeFrom = 24} if (this.modalField.timeTo == 0) {this.modalField.timeTo = 24}
                    this.forAll = true;
               }
               this.modalField.indexes = {gIndex,ind,index,i,fI}
               if (shiftField && shiftEmpty == false) {
                   this.modalField.empl = shiftField.employeeID
                   this.modalField.note = shiftField.note
               }
               else {this.modalField.empl=0}
               this.modalField.split = shiftField ? true : false;
               this.modalField.hours = this.timeDiff(this.modalField.timeFrom,this.modalField.timeTo)
            },
            timeDiff(from,to) {
                if (parseFloat(from)<parseFloat(to)) { return parseFloat(to)-parseFloat(from);} 
                else { return 24-parseFloat(from)+parseFloat(to);}
            },
            determinZoneDST(original) {
                var tzone = 0;
                if (this.$moment().isDST()) {
                    if (this.$moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                        tzone = parseInt(this.timeZone)
                    } else {
                        tzone = (parseInt(this.timeZone)/60-1)*60
                    }
                } else {
                    if (this.$moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                        tzone = (parseInt(this.timeZone)/60+1)*60
                    } else {
                        tzone = parseInt(this.timeZone)
                    }
                }
                return tzone;
            },
            formatTime(original,finalFormat) {
                if (this.$moment().isDST()) {
                    if (this.myself && this.myself.companyTz) {
                        if (parseInt(this.timeZone)<0) {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract((parseInt(this.timeZone)/60+1)*60, 'minutes').format(finalFormat);
                        } else {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add((parseInt(this.timeZone)/60-1)*60, 'minutes').format(finalFormat);
                        }
                    } else { // MSB branch ONLY -> IN SUMMER
                        var tzone = this.determinZoneDST(original)
                        if (parseInt(this.timeZone)<0) {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract(tzone, 'minutes').format(finalFormat);
                        } else {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add(tzone, 'minutes').format(finalFormat);
                        }
                    }
                } else {
                    if (parseInt(this.timeZone)<0) {
                        return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    } else {
                        return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    }
                }
            },
            formatTimeBack(original,finalFormat) {
                if (this.$moment().isDST()) {
                    if (this.myself && this.myself.companyTz) {
                        if (parseInt(this.timeZone)<0) {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add((parseInt(this.timeZone)/60+1)*60, 'minutes').format(finalFormat);
                        } else {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract((parseInt(this.timeZone)/60-1)*60, 'minutes').format(finalFormat);
                        }
                    } else { // MSB branch ONLY -> IN SUMMER
                        var tzone = this.determinZoneDST(original)
                        if (parseInt(this.timeZone)<0) {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add(tzone, 'minutes').format(finalFormat);
                        } else {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract(tzone, 'minutes').format(finalFormat);
                        }
                    }
                } else {
                    if (parseInt(this.timeZone)<0) {
                        return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    } else {
                        return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    }
                }
            },
            formatDate(original) {
                return this.$moment(original, "YYYY-MM-DD").format("DD.MM.");
            },
            async getListData(whichData,group) {
                return new Promise(async (resolve, reject) => {
                    try {
                      const result = await this.$axios.get(baseURL+"lists/", {
                            params: {
                                build: whichData,
                                groupFilter: group,
                                userToken: this.$cookies.get('userToken'),
                                companyID: this.$cookies.get('c_id')
                            }
                        })
                      resolve(result.data);
                    } catch (error) {
                      reject(error);
                    }
                });
            },
            async loadShifts(team) {
                if (this.isLoadingShifts) {return;}
                this.isLoadingShifts = true;
                try {
                    this.shiftsData = await get_all_shifts(this.$cookies.get('userToken'),this.$cookies.get('c_id'),'shiftsAll',this.selectedGroup,team,this.$moment(this.dateFrom).format('YYYY-MM-DD'),this.$moment(this.dateTo).format('YYYY-MM-DD'));
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isLoadingShifts = false;
                    this.loading = false;
                }
            },
        },
        watch: {
            dateFrom: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || (newVal === 0 && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.loadShifts(this.selectedTeam);
            },
            dateTo: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || (newVal === 0 && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.loadShifts(this.selectedTeam);
            },
            selectedGroup: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || (newVal === 0 && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.loadShifts(0);
            },
            selectedTeam: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || (newVal === 0 && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.loadShifts(this.selectedTeam==null?0:this.selectedTeam);
            }
        },
        async created() {
            this.$axios.get(baseURL+"settingsget/", {
                params: {
                    action: 'shiftsSettings',
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }
            }).then(result => {
                if (result.data) {
                    this.shiftTimes = result.data;
                    this.borderCount = this.shiftTimes.length -1;
                } else {
                    this.nodata = true;
                }
            }, (error) => {
                console.log(error);
            });
            this.employeeList = await get_users(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,null,null,1);
            this.modelsList = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,null);
        },
        mounted() {
            setTimeout(() => {
                if (this.dateFrom && this.dateTo && this.dateFrom != this.dateTo) {
                    this.mounted = true;
                    this.loadShifts(this.selectedTeam==null?0:this.selectedTeam);
                }
            }, 500);
        }
    }
</script>

<style scoped>
    .zoom-fade-enter-active {
        transition: all .3s ease;
    }
    .zoom-fade-leave-active {
        transition: all .3s ease;
    }
    .zoom-fade-enter-from, .zoom-fade-leave-to {
        transform: scale(0.9);
        opacity: 0;
    }
    .rightBorder {
        border-right-color: #E4E5E4 !important;
        border-right-width: 4px !important;
    }
    #parent {
        position: absolute;
        overflow: hidden;
        height: calc(100% - 8rem);
    }
    table {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        max-height:100%;
        border-collapse: collapse;
        overflow: hidden;
    }

    thead {
        flex: 1 0 auto;
        display: block;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-base-color: #ccc;
        scrollbar-face-color: #ccc;
        scrollbar-highlight-color: #ccc;
        scrollbar-track-color: #ccc;
        scrollbar-arrow-color: #ccc;
        scrollbar-shadow-color: #ccc;
    }
    thead::-webkit-scrollbar { display: block; background-color: #ccc; }
    thead::-webkit-scrollbar-track { background-color: #ccc; }
    tbody {
        display: block;
        overflow: scroll;
    }
    tbody:nth-child(3) { display: none; }
    td, th {
        padding-left: 0.3em;
        padding-right: 0.3em;
    }

    td:first-child,th:first-child {
        position: sticky;
        position: -webkit-sticky;
        left:0;
    }
    
</style>