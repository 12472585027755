<template>
    <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
    <header-filter :enable="loadingTables" :pageTitle="'Metrics'" :myself="myself" :lrIndex="lrIndex" :lrData="lrData" @selectedPlatform="selectedPlatform = $event" @selectedEmpl="selectedEmpl = $event" @selectedModelN="selectedModelN = $event" @selectedModel="selectedModel = $event" @selectedGroupN="selectedGroupN=$event" @selectedGroup="selectedGroup = $event" @dateFrom="dateFrom = $event" @dateTo="dateTo = $event"/>
    
    <div v-if="permissions != null && $getPermission(permissions,1).is_granted" class="mx-2 mb-24 sm:ml-20 mr-2" :class="{'mt-36':selectedModel, 'mt-24': !selectedModel}">
        <section v-if="selectedModel" class="hidden float-right md:clear-both lg:mx-auto w-max grid grid-cols-2 text-center font-semibold cursor-pointer">
            <div class="p-1 px-4 rounded-l" :class="{'bg-button text-black':displayTab == 1, 'border border-indigo-300 text-button': displayTab == 2}" @click="dTab(1)">Metrics</div>
            <div class="p-1 px-4 rounded-r" :class="{'bg-button text-black':displayTab == 2, 'border border-indigo-300 text-button': displayTab == 1}" @click="dTab(2)">History metrics</div>
        </section>

        <section :class="{'-mt-16 sm:-mt-10':selectedModel}">
            <div class="float-left whitespace-nowrap cursor-pointer p-1.5 px-5 rounded bg-button text-buttonText font-semibold w-max border border-sepBorder" @click="refresh()" :title="$t('refresh')"><font-awesome-icon icon="fas fa-redo" /> {{ $t('refresh') }}</div>
            <div class="text-textOnBkg text-lg pt-1 block"> 
                <span v-if="selectedModel">
                    <font-awesome-icon icon="fas fa-caret-left" @click="lrIndex = lrIndex-1, lrData = 'model'" class="float-left ml-8 p-1 px-2 -mt-2 cursor-pointer text-3xl transition-all transform hover:scale-125 ease-in-out" /> 
                    <div class="block float-left w-60 px-1 text-center truncate text-base"> {{selectedModelN}} </div>
                    <font-awesome-icon icon="fas fa-caret-right" @click="lrIndex = lrIndex+1, lrData = 'model'" class="transition-all -mt-2  p-1 px-2 transform hover:scale-125 ease-in-out text-3xl cursor-pointer" />
                </span>
                <span v-else-if="selectedGroup">
                    <font-awesome-icon icon="fas fa-caret-left" @click="lrIndex = lrIndex-1, lrData = 'group'" class="float-left ml-8 p-1 -mt-2 px-2 cursor-pointer transition-all transform hover:scale-125 text-3xl ease-in-out" /> 
                    <div class="block float-left w-48 px-1 text-center truncate text-base"> {{selectedGroupN}}</div>
                    <font-awesome-icon icon="fas fa-caret-right" @click="lrIndex = lrIndex+1, lrData = 'group'" class="p-1 px-2 -mt-2 cursor-pointer transition-all transform hover:scale-125 text-3xl ease-in-out" />
                </span>
            </div>
        </section>
        
        <metrics-table v-if="dateFrom < dateTo && ($moment(dateFrom).format('YYYY-MM-DD')) !== $moment().format('YYYY-MM-DD')" :refreshing="refreshing" period="daily" tableTitle="Daily stats" :blockedColumns="blockedColumns" :selectedPlatform="selectedPlatform" :selectedModelN="selectedModelN" :selectedModel="selectedModel" :permissions="permissions" :selectedGroup="selectedGroup" :selectedGroupN="selectedGroupN" :dateFrom="dateFrom" :dateTo="dateTo" />
        <metrics-table :refreshing="refreshing" period="week" tableTitle="Weekly stats" :blockedColumns="blockedColumns" :selectedPlatform="selectedPlatform" :selectedModelN="selectedModelN" :selectedModel="selectedModel" :permissions="permissions" :selectedGroup="selectedGroup" :selectedGroupN="selectedGroupN" :dateFrom="dateFrom" :dateTo="dateTo" />
        <metrics-table :refreshing="refreshing" period="month" tableTitle="Monthly stats" :blockedColumns="blockedColumns" :selectedPlatform="selectedPlatform" :selectedModelN="selectedModelN" :selectedModel="selectedModel" :permissions="permissions" :selectedGroup="selectedGroup" :selectedGroupN="selectedGroupN" :dateFrom="dateFrom" :dateTo="dateTo" />
    </div>

    <div v-if="permissions != null && (!$getPermission(permissions,1).is_granted)" class="text-center mt-52 text-textOnBkg">{{ $t("no_permission") }}</div>
</template>

<script setup>
    import metricsTable from '@/components/metrics/table.vue'
    import headerFilter from "@/components/headerFilter.vue"
    import leftMenu from "@/components/LeftMenu.vue"
</script>

<script>
    export default {
        props:['selectedModel', 'selectedModelN','selectedPlatform','selectedGroupN','selectedEmpl','selectedGroup','dateFrom','dateTo'],
        emits: ['lrIndex','lrData'],
        data() {
            return {
                permissions:null,
                blockedColumns: {renewOn:0,subsPaid:0},
                displayTab:1,
                loadingTables:0,
                myself:null,
                loaded:false,
                refreshing:false,
                lrIndex:null,
                lrData:null
            }
        },
        components() {
            branch,
            metricsTable,
            headerFilter,
            leftMenu
        },
        methods: {
            dTab(w) {
                this.displayTab = w;
            },
            refresh() {
                this.refreshing = true
                setTimeout(() => {
                    this.refreshing = false
                }, 200);
            },
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        companyID: companyID,
                        place: 'header'
                    }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', query: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.myself) {this.myself = result.data.myself;}
                    if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                    if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
                    setTimeout(() => {
                        this.loaded = true;
                    }, 500); 
                }, (error) => {
                console.log(error);
            });
        },
        mounted() {
            setTimeout(() => {
                this.loadingTables = 1;
            }, 1000);
            setTimeout(() => {
                this.loadingTables = 2;
            }, 1000); 
        },
    }
</script>