<template>
    <div class="p-2 px-5 cursor-pointer rounded bg-button text-white font-semibold w-max">
        <font-awesome-icon :icon="icon" class="mr-2" /> {{label}}
    </div>
</template>

<script>
    export default {
        props: ['label','icon']
    }
</script>