<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   
    
    <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
    <header-filter :myself="myself" pageTitle="Sexting" @selectedMonth="selectedMonth = $event" @selectedTeam="selectedTeam = $event" @selectedGroup="selectedGroup = $event" @selectedEmpl="selectedEmpl = $event" @dateFrom="dateFrom = $event"/>

    <section v-if="sexting && sexting?.values?.length>0 && permissions && $getPermission(permissions,3).is_granted">
        <div class="whitespace-nowrap float-left cursor-pointer w-min p-1.5 px-5 mt-24 ml-4 sm:ml-20 rounded text-buttonText bg-button w-max border border-sepBorder" @click="refresh()" :title="$t('refresh')"><font-awesome-icon icon="fas fa-redo" /> {{ $t('refresh') }}</div>
        
        <div class="w-max absolute right-5 top-0 bg-bkg z-20 border border-t-0 border-sepBorder shadow-lg rounded-b p-1 pb-2 px-4" style="z-index:40">
            <div class="grid grid-cols-5 w-72 gap-2">
                <div class="col-span-3">
                    <label class="text-black mt-16 text-xs">Date from:</label><br />
                    <VueDatePicker
                        v-model="customPeriod.dateFrom"
                        :max-date="customPeriod.maxTo"
                        :enable-time-picker="false"
                        format="yyyy-MM-dd"
                        />
                </div>
                <div class="col-span-2">
                    <label class="text-black mt-16 text-xs">Time from:</label><br />
                    <VueDatePicker
                        v-model="customPeriod.timeFrom"
                        time-picker
                        :enable-time-picker="false"
                        />
                </div>
            </div>
            <div class="grid grid-cols-5 w-72 gap-2 mt-0.5">
                <div class="col-span-3">
                    <label class="text-black mt-16 text-xs">Date to:</label><br />
                    <VueDatePicker
                        v-model="customPeriod.dateTo"
                        :max-date="customPeriod.maxTo"
                        :min-date="customPeriod.dateFrom"
                        :enable-time-picker="false"
                        format="yyyy-MM-dd"
                        />
                </div>
                <div class="col-span-2">
                    <label class="text-black mt-16 text-xs">Time to:</label><br />
                    <VueDatePicker
                        v-model="customPeriod.timeTo"
                        time-picker
                        :enable-time-picker="false"
                        />
                </div>
            </div>
            <div class="text-xs mt-1 float-left leading-0"><strong>Timezone:</strong><br />{{$moment.tz.guess()}}</div>
            <button @click="fetch_shifts_earnings(selectedGroup,selectedTeam)" class="bg-button text-white p-0.5 px-2.5 mt-2 float-right rounded"><font-awesome-icon icon="fas fa-filter" /> Filter</button>
        </div>

        <div class="hidden absolute right-2 mr-5 mt-16 text-textOnBkg w-max ml-5 pt-8">
            <div class="mt-1.5 text-sm float-left">Display options: </div>
            <font-awesome-icon icon="fas fa-table-cells-large" @click="layout=1" :class="{'opacity-70':layout != 1}" class="hover:opacity-100 ml-3 cursor-pointer p-2 px-3 mr-2 text-buttonText bg-button rounded-lg border border-sepBorder" />
            <font-awesome-icon icon="fas fa-bars" @click="layout=2" :class="{'opacity-70':layout != 2}" class="hover:opacity-100 cursor-pointer p-2 px-3 text-buttonText bg-button rounded-lg border border-sepBorder" />
        </div>

        <div class="clear-both"></div>

        <slot v-if="companySummary">
            <slot v-if="layout == 1">
                <summary-panel :panelData="companySummary.total" headline="Total" :lineSeparator="true" :layout="layout" class="sm:ml-16" />
            </slot>
            <slot v-else>
                <summary-panel :panelData="companySummary.first" headline="1. half" :lineSeparator="false" :layout="layout" class="sm:ml-16" />
                <summary-panel :panelData="companySummary.second" headline="2. half" :lineSeparator="true" :layout="layout" class="sm:ml-16" />
            </slot>
        </slot>

        <section class="relative sm:ml-16">
            <section class="flex h-screen">
                <section class="float-left" v-for="(g, gIndex) in sexting.values" :key="gIndex" >
                    
                    <section v-if="g.employeeCards && g.employeeCards.length > 0 && computedPanelSummaries[gIndex].total.hours>0" class="border-r border-gray-500 pr-3">
                        <div class="h-max -mt-5 sm:mt-0">
                            <slot v-if="layout == 1">
                                <summary-panel :panelData="computedPanelSummaries[gIndex].total" :headline="g.groupName" :layout="layout" :lineSeparator="false" />
                            </slot>
                            <slot v-else>
                                <summary-panel :panelData="computedPanelSummaries[gIndex].first" :headline="g.groupName" subHeadline="1. half" :layout="layout" :lineSeparator="false" />
                                <summary-panel :panelData="computedPanelSummaries[gIndex].second" :headline="g.groupName" subHeadline="2. half" :layout="layout" :lineSeparator="false" />
                            </slot>
                        </div>
                        <section v-if="g.employeeCards && g.employeeCards.length > 0" class="relative clear-both">
                            <div class="ml-3 overflow-hidden mb-16 grid grid-cols-1 w-200">
                                <slot v-for="(employee,i) in g.employeeCards" :key="i">
                                    <sexting-bubble :employeeData="employee" :layout="layout" :selectedEmpl="selectedEmpl" :gIndex="gIndex" :i="i" :groupID="g.groupID" :selectedMonth="selectedMonth" :permissions="permissions" />
                                </slot>
                            </div>
                        </section>
                    </section>
                </section>
            </section>
        </section>
    </section>
    <div v-else-if="loading" class="text-center mt-52 text-textOnBkg">Loading <font-awesome-icon icon="fas fa-spinner" class="animate-spin" /></div>
    <div v-else>
        <transition name="fade">
            <section class="mb-36 mx-2 sm:mx-0 sm:pl-24 w-full pr-2 h-full pt-40 sm:pt-24 -mt-24 sm:-mt-4">
                <div class="w-full bg-bkg rounded text-center h-full mx-2 py-96">
                    <span v-html="$t('finish_setup')"></span><br /><br />
                    <router-link to="/profile" class="bg-button text-white p-1 px-3 rounded">Finish agency setup</router-link>
                </div>
            </section>
        </transition>
    </div>
    <div v-if="permissions != null && (!$getPermission(permissions,3).is_granted)" class="text-center mt-52 text-textOnBkg">{{ $t("no_permission") }}</div>
</template>

<script setup>
    import headerFilter from "@/components/headerFilter.vue"
    import sextingBubble from "@/components/sexting/sextingBubble.vue"
    import summaryPanel from "@/components/sexting/summaryPanel.vue"
    import leftMenu from '@/components/LeftMenu.vue'
</script>

<script>
    import { verify_token,get_sexting } from "@/api"
    export default {
        props: ['dateFrom','selectedEmpl','selectedGroup','selectedMonth','selectedTeam','selectedPlatform'],
        components() {
            leftMenu,
            headerFilter,
            sextingBubble,
            summaryPanel
        },
        data() {
            return {
                sexting: null,
                layout: 1,
                groups: [],
                singleGroup: null,
                loading: true,
                calcLoad: null,
                mounted: false,
                permissions:null,
                myself:null,
                customPeriod: {
                    dateFrom: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-01',
                    timeFrom: {hours: 0, minutes: 0, seconds: 0},
                    dateTo: new Date().toISOString().split('T')[0],
                    timeTo: {hours: 23, minutes: 59, seconds: 59},
                    maxTo: new Date().toISOString().split('T')[0]
                }
            }
        },
        computed: {
            computedPanelSummaries() {
                if (this.sexting && this.sexting.values?.length > 0) {
                    // Calculate summaries for each half of the month
                    return this.sexting.values.map(g => {
                        if (g && g.employeeCards) {
                            const firstHalfSummary = this.calculateHalfSummary(g, true);
                            const secondHalfSummary = this.calculateHalfSummary(g, false);
                        return {
                            first: firstHalfSummary,
                            second: secondHalfSummary,
                            // Calculate the total if needed, or you can handle total calculation elsewhere
                            total: {
                                hours: firstHalfSummary.hours + secondHalfSummary.hours,
                                salary: (parseFloat(firstHalfSummary.salary) + parseFloat(secondHalfSummary.salary)).toFixed(2),
                                earnings: (parseFloat(firstHalfSummary.earnings) + parseFloat(secondHalfSummary.earnings)).toFixed(2),
                                shifts: (parseFloat(firstHalfSummary.shifts) + parseFloat(secondHalfSummary.shifts)),
                                word_count: (parseInt(firstHalfSummary.word_count) + parseInt(secondHalfSummary.word_count)),
                                costRatio: (((parseFloat(firstHalfSummary.salary) + parseFloat(secondHalfSummary.salary))/(parseFloat(firstHalfSummary.earnings) + parseFloat(secondHalfSummary.earnings)))*100).toFixed(2)
                                // Other properties as needed
                            },
                        };
                        }
                        return null;
                    }).filter(summary => summary !== null);
                }
            },
            companySummary() {
                const summary = {
                    first: { hours: 0, salary: 0, earnings: 0, shifts: 0, word_count: 0 },
                    second: { hours: 0, salary: 0, earnings: 0, shifts: 0, word_count: 0 },
                    total: { hours: 0, salary: 0, earnings: 0, shifts: 0, word_count: 0 }
                };

                if (this.computedPanelSummaries && this.computedPanelSummaries.length > 0) {
                    this.computedPanelSummaries.forEach(groupSummary => {
                        if (groupSummary && groupSummary.first && groupSummary.second) {
                            // Aggregate first half
                            summary.first.hours += groupSummary.first.hours;
                            summary.first.salary += parseFloat(groupSummary.first.salary || 0);
                            summary.first.earnings += parseFloat(groupSummary.first.earnings || 0);
                            summary.first.shifts += groupSummary.first.shifts;
                            summary.first.word_count += groupSummary.first.word_count;

                            // Aggregate second half
                            summary.second.hours += groupSummary.second.hours;
                            summary.second.salary += parseFloat(groupSummary.second.salary || 0);
                            summary.second.earnings += parseFloat(groupSummary.second.earnings || 0);
                            summary.second.shifts += groupSummary.second.shifts;
                            summary.second.word_count += groupSummary.second.word_count;

                            // Calculate total for the whole month
                            summary.total.hours += groupSummary.total.hours;
                            summary.total.salary += parseFloat(groupSummary.total.salary || 0);
                            summary.total.earnings += parseFloat(groupSummary.total.earnings || 0);
                            summary.total.shifts += groupSummary.total.shifts;
                            summary.total.word_count += groupSummary.total.word_count;
                        }
                    });

                    // Calculate cost ratio for the first half
                    if (summary.first.earnings > 0) {
                        summary.first.costRatio = ((summary.first.salary / summary.first.earnings) * 100).toFixed(2);
                    } else {
                        summary.first.costRatio = 0;
                    }

                    // Calculate cost ratio for the second half
                    if (summary.second.earnings > 0) {
                        summary.second.costRatio = ((summary.second.salary / summary.second.earnings) * 100).toFixed(2);
                    } else {
                        summary.second.costRatio = 0;
                    }

                    // Calculate cost ratio for the entire month
                    if (summary.total.earnings > 0) {
                        summary.total.costRatio = ((summary.total.salary / summary.total.earnings) * 100).toFixed(2);
                    } else {
                        summary.total.costRatio = 0;
                    }
                }

                return summary;
            }
        },
        watch: {
            'customPeriod.dateFrom': function(newVal) {
                this.customPeriod.dateFrom = this.formatDate(newVal);
            },
            'customPeriod.dateTo': function(newVal) {
                this.customPeriod.dateTo = this.formatDate(newVal);
            },
            /*selectedMonth: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || (newVal === 0 && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.customPeriod.dateFrom = this.formatDate(newVal);
                this.getSexting(this.selectedGroup,this.selectedTeam);
            },*/
            selectedGroup: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || (newVal === 0 && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.fetch_shifts_earnings(this.selectedGroup,this.selectedTeam);
            },
            selectedEmpl: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || (newVal === 0 && oldVal === undefined) || !this.mounted) {
                        return;
                }
                this.fetch_shifts_earnings(this.selectedGroup,this.selectedTeam);
            },
            selectedTeam: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || (newVal === 0 && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.fetch_shifts_earnings(this.selectedGroup,this.selectedTeam);
            },
            selectedPlatform: function(newVal,oldVal) {
                if ((newVal === null && oldVal === undefined) || (newVal === 0 && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.fetch_shifts_earnings(this.selectedGroup,this.selectedTeam);
            }
        },
        methods: {
            formatDate(date) {
                // Check if date is already a Date object
                if (!(date instanceof Date)) {
                    date = new Date(date);
                }
                if (isNaN(date.getTime())) {
                    throw new Error('Invalid date');
                }
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            },
            calculateHalfSummary(group, isFirstHalf) {
                let salary = this.halfMonthReduce(group, "salary", isFirstHalf).toFixed(2);
                let earnings = this.halfMonthReduce(group, "shiftSum", isFirstHalf).toFixed(2);
                return {
                    hours: this.halfMonthReduce(group, "hours", isFirstHalf),
                    salary: salary,
                    earnings: earnings,
                    costRatio: earnings > 0 && salary > 0 ? (salary / earnings * 100).toFixed(2) : 0,
                    shifts: this.halfMonthReduce(group, "shiftCount", isFirstHalf), // Use "shiftCount" or a similar unique property name
                    word_count: this.halfMonthReduce(group, "word_count", isFirstHalf),
                    // Bonus and other properties if needed
                };
            },

            halfMonthReduce(group, propertyName, firstHalf = true) {
                return group.employeeCards.reduce((total, employeeCard) => {
                    // Now we reduce the shifts for each employeeCard
                    const employeeTotal = employeeCard.shifts.reduce((employeeTotal, shift) => {
                    const day = parseInt(shift.date.split(".")[0], 10); // Assuming the date format is "DD.MM."

                    // Determine the date range based on the half of the month
                    const isInFirstHalf = day >= 1 && day <= 15;
                    const isInSecondHalf = day >= 16 && day <= 31; // Adjust if needed for different month lengths

                    // Check if the shift date is within the specified half of the month
                    if ((firstHalf && isInFirstHalf) || (!firstHalf && isInSecondHalf)) {
                        if (propertyName === "shiftCount") { // Check for a unique property name to count shifts
                        return employeeTotal + 1;
                        }
                        return employeeTotal + (shift[propertyName] || 0);
                    }
                    return employeeTotal;
                    }, 0);

                    // Sum up the totals for each employeeCard
                    return total + employeeTotal;
                }, 0);
            },

            getSum(propertyName,g) {
                var sum = g.employeeCards.reduce((sum, card) => {
                    return sum + card.shifts.reduce((shiftTotal, shift) => {
                        return shiftTotal + (shift[propertyName] || 0);
                    }, 0);
                }, 0);
                return sum;
            },
            async fetch_shifts_earnings(groupID,teamID = null) {
                this.loading = true;
                let timestamp_from = this.formatDate(this.customPeriod.dateFrom) + ' ' + String(this.customPeriod.timeFrom.hours).padStart(2, '0') + ':' + String(this.customPeriod.timeFrom.minutes).padStart(2, '0') + ':' + String(this.customPeriod.timeFrom.seconds).padStart(2, '0');
                let timestamp_to = this.formatDate(this.customPeriod.dateTo) + ' ' + String(this.customPeriod.timeTo.hours).padStart(2, '0') + ':' + String(this.customPeriod.timeTo.minutes).padStart(2, '0') + ':' + String(this.customPeriod.timeTo.seconds).padStart(2, '0');

                this.sexting = await get_sexting(this.$cookies.get('userToken'),this.$cookies.get('c_id'),'sextingData',groupID,teamID,this.selectedEmpl, timestamp_from, timestamp_to);
                if (this.sexting) {this.loading = false;}
            },
            /*async getSexting(groupID,teamID = null,customPeriod = null) {
                if (this.selectedMonth) {
                    this.sexting = await get_sexting(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.selectedMonth,'sextingData',groupID,teamID,this.selectedEmpl, customPeriod)
                    if (this.sexting) {this.loading = false;}
                }
            },*/
            refresh() {
                var groupID = 0;
                if ((this.selectedTeam == 0) || (this.selectedTeam == null)) {
                    groupID = this.selectedGroup;
                } else {
                    groupID = this.selectedTeam;
                }
                this.fetch_shifts_earnings(groupID,null);
            },
        },
        async mounted() {
            this.mounted = true
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
            }).then((result) => {
                if (result.data.selectBranch) {
                    let prev = this.$route.path;
                    this.$router.push({name:'Branches', query: { prevRoute: prev } });
                } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                    this.$cookies.set('userToken','');
                    window.location.href = '/';
                }
                if (result.data.myself) {this.myself = result.data.myself;}
                if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
            }, (error) => {
                console.log(error);
            });

            this.fetch_shifts_earnings(null,null);
            //this.getSexting(null,null,['2024-07-01 00:00:00','2024-07-23 23:59:59']);
        }
    }
</script>

<style scoped>
	.table {
		border-spacing: 0 15px;
        border:0;
	}

	i {
		font-size: 1rem !important;
	}

	.table tr {
		border-radius: 5px;
	}

	tr td:nth-child(n+8),
	tr th:nth-child(n+8) {
		border-radius: 0 5px 5px 0;
	}

	tr td:nth-child(1),
	tr th:nth-child(1) {
		border-radius: 5px 0 0 5px;
	}

    tbody tr:nth-child(odd) {
        background:#1a212c;
    }

    tbody tr:nth-child(even) {
        background:#2c333d;
    }
</style>