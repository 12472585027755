<template>
    <section class="bg-bkg text-contentText p-6 pt-12 mb-1">
        <section class="container mx-auto px-2 relative md:h-96">
          <div>
              <h2 class="text-xl sm:text-3xl font-bold mb-4">{{$t('Our_Partnership_Triumphs')}}</h2>
          </div>

          <carousel v-bind="settings" :breakpoints="breakpoints" :wrap-around="true">
            <slide v-for="slide in reviews" :key="slide.id">
              <div :key="slide.id" class="mx-auto px-5 md:px-10 sm:px-16 lg:px-32 w-full carousel__item">
                <div class="md:h-64 sm:h-48 bg-squares border border-sepBorder p-3 px-4 md:p-6 rounded-lg shadow-md text-center">
                    <div class="mb-1">
                        <font-awesome-icon icon="fas fa-star" class="text-gold" v-for="n in 4" :key="n" />
                        <font-awesome-icon class="text-gold" :icon="lastStar(currentIndex)" />
                    </div>
                    <p class="text-panelsText mb-4 text-sm md:text-base">{{ slide.text }}</p>
                    <div class="flex items-center justify-center">
                        <div>
                            <h2 class="text-panelsText font-semibold">{{ slide.userName }}</h2>
                            <h3 class="text-sm text-tableText">{{ slide.userTitle }}</h3>
                        </div>
                    </div>
                </div>
            </div>
            </slide>
        
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </section>
    </section>
</template>


<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
  name: 'Reviews',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data() {
    return {
      reviews: [
        {
          id: 1,
          text: "CreatorXone that's a total game-changer for handling Fansly. Unlike the rest, it lets you manage everything in one spot and even has a lot of cool auto-engagement things. Super handy and a real time-saver!",
          userName: "E&E babezz",
          userTitle: "Creator Agency",
        },
        {
          id: 2,
          text: "Insightful metrics really helped me understand my audience better and plan my content strategy more effectively!",
          userName: "Morgan Blake",
          userTitle: "Agency Owner",
        },
        {
          id: 3,
          text: "Automated data gathering is a lifesaver. It allows me to focus more on creating and less on tedious tasks.",
          userName: "Casey Turner",
          userTitle: "Marketing Manager",
        },
      ],
      currentIndex: 0,
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 1.4,
          snapAlign: 'center',
        },
      }
    }
  },
  computed: {
    currentReview() {
      return this.reviews[this.currentIndex];
    }
  },
  methods: {
    lastStar(index) {
      return index % 2 === 0 ? 'fa-star-half-stroke' : 'fa-star';
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 768px){
  :deep(.carousel__prev) {
    left: -25px;
    z-index: 10;
  }
  :deep(.carousel__next) {
    right: -25px;
    z-index: 10;
  }
}
</style>