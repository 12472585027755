<template>
    <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
    <header-filter :pageTitle="'Transactions'" @selectedEmpl="selectedEmpl = $event" @selectedModel="selectedModel = $event" @selectedGroup="selectedGroup = $event" @dateFrom="dateFrom = $event" @dateTo="dateTo = $event"/>

    <div class="bg-green-300 rounded-full text-black p-2 fixed top-5 px-6 ml-60" v-if="saved">{{ $t('saved') }}</div>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>

    <section class="bg-white ml-24 p-5 px-10 mt-24 rounded container max-w-screen-lg relative" v-if="transactionsObj && transactionsObj.free && transactionsObj.free.length>0 && permissions && ($getPermission(permissions,15).is_granted || $getPermission(permissions,16).is_granted)">
        <slot>
            <section class="mt-5 w-max grid grid-cols-2 text-center font-semibold cursor-pointer">
                <div class="p-1 px-4 rounded-l" :class="{'bg-button text-buttonText':displayTab == 1, 'border border-button text-button': displayTab == 2}" @click="dTab(1)">{{ $t('Transactions') }}</div>
                <div class="p-1 px-4 rounded-r" :class="{'bg-button text-buttonText':displayTab == 2, 'border border-button text-button': displayTab == 1}" @click="dTab(2)">{{ $t('reverse') }}</div>
            </section>
            <div class="w-full h-full z-40 bg-opacity-30 bg-black fixed top-0" v-if="openIndex" @click="openIndex = null"></div>

            <slot v-if="displayTab == 1">
                <section v-if="transactionsStats && transactionsStats.data?.length>0" @click="getWholeGraph()" class="rounded p-1.5 px-3 text-button border-2 border-button cursor-pointer font-bold w-max right-10 absolute -mt-2">
                    <font-awesome-icon icon="fas fa-eye fa-sm" class="mr-1" /> {{ $t('whole_graph') }}
                </section>
                <div class="clear-both"></div>
                <div class="h-80" v-if="transactionsStats && transactionsStats.data?.length>0">
                    <chart-component :g_data="transactionsStats" :chartType="'bar'" :graphLabel="$t('earnings')" :colors="['#70C1B2','#E4E5E4']" />
                </div>
            </slot>

            <slot v-if="displayTab == 1">
                <section v-if="transactionsObj && transactionsObj.free && transactionsObj.free.length>0">
                    <div class="text-white float-left w-full sm:mr-5 mb-10 block max-h-200 mt-3 overflow-y-auto">
                        <table class="text-tableText bg-tableBG w-full text-sm sm:text-base" id="transactionsTable">
                            <thead class="sticky top-0">
                                <tr class="bg-tableBG">
                                    <th class="border border-sepBorder cursor-pointer">{{ $t('date') }}</th>
                                    <th class="border border-sepBorder cursor-pointer">{{ $t('time') }}</th>
                                    <th class="border border-sepBorder relative">{{ $t('net') }}</th>
                                    <th class="border border-sepBorder ">{{ $t('username') }}</th>
                                    <th class="border border-sepBorder px-2">{{ $t('type') }}</th>
                                    <th class="border border-sepBorder px-2">{{ $t('actions') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(transaction, index) in transactionsObj.free" :key="index" :class="{'bg-tableRow1':index%2==0}">
                                    <td class="text-center p-1 px-1">{{ formatDate(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                    <td class="text-center p-1 px-1">{{ formatTime(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                    <td class="text-center p-1 px-1">$ {{ transaction.net }}</td>
                                    <td class="text-center p-1 px-1">{{ transaction.follower_user_name}}</td>
                                    <td class="text-center p-1"><span class="hidden sm:contents">{{transaction.TransType}}</span><transaction-type class="sm:hidden" :t="transaction.TransType" /></td>
                                    <td lass="opacity-100 cursor-pointer p-1 px-3 text-center" @click="changeCharge('free',index)"><font-awesome-icon icon="fas fa-rotate-left" v-bind:class="{'text-gray-500':openIndex=='free'+index,'text-gray-500':openIndex!='free'+index,'text-red-300':transaction.reverse && transaction.reverseTimeStamp}" class="px-3 cursor-pointer" /></td>
                                    <div v-if="!(transaction.reverse && transaction.reverseTimeStamp) && openIndex=='free'+index" 
                                        class="absolute bg-gray-100 z-50 rounded p-2 -ml-36 mt-9 text-black cursor-pointer" v-bind:class="{'hidden' :!transaction.reverse}">
                                        <dInput vmodel="changeNet" vvalue='' @changeNet="changeNet = $event" />
                                        <button @click="saveTimeStamp('free',index,transaction.transactionID,transaction.modelID),openIndex=null" class="bg-green-400 text-black rounded-full mt-1 p-1 px-4 text-base float-right">{{ $t('save') }}</button>
                                    </div>
                                    <div v-else-if="transaction.reverse && transaction.reverseTimeStamp && openIndex=='free'+index"
                                        @click="removeTimeStamp('free',index,transaction.transactionID,transaction.modelID), openIndex=null"
                                        class="absolute bg-gray-100 z-50 rounded p-2 pr-4 -ml-16 mt-9 text-black cursor-pointer">
                                        <font-awesome-icon icon="fas fa-xmark" class="mr-2 ml-2" /> {{ $t('remove') }} 
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <slot v-if="transactionsObj && transactionsObj.paid && transactionsObj.paid.length>0">
                        <h1 class="text-contentText pb-3 text-2xl font-semibold sticky top-0 w-full py-1">{{ $t('account_with_subscription') }}</h1>
                        <div class="text-white float-left w-max sm:pr-5 pb-24 max-h-200 mt-7 overflow-y-scroll">
                            <table class="text-white bg-tableBG w-max text-sm sm:text-base" id="transactionsTable">
                                <thead class="sticky top-12 border border-sepBorder ">
                                    <tr>
                                        <th class="border border-sepBorder cursor-pointer ">{{ $t('date') }}</th>
                                        <th class="cursor-pointer ">{{ $t('time') }}</th>
                                        <th class=" relative">{{ $t('net') }}</th>
                                        <th class="">{{ $t('username') }}</th>
                                        <th class="">{{ $t('type') }}</th>
                                        <th class="">{{ $t('actions') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(transaction, index) in transactionsObj.paid" :key="index">
                                        <td class="text-center p-1">{{ formatDate(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                        <td class="text-center p-1">{{ formatTime(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                        <td class="text-center p-1">$ {{ transaction.net }}</td>
                                        <td class="text-center p-1">{{ transaction.follower_user_name}}</td>
                                        <td class="text-center p-1"><span class="hidden sm:contents">{{transaction.TransType}}</span><transaction-type class="sm:hidden" :t="transaction.TransType" /></td>
                                        <td lass="opacity-100 cursor-pointer p-1 px-3 text-center" @click="changeCharge('paid',index)"><font-awesome-icon icon="fas fa-rotate-left" v-bind:class="{'text-gray-500':openIndex=='paid'+index,'text-gray-500':openIndex!='paid'+index,'text-red-300':transaction.reverse && transaction.reverseTimeStamp}" class="px-3 cursor-pointer" /></td>
                                        <div v-if="!(transaction.reverse && transaction.reverseTimeStamp) && openIndex=='paid'+index" 
                                            class="absolute bg-gray-100 z-50 rounded p-2 -ml-36 mt-9 text-black cursor-pointer" v-bind:class="{'hidden' :!transaction.reverse}">
                                            <dInput vmodel="changeNet" vvalue='' @changeNet="changeNet = $event" />
                                            <button @click="saveTimeStamp('paid',index,transaction.transactionID,transaction.modelID),openIndex=null" class="bg-green-400 text-black rounded-full mt-1 p-1 px-4 text-base float-right">{{ $t('save') }}</button>
                                        </div>
                                        <div v-else-if="transaction.reverse && transaction.reverseTimeStamp && openIndex=='paid'+index"
                                            @click="removeTimeStamp('paid',index,transaction.transactionID,transaction.modelID), openIndex=null"
                                            class="absolute bg-gray-100 z-50 rounded p-2 pr-4 -ml-16 mt-9 text-black cursor-pointer">
                                            <font-awesome-icon icon="fas fa-xmark" class="mr-2 ml-2" /> {{ $t('remove') }} 
                                        </div>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </slot>
                    <div class="clear-both"></div>
                </section>
                <div v-else-if="loading" class="mt-10 ml-2 sm:ml-28 text-contentText font-semibold text-lg">
                    <font-awesome-icon icon="fas fa-spinner" class="animate-spin text-lg text-center" />
                </div>
                <div v-else class="my-5">
                    {{ $t('select_creator') }}
                </div>
            </slot>

            <!-- REVERSE -->
            <slot v-else>
                <table v-if="reverseObj && reverseObj.metrics" class="w-min bg-tableBG text-contentText mt-5">
                    <thead>
                        <tr class="border border-sepBorder">
                            <th></th>
                            <th class="border border-sepBorder" colspan="2">FREE</th>
                            <th class="border border-sepBorder" colspan="2">PAID</th>
                            <th class="border border-sepBorder" colspan="2">{{ $t('together') }}</th>
                        </tr>
                        <tr>
                            <th class="border border-sepBorder p-1 px-3">{{ $t('date') }}</th>
                            <th class="border border-sepBorder p-1 px-3">{{ $t('amount') }}</th>
                            <th class="border border-sepBorder p-1 px-3">{{ $t('price') }}</th>
                            <th class="border border-sepBorder p-1 px-3">{{ $t('amount') }}</th>
                            <th class="border border-sepBorder p-1 px-3">{{ $t('price') }}</th>
                            <th class="border border-sepBorder p-1 px-3">{{ $t('amount') }}</th>
                            <th class="border border-sepBorder p-1 px-3">{{ $t('price') }}</th>
                        </tr>
                    </thead>
                    <slot v-for="(stats,index) in reverseObj.metrics" :key="index">
                        <tr class="whitespace-nowrap" :class="{'bg-tableRow1':index%2==0}">
                            <td class="p-1 px-3">{{stats.date}}</td>
                            <td class="p-1 px-3">{{stats.freeAmount.toFixed(2)}}</td>
                            <td class="p-1 px-3">$ {{stats.freePrice.toFixed(2)}}</td>
                            <td class="p-1 px-3">{{stats.paidAmount.toFixed(2)}}</td>
                            <td class="p-1 px-3">$ {{stats.paidPrice.toFixed(2)}}</td>
                            <td class="p-1 px-3">{{stats.togetherAmount.toFixed(2)}}</td>
                            <td class="p-1 px-3">$ {{stats.togetherPrice.toFixed(2)}}</td>
                        </tr>
                    </slot>
                    <tfoot v-if="reverseObj.metricsSummary">
                        <tr class="text-center whitespace-nowrap bg-panels">
                            <td class="p-1 px-3">{{ $t('summary') }}</td>
                            <td class="p-1 px-3">{{reverseObj.metricsSummary.amountF.toFixed(2)}}</td>
                            <td class="p-1 px-3">$ {{reverseObj.metricsSummary.priceF.toFixed(2)}}</td>
                            <td class="p-1 px-3">{{reverseObj.metricsSummary.amountP.toFixed(2)}}</td>
                            <td class="p-1 px-3">$ {{reverseObj.metricsSummary.priceP.toFixed(2)}}</td>
                            <td class="p-1 px-3">{{reverseObj.metricsSummary.amountT.toFixed(2)}}</td>
                            <td class="p-1 px-3">$ {{reverseObj.metricsSummary.priceT.toFixed(2)}}</td>
                        </tr>
                    </tfoot>
                </table>
                <div v-else class="my-5">
                    {{ $t('no_reverse') }}
                </div>
                <section class="ml-28 container flex gap-10" v-if="reverseObj">
                    <div class="text-white bold mb-10" v-for="(reverse,ind) in reverseObj.values" :key="ind" v-bind:class="{'mt-5':reverse, 'mt-5': !reverse}">
                        <h1 class="uppercase ml-2 mb-2 text-2xl font-semibold">{{ind}}</h1>
                        <table class="text-tableText bg-tableBG w-max" id="transactionsTable" v-if="reverse">
                            <thead>
                                <tr>
                                    <th onclick="sortTable(0,'transactionsTable')" class="cursor-pointer" @click="sortColumn=0" >{{ $t('date') }} &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-button':sortColumn == 0}" /></th>
                                    <th onclick="sortTable(1,'transactionsTable')" class="cursor-pointer" @click="sortColumn=1" >{{ $t('time') }} &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-button':sortColumn == 0}" /></th>
                                    <th onclick="sortTable(3,'transactionsTable')" class="cursor-pointer" @click="sortColumn=3" >{{ $t('net') }} &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-button':sortColumn == 2}" /></th>
                                    <th>{{ $t('username') }}</th>
                                    <th>{{ $t('type') }}</th>
                                    <th>{{ $t('reversed_at') }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="h-max overflow-y-scroll">
                                <tr v-for="(transaction, index) in reverse" :key="index">
                                    <td class="text-center p-1 px-3">{{ formatDate(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                    <td class="text-center p-1 px-3">{{ formatTime(transaction.createdDate + " " + transaction.createdTime) }}</td>
                                    <td class="text-center p-1 px-3">$ {{ transaction.net }}</td>
                                    <td class="text-center p-1 px-3">{{ transaction.follower_user_name}}</td>
                                    <td class="text-center p-1 px-3">{{ transaction.TransType}}</td>
                                    <td class="text-center p-1 px-3">{{ transaction.reverseTimeStamp}}</td>
                                    <td class="opacity-100 cursor-pointer p-1 px-3 text-center" @click="changeCharge(ind,index)"><font-awesome-icon icon="fas fa-rotate-left" v-bind:class="{'text-gray-500':openIndex==ind+index,'text-gray-500':openIndex!=ind+index,'text-red-300':transaction.reverse && transaction.reverseTimeStamp}" class="px-3 cursor-pointer" /></td>
                                    <div v-if="!(transaction.reverse && transaction.reverseTimeStamp) && openIndex==ind+index" 
                                        class="absolute bg-gray-100 z-50 rounded p-2 -ml-36 mt-9 text-black cursor-pointer" v-bind:class="{'hidden' :!transaction.reverse}">
                                        <dInput vmodel="changeNet" vvalue='' @changeNet="changeNet = $event" />
                                        <button @click="saveTimeStamp(ind,index,transaction.transactionID,transaction.modelID),openIndex=null" class="bg-green-400 text-black rounded-full mt-1 p-1 px-4 text-base float-right">{{ $t('save') }}</button>
                                    </div>
                                    <div v-else-if="transaction.reverse && transaction.reverseTimeStamp && openIndex==ind+index"
                                        @click="removeTimeStamp(ind,index,transaction.transactionID,transaction.modelID), openIndex=null"
                                        class="absolute bg-gray-100 z-50 rounded p-2 pr-4 -ml-16 mt-9 text-black cursor-pointer">
                                        <font-awesome-icon icon="fas fa-xmark" class="mr-2 ml-2" /> {{ $t('remove') }} 
                                    </div>
                                </tr>
                                <tr v-if="reverse.length>0" class="text-white">
                                    <td class="text-center text-gray-300 py-2">{{ $t('summary') }}:</td>
                                    <td v-if="ind == 'free'" class="text-center" colspan="3"><span class="text-gray-300">{{ $t('net') }}:</span> $ {{reverseObj.netF.toFixed(2)}}</td>
                                    <td v-else class="text-center" colspan="3"><span class="text-gray-300">{{ $t('net') }}:</span> $ {{reverseObj.netP.toFixed(2)}}</td>
                                    <td class="text-center" colspan="3">
                                        <span class="text-gray-300">{{ $t('count') }}: </span>
                                        <span v-if="ind == 'free'">{{reverseObj.noF}}</span>
                                        <span v-else>{{reverseObj.noP}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else class="text-white">
                            {{ $t('select_creator') }}
                        </div>
                    </div>
                </section>
            </slot>
        </slot>
    </section>
    <div v-else-if="permissions != null && (!$getPermission(permissions,15).is_granted || !$getPermission(permissions,16).is_granted)" class="text-center mt-52 text-textOnBkg">{{ $t("no_permission") }}</div>
    <div v-else-if="loading" class="text-center mt-52 text-textOnBkg"><font-awesome-icon icon="fas fa-spinner" class="animate-spin text-lg" /></div>
    <div v-else-if="!loading">
        <transition name="fade">
            <section class="mb-36 mx-2 sm:mx-0 sm:pl-24 w-full pr-2 h-full pt-40 sm:pt-24 -mt-24 sm:-mt-4">
                <div class="w-full bg-bkg rounded text-center h-full mx-2 py-96">
                    <span v-html="$t('finish_setup')"></span><br /><br />
                    <router-link to="/profile" class="bg-button text-white p-1 px-3 rounded">{{ $t('finish_agency_setup') }}</router-link>
                </div>
            </section>
        </transition>
    </div>
</template>

<script setup>
    import headerFilter from "@/components/headerFilter.vue"
    import niceInput from "@/components/niceInput.vue"
    import filterSelect from "@/components/transactions/filterSelect.vue"
    import dInput from "@/components/inputTransactions.vue"
    import transactionType from "@/components/transactionType.vue"
    import leftMenu from "@/components/LeftMenu.vue"
    import ChartComponent from "@/components/charts/chart_comp.vue"
</script>

<script>
export default {
    props:{
        selectedModel: {default() {return null;}},
        changeNet: {default() {return null;}},
        selectedEmpl: {default() {return null;}},
        selectedGroup: {default() {return null;}},
        dateFrom:{default() {return null;}},
        dateTo: {default() {return null;}},
        srchUser: {default() {return '';}},
        srchType: {default() {return null;}},
        srchNetFrom: {default() {return 0;}},
        srchNetTo: {default() {return 0;}}
    },
    watch: {
        selectedModel: function(newVal, oldVal) { // watch it
            if (this.displayTab === 1) {
                this.getData(true);
            } else {
                this.getReverse();
            }
        },
        selectedGroup: function(newVal, oldVal) { // watch it
            if (this.mounted) {
                if (this.displayTab == 2) {
                    this.getReverse('group');
                }
            }
        },
        dateFrom: function(newVal, oldVal) { // watch it
            if (this.mounted && this.selectedModel) {
                if (this.displayTab == 1) {
                    this.getData(true);
                } else {
                    this.getReverse();
                }
            }
        },
        dateTo: function(newVal, oldVal) { // watch it
            if (this.mounted && this.selectedModel) {
                if (this.displayTab == 1) {
                    this.getData(true);
                } else {
                    this.getReverse();
                }
            }
        },
        srchType: function() { this.searchBy = 1; },
        srchUser: function() { this.searchBy = 0; },
        srchNetFrom: function() { this.searchBy = 2; },
        srchNetTo: function() { this.searchBy = 2; }
    },
    components() {
      headerFilter,
      dInput,
      niceInput,
      filterSelect,
      transactionType,
      leftMenu,
      chartComponent
    },
    data() {
        return { 
            permissions:null,
            loading: true,
            saved: false,
            displayFilter:null,
            mounted:false,
            sortColumn: null,
            transactionsObj: {free: [], paid:[]},
            reverseObj: null,
            transactionsStats: null,
            openIndex: null,
            timeZone: null,
            displayTab:1,
            searchBy:0,
            filterType:0
        } 
    },
    methods: {
        dFilter(filter) {
            this.displayFilter = filter
            this.srchUser = ''
        },
        dTab(w) {
            this.displayTab = w
            if (w == 2) {
                this.getReverse();
            } else {
                this.getData(true);
            }
        },
        changeCharge(type,index) {
            if (this.permissions && this.$getPermission(this.permissions,15) && this.$getPermission(this.permissions,26).is_granted) {
                if (this.displayTab == 1) {
                    this.transactionsObj[type][index].reverse = 1;
                    this.openIndex = type+index
                } else {
                    this.reverseObj.values[type][index].reverse = 1;
                    this.openIndex = type+index
                }
            }
        },
        removeTimeStamp(type,index,transactionID,modelID) {
            if (this.permissions && this.$getPermission(this.permissions,15) && this.$getPermission(this.permissions,26).is_granted) {
                if (confirm('Are you sure?')) {
                    //odošli cez Axios.
                    this.$axios.post(baseURL+"transactions/postTransactions.php", {
                        action: "removereverse",
                        modelID: modelID,
                        transactionID: transactionID,
                        companyID: this.$cookies.get('c_id'),
                        userToken: this.$cookies.get('userToken')
                    }).then((result) => {
                        if (this.displayTab == 1) {
                            this.transactionsObj[type][index].reverse = null;
                            this.transactionsObj[type][index].reverseTimeStamp = null;
                        } else {
                            this.reverseObj.values[type].splice(index,1);
                        }
                    }, (error) => {
                        console.log(error);
                    });
                }
            }
        },
        saveTimeStamp(type,index,transactionID,modelID) {
            if (this.permissions && this.$getPermission(this.permissions,15) && this.$getPermission(this.permissions,26).is_granted) {
                if (this.changeNet) {
                    //odošli cez Axios.
                    this.$axios.post(baseURL+"transactions/postTransactions.php", {
                        action: "savereverse",
                        modelID: modelID,
                        timeStamp: this.changeNet,
                        transactionID: transactionID,
                        companyID: this.$cookies.get('c_id'),
                        userToken: this.$cookies.get('userToken')
                    }).then((result) => {
                        this.transactionsObj[type][index].reverse = 1;
                        this.transactionsObj[type][index].reverseTimeStamp = this.$moment(this.changeNet).format('YYYY.MM.DD HH:mm');
                    }, (error) => {
                        console.log(error);
                    });
                }
            }
        },
        determinZoneDST(original) {
            var tzone = 0;
            if (this.$moment().isDST()) {
                if (this.$moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                    tzone = parseInt(this.timeZone)
                } else {
                    tzone = (parseInt(this.timeZone)/60-1)*60
                }
            } else {
                if (this.$moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                    tzone = (parseInt(this.timeZone)/60+1)*60
                } else {
                    tzone = parseInt(this.timeZone)
                }
            }
            return tzone;
        },
        formatTime(original) {
            var tzone = this.determinZoneDST(original);
            if (tzone<0) {
                return this.$moment(original,"YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
            } else {
                return this.$moment(original,"YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
            }
        },
        formatDate(original) {
            var tzone = this.determinZoneDST(original);
            if (parseInt(this.timeZone)<0) {
                return this.$moment(original,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
            } else {
                return this.$moment(original,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
            }
        },        
        getWholeGraph() {
            this.$axios.get(baseURL+"transactions/", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    build: "statistics",
                    selectedModel: this.selectedModel,
                    fromDate: null,
                    toDate: null,
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.transactionsStats = result.data
            }, (error) => {
                console.log(error);
            });
        },
        getData(stats) {
            //load transactions of this model
            this.$axios.get(baseURL+"transactions/", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    build: "getModelsTransactions",
                    selectedModel: this.selectedModel,
                    fromDate: this.$moment(this.dateFrom).format("YYYY-MM-DD"),
                    toDate: this.$moment(this.dateTo).format("YYYY-MM-DD"),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.transactionsObj = result.data
            }, (error) => {
                console.log(error);
            });
            
            if (stats) {
                this.transactionsStats = null;
                this.$axios.get(baseURL+"transactions/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        build: "statistics",
                        selectedModel: this.selectedModel,
                        fromDate: this.$moment(this.dateFrom).format("YYYY-MM-DD"),
                        toDate: this.$moment(this.dateTo).format("YYYY-MM-DD"),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.transactionsStats = result.data
                }, (error) => {
                    console.log(error);
                });
            }
            setTimeout(() => {
                this.loading = false;
            }, 500);
        },
        getReverse(target) {
            this.loading = true;
            let sModel = null;
            if (target != 'group') {
                sModel = this.selectedModel;
            }
            //load transactions of this model
            this.$axios.get(baseURL+"transactions/", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    build: "getReverses",
                    selectedGroup: this.selectedGroup,
                    selectedModel: sModel,
                    fromDate: this.$moment(this.dateFrom).format("YYYY-MM-DD"),
                    toDate: this.$moment(this.dateTo).format("YYYY-MM-DD"),
                    companyID: this.$cookies.get('c_id')
                }
            }).then((result) => {
                this.reverseObj = result.data;
            }, (error) => {
                console.log(error);
            }).finally(() => this.loading = false);
        }
    },
    async created() {
        this.loading = true;
        this.mounted = true;

        if (!(this.$cookies.get('c_id'))) {
            var companyID = null;
        } else {
            var companyID = this.$cookies.get('c_id');
        }
        this.$axios.get(baseURL+"opravnenia/userToken.php", {
            params: {
                userToken: this.$cookies.get('userToken'),
                companyID: companyID,
                place: 'header'
            }
        }).then((result) => {
            if (result.data.selectBranch) {
                let prev = this.$route.path;
                this.$router.push({name:'Branches', query: { prevRoute: prev } });
            } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                this.$cookies.set('userToken','');
                window.location.href = '/';
            }
            if (result.data.myself) {this.myself = result.data.myself;}
            if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
            if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
        }, (error) => {
            console.log(error);
        });
    },
    mounted() {
        if (this.selectedModel && this.dateFrom && this.dateTo) {
            this.getData(true);
        } else {
            setTimeout(() => {
                this.loading = false;
            }, 500);
        }
    }
}

</script>

<style scoped>
  td {
    padding:5px;
    border:1px solid #D1D5DB;
    text-align:center;
  }
</style>