<template>
    <canvas :ref="`myChart${uniqueId}`"></canvas>
</template>

<script setup>
    import headerFilter from "@/components/headerFilter.vue"
</script>

<script>
    import Chart from 'chart.js/auto'
    import { analytics_data } from "@/api.js"

    export default {
        props:['column', 'graphLabel', 'chartType', 'colors', 'lrIndex','lrData','selectedModel', 'selectedModelN','selectedGroupN','selectedEmpl','selectedGroup','selectedTeam','dateFrom','dateTo'],
        data() {
            return {
                mounted:false,
                myChart: null,
                uniqueId:null,
            }
        },
        watch: { 
            selectedModel: 'handleUpdate',
            dateFrom: 'handleUpdate',
            selectedGroup: 'handleUpdate',
            selectedTeam: 'handleUpdate',
        },
        methods: {
            handleUpdate() {
                // Avoid running on the initial null-to-undefined transition
                if (!this.mounted) {
                    return;
                }

                // Prevent updateChart from running multiple times in quick succession
                if (!this.updateChartPending) {
                    this.updateChartPending = true;
                    // Use this.$nextTick to delay the chart update until after the DOM has been updated
                    this.$nextTick(() => {
                        this.updateChart();
                        this.updateChartPending = false; // Reset the flag
                    });
                }
            },
            async updateChart() {
                // Destroy the existing chart using the reference
                if (this.myChart) {
                this.myChart.destroy();
                this.myChart = null;
                }

                // Call your method that fetches data and creates the chart
                await this.fetchAndCreateChart();
            },
            async fetchAndCreateChart() {
                const DATA_COUNT = 7;
                const NUMBER_CFG = { count: DATA_COUNT, min: -100, max: 100 };

                const g_data = await analytics_data(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.dateFrom,this.dateTo,"graph",this.selectedGroup, this.selectedTeam, this.selectedModel, this.selectedEmpl, this.column);

                const data = {
                    labels: g_data.labels,
                    datasets: [{
                        label: this.graphLabel,
                        data: g_data.data,
                        borderColor: this.colors[0],
                        backgroundColor: this.colors[1],
                        borderWidth: 2,
                        borderRadius: 20,
                        borderSkipped: false,
                    }]
                };
                
                const config = {
                    type: this.chartType,
                    data: data,
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                            grid: {
                                color: 'rgba(200, 200, 200, 0.3)',
                            },
                            ticks: {
                                color: '#333',
                            }
                            },
                            y: {
                            grid: {
                                color: 'rgba(200, 200, 200, 0.3)',
                            },
                            ticks: {
                                color: '#333',
                            }
                            }
                        },
                        plugins: {
                            legend: {
                            position: 'top',
                            labels: {
                                padding: 20,
                                color: '#333'
                            }
                            },
                            tooltip: {
                            backgroundColor: 'rgba(50, 50, 50, 0.9)',
                            titleColor: '#fff',
                            bodyColor: '#fff',
                            borderColor: 'rgba(0, 0, 0, 0)',
                            borderWidth: 0,
                            cornerRadius: 3,
                            displayColors: false,
                            }
                        },
                        animation: {
                            duration: 1000,
                            easing: 'easeOutCubic'
                        }
                    }
                };

                this.$nextTick(() => {
                    // Ensure the canvas element is in the DOM
                    const canvas = this.$refs[`myChart${this.uniqueId}`];
                    if (canvas) {
                        // Only try to get the context if the canvas actually exists
                        const context = canvas.getContext('2d');
                        if (context) {
                            this.myChart = new Chart(context, config);
                        } else {
                            console.error('Canvas context could not be acquired.');
                        }
                    } else {
                        console.error('Canvas element does not exist in the DOM.');
                    }
                });
            },
            generateUniqueId(prefix = 'id') {
                const timePart = new Date().getTime().toString(36);
                const randomPart = Math.random().toString(36).substring(2, 15);
                return `${prefix}-${timePart}-${randomPart}`;
            }
        },
        beforeUnmount() {
            // Destroy the chart instance before the component is unmounted
            if (this.myChart) {
                this.myChart.destroy();
                this.myChart = null;
            }
        },
        created() {
            this.uniqueId = this.generateUniqueId('chart');
        },
        mounted() {
            this.mounted = true;
        }
    }
</script>