<template>
    <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
    <header-filter :pageTitle="'Download'" :myself="myself"/>

    <div class="sm:ml-16">
        <section class="mt-24 sm:ml-5 mb-20 w-full sm:w-max bg-squares text-panelsText p-1 px-7 pr-10 rounded-xl border border-sepBorder">
            <div class="mt-5">
                <h2 class="text-panelsText font-semibold text-lg">LoginMate</h2>
                <span class="text-red-300 sm:hidden">{{ $t('not_available_on_mobile') }}<br /></span>
                <span class="text-tableText"><strong class="text-tableText">* {{ $t('fot_loginmate_1') }} <a class="underline" href="https://www.google.com/chrome/" target="_blank">{{ $t('for_loginmate_2') }}</a> {{ $t('for_loginmate_3') }}!</strong><br />{{ $t('for_loginmate_4') }}</span>
                <table v-if="loginmate_versions_block" class="hidden sm:block mt-4 text-tableText leading-loose bg-bkg p-5 w-max rounded">
                    <tr class="border border-sepBorder">
                        <th class="border border-sepBorder p-0.5 px-3">{{ $t('operation_system') }} <font-awesome-icon icon="fas fa-spinner" v-if="loading" class="ml-2 animate-spin" /></th>
                        <th class="border border-sepBorder p-0.5 px-3">{{ $t('filename') }}</th>
                        <th class="border border-sepBorder p-0.5 px-3">{{ $t('size') }}</th>
                        <th class="border border-sepBorder p-0.5 px-3">{{ $t('Download') }}</th>
                    </tr>
                    <slot v-for="(version, index) in loginmate_versions" :key="index">
                        <tr>
                            <td class="border border-sepBorder p-1 px-1 sm:px-3 text-center">{{ version.os_version }}</td>
                            <th class="border border-sepBorder p-0.5 px-3 font-normal">{{version.name}}</th>
                            <th class="border border-sepBorder p-0.5 px-3 font-normal">{{version.size}}</th>
                            <th class="border border-sepBorder pt-1.5 cursor-pointer"><font-awesome-icon @click="download(version.name)" class="p-1.5 px-2.5 rounded-lg bg-button text-white" icon="fas fa-download" /></th>
                        </tr>
                    </slot>
                </table>
                <ofdash-button v-if="!loginmate_versions_block" @click="enroll_loginmate_versions" icon="fas fa-chevron-down" class="mt-3" :label="$t('see_versions')" />
            </div>
        </section>
    </div>
</template>

<script setup>
    import headerFilter from "@/components/headerFilter.vue"
    import ofdashButton from '@/components/ofdashButton.vue'
    import leftMenu from "@/components/LeftMenu.vue"
</script>

<script>
    import { verify_token } from "@/api"
    export default {
        components() {
            headerFilter,
            ofdashButton,
            leftMenu
        },
        data() {
            return {
                loginmate_versions:[],
                loginmate_versions_block:false,
                loginx_versions: [],
                loginx_versions_block: false,
                loading:false,
                myself:null,
                companyID:null,
                permissions:null
            }
        },
        methods: {
            download(file) {
                window.open(`${baseURL}githubDownload/?q=download&file=${file}&userToken=${this.$cookies.get('userToken')}&companyID=${this.$cookies.get('c_id')}`)
            },
            get_loginmate_versions() {
                this.loading = true
                this.$axios.get(`${baseURL}githubDownload/?q=assets&app=loginmate&userToken=${this.$cookies.get('userToken')}&companyID=${this.$cookies.get('c_id')}`).then((result) => {
                    this.loginmate_versions = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => {this.loading = false});
            },
            enroll_loginmate_versions() {
                this.loginmate_versions_block = !this.loginmate_versions_block;
                if (this.loginmate_versions_block == true) {this.get_loginmate_versions();}
            },
            get_loginx_versions() {
                this.loading = true
                this.$axios.get(`${baseURL}githubDownload/?q=assets&app=loginx&userToken=${this.$cookies.get('userToken')}&companyID=${this.$cookies.get('c_id')}`).then((result) => {
                    this.loginx_versions = result.data
                }, (error) => {
                    console.log(error);
                }).finally(() => {this.loading = false});
            },
            enroll_loginx_versions() {
                this.loginx_versions_block = !this.loginx_versions_block;
                if (this.loginx_versions_block == true) {this.get_loginx_versions();}
            },
        },
        async created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
            }).then((result) => {
                if (result.data.selectBranch) {
                    let prev = this.$route.path;
                    this.$router.push({name:'Branches', query: { prevRoute: prev } });
                } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                    this.$cookies.set('userToken','');
                    window.location.href = '/';
                }
                if (result.data.myself) {this.myself = result.data.myself;}
                if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
            }, (error) => {
            console.log(error);
            });
        }
    }
</script>