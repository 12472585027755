<template>
    <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
    <header-filter :pageTitle="'Creators'" @selectedModel="selectedModel = $event" @selectedTeam="selectedTeam = $event" @selectedGroup="selectedGroup = $event" />
    <section v-if="modelDataFiltered.length>0 && !loading" class="hidden sm:block sm:ml-22 fixed w-60 text-center border border-sepBorder rounded h-full mt-2 py-5 pb-36 overflow-auto">
        <slot v-if="topModels && topModels.length > 0">
            <h2 class="font-bold text-3xl">{{ $t('top_model') }}</h2>
            <select class="bg-transparent font-light cursor-pointer outline-0 text-center" @change="getTopModels()" v-model="selectedTopMonth">
                <option v-for="(month, index) in lastSixMonths" :key="index" :value="month.value">{{ month.label }} <font-awesome-icon icon="fas fa-angle-down" /></option>
            </select>
            <div class="grid grid-rows-3 gap-6 w-full mt-5">
                <div v-for="(t_model,t_i) in topModels" :key="t_i" class="text-center rounded text-center">
                    <div class="rounded-full h-36 w-36 border-6 mx-auto shadow bg-gray-200" :class="{'border-gold':t_i == 0, 'border-silver':t_i == 1, 'border-bronze':t_i == 2}" :style="'box-shadow: inset 0 0 5px rgba(0,0,0,0.3);background-image: url(&quot;' + t_model.avatar + '&quot;); background-size: cover; background-position: center center;'"></div>
                    <div class="rounded-full p-1 px-3 w-max mx-auto -mt-5 text-black" :class="{'bg-gold':t_i == 0, 'bg-silver':t_i == 1, 'bg-bronze':t_i == 2}" style="box-shadow: inset 0 0 5px rgba(0,0,0,0.3)">{{t_i+1}}</div>
                    <h4 class="mt-1.5 w-56 mx-auto truncate">{{t_model.name}} - {{t_model.platformName}}</h4>
                    <h3 class="font-bold text-2xl">$ {{parseFloat(t_model.earnings).toFixed(2)}}</h3>
                </div>
            </div>
        </slot>
        <font-awesome-icon v-else icon="fas fa-spinner" class="animate-spin absolute top-1/3" />
    </section>
    <div class="mt-22 mr-1 sm:mr-5" :class="modelDataFiltered.length>0 && !loading ? 'sm:ml-86' : 'sm:ml-22'">
        <div class="sm:flex justify-between">
            <div class="mx-auto sm:mx-0 w-max">
                <button v-if="permissions && $getPermission(permissions,6).is_granted" @click="show_creator_modal(null)" class="mt-2 bg-button rounded cursor-pointer p-1.5 px-7 text-buttonText text-center font-semibold whitespace-nowrap">
                    <font-awesome-icon icon="fa-solid fa-plus" /> {{ $t('connect_a_creator') }}
                </button>
                <button v-if="modelDataFiltered.length>0 && permissions && $getPermission(permissions,8).is_granted" @click="showActive = !showActive" class="block outline-0 ml-3 mt-2 rounded cursor-pointer text-button text-center text-sm">
                    <font-awesome-icon :icon="showActive ? 'fas fa-check-square' : 'far fa-square'" /> {{ $t('show_active_creators_only') }}
                </button>
            </div>
            <div class="flex">
                <div v-if="modelDataFiltered.length>0" class="mt-5 px-5 w-80 mr-5">
                    <select v-on:change="orderModels" v-model="order" name="order_by" id="order_by" class="border-b py-1 bg-transparent border-sepBorder w-full outline-0 cursor-pointer">
                    <option value="">{{ $t('sort_by') }}</option>
                    <option value="earnings_asc">{{ $t('earnings') }}: {{ $t('low_to_high') }}</option>
                    <option value="earnings_desc">{{ $t('earnings') }}: {{ $t('high_to_low') }}</option>
                    <option value="salary_asc">{{ $t('salary') }}: {{ $t('low_to_high') }}</option>
                    <option value="salary_desc">{{ $t('salary') }}: {{ $t('high_to_low') }}</option>
                    <option value="ranking_asc">{{ $t('ranking') }}: {{ $t('low_to_high') }}</option>
                    <option value="ranking_desc">{{ $t('ranking') }}: {{ $t('high_to_low') }}</option>
                    </select>
                </div>
                <button class="block h-9 text-button border border-button ml-2 mt-5 rounded p-1 px-3 cursor-pointer whitespace-nowrap" @click="exportToCSV">
                    <span ref="exportLoading" class="hidden"><font-awesome-icon icon="fas fa-spinner" class="animate-spin mr-2" /></span>{{ $t('export') }} <span class="hidden sm:contents"> {{ $t('to_csv') }}</span>
                </button>
            </div>
        </div>

        <div class="overflow-x-auto">
            <div v-if="modelDataFiltered.length>0">
                <div class="overflow-auto" style="height: calc(100vh - 155px);">
                    <table class="bg-transparent mt-3 mx-auto whitespace-nowrap rounded font-normal border border-sepBorder table-auto w-full">
                        <thead>
                            <tr>
                                <th class="w-28 py-2 px-1">{{ $t('id') }}</th>
                                <th class="w-28 py-2 px-2 text-left pl-2 text-center">{{ $t('creator') }}</th>
                                <th class="w-32 py-2 text-left relative">{{ $t('platform') }} <iconfilteringArraysComponent :filters="filters.platform" @filteredPlatform="filteredPlatform = $event.slice()" /></th>
                                <th class="py-2 text-left pl-2">{{ $t('stage_name') }}</th>
                                <th class="py-2 text-left">{{ $t('username') }}</th>
                                <th class="py-2 px-2.5 text-left relative">{{ $t('fans') }} <iconfilteringArraysComponent :filters="filters.fans" /></th>
                                <th class="py-2 px-2.5 text-left relative pr-2 text-left">{{ $t('ranking') }} <iconfilteringArraysComponent :filters="filters.ranking" /></th>
                                <th class="py-2 px-5 text-left">{{ $t('net_earnings') }}</th>
                                <th class="py-2 px-5 text-left">{{ $t('creator') }} {{ $t('salary') }}</th>
                                <th class="py-2 px-5 text-left">{{ $t('profit') }}</th>
                                <th v-if="1==2" class="py-2 px-4 text-left">{{ $t('Metrics') }}</th>
                                <th class="py-2 px-4 text-center">{{ $t('actions') }}Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(model,index) in modelDataFiltered" :key="index" class="cursor-pointer hover:bg-gray-200" :class="{'opacity-50' : model?.free?.active == 0}" @click="show_creator_modal(model.pair_id)">
                                <td class="border-t border-l border-sepBorder px-3 text-center">{{model.free.id}}</td>
                                <td class="p-2 border-t border-b relative">
                                    <div class="w-14 relative">
                                        <div class="rounded-full h-12 w-12 mx-auto shadow bg-gray-200" :style="{ backgroundImage: `url(${model?.avatar})`, backgroundSize: 'cover', backgroundPosition: 'center' }"></div>
                                        <div :title="$t('scraper_notice')" class="w-3.5 h-3.5 rounded-full absolute bg-decline border border-white ml-11 -mt-12 shadow" v-if="!model.free.scraped"></div>
                                    </div>
                                </td>
                                <td class="border-t border-b border-sepBorder px-3">
                                    <div class="w-20">
                                        <slot v-if="model.platform==1"><img src="@/assets/images/of_logo.png" title="Onlyfans" alt="Onlyfans" class="h-5 mx-auto"/></slot>
                                        <slot v-else-if="model.platform==2"><img src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="h-5 mx-auto"/></slot>
                                    </div>
                                </td>
                                <td class="p-2.5 border-t border-b border-sepBorder border-l-0 leading-5">
                                    <div class="font-semibold whitespace-nowrap">{{model.free.name}}</div>
                                </td>
                                <td class="px-2.5 border-t border-b">
                                    <div>{{model.username ? '@'+model.username : null}}</div>
                                </td>
                                <td class="px-2.5 border-t border-b">
                                    <div>{{ model.fans.data[model?.fans?.data?.length-1] ?? '-' }}</div>
                                </td>
                                <td class="px-5 border-t border-b text-left" :class="{'text-blueColor font-bold':model.performerTop<5}">
                                    <div>{{model.performerTop < 100 ? model.performerTop+'%' : '-'}}</div>
                                </td>
                                <td class="px-5 border-t border-b text-left">
                                    <div>$ {{ model.earnings ? model.earnings.sum.toFixed(2) : 0}}</div>
                                </td>
                                <td class="px-5 border-t border-b text-left">
                                    <div>{{ model.commission > 0 ? '$ '+(model.commission_type == "%" ? (model.earnings.sum * (model.commission/100)).toFixed(2) : model.commission) : "-"}}</div>
                                </td>
                                <td class="px-5 border-t border-b text-left">
                                    <div v-if="model.earnings.data.length>0 && model.commission > 0">$ {{ (model.earnings.sum - ( model.commission_type == "%" ? (model.earnings.sum * (model.commission/100)).toFixed(2) : model.commission )).toFixed(2) }}</div>
                                    <div v-else-if="model.earnings.data.length>0 && model.commission <= 0">$ {{ model.earnings.data ? model.earnings.sum.toFixed(2) : 0}}</div>
                                    <div v-else>-</div>
                                </td>
                                <td class="px-2.5 border-t border-b border-sepBorder text-center text-button">
                                    <button type="button" @click.stop="show_creator_graph(index)" :title="$t('Analytics')" class="p-1 px-3 -mt-1 cursor-pointer" >
                                        <font-awesome-icon icon="fa-solid fa-chart-column" class="text-growth text-lg cursor-pointer" />
                                    </button>
                                    <font-awesome-icon v-if="permissions && $getPermission(permissions,13).is_granted" @click.stop="sessionModal(index)" :title="$t('session_management')" icon="fa-solid fa-server" />
                                    <font-awesome-icon v-if="permissions && $getPermission(permissions,7).is_granted" @click.stop="modelArchive(modelDataFiltered[index].free.active,index)" :title="$t('archive')" icon="fas fa-archive" class="ml-4" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-else-if="!loading">
                <h1 class="text-center text-2xl font-semibold text-contentText mt-5">{{ $t('no_creator_account') }}</h1>
                <img src="@/assets/images/photoshoot.svg" class="w-full sm:h-96 mx-auto mt-16" />
            </div>
            <div v-else class="text-center">
                <font-awesome-icon icon="fas fa-spinner" class="text-2xl mt-10 animate-spin" />
            </div>
        </div>

        <transition name="zoom-fade">
            <section v-if="modal.display" @click="modal.display = false" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
                <section @click.stop class="bg-white rounded-lg w-full max-h-screen max-w-md mx-auto my-auto p-8 px-6 sm:p-10 z-50 overflow-auto relative" :class="modal.type == 'graph' ? 'sm:max-w-7xl' : 'sm:max-w-5xl'">
                    <font-awesome-icon @click="modal.display = false" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
                    <div v-if="modal.type != 'model'" class="col-span-2 grid grid-cols-2 gap-2 sm:gap-10 w-full pb-5 border-b border-sepBorder" :class="modal.type == 'graph' ? 'sm:grid-cols-5' : 'sm:grid-cols-4'">
                        <div class="flex sm:w-max">
                            <div class="hidden sm:block rounded-full h-14 w-14 mx-auto shadow bg-gray-200 mr-5" :style="{ backgroundImage: `url(${modelDataFiltered[modal.index].avatar})`, backgroundSize: 'cover', backgroundPosition: 'center' }"></div>
                            <div>
                                <strong>{{modelDataFiltered[modal.index].free.name}}</strong><br />
                                <div class="text-xs">{{modelDataFiltered[modal.index].username ? '@'+modelDataFiltered[modal.index].username : null}}</div>
                                <div class="w-20">
                                    <slot v-if="modelDataFiltered[modal.index].platform==1"><img src="@/assets/images/of_logo.png" class="h-4"/></slot>
                                    <slot v-else-if="modelDataFiltered[modal.index].platform==2"><img src="@/assets/images/fansly_logo.png" class="h-4"/></slot>
                                </div>
                            </div>
                        </div>
                        <div><strong>{{ modelDataFiltered[modal.index].fans.data[modelDataFiltered[modal.index]?.fans?.data?.length-1] ?? '-' }}</strong><br /><small>{{ $t('subscribers') }}</small></div>
                        <div><strong>${{ modelDataFiltered[modal.index].earnings ? modelDataFiltered[modal.index].earnings.sum.toFixed(2) : 0}}</strong><br /><small>{{ $t('earnings') }}</small></div>
                        <div><strong>{{modelDataFiltered[modal.index].performerTop < 100 ? modelDataFiltered[modal.index].performerTop+'%' : '-'}}</strong><br /><small>{{ $t('ranking') }}</small></div>
                        <div v-if="modal.type == 'graph'" class="col-span-2 sm:col-span-1">
                            <label class="text-xs">{{ $t('filter') }}</label><br />
                            <select v-model="graph_dates" @change="order_g_data()" class="outline-0 pb-1.5 border-b border-sepBorder">
                                <option value="this_month">{{ $t('this_month') }}</option>
                                <option value="last_month">{{ $t('last_month') }}</option>
                                <option value="last_week">{{ $t('last_week') }}</option>
                                <option value="15_days">{{ $t('past_15_days') }}</option>
                                <option value="custom">{{ $t('custom') }}</option>
                            </select>
                            <VueDatePicker v-if="graph_dates=='custom'" :model-value="dRange" @update:model-value="handledRange" :max-date="new Date()" range multi-calendars :enable-time-picker="false"></VueDatePicker>
                        </div>
                    </div>
                    
                    <add-model v-if="modal.type == 'model'" :noarrow="true" :model_id_prop="model_pair_id" @close="handleClose" />
                    <session-management v-else-if="modal.type == 'session'" :model_pair_id="modelDataFiltered[modal.index].pair_id" @close="handleClose" />
                    <div v-else-if="modal.type == 'graph'" class="w-full grid grid-cols-1 sm:grid-cols-2">
                        <div class="h-60 sm:h-96">
                            <chart-component v-if="g_data.earnings?.data" :g_data="g_data.earnings" :chartType="'bar'" :graphLabel="$t('earnings')" :colors="['#70C1B2','#E4E5E4']" />
                        </div>
                        <div class="h-60 sm:h-96">
                            <chart-component v-if="g_data.fans?.data" :g_data="g_data.fans" :chartType="'line'" :graphLabel="$t('subscribers')" :colors="['#70C1B2','#E4E5E4']" />
                        </div>
                    </div>
                </section>
            </section>
        </transition>
    </div>
</template>

<script setup>
    import headerFilter from "@/components/headerFilter.vue"
    import addModel from "@/components/settings/modelsForm.vue"
    import leftMenu from '@/components/LeftMenu.vue'
    import ChartComponent from "@/components/charts/chart_comp.vue"
    import sessionManagement from "@/components/settings/sessionManagement.vue"
    import iconfilteringArraysComponent from "@/components/iconfilteringArraysComponent.vue"
</script>

<script>
    import { get_models, archive_model, get_top_models, get_model_graph } from '@/api';
    export default {
        components() {
            leftMenu, headerFilter, addModel, ChartComponent, sessionManagement, iconfilteringArraysComponent
        },
        data() {
            return {
                filteredPlatform: [],
                dRange: [null, null],
                modal: {display: false, type: null, index: 0},
                g_data: {earnings: null, fans: null},
                graph_dates: 'this_month',
                custom_end_date: null,
                modelData: [],
                permissions:null,
                myself:null,
                loading: true,
                model_pair_id: null,
                order: '',
                showActive: true,
                topModels:[],
                filters:{
                    platform: [
                        {name: this.$t('all'), flag: true, type:'all'},
                        {name: 'OnlyFans', flag: true, type:'string', value:'OnlyFans'},
                        {name: 'Fansly', flag: true, type:'string', value:'Fansly'},
                    ],
                    fans:[
                        {name: this.$t('all'), flag: true, type:'all'},
                        {name: '< 1000', flag: true, type:'range', value: [0,1000]},
                        {name: '1000 - 5000', flag: true, type:'range', value: [1000,5000]},
                        {name: '5000 - 10000', flag: true, type:'range', value: [5000,10000]},
                    ],
                    ranking:[
                        {name: this.$t('all'), flag: true, type:'all'},
                        {name: '< 1%', flag: true, type:'range', value: [0,1]},
                        {name: '1% - 5%', flag: true, type:'range', value: [1,5]},
                        {name: '5% - 25%', flag: true, type:'range', value: [5,25]},
                        {name: '> 25%', flag: true, type:'range', value: [25,0]},
                    ]
                },

            }
        },
        computed: {
            lastSixMonths() {
                let months = [];
                for (let i = 0; i < 6; i++) {
                    let month = this.$moment().subtract(i, 'months');
                    months.push({
                    value: month.format('MM-YYYY'),
                    label: month.format('MMMM YYYY')
                    });
                }
                return months;
            },
            modelDataFiltered() {
                if (this.modelData) {
                    if (this.showActive == true) {
                        return this.modelData.filter(model => model.free.active == 1)
                    } else {
                        return this.modelData;
                    }
                } else {
                    return [];
                }
            }
        },
        methods: {
            handledRange(modelData) {
                this.dRange = modelData;
                this.order_g_data();
            },
            async modelArchive(action, index) {
                if (confirm(this.$t('you_sure') + (action == 1 ? this.$t('archive_model') : this.$t('unarchive_model')))) {
                    const model = this.modelData.find(m => m.pair_id === this.modelDataFiltered[index].pair_id);
                    if (model) {
                        model.free.active = !action;
                        console.log(model.free.active)
                        await archive_model(this.$cookies.get('userToken'),this.$cookies.get('c_id'),model.free.active,model.pair_id,null);
                    }
                }
            },
            handleClose() {
                this.modal.display = false;
                this.getModelData();
            },
            sessionModal(index) {
                this.modal.display = true
                this.modal.type = 'session'
                this.modal.index = index
            },
            show_creator_modal(pair_id = null) {
                if (this.permissions && this.$getPermission(this.permissions,7).is_granted) {
                    this.model_pair_id = pair_id;
                    this.modal.display = true;
                    this.modal.type = 'model';
                }
            },
            show_creator_graph(index) {
                this.modal.display = true;
                this.modal.type = 'graph';
                this.modal.index = index;
                this.g_data.earnings = {data: this.modelDataFiltered[index].earnings.data, labels: this.modelDataFiltered[index].earnings.labels};
                this.g_data.fans = {data: this.modelDataFiltered[index].fans.data, labels: this.modelDataFiltered[index].fans.labels};
                this.ordered_g_data = this.g_data;
            },
            async getModelData() {
                this.modelData = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),null,null,null,0,0,1);
                this.loading = false;
            },
            async getTopModels() {
                let date = this.$moment(this.selectedTopMonth, "MM-YYYY");
                let startOfMonth = date.startOf('month').format('YYYY-MM-DD');
                let endOfMonth = date.endOf('month').format('YYYY-MM-DD');
                this.topModels = await get_top_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'), startOfMonth, endOfMonth, 3);
            },
            orderModels() {
                const getEarningsTotal = (earnings) => earnings ? earnings.sum : 0;
                const getSalary = (model) => {
                    if (model.commission > 0) {
                        return model.commission_type === "%" ? getEarningsTotal(model.earnings) * (model.commission / 100) : model.commission;
                    }
                    return 0;
                };

                const sortFunctions = {
                    '': (a, b) => a.free.id - b.free.id,
                    'earnings_asc': (a, b) => getEarningsTotal(a.earnings) - getEarningsTotal(b.earnings),
                    'earnings_desc': (a, b) => getEarningsTotal(b.earnings) - getEarningsTotal(a.earnings),
                    'salary_asc': (a, b) => getSalary(a) - getSalary(b),
                    'salary_desc': (a, b) => getSalary(b) - getSalary(a),
                    'ranking_asc': (a, b) => a.performerTop - b.performerTop,
                    'ranking_desc': (a, b) => b.performerTop - a.performerTop
                };

                if (this.order in sortFunctions) {
                    this.modelData.sort(sortFunctions[this.order]);
                }
            },
            async order_g_data() {
                let dateFrom, dateTo;
                switch (this.graph_dates) {
                    case 'last_month': 
                        dateFrom = this.$moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
                        dateTo = this.$moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                    break;
                    case 'this_month':
                        dateFrom = this.$moment().startOf('month').format('YYYY-MM-DD');
                        dateTo = this.$moment().endOf('month').format('YYYY-MM-DD');
                    break;
                    case 'last_week': 
                        dateFrom = this.$moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
                        dateTo = this.$moment().subtract(1, 'weeks').endOf('weeks').format('YYYY-MM-DD');
                    break;
                    case '15_days': 
                        dateFrom = this.$moment().subtract(15, 'days').format('YYYY-MM-DD');
                        dateTo = this.$moment().format('YYYY-MM-DD');
                    break;
                    case 'custom':
                        dateFrom = this.$moment(this.dRange[0]).format('YYYY-MM-DD');
                        dateTo = this.$moment(this.dRange[1]).format('YYYY-MM-DD');
                    break;
                }
                
                this.g_data.earnings = await get_model_graph(this.$cookies.get('userToken'), this.$cookies.get('c_id'), 'earnings', dateFrom, dateTo, this.modelData[this.modal.index].free.id);
                this.g_data.fans = await get_model_graph(this.$cookies.get('userToken'), this.$cookies.get('c_id'), 'fans', dateFrom, dateTo, this.modelData[this.modal.index].free.id);
            },
            exportToCSV(){
                this.$refs.exportLoading.classList.remove('hidden');
                let csvContent = "data:text/csv;charset=utf-8,";
                csvContent += this.$t('creators_csv_columns');
                let platform,fans,ranking,net_earning,salary,profit;

                this.modelData.forEach((model) => {
                    platform = model.platform == 1 ? 'OnlyFans' : 'Fansly';
                    fans = model.fans.data[model.fans.data.length-1] ?? 0;
                    ranking = model.performerTop < 100 ? model.performerTop+'%' : '-';
                    net_earning =  '$' + model.earnings ? model.earnings.sum.toFixed(2) : 0
                    salary = model.commission > 0 ? '$ '+(model.commission_type == "%" ? (model.earnings.sum * (model.commission/100)).toFixed(2) : model.commission) : "-";
                    if(model.earnings.data.length>0 && model.commission > 0){
                        profit = '$ '+(model.earnings.sum - ( model.commission_type == "%" ? (model.earnings.sum * (model.commission/100)).toFixed(2) : model.commission )).toFixed(2);
                    }else if(model.earnings.data.length>0 && model.commission <= 0){
                        profit = '$ ' + model.earnings.data ? model.earnings.sum.toFixed(2) : 0;
                    }else{
                        profit = '-';
                    }

                    csvContent += `${model.free.name},${platform},@${model.username},${fans},${ranking},${net_earning},${salary},${profit}\n`;
                });
                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `${this.$t('Creators')}.csv` );
                document.body.appendChild(link); // Required for FF
                link.click();
                this.$refs.exportLoading.classList.add('hidden');
            }
        },        
        async created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
            }).then((result) => {
                if (result.data.selectBranch) {
                    let prev = this.$route.path;
                    this.$router.push({name:'Branches', query: { prevRoute: prev } });
                } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                    this.$cookies.set('userToken','');
                    window.location.href = '/';
                }
                if (result.data.myself) {this.myself = result.data.myself;}
                if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}    
            }, (error) => {
                console.log(error);
            });
        },
        async mounted() {
            if (this.permissions && (!this.$getPermission(this.permissions,6).is_granted || !this.$getPermission(this.permissions,7).is_granted)) { this.$router.go(-1); }
            this.dRange = [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')];
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() - 1); // Subtract 1 month from the current date
            const month = currentDate.getMonth() + 1; // Adding 1 to get month in range 1-12

            this.selectedTopMonth = `${month.toString().padStart(2, '0')}-${currentDate.getFullYear()}`;

            this.getModelData();
            if (this.selectedTopMonth) {
                this.getTopModels();
            }
        }
    }
</script>

<style scoped>
    .zoom-fade-enter-active {
        transition: all .3s ease;
    }
    .zoom-fade-leave-active {
        transition: all .3s ease;
    }
    .zoom-fade-enter-from, .zoom-fade-leave-to {
        transform: scale(0.9);
        opacity: 0;
    }
</style>