<template>
    <div class="text-xl text-contentText font-bold text-center">Fansly reposting settings</div>
    <div class="mt-5 grid grid-cols-1 md:grid-cols-2 gap-10">
        
        <div class="hidden md:block">
            <div class="grid grid-cols-2 gap-5">
                <div>
                    <div class="flex items-center w-full p-2 px-3 rounded bg-gray-100">
                        <div class="rounded-full h-16 w-16 block bg-gray-200" :style="{ backgroundImage: `url(${model.avatar})`, backgroundSize: 'cover', backgroundPosition: 'center' }"></div>
                        <div class="ml-6">
                            <h2 class="font-bold">{{model.username ? '@' + model.username : ''}}</h2>
                            <div class="my-1">
                                <slot v-if="model.platform==1"><span class="hidden">O</span><img src="@/assets/images/of_logo.png" title="Onlyfans" alt="Onlyfans" class="h-5"/></slot>
                                <slot v-else-if="model.platform==2"><span class="hidden">F</span><img src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="h-5"/></slot>
                            </div>
                        </div>
                    </div>
                    <h3 class="text-lg font-bold mt-5">Don't know what links?</h3>
                    <p class="text-sm">Just go to your page and search for a post that you want to have shared and click on <strong class="font-semibold italic">Copy post link</strong></p>
                </div>

                <img src="@/assets/images/repost_instructions.jpg" title="Instructions on reposting" alt="Instructions on reposting" class="h-60 mt-5 mx-auto shadow-lg rounded-lg" />
            </div>
        </div>
        <section>
            <h3 class="text-lg font-bold mb-2 text-button">Post links</h3>
            <div class="flex justify-between whitespace-nowrap truncate p-1.5 my-1 px-4 border border-sepBorder rounded w-full font-light" v-for="(link,index) in links" :key="index">
                <div class="truncate pr-5">{{link.post_url}}</div><font-awesome-icon icon="fas fa-trash-can" class="cursor-pointer mt-1" @click="removePostURL(link.id, index)" />
            </div>
            <input type="text" v-model="posturl" placeholder="Post link" class="w-full p-2 px-4 border border-sepBorder rounded mt-2" />
            <button @click="addPostURL()" class="mt-2 whitespace-nowrap cursor-pointer w-min p-1 px-3 float-right rounded bg-button text-white">
                <font-awesome-icon icon="fas fa-plus" class="mr-1" /> Add
            </button>
        </section>
    </div>
</template>

<script setup>
    import { get_repost_links, add_repost_link, delete_repost_link } from '@/api.js';
</script>

<script>
    export default {
        props: ['model'],
        data() {
            return {
                links: [],
                posturl: null,
            }
        },
        methods: {
            async removePostURL(link_id, index) {
                console.log("remove link id", link_id);
                let response = await delete_repost_link(this.$cookies.get('userToken'), this.$cookies.get('c_id'), this.model.free.id, link_id)
                if (response.success) {
                    this.links.splice(index, 1);
                }
            },
            async addPostURL() {
                if (this.posturl) {
                    // Check if it's a valid URL format
                    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?\??([\w=&]*)*$/;
                    if (!urlRegex.test(this.posturl)) {
                        // Return error if it's not a valid URL format
                        alert('Invalid URL format');
                        return;
                    }
                    if (!this.posturl.includes('fansly')) {
                        // Return error if it doesn't contain "fansly"
                        alert('This URL seems to not be from Fansly');
                        return;
                    }
                    // Rest of the code
                } else {return;}
                let result = await add_repost_link(this.$cookies.get('userToken'), this.$cookies.get('c_id'), this.model.free.id, this.posturl);
                if (result.success) {
                    this.links.push({post_url: this.posturl});
                    this.posturl = null;
                }
            }
        },
        async mounted() {
            this.links = await get_repost_links(this.$cookies.get('userToken'), this.$cookies.get('c_id'), this.model.free.id);
            console.log(this.links);
        }
    }
</script>