<template>
    <select v-model="currentLang" @change="changeLanguage" class="p-0.5 px-1 outline-none border border-gray-200 rounded">
      <option value="en">EN</option>
      <option value="es">ES</option>
    </select>
</template>

<script>
export default {
  data() {
    return {
      currentLang: this.$i18n.locale // Initialize with the current locale
    }
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.currentLang
      document.cookie = `language=${this.currentLang}; path=/`
    }
  },
  created() {
    // Set the initial language from cookies if available
    const lang = document.cookie.replace(/(?:(?:^|.*;\s*)language\s*=\s*([^;]*).*$)|^.*$/, "$1") || 'en'
    this.currentLang = lang
    this.$i18n.locale = lang
  }
}
</script>
