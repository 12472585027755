<template>
    <div>
        <header class="bg-bkg text-black px-6 sm:px-8 py-1 sm:py-2 fixed w-full top-0 z-30 shadow-lg">
            <div class="container mx-auto">
                <logo-component class="float-left" />
                <div class="relative float-right mt-2 sm:mt-0.5">
                    <font-awesome-icon icon="fas fa-bars" class="lg:hidden cursor-pointer" @click="menuOpen = !menuOpen" />
                    <!-- Dropdown Menu -->
                    <nav :class="{'absolute right-0 top-full mt-2 md:mt-0 bg-header border border-sepBorder lg:border-0 rounded shadow-lg': menuOpen, 'hidden': !menuOpen}" class="lg:static lg:block lg:shadow-none">
                        <div class="grid lg:grid-cols-6 gap-x-4 py-2.5 px-5 lg:px-0 leading-loose lg:leading-none whitespace-nowrap">
                            <button @click="$router.push({ path: '/', query: { open: 'benefits' } });" class="hover:text-growth border-0 cursor-pointer text-center">{{ $t('benefits') }}</button>
                            <button @click="$router.push({ path: '/', query: { open: 'pricing' } });" class="hover:text-growth border-0 cursor-pointer text-center">{{ $t('pricing') }}</button>
                            <button @click="$router.push({ path: '/', query: { open: 'contact' } });" class="hover:text-growth border-0 cursor-pointer text-center">{{ $t('contact_us') }}</button>
                            <button @click="$router.push({ path: '/', query: { open: 'register' } });" class="hover:text-growth font-bold cursor-pointer text-center">{{ $t('sign_up') }}</button>
                            <button @click="$router.push({ path: '/', query: { open: 'login' } });" class="hover:text-growth font-bold cursor-pointer text-center">{{ $t('log_in') }}</button>
                            <language-picker />
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    </div>
</template>

<script setup>
    import logoComponent from "@/components/logo.vue";
    import languagePicker from "@/components/languagePicker.vue";
</script>
<script>
    export default {
        data() {
            return {
                menuOpen: false
            }
        },
        components: {
            logoComponent,
            languagePicker
        }
    }
</script>