<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>

    <div class="w-full mb-4 -mt-2 relative">
        <router-link v-if="!noarrow" :to="'/Settings'">
            <font-awesome-icon icon="fas fa-arrow-left" class="absolute float-left text-lg p-2 text-button -mt-2" />
        </router-link>
        <h2 class="text-xl text-contentText font-semibold text-center">Groups</h2>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-10 mt-5 max-w-screen-lg">
        <section class="hidden relative sm:block">
            Here, you can manage the hierarchical structure of Groups and 
            Teams to effectively assign and track models and their associated employees.<br />

            <strong>Groups:</strong> Larger organizational units. E.g., Group A.<br />
            <strong>Teams:</strong> Sub-units within a Group. E.g., Team 1 within Group A.<br /><br />
            <strong>Steps to Manage Groups & Teams:</strong><br />

            <u>Create a Group:</u> Start by establishing your primary organizational unit. This represents the broader category, like 'Group A'.<br />

            <u>Add Teams to Groups:</u> Once a group is established, you can create sub-teams within. For example, within 'Group A', you can have 'Team 1' and 'Team 2'.<br />
            <br />
            <strong>Assign models & employees to groups:</strong><br />
            <u>Models:</u> Link models to specific teams. Note: A model assigned to 'Team 1' in 'Group A' is indirectly associated with 'Group A'.<br />
            <u>Group Managers:</u> Assign them to a group. They oversee all teams and models within that group.<br />
            <u>Team Leaders:</u> Assign them to specific teams within groups. They manage models directly in their designated teams.<br />
            <u>Sexters:</u> Depending on your setup, assign them either by group or team for specific oversight.<br />
        </section>
        <ol class="text-contentText w-80 leading-8">
            <li v-for="(group, groupIndex) in groups" :key="group.id">
                <group-label :team="group" is_group="1" :showDelete="!group.hasChildren" :groupIndex="groupIndex" @remove-prop="handleRemoval(null,groupIndex)" />

                <ul v-if="group.hasChildren">
                    <draggable v-model="group.children" group="people" 
                    @start="drag=true" 
                    @end="drag=false" 
                    item-key="id"
                    @change="reorderCallBack">
                    <template #item="{element, index}">
                        <group-label :team="element" is_group="0" :showDelete="true" :teamIndex="index" @remove-prop="handleRemoval(index,groupIndex)" />
                    </template>
                    </draggable>
                    <add-group-component class="ml-8" label="New team" type="team" :groupID="group.id" @newGroup="(newGroupName, newTeamId) => handleNewGroup(newGroupName, newTeamId, group.id)" />
                </ul>

                <ul v-else class="pl-8">
                    <add-group-component label="New team" type="team" :groupID="group.id" @newGroup="(newGroupName, newTeamId) => handleNewGroup(newGroupName, newTeamId, group.id)" />
                </ul>
                <hr class="border border-sepBorder mt-4"/>
            </li>

            <add-group-component class="mt-4" label="New group" type="team" :groupID="null" @newGroup="(newGroupName, newTeamId) => handleNewGroup(newGroupName, newTeamId)" />
        </ol>
    </div>
    
</template>

<script setup>
    import draggable from 'vuedraggable'
    import addGroupComponent from './groups/add_group_component.vue';
    import groupLabel from './groups/group_label.vue';
</script>

<script>
    import { get_groups, reorder_groups } from '@/api';
    export default {
        components: {addGroupComponent,groupLabel,draggable},
        props: ['headerName', 'noarrow'],
        data() {
            return {
                groups: [],
                loading: false,
                drag: false,
            }  
        }, 
        methods: {
            async reorderCallBack(){
                await reorder_groups(this.$cookies.get("userToken"),this.$cookies.get('c_id'),this.groups);
            },
            handleRemoval(teamIndex,groupIndex) {
                if (teamIndex != null) {
                    this.groups[groupIndex].children.splice(teamIndex, 1);
                    if (this.groups[groupIndex].children.length == 0) {
                        this.groups[groupIndex].hasChildren = false;
                    }
                } else {
                    this.groups.splice(groupIndex, 1);
                }
            },
            handleNewGroup(newGroupName, newTeamId, groupId = null) {
                if (groupId) {
                    const groupToUpdate = this.groups.find(group => group.id === groupId);
                    
                    if (groupToUpdate) {
                        if (!groupToUpdate.children) {
                            groupToUpdate.children = [];
                        }
                        groupToUpdate.hasChildren = true;
                        groupToUpdate.children.push({
                            id: newTeamId,
                            name: newGroupName,
                            hasChildren: false
                        });
                    } else {
                        console.error("Group with given ID not found.");
                    }
                } else {
                    this.groups.push({
                        id: newTeamId,
                        name: newGroupName,
                        hasChildren: false,
                        children: []
                    });
                }
            }
        },
        async created() {
            this.loading = true;
            this.groups = await get_groups(this.$cookies.get("userToken"),this.$cookies.get('c_id'));
            console.log(this.groups);
            this.loading = false;
        }
    }
</script>





