<template>
    <div class="overflow-x-hidden">
        <header-panel />

        <div class="px-2">
            <section class="container mx-auto bg-white rounded-lg shadow-lg p-5 sm:p-10 mt-22">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
                    <div class="max-w-base">
                        <font-awesome-icon icon="fa-solid fa-arrow-left" class="ml-2 text-2xl cursor-pointer" @click="$router.push('/')" />
                        <h1 class="mt-5 text-3xl font-bold">{{$t('Model-Fan_Connections')}} <span class="text-growth">ChatterMate</span></h1>
                        <p class="max-w-lg mt-3 text-lg">
                            {{$t('Chattermate_title')}}<br />
                            {{$t('Chattermate_desc')}}</p>
                    </div>
                    <div>
                        <img :src="chatterMate" class="hidden sm:block" alt="Seamless Model-Fan Interaction Pad" title="Seamless Model-Fan Interaction Pad" />
                    </div>
                    <div @click="$router.push({ path: '/', query: { open: 'register' } });" class="col-span-full bg-growth w-full mx-auto rounded shadow-lg text-center text-white p-3 font-semibold text-lg cursor-pointer">
                        <font-awesome-icon icon="fa-solid fa-seedling" class="mr-3" /> {{$t("Try_now")}}
                    </div>

                    <div class="col-span-full text-left relative mt-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 items-center">
                            <div class="w-1/3 mx-auto">
                                <img src="@/assets/images/spender_flags.jpg" :title="$t('Spender_flags')" alt="Spender flags" class="mr-2 w-full rounded shadow"/>
                            </div>
                            <div class="sm:px-5">
                                <span class="ml-10 flex text-2xl font-bold text-blueColor">{{ $t('Spender_flags') }}</span>
                                <div class="absolute border border-growth text-growth font-bold p-0.5 px-3 rounded -rotate-12 -mt-14"><font-awesome-icon icon="fa-solid fa-fire" /> New</div>
                                <p class="mt-5 leading-relaxed">
                                   {{$t('SF_1')}} <br />
                                   {{$t('SF_1')}} 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-full text-left relative mt-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 items-center">
                            <div class="sm:px-5">
                                <span class="flex text-2xl font-bold text-blueColor">{{$t('emoji')}}</span>
                                <p class="mt-5 leading-relaxed">
                                    {{$t('emoji_1')}}<br />
                                    {{$t('emoji_2')}}
                                </p>
                            </div>
                            <div class="mx-auto">
                                <img src="@/assets/images/emoji.jpg" title="Added emoji support" alt="Added emoji support" class="mr-2 h-60 rounded shadow"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-full text-left relative mt-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 items-center">
                            <div class="mx-auto">
                                <img src="@/assets/images/chatting_scripts.jpg" title="Customizable Chatting Scripts" alt="Customizable Chatting Scripts" class="mr-2 h-60 rounded shadow"/>
                            </div>
                            <div class="sm:px-5">
                                <span class="ml-10 flex text-2xl font-bold text-blueColor">{{$t('Chatting_script')}}</span>
                                <div class="absolute border border-growth text-growth font-bold p-0.5 px-3 rounded -rotate-12 -mt-14"><font-awesome-icon icon="fa-solid fa-fire" /> New</div>
                                <p class="mt-5 leading-relaxed">
                                    {{$t('CS1')}}.<br />
                                    {{$t('CS2')}}.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <footer-panel />
    </div>
</template>

<script setup>
    import headerPanel from "@/components/landing/HeaderLanding.vue";
    import chatterMate from '@/assets/images/fanpad.svg';
    import FooterPanel from "@/components/landing/FooterLanding.vue"
</script>

<script>
    export default {
        components: {FooterPanel, headerPanel},
        data() {
            return {
                menuOpen:false,
            }
        },
    }
</script>