<template>
    <headerfilter :enable="loadingTables" :pageTitle="'Metrics summary'" :lrIndex="lrIndex" :lrData="lrData" @selectedEmpl="selectedEmpl = $event" @selectedModelN="selectedModelN=$event" @selectedModel="selectedModel = $event" @selectedGroupN="selectedGroupN=$event" @selectedGroup="selectedGroup = $event" @dateFrom="dateFrom = $event" @dateTo="dateTo = $event"/>
    
    <div class="mx-2 mb-24 sm:ml-24 mr-2" :class="{'mt-36':selectedModel, 'mt-24': !selectedModel}" v-if="permissions != null && $getPermission(permissions,1).is_granted">
        <section v-if="selectedModel" class="hidden float-right md:clear-both lg:mx-auto w-max grid grid-cols-2 text-center font-semibold cursor-pointer">
            <div class="p-1 px-4 rounded-l" :class="{'bg-button text-black':displayTab == 1, 'border border-indigo-300 text-indigo-300': displayTab == 2}" @click="dTab(1)">Metrics</div>
            <div class="p-1 px-4 rounded-r" :class="{'bg-button text-black':displayTab == 2, 'border border-indigo-300 text-indigo-300': displayTab == 1}" @click="dTab(2)">History metrics</div>
        </section>

        <metrics-table period="daily" tableTitle="Daily stats" :blockedColumns="blockedColumns" :selectedPlatform="selectedPlatform" :selectedModelN="selectedModelN" :selectedModel="selectedModel" :permissions="permissions" :selectedGroup="selectedGroup" :selectedGroupN="selectedGroupN" :dateFrom="dateFrom" :dateTo="dateTo" />
        <metrics-table period="week" tableTitle="Weekly stats" :blockedColumns="blockedColumns" :selectedPlatform="selectedPlatform" :selectedModelN="selectedModelN" :selectedModel="selectedModel" :permissions="permissions" :selectedGroup="selectedGroup" :selectedGroupN="selectedGroupN" :dateFrom="dateFrom" :dateTo="dateTo" />
        <metrics-table period="month" tableTitle="Monthly stats" :blockedColumns="blockedColumns" :selectedPlatform="selectedPlatform" :selectedModelN="selectedModelN" :selectedModel="selectedModel" :permissions="permissions" :selectedGroup="selectedGroup" :selectedGroupN="selectedGroupN" :dateFrom="dateFrom" :dateTo="dateTo" />
    </div>
    <div v-if="permissions != null && (!$getPermission(permissions,1).is_granted)" class="text-center mt-52 text-textOnBkg">{{ $t("no_permission") }}</div>
</template>

<script setup>
    import metricsTable from '@/components/metrics/table.vue'
    import headerfilter from "@/components/headerFilter.vue"
</script>

<script>
    export default {
        props:['lrIndex','lrData','selectedModel', 'selectedModelN','selectedGroupN','selectedEmpl','selectedGroup','dateFrom','dateTo'],
        data() {
            return {
                permissions:null,
                blockedColumns: {renewOn:0,subsPaid:0},
                displayTab:1,
                loadingTables:0
            }
        },
        components() {
            branch,
            metricsTable,
            headerfilter
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', query: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.myself) {this.myself = result.data.myself;}
                    if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                    if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
                    setTimeout(() => {
                        this.loaded = true;
                    }, 500); 
                }, (error) => {
                console.log(error);
            });
        },
        mounted() {
            setTimeout(() => {
                this.loadingTables = 1;
            }, 1000);
            setTimeout(() => {
                this.loadingTables = 2;
            }, 1000); 
        },
        methods: {
            dTab(w) {
                this.displayTab = w;
            },
        }
    }
</script>