<template>
    <div class="overflow-x-hidden">
        <header-panel />

        <div class="px-2">
            <section class="container mx-auto bg-white rounded-lg shadow-lg p-5 sm:p-10 mt-22">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
                    <div class="max-w-base">
                        <font-awesome-icon icon="fa-solid fa-arrow-left" class="ml-2 text-2xl cursor-pointer" @click="$router.push('/')" />
                        <h1 class="mt-5 text-3xl font-bold">{{$t('Chatter_shifts_metrics')}}</h1>
                        <p class="max-w-lg mt-3 text-lg">
                            {{$t('CSM1')}} <br />{{$t('CSM2')}}<br />
                            {{$t('CSM3')}}</p>
                    </div>
                    <div>
                        <img :src="shifts" class="hidden sm:block" alt="Performance tool to track your chatters" title="Performance tool to track your chatters" />
                    </div>
                    <div @click="$router.push({ path: '/', query: { open: 'register' } });" class="col-span-full bg-growth w-full mx-auto rounded shadow-lg text-center text-white p-3 font-semibold text-lg cursor-pointer">
                        <font-awesome-icon icon="fa-solid fa-seedling" class="mr-3" /> {{$t('Try_now')}}
                    </div>

                    <div class="col-span-full text-left relative mt-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 items-center">
                            <div class="mx-auto">
                                <img src="@/assets/images/shifts_schedule_screenshot.jpg" title="Plan Your Shifts with Ease" alt="Plan Your Shifts with Ease" class="mr-2 h-72 rounded shadow-lg"/>
                            </div>
                            <div class="sm:px-5">
                                <span class="flex text-2xl font-bold text-blueColor">{{$t('Plan_shift')}}</span>
                                <p class="mt-5 leading-relaxed">
                                    {{ $t('PS1') }}<br />
                                    {{ $t('PS2') }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-full text-left relative mt-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 items-center">
                            <div class="sm:px-5">
                                <span class="ml-10 flex text-2xl font-bold text-blueColor">{{$t('Recognize_Top_Performers')}}</span>
                                <div class="absolute border border-growth text-growth font-bold p-0.5 px-3 rounded -rotate-12 -mt-14"><font-awesome-icon icon="fa-solid fa-fire" /> New</div>
                                <p class="mt-5 leading-relaxed">
                                    {{$t('RTP1')}}<br />{{$t('RTP2')}}
                                </p>
                            </div>
                            <div class="mx-auto">
                                <img src="@/assets/images/top_employee.jpg" title="Recognize Top Employee Performers" alt="Recognize Top Employee Performers" class="mr-2 h-72 rounded shadow-lg"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-full text-left relative mt-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 items-center">
                            <div class="mx-auto">
                                <img src="@/assets/images/chatting_earnings.jpg" title="Track Employee Performance Metrics" alt="Track Employee Performance Metrics" class="mr-2 h-60 rounded shadow-lg"/>
                            </div>
                            <div class="sm:px-5">
                                <span class="flex text-2xl font-bold text-blueColor">{{$t('Track_Performance_Metrics')}}</span>
                                <p class="mt-5 leading-relaxed">
                                    {{$t('TPM1')}}<br />{{$t('TPM2')}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-full text-left relative mt-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 items-center">
                            <div class="sm:px-5">
                                <span class="flex text-2xl font-bold text-blueColor">{{$t('Streamline_Salary_Calculation_Processing')}}</span>
                                <p class="mt-5 leading-relaxed">
                                    {{$t('SSCP1')}} <br />{{$t('SSCP2')}}
                                </p>
                            </div>
                            <div class="mx-auto">
                                <img src="@/assets/images/employee_payroll.jpg" title="Streamline Salary Calculation Processing" alt="Streamline Salary Calculation Processing" class="mr-2 h-60 rounded shadow-lg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <footer-panel />
    </div>
</template>

<script setup>
    import headerPanel from "@/components/landing/HeaderLanding.vue";
    import shifts from '@/assets/images/shifts.svg';
    import FooterPanel from "@/components/landing/FooterLanding.vue"
</script>

<script>
    export default {
        components: {FooterPanel, headerPanel},
        data() {
            return {
                menuOpen:false,
            }
        },
    }
</script>