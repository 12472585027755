<template>
    <div class="pb-10">
        <div class="bg-green-300 rounded-full text-black p-2 fixed z-50 top-5 px-6 left-1/2" v-if="saved">{{ $t('saved') }}</div>

        <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>
        <div class="w-full mb-4 relative">
            <router-link :to="'../Settings'">
                <font-awesome-icon icon="fas fa-arrow-left" class="float-left text-lg p-2 text-button -mt-2" />
            </router-link>
            <h2 class="mt-2 text-xl text-contentText font-semibold text-center">Task scheduler</h2>
            <p class="mt-2 text-sm text-panelsText text-center">This visual interface is for scheduling tasks and allows you to manage the movement of models or employees between teams and groups.</p>
        </div>        

        <label for="type" class="text-sm">Type:</label><br />
        <select v-model="type" id="type" class="w-60 border-b border-sepBorder rounded p-1 px-3 outline-0">
            <option value="model">Model</option>
            <option value="user">User</option>
            <option value="tool">Tool</option>
        </select><br />

        <label for="item" class="text-sm">{{ type == 'user' ? 'User' : 'Model' }}:</label><br />
        <select v-model="accountID" id="item" class="w-60 border-b border-sepBorder rounded p-1 px-3 outline-0">
            <slot v-if="type == 'user'">
                <option v-for="(account,index) in usersList" :key="index" :value="account.id">{{ account.name }}</option>
            </slot>
            <slot v-else>
                <option v-for="(account,index) in modelsList" :key="index" :value="account.pair_id">{{ account.code + " " + account.name }}</option>
            </slot>
        </select><br />

        <slot v-if="type == 'tool'">
            <div class="mt-2 text-gray-300 float-left mr-5">
                <label>Tool:</label><br />
                <select class="mt-1 clear-both w-60 border-b border-sepBorder rounded p-1 px-3 outline-0" v-model="groupID" v-if="groupList.length>1">
                    <option v-for="(tool,index) in toolsList" :key="index" :value="tool.id">{{tool.name}}</option>
                </select>
            </div>
            State: 
            <font-awesome-icon :icon="toggleVal(toolOn)" class="ml-2 cursor-pointer mt-14" @click="toolOn = !toolOn" :class="{'text-green-500':toolOn, 'text-red-500': !toolOn}" />
        </slot>
        <slot v-else>
            <div class="mt-2 float-left mr-5">
                <label class="text-sm">Group:</label><br />
                <select class="mt-1 w-60 border-b border-sepBorder rounded p-1 px-3 outline-0" v-model="groupID" v-if="noGroups">
                    <option value="0">All groups</option>
                </select>
                <select class="mt-1 clear-both w-60 border-b border-sepBorder rounded p-1 px-3 outline-0" v-model="groupID" v-if="groupList.length>1" @change="switchView($event, $event.target.selectedIndex)">
                    <option v-for="(group,index) in groupList" :key="index" :value="group.id">{{group.name}}</option>
                </select>
            </div>

            <slot class="mt-2 mb-2 float-left" v-if="groupList && (groupList.length>0 && groupIndex!=null) || teamID">
                <div class="mt-2" v-if="(groupIndex!=-1 && groupList[groupIndex].hasChildren == true) || teamID">
                    <label class="text-sm">Team:</label><br />
                    <select class="mt-1 clear-both w-60 border-b border-sepBorder rounded p-1 px-3 outline-0" v-model="teamID">
                        <option value="0">All teams</option>
                        <option v-for="(team,index) in groupList[groupIndex].children" :key="index" :value="team.id">{{ team.name }}</option>
                    </select>
                </div>
            </slot>
        </slot>
        
        <div class="clear-both"></div>
        
        <div class="mt-3">
            <label for="datetime" class="text-sm">When:</label><br />
            <input type="datetime-local" class="w-60 border-b border-sepBorder rounded p-1 px-3 outline-0" v-model="datetime" id="datetime" :min="minDateTime"><br />
        </div>
        
        <button @click="saveTask()" class="delay-75  duration-75 transition-all absolute right-20 p-2 px-7 rounded text-buttonText font-medium bg-button uppercase">
            <font-awesome-icon icon="fas fa-spinner" class="animate-spin" v-if="loading" /> Save task
        </button>
    </div>

    <section class="mt-10">
        <table class="bg-tableBG text-tableText">
            <thead>
                <tr>
                    <th>Account Type</th>
                    <th>Account id</th>
                    <th>Run At</th>
                    <th>Task Type</th>
                    <th>Task Change</th>
                    <th>Scheduled By</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(task,index) in scheduledTasks" :key="index" :class="{'bg-tableRow1':index%2==0}">
                    <td class="p-0.5 px-2 text-left">{{ task.accountType }}</td>
                    <td class="p-0.5 px-2 text-left">{{ task.accountID }}</td>
                    <td class="p-0.5 px-2 text-left">{{ formatTimeStamp(task.runAt) }}</td>
                    <td class="p-0.5 px-2 text-left">{{ task.taskType }}</td>
                    <td class="p-0.5 px-2 text-left">{{ task.taskChange }}</td>
                    <td class="p-0.5 px-2 text-left">{{ task.scheduledBy }}</td>
                    <td class="p-0.5 px-2 text-left cursor-pointer"><font-awesome-icon icon="fas fa-trash-can" v-if="task.id" @click="removeTask(task.id,index)" /></td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                noGroups:false,
                groupID:1,
                groupList: [],
                groupIndex:null,
                teamID:null,
                saved:false,
                loading:false,
                datetime: "",
                type: "model",
                accountID: "",
                modelsList: [],
                usersList:[],
                toolsList:[],
                toolOn:true,
                scheduledTasks:[]
            }
        },
        computed: {
            minDateTime() {
                const currentDateTime = new Date();
                return currentDateTime.toJSON().slice(0, 16);
            },
        },
        methods: {
            toggleVal(condition) {
                return condition ? 'fas fa-2xl fa-toggle-on' : 'fas fa-2xl fa-toggle-off';
            },
            formatTimeStamp(timestamp) {
                return this.$moment.utc(timestamp).local().format('YYYY-MM-DD HH:mm:ss');
            },
            removeTask(tID,index) {
                if (confirm("Are you sure?")) {
                    this.$axios.post(baseURL+"settingspost/", {                    
                        action: "deleteTask",
                        taskID: tID,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        this.scheduledTasks.splice(index,1);
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            saveTask() {
                this.$axios.post(baseURL+"settingspost/", {                    
                    action: "scheduleTask",
                    taskData: {
                        type: this.type,
                        toolOn: this.type == 'tool' ? this.toolOn : null,
                        accountID: this.accountID,
                        groupID: this.groupID,
                        teamID: this.teamID,
                        runAt: this.$moment(this.datetime, "YYYY-MM-DDTHH:mm").utc().format("YYYY-MM-DDTHH:mm")
                    },
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    this.scheduledTasks.push({accountType:this.type,accountID:this.accountID,taskType:'move to',runAt:moment(this.datetime, "YYYY-MM-DDTHH:mm").utc().format("YYYY-MM-DDTHH:mm"),scheduledBy:null,taskChange:this.teamID>0 ? this.teamID : this.groupID});
                }, (error) => {
                    console.log(error);
                });
            },
            switchView(event, selectedIndex) {
                this.groupIndex = selectedIndex;
                this.teamID = null;
            },
            getScheduledTasks() {
                this.$axios.get(baseURL+"settingsget/", {
                    params: {
                        action: 'getScheduledTasks',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.scheduledTasks = result.data;
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
            },
            async getListData(whichData,group,team) {
                return new Promise(async (resolve, reject) => {
                    try {
                      const result = await this.$axios.get(baseURL+"lists/", {
                            params: {
                                build: whichData,
                                groupFilter: group,
                                teamFilter: team,
                                userToken: this.$cookies.get('userToken'),
                                companyID: this.$cookies.get('c_id')
                            }
                        })
                      resolve(result.data);
                    } catch (error) {
                      reject(error);
                    }
                });
            },
            getTeamsAndGroups() {
                this.$axios.get(baseURL+"lists/", {
                    params: {
                        build: 'groups',
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.groupList = result.data;
                    if(result.data.length) {
                        for(var i=0;i<this.groupList.length;i++) {
                            console.log(this.groupID, this.groupList[i].id);
                            if (this.groupID == this.groupList[i].id) {
                                this.groupIndex = i;
                                break;
                            } else {
                                this.groupIndex = 0;
                            }
                        }
                        this.displayForm = true;
                    } else {
                        this.displayForm = false;
                        this.noGroups = true;
                    }
                }, (error) => {
                    console.log(error);
                }).finally(() => this.createdForm = true);
            }
        },
        async created() {
            this.loading = true;
            this.getTeamsAndGroups();
            this.usersList = await this.getListData('sextarsList',null,null);
            this.modelsList = await this.getListData('models',null,null);
            this.toolsList = await this.getListData('getTools',null,null);
            this.getScheduledTasks();
        }
    }
</script>

<style scoped>
    td, th {
        padding:5px;
        border:1px solid rgba(255,255,255,0.2);
        text-align:center;
    }
</style>