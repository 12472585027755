<template>
    <div class="">
        <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
        <headerfilter pageTitle="Payrolls" @selectedMonth="selectedMonth = $event" @selectedTeam="selectedTeam = $event" @selectedGroup="selectedGroup = $event" @selectedEmpl="selectedEmpl = $event" @dateFrom="dateFrom = $event"/>

        <section class="sm:ml-24 mt-24 ml-5 mb-10 bg-squares text-panelsText p-1 px-7 rounded-xl">
            <h1 class="m-5 ml-0 font-bold text-xl">Employees payrolls</h1>
            <div class="mt-5 pb-5">
                <table v-if="sextarSalaries.length>0">
                    <thead>
                        <tr class="">
                            <td class="text-left p-1 pl-4 rounded-l-lg border border-sepBorder">Name</td>
                            <td class="text-left p-1 border border-sepBorder">Team - position</td>
                            <td class="text-left p-1 border border-sepBorder">Cost ratio</td>
                            <td class="text-center p-1 border border-sepBorder">Soft bonus</td>
                            <td class="text-left p-1 border border-sepBorder">Salary (with bonus)</td>
                            <td class="text-center p-1 border border-sepBorder">Paid</td>
                            <td class="text-left p-1 rounded-r-lg border border-sepBorder">Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(salary,index) in sextarSalaries" :key="index" :style="'border-left:6px solid ' + salary.data.emplData.color" :class="index % 2 == 0 ? 'bg-tableRow1' : ''">
                            <td class="text-left p-2 pl-4 border border-sepBorder">{{salary.data.emplData.emplName}}</td>
                            <td class="text-left p-1 border border-sepBorder">{{salary.data.emplData.team}} - {{salary.data.emplData.positionName}}</td>
                            <td class="text-left p-1 border border-sepBorder">{{((salary.data.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0)/salary.data.shifts.reduce((totalEarnings, shift) => totalEarnings + shift.models.reduce((sum, model) => sum + parseFloat(model.earningsSum), 0), 0))*100).toFixed(2)}} %</td>
                            <td class="text-left p-1 border border-sepBorder">$ {{salary.correction??0}}</td>
                            <td class="text-left p-1 font-bold border border-sepBorder">$ {{String(parseFloat(parseFloat(salary.data.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0)).toFixed(2) + parseFloat(salary.correction??0)).toFixed(2).toLocaleString('en-US')).replace(/,/g,' ')}}</td>
                            <td class="text-center p-1 border border-sepBorder"><font-awesome-icon :icon="xcheckIcon(salary.paid)" :class="salary.paid==1?'text-green-400':'text-red-400'"></font-awesome-icon></td>
                            <td class="text-left p-1 border border-sepBorder"><button v-if="salary.paid == 0" @click="markAsPaid(salary.id,index)" class="bg-button text-buttonText px-2 rounded">Mark as paid</button></td>
                        </tr>
                    </tbody>
                </table>
                <span v-else class="text-black/80">This month has no locked salaries yet. Choose a different month</span>
            </div>
        </section>
    </div>
</template>

<script setup>
    import headerfilter from "@/components/headerFilter.vue";
    import leftMenu from "@/components/LeftMenu.vue";
</script>

<script>
    export default {
        props: ['dateFrom','selectedEmpl','selectedGroup','selectedMonth','selectedTeam'],
        data() {
            return {
                sextarSalaries:[],
                sextingCosts: [],
                mounted:false,
                permissions:null,
                myself:null,
            }
        },
        components() {
            headerfilter,
            leftMenu
        },
        watch: {
            selectedMonth: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.getSalaries();
                }
            },
            selectedGroup: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.getSalaries();
                }
            },
            selectedEmpl: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.loading = true;
                    this.getSalaries();
                }
            },
            selectedTeam: function(newVal, oldVal) { // watch it
                if (this.mounted) {
                    this.loading = true;
                    this.getSalaries();
                }
            },
        },
        methods: {
            xcheckIcon(condition) {
                return condition==1 ? 'fas fa-check' : 'fas fa-xmark';
            },
            markAsPaid(salary_id,salary_index) {
                if (confirm("Mark as paid?")) {
                    this.$axios.post(baseURL+"finance/", {
                        userToken: this.$cookies.get('userToken'),
                        action:'markAsPaid',
                        salaryID: salary_id,
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        if (result.data.success == true) {
                            this.sextarSalaries[salary_index].paid = true;
                        }
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            getSalaries() {
                this.$axios.get(baseURL+"finance/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        build:'sextarSalaries',
                        selectedMonth: this.selectedMonth,
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    if (result.status == 200) {
                        this.sextarSalaries = result.data;
                    }
                }, (error) => {
                    console.log(error);
                });
            },
        },
        created() {
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
            }).then((result) => {
                if (result.data.selectBranch) {
                    let prev = this.$route.path;
                    this.$router.push({name:'Branches', query: { prevRoute: prev } });
                } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                    this.$cookies.set('userToken','');
                    window.location.href = '/';
                }
                if (result.data.myself) {this.myself = result.data.myself;}
                if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
            }, (error) => {
                console.log(error);
            });

            this.getSalaries();
        }, 
        mounted() {
            this.mounted = true;
        }
    }
</script>