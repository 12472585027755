<template>
    <section class="grid grid-cols-2 gap-x-10">
        <div class="col-span-2">
            <h2 class="font-bold text-xl text-center -mt-2">Assign an employee</h2>
            <h2 class="font-semibold text-center"> {{modalField.den}} - {{modalField.date}} (Shift: {{formatTime(modalField.date + ' ' + helpFrom,"HH:mm")}} - {{formatTime(modalField.date + ' ' + helpTo,"HH:mm")}}) </h2>
            <h2 class="text-center">Model: {{modalField.modelName}}<span v-if="modalField.group" class="text-tooltipText font-light text-center"> - {{modalField.group}}</span></h2>
            <h2 class="text-sm font-semibold text-center" v-if="modalField.assignedBy">Assigned by: {{modalField.assignedBy}} {{modalField.assignedAt}}</h2>
            <hr class="mt-2 border-sepBorder" noshade><br />
        </div>
        <div>
            <h3 class="text-2xl font-bold leading-relaxed">Quick Shift Assignment</h3>
            <p>Set up individual shifts with ease using the 'Assign an Employee' function. Choose times, toggle between start and end times with precision, and opt for single or multiple shift assignments with the 'Save for one model' or 'Save for the team' buttons.</p>

            <h3 class="text-2xl font-bold mt-5 leading-relaxed">Team Scheduling Made Simple</h3>
            <p>'Save for the team' enables you to assign an employee to consistent shifts across multiple schedules, streamlining the planning process for recurring work patterns.</p>
        </div>
        <section>
            <label class="text-xs text-gray-500">Name</label><br />
            <select id="empl_id" class="border-b border-sepBorder py-1 outline-0 block" v-model="modalField.empl">
                <option value="0">Choose employee</option>
                <option v-for="(v,i) in employeeList" :key="i" :value="v.id">{{v.name}}</option>
            </select>
            <label class="text-xs text-gray-500">Note</label><br />
            <textarea id="shifts_note" placeholder="Add note" v-model="modalField.note" class="border-b border-sepBorder w-full h-8 outline-0"></textarea><br />

            <div @click="modalField.salary = !modalField.salary" class="cursor-pointer w-max mt-2 mb-2 leading-none">
                <input type="checkbox" class="w-max" v-model="modalField.salary" /> Give salary for this shift 
                <small class="font-light"><br />(* if unchecked, sextar will not get salary for this shift)</small>
            </div>

            <div v-if="!modalField.shiftID && modalField.split" class="bg-red-400 text-white p-2 px-3 rounded text-center font-bold my-2 mt-4">Don't forget to change the time</div>
            <hr class="mb-2 border-sepBorder" />

            <div class="float-left mr-5">
                <label class="text-xs text-gray-500">Time from</label><br />
                <select class="w-14 rounded p-1 outline-0 border-b border-sepBorder" v-model="modalField.timeFrom" @change="changeHours()">
                    <option v-for="index in 24" :key="index" :value="index">{{index}}</option>
                </select>
                <select class="w-14 rounded p-1 ml-1 outline-0 border-b border-sepBorder" v-model="modalField.timeFromMinutes" @change="changeHours()">
                    <option value="0">00</option><option value="30">30</option>
                </select>
            </div>
            <div class="float-left mr-5">
                <label class="text-xs text-gray-500">Time to</label><br />
                <select class="w-14 rounded p-1 outline-0 border-b border-sepBorder" v-model="modalField.timeTo" @change="changeHours()">
                    <option v-for="index in 24" :key="index" :value="index">{{index}}</option>
                </select>
                <select class="w-14 rounded p-1 ml-1 outline-0 border-b border-sepBorder" v-model="modalField.timeToMinutes" @change="changeHours()">
                    <option value="0">00</option><option value="30">30</option>
                </select>
            </div>
            <label class="text-xs text-gray-500">Shift lenght:</label><br /><span class="font-semibold">{{modalField.hours}} hours</span><br />

            <div class="errorMessage" v-if="errorMessage"></div>
            <section class="grid grid-cols-2 gap-2 mt-5">
                <button @click="forAll = false,saveShift(modalField.teamID)" class="delay-75 duration-75 transition-all bottom-10 p-1 w-full rounded text-button font-medium border-2 border-button hover:scale-105">Save for one model</button>
                <button @click="forAll = true,saveShift(modalField.teamID)" class="delay-75 duration-75 transition-all p-1 w-full rounded bg-button text-buttonText font-medium hover:scale-105">Save for the team</button>
            </section>
        </section>
    </section>
</template>

<script>
    export default {
        props: ['modalField','employeeList','helpFrom','errorMessage','helpTo','timeZone','myself'],
        emits: ['result'],
        data() {
            return {
                mounted: false
            }
        },
        methods: {
            determinZoneDST(original) {
                var tzone = 0;
                if (this.$moment().isDST()) {
                    if (this.$moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                        tzone = parseInt(this.timeZone)
                    } else {
                        tzone = (parseInt(this.timeZone)/60-1)*60
                    }
                } else {
                    if (this.$moment(original,"YYYY-MM-DD HH:mm:ss").isDST()) {
                        tzone = (parseInt(this.timeZone)/60+1)*60
                    } else {
                        tzone = parseInt(this.timeZone)
                    }
                }
                return tzone;
            },
            changeHours() {
                if(this.mounted) { 
                    if (parseInt(this.modalField.timeFrom) < parseInt(this.modalField.timeTo)) {
                        this.modalField.hours = this.modalField.timeTo - this.modalField.timeFrom;
                        if (this.modalField.timeToMinutes == 30) {this.modalField.hours = parseFloat(this.modalField.hours) + .5;}
                        if (this.modalField.timeFromMinutes == 30) {this.modalField.hours = parseFloat(this.modalField.hours) - .5;}
                    } else if (parseInt(this.modalField.timeFrom) > parseInt(this.modalField.timeTo)) {
                        this.modalField.hours = (24 - parseInt(this.modalField.timeFrom)) + parseInt(this.modalField.timeTo);
                        if (this.modalField.timeToMinutes == 30) {this.modalField.hours = parseFloat(this.modalField.hours) + .5;}
                        if (this.modalField.timeFromMinutes == 30) {this.modalField.hours = parseFloat(this.modalField.hours) - .5;}
                    } else {
                        alert('The time difference must be at least 1 hour');
                        this.modalField.hours = 1;
                        if (this.modalField.timeTo != 24) {
                            this.modalField.timeTo=this.modalField.timeTo + 1; 
                        } else { 
                            this.modalField.timeTo = 1;
                        }
                    }
                    console.log("the hours field value is: " + this.modalField.hours);
                }
            },
            formatTime(original,finalFormat) {
                if (this.$moment().isDST()) {
                    if (this.myself && this.myself.companyTz) {
                        if (parseInt(this.timeZone)<0) {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract((parseInt(this.timeZone)/60+1)*60, 'minutes').format(finalFormat);
                        } else {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add((parseInt(this.timeZone)/60-1)*60, 'minutes').format(finalFormat);
                        }
                    } else { // MSB branch ONLY -> IN SUMMER
                        var tzone = this.determinZoneDST(original)
                        if (parseInt(this.timeZone)<0) {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract(tzone, 'minutes').format(finalFormat);
                        } else {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add(tzone, 'minutes').format(finalFormat);
                        }
                    }
                } else {
                    if (parseInt(this.timeZone)<0) {
                        return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    } else {
                        return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    }
                }
            },
            formatTimeBack(original,finalFormat) {
                if (this.$moment().isDST()) {
                    if (this.myself && this.myself.companyTz) {
                        if (parseInt(this.timeZone)<0) {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add((parseInt(this.timeZone)/60+1)*60, 'minutes').format(finalFormat);
                        } else {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract((parseInt(this.timeZone)/60-1)*60, 'minutes').format(finalFormat);
                        }
                    } else { // MSB branch ONLY -> IN SUMMER
                        var tzone = this.determinZoneDST(original)
                        if (parseInt(this.timeZone)<0) {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add(tzone, 'minutes').format(finalFormat);
                        } else {
                            return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract(tzone, 'minutes').format(finalFormat);
                        }
                    }
                } else {
                    if (parseInt(this.timeZone)<0) {
                        return this.$moment(original,"YYYY-MM-DD HH:mm:ss").add(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    } else {
                        return this.$moment(original,"YYYY-MM-DD HH:mm:ss").subtract(parseInt(this.timeZone), 'minutes').format(finalFormat);
                    }
                }
            },
            saveShift(teamID) {
                if (this.modalField.timeFrom == 24) {this.modalField.timeFrom = 0}
                if (this.modalField.timeTo == 24) {this.modalField.timeTo = 0}

                this.modalField.timeFrom = this.formatTimeBack(this.$moment(this.modalField.date,'DD.MM.YYYY').format("YYYY-MM-DD") + ' ' + this.modalField.timeFrom + ':'+ this.modalField.timeFromMinutes + ':00',"HH:mm","H")
                this.modalField.timeTo = this.formatTimeBack(this.$moment(this.modalField.date,'DD.MM.YYYY').format("YYYY-MM-DD") + ' ' + this.modalField.timeTo + ':'+ this.modalField.timeToMinutes + ':00',"HH:mm","H")
                
                this.$axios.post(baseURL+"shifts/", {
                    action: "saveShiftTimeTable",
                    modelObject: this.modalField,
                    forAll: this.forAll,
                    summer: this.$moment(this.modalField.date,'DD.MM.YYYY').isDST(),
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    var groupIndex = this.modalField.indexes['gIndex']; // index of the 1 layer - group
                    var columnIndex = this.modalField.indexes['index']; 
                    var shiftIndex = this.modalField.indexes['i']; //id of the shift of the day
                    if (result.data && result.data.success == true) {
                        this.$emit('result', {groupIndex,columnIndex,shiftIndex,result:result.data,teamID:teamID});
                    } else {
                        alert("Server error, shift not added. Please refresh the page");
                    }
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false);
                this.showModalkey = false;
            },
        },
        mounted() {
            this.mounted = true;
        }
    }
</script>