<template>
    <div class="bg-growth rounded-full text-white p-2 fixed top-5 px-6 left-1/2 z-40" v-if="saved">{{ $t('saved') }}</div>
    <div v-if="modelData" class="w-full mb-7 -mt-3 relative">
        <slot v-if="!noarrow">
            <router-link to="../archivedModels" v-if="$route.query.routedFrom == 'archived'">
                <font-awesome-icon icon="fas fa-arrow-left" class="float-left text-lg p-2 text-button -mt-2" />
            </router-link>
            <router-link to="../models" v-else-if="$route.query.e">
                <font-awesome-icon icon="fas fa-arrow-left" class="float-left text-lg p-2 text-button -mt-2" />
            </router-link>
            <router-link to="../../settings" v-else>
                <font-awesome-icon icon="fas fa-arrow-left" class="float-left text-lg p-2 text-button -mt-2" />
            </router-link>
        </slot>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-10 max-w-screen-lg">
        <div class="hidden sm:block mt-2">
            <div v-if="$route.query.e || model_id_prop" class="flex items-center w-max bg-sepBorder bg-opacity-40 p-3 px-5 rounded">
                <div class="rounded-full h-24 w-24 bg-gray-200" :style="{ backgroundImage: `url(${modelData.avatar})`, backgroundSize: 'cover', backgroundPosition: 'center'}"></div>
                <div class="ml-8">
                    <h2 class="font-bold">{{modelData.username ? '@' + modelData.username : ''}}</h2>
                    <div class="my-1">
                        <slot v-if="modelData.platform==1"><span class="hidden">O</span><img src="@/assets/images/of_logo.png" title="Onlyfans" alt="Onlyfans" class="h-5"/></slot>
                        <slot v-else-if="modelData.platform==2"><span class="hidden">F</span><img src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="h-5"/></slot>
                    </div>
                    <span class="font-light">{{modelData.performerTop < 100 ? modelData.performerTop + '%':null}}</span>
                    <div @click="modal.type = 'scraping', modal.display = true" title="Here you can fetch data if it's missing from metrics or transactions" class="cursor-pointer rounded border border-button p-1.5 px-3 text-button font-bold mt-2 text-sm">Fetch data (Scraper)</div>
                </div>
            </div>
            <slot v-else>
                <h3 class="text-xl font-bold leading-relaxed">{{ $t('creator_modal.h1') }}</h3>
                <p>{{ $t('creator_modal.p1') }}</p>
            </slot>

            <h3 class="text-xl font-bold leading-relaxed mt-5">{{ $t('creator_modal.h2') }}</h3>
            <p v-html="$t('creator_modal.p2')"></p>
        </div>
        <div v-if="model_id_prop == null || modelData?.pair_id != null" class="text-panelText pb-1">
            <h2 class="text-2xl text-button font-bold mb-5" v-if="$route.query.e || model_id_prop">{{ $t('edit_creator') }}</h2>
            <h2 class="text-2xl text-button font-bold mb-5" v-else>{{ $t('add_creator') }}</h2>
            <div class="grid" :class="{'grid-cols-2 gap-5' : !$route.query.e && model_id_prop == null}">
                <div v-if="!$route.query.e && model_id_prop == null" class="w-full">
                    <label class="text-xs text-gray-500">{{ $t('platform') }}</label><br />
                    <select v-model="modelData.platform" @change="getTeamsAndGroups(modelData.platform)" class="w-full border-b border-sepBorder rounded p-1 px-3 outline-0">
                        <option value="1">Onlyfans</option>
                        <option value="2">Fansly</option>
                    </select>
                </div>
                <div>
                    <label class="text-xs text-gray-500 ">{{ $t('stage_name') }}</label>
                    <input class="border-b rounded border-sepBorder w-full p-1 px-3" type="text" :placeholder="$t('stage_name')" v-model="modelData.free.name"><font-awesome-icon icon="fas fa-exclamation" class="text-red-500 absolute -ml-4 mt-3" v-if="submitted && !modelData.free.name" />
                </div>
            </div>
            <div class="pb-2 rounded-lg">
                <div class="leading-none">
                    <div class="mt-3 text-xs text-gray-500">{{ $t('login_details') }}</div>
                    <div class="grid grid-cols-2 gap-3 my-1">
                        <div>
                            <input class="border-b rounded border-sepBorder w-full p-1.5 px-3 w-full" autocomplete="off" type="text" :placeholder="$t('reg_form.email')" v-model="modelData.free.email"><font-awesome-icon icon="fas fa-exclamation" class="text-red-500 absolute -ml-4 mt-3" v-if="submitted && !modelData.free.email" /><br />
                        </div>
                        <div class="relative w-full">
                            <input class="border-b rounded border-sepBorder w-full p-1.5 px-3 w-full" autocomplete="off" v-bind:type="displayPass.f ? 'text' : 'password'" :placeholder="$t('reg_form.password')" v-model="modelData.free.password">
                            <font-awesome-icon icon="fas fa-exclamation" class="text-red-500 absolute -ml-4 mt-3" v-if="submitted && !modelData.free.password" />
                            <font-awesome-icon :icon="seePass(displayPass.f)" class="absolute text-gray-700 right-2 mt-1 text-center p-1 cursor-pointer" @click="displayPass.f = !displayPass.f"  /> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="clear-both"></div>

            <section class="pb-2">
                <label class="text-xs text-gray-500 mt-1 block">{{ $t('location') }}</label>
                <select v-model="modelData.countryCode" class="border-b p-1 px-3 border-sepBorder rounded w-full outline-0 mt-1 mb-2">
                    <option value="0">{{ $t('pick_country') }}</option>
                    <option v-for="(country,cInd) in countryList" :key="cInd" :value="country.id">{{country.name}}</option>
                </select>

                <label class="text-xs text-gray-500">{{ $t('proxy') }} ({{ $t('if_needed') }}) <strong>+$1 {{ $t('pricing_section.per_month') }}</strong></label><br />
                <select v-if="modelData.proxy == null || modelData.proxy > 0" class="w-max p-1.5 px-3 border-b border-sepBorder rounded outline-0" v-model="modelData.proxy">
                    <option :value="null">{{ $t('select_proxy') }}</option>
                    <option :value="proxy.id" v-for="(proxy,index) in proxy_list" :key="index">{{ proxy.country }} - {{ proxy.proxy_address }}</option>
                </select>
                <div v-else class="flex rounded-2xl border w-max border-gray-400 px-2 items-center">
                    {{modelData.proxy}}
                    <button @click="removeProxy()" class="ml-2 p-1 text-red-400 cursor-pointer"><font-awesome-icon icon="fas fa-times" /></button>
                </div>
                <div class="text-xs mt-0.5 italic">{{ $t('proxy_notice') }}</div>

                <div class="clear-both mt-2">
                    <label class="text-xs text-gray-500 ">{{ $t('commission') }}</label><br />
                    <input class="p-1.5 px-3 border-b border-sepBorder rounded w-20 pb-1" id="modelCommission" type="number" min="0" max="100" :placeholder="$t('commission')" v-model="modelData.commission">
                    <select class="w-max sm:w-20 ml-4 p-1.5 px-3 border-b border-sepBorder rounded outline-0" v-model="modelData.commission_type">
                        <option value="%" selected>%</option>
                        <option value="$">$</option>
                    </select>
                </div>
                
                <div class="grid grid-cols-2 gap-5 mt-1" v-if="groups.length>0">
                    <div>
                        <label class="text-xs text-gray-500">{{ $t('group') }}</label><br />
                        <select class="p-1.5 px-3 border-b border-sepBorder rounded clear-both outline-0 w-full" v-model="modelData.group_id" v-if="groups.length>0" @change="updateSelectedGroup">
                            <option v-if="groups.length>1" value="0">{{ $t('all_groups') }}</option>
                            <option v-for="(group,index) in groups" :key="index" :value="group.id">{{group.name}}</option>
                        </select>
                    </div>
                    <div v-if="selectedTeams && selectedTeams.length>0">
                        <label class="text-xs text-gray-500 ">{{ $t('team') }}</label><br />
                        <select class="p-1.5 px-3 border-b border-sepBorder rounded clear-both outline-0 w-full" v-model="modelData.team_id">
                            <option v-if="selectedTeams.length>1" value="0">{{ $t('all_teams') }}</option>
                            <option v-for="(team,index) in selectedTeams" :key="index" :value="team.id">{{team.name}}</option>
                        </select>
                    </div>
                </div>
                <div v-else class="bg-opacity-50 p-2 pt-0.5 rounded mt-2" :class="submitted && !modelData.groupName ? 'bg-red-100' : 'bg-sepBorder'">
                    <label class="text-xs text-gray-500 ">{{ $t('group_name') }}*</label><br />
                    <div class="grid grid-cols-2 gap-1 sm:gap-5 py-1">
                        <input class="border-b bg-transparent border-sepBorder w-full py-1 mb-1" type="text" :placeholder="$t('group_name')" v-model="modelData.groupName">
                        <button @click="modal.type = 'group', modal.display = true" class="border-2 border-button text-button rounded p-1 font-bold w-full text-center text-sm sm:text-base">Group settings <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" class="ml-1" /></button>
                    </div>
                    <p class="text-xs">{{ $t('group_warning') }}</p>
                </div>

                <div v-if="modelData.free.tools && modelData.free.tools.length>0" @click="modal.type = 'tools', modal.display = true" class="border-b border-sepBorder relative rounded mt-2 w-full cursor-pointer">
                    <label class="text-xs text-gray-500">{{ $t('Tools') }}</label>
                    <span class="text-sm w-full sm:w-72 block whitespace-nowrap overflow-ellipsis overflow-hidden py-1">
                        <slot v-for="(tool,index) in modelData.free.tools" :key="index">
                            <slot v-if="tool.active==1">{{tool.name}}<slot v-if="index<modelData.free.tools.length-1">, </slot></slot>
                        </slot>
                    </span>
                    <font-awesome-icon icon="fa-xs fas fa-pen-to-square" class="absolute cursor-pointer right-3 top-5 text-panelsText" />
                </div>

                <div v-if="1==2" @click="openModelAccessModal()" class="bg-opacity-10 bg-white rounded p-2 px-3 w-max ml-10 mt-5 relative cursor-pointer">
                    <h1>Model dashboard access <font-awesome-icon icon="fas fa-arrow-up-right-from-square" class="ml-1.5"></font-awesome-icon></h1>
                </div>
                <button v-if="$route.query.routedFrom != 'archived'" @click="saveModel()" class="delay-75 duration-75 transition-all mt-5 bg-button rounded cursor-pointer p-2 px-5 text-buttonText text-center font-semibold">
                    <font-awesome-icon icon="fas fa-spinner" class="animate-spin" v-if="loading" /> {{buttonValue}}
                </button>
            </section>
        </div>
        <div v-else class="text-center">
            <font-awesome-icon icon="fas fa-spinner" class="animate-spin" />
        </div>
        <div class="mt-3 col-span-2 text-button mt-0 clear-both text-center text-sm sm:text-base">{{ $t('loginmate_notice_1') }} <a :href="$router.resolve({ name: 'Tools' }).href" target="_blank" class="font-bold underline">{{ $t('loginmate_notice_1') }}. </a>{{ $t('loginmate_notice_2') }}.<br /></div>
    </div>
    <div class="clear-both"></div>

    <transition name="zoom-fade">
        <section v-if="modal.display" @click="modal.display = false" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
            <section @click.stop class="bg-white rounded-lg w-full max-h-screen max-w-md sm:max-w-5xl mx-auto my-auto p-8 sm:p-12 z-50 overflow-auto relative">
                <font-awesome-icon @click="modal.display = false" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
                <groups-panel v-if="modal.type == 'group'" :noarrow="true" />
                <scraper-panel v-if="modal.type == 'scraping'" :model="modelData" />
                <tools-panel v-if="modal.type == 'tools'" :model="modelData" />
            </section>
        </section>
    </transition>
</template>

<script setup>
    import modelAccess from "@/components/settings/models/model_access.vue"
    import groupsPanel from "@/components/settings/groupsSettings.vue"
    import scraperPanel from "@/components/settings/models/scraping.vue"
    import toolsPanel from "@/components/settings/models/tools.vue"
</script>

<script>
    import { get_groups, archive_model, get_models,get_list_data,save_model, get_proxy_list, remove_proxy_from_model } from '@/api';
    export default {
        props: ['noarrow', 'model_id_prop'],
        emits: ['close'],
        components: {modelAccess, groupsPanel, toolsPanel, scraperPanel},
        computed: {
            selectedTeams() {
                if (this.modelData.group_id) {
                    const selectedGroup = this.groups.find(group => group.id == this.modelData.group_id);
                    return selectedGroup ? selectedGroup.children : [];
                }
                return [];
            }
        },
        data() {
            return {
                proxy_list: [{id:1, proxy_address: '0.0.0.0.', port: '0000', country: 'US'}],
                modal: {type:null, display: false },
                dialog: {type:1,show:false,title:'Change the tools settings',data:{tool:null,model:null}},
                groups: [],
                showVIP: false,
                modelData: {
                    pair_id: null,
                    groupName:null,
                    commission_type: "%",
                    free: {name: null, email:null, password:null, accountId: {filled:false, value:null},tools:[]},
                    paid: {active: true, name: null, email:null, password:null, accountId: {filled:false, value:null},tools:[]},
                    code: null,
                    platform: '1',
                    commission: null,
                    countryCode:0,
                    group_id:1,
                    team_id:null,
                    proxy:null
                },
                loading: false,
                buttonValue: this.$t('create_account'),
                saved:false,
                submitted:false,
                groupIndex:null,
                displayForm:false,
                displayPass: {f:false,p:false,m:false},
                countryList:null,
                modelPairID:this.$route.query.id,
                codePartOne: "001",
                modelAccessModal:false,
                modelCred: {email:null,password:null}
            }
        },
        methods: {
            async removeProxy() {
                if (confirm(this.$t('you_sure') + this.$t('remove_proxy_from_model'))) {
                    //send an API call to remove the proxy from the model
                    await remove_proxy_from_model(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.modelData.pair_id,this.modelData.proxy);

                    //when finished
                    this.modelData.proxy = null;
                }
            },
            seePass(condition) {
                return !condition ? 'fas fa-eye' : 'fas fa-eye-slash';
            },
            updateSelectedGroup() {
                this.modelData.team_id = 0;  // reset the selected team whenever the group changes
            },
            pad(num, size) {
                while (num.length < size) num = "0" + num;
                return num;
            },
            async saveModel() {
                if (this.modelData.groupName || this.groups.length == 0) {
                    this.modelData.group_id = null;
                }

                this.submitted = true;
                
                if (this.modelData.free.name && this.modelData.free.email && this.modelData.free.password && (this.modelData.groupName || this.modelData.group_id)) {
                    this.loading = true
                    if (this.modelPairID) {
                        this.buttonValue = `${this.$t('updating')}...`;
                        let save_b_label = this.$t('save_changes');
                        setTimeout(function() {
                            this.loading = false;
                            this.buttonValue = save_b_label;
                        }.bind(this), 1000);
                    }else {
                        this.buttonValue = `${this.$t('creating')}...`;
                        let create_b_label = this.$t('create_account');
                        setTimeout(function() {
                            this.loading = false;
                            this.buttonValue = create_b_label;
                        }.bind(this), 1000);
                    }
                } else {
                    alert(this.$t('fill_required_fields'));
                    return;
                }
                
                const result = await save_model(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.modelData);
                if (result && result.message) {
                    alert(result.message)
                    return;
                } else {
                    if(!this.modelPairID) {this.modelData = {platform:this.modelData.platform, free: {name: null,accountId: {filled:false,value:null}},paid: {name: null,accountId: {filled:false,value:null}},code: null}; this.submitted = false;}
                    this.saved = true;
                    if (!this.noarrow) {
                        setTimeout(() => (this.saved = false, this.$router.push({name:'models'}), 2000));
                    } else {
                        this.$router.push({name: 'Creators'});
                        setTimeout(() => {this.saved = false;}, 500);
                        setTimeout(() => this.$emit('close'), 500)
                    }
                }
            },
            async paidToArchiveAndBack(active) {
                await archive_model(this.$cookies.get('userToken'),this.$cookies.get('c_id'),active,null,this.modelData.paid.id);
                this.modelData.paid.active = active;
            },
            async getTeamsAndGroups() {
                this.groups = await get_groups(this.$cookies.get("userToken"),this.$cookies.get('c_id'));
                if (this.groups && this.groups.length) {this.displayForm = true;}
                if (this.groups.length == 1) {this.modelData.group_id = this.groups[0].id;}
            }
        },
        async created() {
            //get list of countries
            this.countryList = await get_list_data(this.$cookies.get('userToken'),this.$cookies.get('c_id'),'getCountryCodes',null,null,null,null,null);
            if (this.model_id_prop > 0) {this.modelPairID = this.model_id_prop;}
            if (this.modelPairID) {
                this.buttonValue = this.$t('save_changes');
                this.modelData = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),null,this.modelPairID);
                if (this.modelData && this.modelData.paid && this.modelData.paid.email) {
                    this.showVIP = true;
                }
                this.getTeamsAndGroups();
            } else {
                // add a new model
                this.getTeamsAndGroups();
            }
            this.proxy_list = await get_proxy_list(this.$cookies.get('userToken'),this.$cookies.get('c_id'));
        }
    }
</script>