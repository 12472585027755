<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full mb-4 relative">
      <h2 class="mt-2 text-xl text-contentText font-semibold text-center">{{ $t('scraper_modal.h1') }}</h2>
    </div>

    <section class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        <section class="">
            <div v-if="model.avatar" class="flex items-center w-max bg-sepBorder bg-opacity-40 p-3 px-5 rounded">
                <div class="rounded-full h-24 w-24" :style="{ backgroundImage: `url(${model.avatar})`, backgroundSize: 'cover', backgroundPosition: 'center' }"></div>
                <div class="ml-5">
                    <h2 class="font-bold truncate w-36">{{model.username ? '@' + model.username : null}}</h2>
                    <div class="my-1">
                        <slot v-if="model.platform==1"><span class="hidden">O</span><img src="@/assets/images/of_logo.png"  class="h-5"/></slot>
                        <slot v-else-if="model.platform==2"><span class="hidden">F</span><img src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="h-5"/></slot>
                    </div>
                    <span class="font-light">{{model.performerTop < 100 ? model.performerTop + '%':null}}</span>
                </div>
            </div>
        </section>
        <div v-if="loading" class="w-full text-center">
            <font-awesome-icon icon="fa-solid fa-spinner" class="mt-5 animate-spin text-xl" />
        </div>
        <section v-else class="mt-5 sm:mt-0 lg:col-span-2 grid grid-cols-3 lg:grid-cols-5 gap-1.5">
            <div v-for="(date,index) in scrapedTable" :key="index" class="border border-sepBorder p-1 px-1.5 rounded">
                <span class="text-xs font-light">{{date.date}}</span>
                <div @click="scrapeData(date.date, 0, index)" class="cursor-pointer text-button px-1 w-max float-right text-xl" v-if="!date.scraped && hasSession"><font-awesome-icon icon="fa-solid fa-download" /></div>
                <div class="cursor-pointer text-button px-1 w-max float-right text-xl" v-else-if="date.scraped == 'loading'"><font-awesome-icon icon="fa-solid fa-spinner" class="animate-spin text-lg" /></div>
                <div @click="scrapeAgain(date.date, 0, index)" class="cursor-pointer text-growth text-center px-1 w-max float-right text-xl" v-else-if="date.scraped"><font-awesome-icon icon="fa-solid fa-check" /></div>
            </div>
        </section>
        <div class="col-span-full mt-5 text-decline text-right">
            <span v-if="!hasSession">{{ $t('scraper_modal.p1') }}</span>
        </div>
    </section>
</template>

<script>
    import { scraped_models, get_sessions, scrape_data } from "@/api.js";
    export default {
        props: ['model'],
        data() {
            return {
                loading: false,
                scrapedTable: [],
                hasSession: true,
                date_range: [this.$moment().subtract(14, 'days').format('YYYY-MM-DD'), this.$moment().subtract(1, 'day').format('YYYY-MM-DD')],
            }
        },
        methods: {
            scrapeAgain(date, filter, index) {
                if (confirm('scraper_modal.confirm')) {
                    this.scrapeData(date, filter, index);
                }
            },
            async scrapeData(date, filter, i) {
                this.scrapedTable[i].scraped = "loading";
                let result = await scrape_data(this.$cookies.get('userToken'), this.$cookies.get('c_id'), this.model.free.id, this.model.platform, date, filter);
                if (result.success) {
                    this.scrapedTable[i].scraped = true;
                } else {
                    this.scrapedTable[i].scraped = false;
                    if (result.message) {
                        alert(result.message);
                    }
                }
            }
        },
        async mounted() {
            this.loading = true;
            this.scrapedTable = await scraped_models(this.$cookies.get('userToken'), this.$cookies.get('c_id'), this.model.free.id, this.date_range[0], this.date_range[1]);  
            let session = await get_sessions(this.$cookies.get('userToken'), this.$cookies.get('c_id'), this.model.pair_id);
            if (session.length > 0) {
                this.hasSession = session[0].accounts[0].sessions.length > 0;
            }
            setTimeout(() => {
                this.loading = false;
            }, 500);
        }
    }
</script>