<template>
    <tr v-if="metricsData && displayColumns && blockedColumns" class="bg-tableRow1">
        <td class="z-9 bg-tableRow1">Average</td>
        <td v-if="!selectedModel">{{addSpace(parseFloat(metricsData.models).toFixed(2))}}</td>
        <td></td>
        <slot v-if="displayColumns.free">
            <td :class="{'text-growth':metricsData.free.fans_expired<0,'text-decline':metricsData.free.fans_expired>0}">{{addSpace(parseFloat(metricsData.free.fans_expired).toFixed(2))}}</td>
            <td :class="{'text-growth':metricsData.free.new>0,'text-decline':metricsData.free.new<0}">{{addSpace(parseFloat(metricsData.free.new).toFixed(2))}}</td>
        </slot>
        <td :class="{'text-growth':metricsData.free.change>0,'text-decline':metricsData.free.change<0}">{{addSpace(parseFloat(metricsData.free.change).toFixed(2))}}</td>
        <td>{{addSpace(parseInt(metricsData.free.likes))}}</td>
        <slot v-if="metricsData.free.renewOn">
            <td v-if="blockedColumns.renewOn == 1 && displayRenewOn">{{parseFloat(metricsData.free.renewOn).toFixed(2)}}</td>
        </slot>
        <slot v-else>
            <td v-if="blockedColumns.renewOn == 1 && displayRenewOn">{{parseFloat(metricsData.paid.renewOn).toFixed(2)}}</td>
        </slot>
        <td>{{parseFloat(metricsData.free.messages).toFixed(2)}}</td>
        <td v-if="blockedColumns.subsPaid == 1 && displaySubs">$ {{addSpace((metricsData.paid.subsEarnings + metricsData.free.subsEarnings).toFixed(2))}}</td>
        <td>$ {{addSpace(metricsData.free.transactionSum.toFixed(2))}}</td>
        <td>$ {{addSpace((metricsData.free.transactionSum + metricsData.paid.transactionSum).toFixed(2))}}</td>
        <slot v-if="!selectedModel">
            <td :class="{'text-growth':metricsData.free.change>0,'text-decline':metricsData.free.change<0}">{{addSpace((metricsData.free.change/metricsData.models).toFixed(2))}}</td>
            <td>$ {{addSpace(((metricsData.free.transactionSum + metricsData.paid.transactionSum)/metricsData.models).toFixed(2))}}</td>
        </slot>
    </tr>
</template>

<script>
    export default {
        props: ['displayRenewOn','displaySubs','blockedColumns','selectedPlatform','selectedModel','metricsData','displayColumns'],
        methods: {
            addSpace(nStr) {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + " " + '$2');
                }
                return x1 + x2;
            },
        }
    }
</script>

<style scoped>
    @import "@/assets/css/metrics.css";
    td {
        padding:5px;
        border:1px solid #D1D5DB;
        text-align:center;
    }
</style>