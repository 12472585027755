<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full relative">
      <router-link :to="'../Settings'">
        <font-awesome-icon icon="fas fa-arrow-left" class="absolute float-left text-lg p-2 text-button -mt-2" />
      </router-link>
      <h2 class="mt-2 text-xl text-contentText font-semibold text-center">Edit an employee</h2>
    </div>

    <div>
        <table class="text-tableText bg-tableBG rounded" id="employeeTable">
            <tr>
                <th class="cursor-pointer" @click="sortTable('id','ASC')">Code &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':$cookies.get('orderColumnE') == 'id'}" /></th>
                <th class="w-1/3 cursor-pointer" @click="sortTable('name','ASC')">Name &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':$cookies.get('orderColumnE') == 'name'}" /></th>
                <th class="cursor-pointer" @click="sortTable('groupName','ASC')">Assignment &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':$cookies.get('orderColumnE') == 'groupName'}" /></th>
                <th class="px-5 cursor-pointer" @click="sortTable('positionName','ASC')">Position &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':$cookies.get('orderColumnE') == 'positionName'}" /></th>
                <th class="px-3">Suspend</th>
                <th class="px-3">Archive</th>
            </tr>
            <slot v-if="myself">
                <tr class="cursor-pointer hover:bg-sepBorder" v-for="(empl, index) in emplData" :key="index" :class="{'opacity-50':empl.active== 0,'bg-tableRow1':index%2==0}">
                    <td @click="navigateToEmployeeForm(empl)">{{ empl.id }}</td>
                    <td @click="navigateToEmployeeForm(empl)"><span class="w-4 h-4 rounded-full mt-1 float-left border border-sepBorder" :style="'background:'+empl.color"></span> {{ empl.name }}</td>
                    <td @click="navigateToEmployeeForm(empl)" v-if="empl.groupName">{{ empl.groupName }} <span v-if="empl.teamName">- {{empl.teamName}}</span></td>
                    <td @click="navigateToEmployeeForm(empl)" v-else><span v-if="empl.attached > 0">{{empl.attached}} <font-awesome-icon icon="fa-sm fas fa-link" /></span></td>
                    <td @click="navigateToEmployeeForm(empl)" class="px-5">{{ empl.positionName }}</td>

                    <slot v-if="((parseInt(empl.orderNo) > parseInt(myself.orderNo)) || (empl.id == myself.id))">
                        <td class="cursor-pointer text-center" @click="emplUnSuspend(index)" v-if="empl.suspended == 1"><font-awesome-icon icon="fas fa-circle-play" class="text-red-500" /></td>
                        <td class="cursor-pointer text-center" @click="emplSuspend(index)" v-else><font-awesome-icon icon="fas fa-circle-pause" class="text-green-500" /></td>
                    </slot>
                    <td v-else></td>
                    <slot v-if="((parseInt(empl.orderNo) > parseInt(myself.orderNo)) || (empl.id == myself.id)) && empl.suspended == 1">
                        <td class="cursor-pointer text-center" @click="emplDeArchive(index)" v-if="empl.active == 0">Return from archive <font-awesome-icon icon="fas fa-undo" /></td>
                        <td class="cursor-pointer text-center" @click="emplArchive(index)" v-else><font-awesome-icon icon="fas fa-archive" /></td>
                    </slot>

                    <td v-else></td>
                </tr>
            </slot>
        </table>
    </div>
</template>

<script setup>
    import employeesForm from '@/components/settings/employeesForm.vue'
</script>

<script>
    import { archive_user, get_users } from "@/api"
    export default {
        components() {
            employeesForm
        },
        data() {
            return { 
                emplData: [],
                emplID: null,
                sortColumn: null,
                loading: false,
                myself:null
            }
        },
        methods: {
            navigateToEmployeeForm(empl) {
                if (((parseInt(empl.orderNo) > parseInt(this.myself.orderNo)) || (empl.id == this.myself.id))) {
                    this.$router.push({ name: 'employeesForm', query: { e: true, id: empl.id } });
                }
            },
            sortTable(column,order) {
                let orderMultiplier = order === 'ASC' ? 1 : -1;
                if (this.$cookies.get('orderColumnE') != column) {
                    this.$cookies.set('orderColumnE',column);
                }
                if (this.emplData.length>0) {
                    this.emplData.sort((a, b) => {
                        const valueA = a[column] ? a[column].toString() : "";
                        const valueB = b[column] ? b[column].toString() : "";

                        const leadingZeroA = valueA.padStart(10, '0');
                        const leadingZeroB = valueB.padStart(10, '0');

                        return order === 'ASC' ? leadingZeroA.localeCompare(leadingZeroB) : leadingZeroB.localeCompare(leadingZeroA);
                    });
                }
            },
            async emplArchive(index) {
                if (confirm("Are you sure you want to archive this user?")) { 
                    this.emplData[index].active=0 
                    await archive_user(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.emplData[index].active,this.emplData[index].id);
                }
            },
            async emplDeArchive(index) {
                if (confirm("Are you sure you want to return this user from archive?")) { 
                    this.emplData[index].active=1 
                    await archive_user(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.emplData[index].active,this.emplData[index].id);
                }
            },
            suspendFunction(index,state) {
                this.emplData[index].suspended=state
                this.$axios.post(baseURL+"settingspost/", {
                    action: "employeeSuspend",
                    employeeID: this.emplData[index].id,
                    suspended: state,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then(() => {
                }, (error) => {
                    console.log(error);
                });
            },
            emplSuspend(index) {
                if (confirm("Are you sure you want to suspend this user?")) {
                    this.suspendFunction(index,1);
                }
            },
            emplUnSuspend(index) {
                if (confirm("Are you sure you want to suspend this user?")) {
                    this.suspendFunction(index,0);
                }
            }
        },
        async created() {
            this.loading = true;
            this.emplData = await get_users(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,null,null);
            if (this.$cookies.get('orderColumnE')) {
                this.sortTable(this.$cookies.get('orderColumnE'),'ASC')
            }
            
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
                }).then((result) => {
                    if (result.data.selectBranch) {
                        let prev = this.$route.path;
                        this.$router.push({name:'Branches', query: { prevRoute: prev } });
                    } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                        this.$cookies.set('userToken','');
                        window.location.href = '/';
                    }
                    if (result.data.myself) {this.myself = result.data.myself;}
                    if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                    if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
                }, (error) => {
                console.log(error);
            });
            this.loading = false;
        }
    }
</script>

<style scoped>
    td,th {
        padding:5px;
        border:1px solid #D1D5DB;
        text-align:center;
    }
</style>