<template>
  <div class="container mx-auto">
    <logoComponent class="pt-2 text-black" />
    <div class="w-full h-screen flex items-center justify-center">
      <div class="block mx-auto -mt-20">
        <div class="mt-5 font-semibold text-xl">
          <p v-if="!verified"><font-awesome-icon icon="fas fa-spinner" class="animate-spin mr-2 text-xl" /> Verification in progress</p>
          <slot v-else>
            <p class="p-2 px-4 rounded text-white bg-growth"><font-awesome-icon icon="fas fa-circle-check" class="mr-2" /> Verification successfull</p>
            <div class="text-sm w-max mx-auto mt-3 flex">Redirecting <div class="ml-2 transition ease-in-out animate-bounce">...</div></div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import logoComponent from "@/components/logo.vue"
</script>
<script>
import { verifyEmail } from '@/api';
export default {
  name: 'Verify',
  data() {
    return {
      verified: false
    };
  },
  components: {
    logoComponent
  },
  async mounted() {
    if (this.$route.query.code) {
        const response = await verifyEmail(this.$route.query.code);
        this.verified = response;
        setTimeout(() => {
          this.$router.push('/');
        }, 3500);
    } else {
      this.$router.push('/');
    }
  }
}
</script>