<template>
    <div class="text-sm sm:text-base mt-2">
        <section class="text-textOnBkg tracking-widest uppercase leading-none block bg-transparent relative ml-6 w-max text-base font-bold sm:float-left" :class="{'sm:text-lg':layout == 2,'sm:text-lg mt-2.5':layout == 1}">
            {{headline}}
            <div v-if="subHeadline" class="font-light text-sm mt-0">{{subHeadline}}</div>
        </section>
        <section class="bg-squares w-max text-contentText rounded ml-5 py-0.5 leading-6 px-2 float-left leading-5 border border-sepBorder">
            <label class="text-sm font-normal">Hours:</label><br /><span class="font-semibold">{{panelData.hours}}</span>
        </section>
        <section class="bg-squares w-max text-contentText rounded ml-2 py-0.5 leading-6 px-2 sm:px-4 float-left leading-5 border border-sepBorder">
            <label class="text-sm font-normal">Shifts:</label><br /><span class="font-semibold">{{panelData.shifts}}</span>
        </section>
        <section class="bg-squares w-max text-contentText rounded ml-2 py-0.5 leading-6 px-2 sm:px-4 float-left leading-5 border border-sepBorder">
            <label class="text-sm font-normal">Earnings:</label><br /><span class="font-semibold">$ {{String(parseFloat(panelData.earnings).toLocaleString('en-US')).replace(/,/g,' ')}}</span>
        </section>
        <section class="bg-squares w-max text-contentText rounded ml-2 py-0.5 leading-6 px-2 sm:px-4 float-left leading-5 border border-sepBorder">
            <label class="text-sm font-normal">Salaries:</label><br /><span class="font-semibold">$ {{String(parseFloat(panelData.salary).toLocaleString('en-US')).replace(/,/g,' ')}}</span>
        </section>
        <section class="bg-squares w-max text-contentText rounded ml-2 py-0.5 leading-6 px-2 sm:px-4 float-left leading-5 border border-sepBorder">
            <label class="text-sm font-normal">Words:</label><br /><span class="font-semibold">{{panelData.word_count}}</span>
        </section>
        <section class="bg-squares w-max text-contentText rounded ml-2 py-0.5 leading-6 px-2 sm:px-4 float-left leading-5 border border-sepBorder">
            <label class="text-sm font-normal">Cost ratio:</label><br /><span class="font-semibold">{{panelData.costRatio}} %</span>
        </section>
        <div class="clear-both"></div>
        <hr v-if="lineSeparator" class="mt-2 mb-1 border-0 border-t border-squares" />
    </div>
</template>

<script>
    export default {
        props: ['panelData','headline','subHeadline','lineSeparator','layout'],
    }
</script>