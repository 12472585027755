<template>
    <div>
        <div class="bg-green-300 rounded-full text-black p-2 fixed z-50 top-5 px-6 left-1/2" v-if="saved">{{ $t('saved') }}</div>

        <div class="w-full mb-4 relative">
            <slot v-if="noarrow_band==true">
                <router-link to="../employees" v-if="$route.query && $route.query.e">
                    <font-awesome-icon icon="fas fa-arrow-left" class="absolute float-left text-lg p-2 text-button -mt-2" />
                </router-link>
                <router-link to="../../settings" v-else>
                    <font-awesome-icon icon="fas fa-arrow-left" class="absolute float-left text-lg p-2 text-button -mt-2" />
                </router-link>
            </slot>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-10 w-full sm:max-w-3xl">
            <div class="hidden sm:block mt-5">
                <h1 class="text-xl font-bold mb-2">{{ $t('employees_modal.h1') }}</h1>
                <p class="leading-relaxed block">{{ $t('employees_modal.p1') }}</p>
                <br />
                <h1 class="text-xl font-bold mb-2">{{ $t('employees_modal.h2') }}</h1>
                <p class="leading-relaxed block">{{ $t('employees_modal.p2') }}</p>
            </div>
            <div v-if="employee_id_prop == null || emplData?.name != null" class="mt-0">
                <h2 class="text-2xl text-button font-semibold" v-if="$route.query && $route.query.e">{{ $t('employees_modal.edit_employee_info') }}</h2>
                <h2 class="text-2xl text-button font-semibold" v-else>{{ $t('add_employee') }}</h2>
                <div class="mb-1">
                    <label class="text-xs text-gray-500">{{emplData.name? $t('name') :null}}</label><br />
                    <input type="text" placeholder="Name" v-model="emplData.name" class="border-b border-gray-400 pb-1" />
                    <font-awesome-icon icon="fas fa-exclamation" class="text-red-500 absolute -ml-4 mt-2" v-if="formSubmit && !emplData.name" />
                </div>

                <div>
                    <label class="text-xs text-gray-500">{{emplData.email? $t('email') :null}}</label><br />
                    <input type="text" class="border-b border-gray-400 pb-1" placeholder="Email" v-model="emplData.email" @blur="validateEmail(emplData.email)" />
                    <font-awesome-icon icon="fas fa-exclamation" class="text-red-500 absolute -ml-4 mt-2" v-if="formSubmit && !emplData.email" />
                </div>

                <div class="w-max">
                    <div v-if="passwordEditing || !emplData.id">
                        <label class="text-xs text-gray-500">{{emplData.password? $t('password') :null}}</label><br />
                        <input type="text" class="border-b border-gray-400 pb-1" :placeholder="$t('password')" v-model="emplData.password" />
                        <font-awesome-icon icon="fas fa-exclamation" class="text-red-500 absolute -ml-4 mt-1" v-if="formSubmit && !emplData.password" />
                    </div>
                    <div v-else class="cursor-pointer bg-button mt-4 p-1 px-3 text-buttonText rounded" @click="passwordEditing = true">{{ $t('edit_password') }}</div>
                </div>

                <div class="clear-both"></div>

                <div class="float-left mr-5 mt-2">
                    <label class="text-xs text-gray-500">{{emplData.positionID ? $t('role') : null}}</label><br />
                    <select placeholder="Position" class="border-b border-gray-400 pb-1 outline-0 w-52" v-model="emplData.positionID" @change="positionChange()">
                        <option value="0">{{ $t('position') }}</option>
                        <option v-for="(type,index) in accountTypeList" :key="index" :value="type.id">{{ type.name }}</option>
                    </select>
                    <font-awesome-icon icon="fas fa-exclamation" class="text-red-500 absolute -ml-6 mt-2" v-if="formSubmit && !emplData.positionID" />
                </div>
                <div @click="permissionsModal = true" class="float-left cursor-pointer mt-5 p-1 px-3 border-2 border-button rounded text-button font-bold">Permissions <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" class="ml-1" /></div>

                <div class="float-left hidden">
                    <label class="text-xs text-gray-500">{{ $t('hourly_wage') }}</label><br />
                    $ <input type="text" class="border-b border-gray-400 pb-1 w-24 mr-20" placeholder="Wage" v-model="emplData.hourly_wage">
                </div>

                <div class="clear-both"></div>

                <slot v-if="1==2">
                    <label class="text-xs text-gray-500">{{ $t('salary_system') }}</label><br />
                    <input type="text" class="border-b border-gray-400 pb-1" :placeholder="$t('salary_system')" v-model="emplData.salarySystem">
                </slot>

                <div class="clear-both"></div>

                <slot v-if="[9, 5, 6, 16, 17, 24].includes(emplData.positionID)">
                    <div class="mt-2">
                        <label class="text-xs text-gray-500">{{ $t('add_creator') }}</label><br />
                        <select class="border-b border-gray-400 pb-1 outline-0 float-left w-52" v-model="sm.pID">
                            <option value="-1">{{ $t('select_creator') }}</option>
                            <slot v-for="(model,index) in modelsList" :key="index">
                                <option v-if="(model.platform != 1 && model.platform != 2) || (model.free && model.free.active)" :value="index">{{model.free?.name??model.name}} - {{model.platformName}}</option>
                            </slot>
                        </select>
                        <div @click="addModel()" class="cursor-pointer bg-button float-left w-max text-buttonText p-1.5 ml-3 px-3 rounded"><font-awesome-icon icon="fas fa-plus" /> {{ $t('add') }}</div>
                    </div>
                    <div class="clear-both"></div>

                    <slot v-if="emplData.attachedModels && emplData.attachedModels.length>0">
                        <table class="mt-2 w-full border border-gray-400">
                            <thead>
                                <tr class="border border-gray-400">
                                    <th class="text-center border border-gray-400">{{ $t('platform') }}</th>
                                    <th class="text-center border border-gray-400">{{ $t('name') }}</th>
                                    <th class="text-center border border-gray-400">{{ $t('code') }}</th>
                                    <th class="text-center border border-gray-400">{{ $t('actions') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <slot v-for="(model, index) in emplData.attachedModels" :key="index">
                                    <tr v-if="(model.platform != 1 && model.platform != 2) || model.free">
                                        <td class="text-center border border-gray-400">{{ model.platformName }}</td>
                                        <td class="text-center border border-gray-400">{{ model.free?.name??model.name }}</td>
                                        <td class="text-center border border-gray-400">{{ model.code??model.id }}</td>
                                        <td  class="text-center border border-gray-400">
                                            <font-awesome-icon icon="fas fa-xmark" @click="remove_from_attached(index);" class="text-red-400 cursor-pointer" />
                                        </td>
                                    </tr>
                                </slot>
                            </tbody>
                        </table>
                    </slot>

                </slot>
                <slot v-else-if="emplData.positionID>0 && (!(emplData.positionID == 2 || emplData.positionID == 3 || emplData.positionID == 8 || emplData.positionID == 13))">
                    <div class="mt-2 float-left mr-5">
                        <label class="text-xs text-gray-500">{{ $t('group') }}</label><br />
                        <select class="border-b border-gray-400 mt-1 clear-both w-52 pb-1 outline-0" v-model="emplData.groupID" v-if="groups.length>0" @change="updateSelectedGroup">
                            <option v-if="groups.length>1" value="0">{{ $t('all_groups') }}</option>
                            <option v-for="(group,index) in groups" :key="index" :value="group.id">{{group.name}}</option>
                        </select>
                    </div>
                    <div class="mt-2 float-left mr-5" v-if="selectedTeams && selectedTeams.length>0">
                        <label class="text-xs text-gray-500">{{ $t('team') }}</label><br />
                        <select class="border-b border-gray-400 mt-1 clear-both w-52 pb-1 outline-0" v-model="emplData.teamID">
                            <option v-if="selectedTeams.length>1" value="0">{{ $t('all_teams') }}</option>
                            <option v-for="(team,index) in selectedTeams" :key="index" :value="team.id">{{team.name}}</option>
                        </select>
                    </div>
                </slot>
                
                <div class="clear-both"></div>

                <div class="mt-3 bg-transparent">
                    <label class="text-xs text-gray-500">{{ $t('employee') }} {{ $t('tag') }}: </label><br />
                    <div class="w-36 h-5 block rounded mt-1 cursor-pointer float-left bg-gray-200" :style="'background:'+emplData.color"></div>
                    <font-awesome-icon icon="fas fa-refresh" @click="emplData.color = getRandomHexColor();" class="cursor-pointer mt-1 ml-2 float-left" />
                </div>

                <section class="float-right mt-10">
                    <div v-if="error_message" class="text-red-500 mb-2">* {{error_message}}</div>
                    <button @click="saveForm()" :disabled="error_message" :class="{'cursor-not-allowed bg-opacity-50':error_message, 'cursor-pointer':!error_message}" class="delay-75 duration-75 transition-all bg-button rounded p-2 mb-4 px-3 text-buttonText text-center font-semibold">
                        {{buttonValue}}
                    </button>
                </section>
            </div>
            <div v-else class="text-center">
                <font-awesome-icon icon="fas fa-spinner" class="animate-spin" />
            </div>
        </div>
    </div>

    <transition name="zoom-fade">
        <section v-if="permissionsModal" @click="permissionsModal = false" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
            <section @click.stop class="bg-white rounded-lg w-full max-h-screen max-w-md sm:max-w-5xl mx-auto my-auto p-8 sm:p-12 z-50 overflow-auto relative">
                <font-awesome-icon @click="permissionsModal = false" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
                <privileges-panel :noarrow="true" :position_id="emplData.positionID" />
            </section>
        </section>
    </transition>
</template>

<script setup>
    import employees from '@/components/settings/employees.vue'
    import privilegesPanel from '@/components/settings/privileges.vue'
</script>

<script>
    import { get_groups, check_if_email_used, get_users, save_user, get_models } from "@/api.js"
    export default {
        props: ['noarrow', 'employee_id_prop'],
        emits: ['close'],
        data() {
            return {
                groups: [{hasChildren:false,children:[]}],
                marketingTeamList: [],
                accountTypeList: [],
                loading: true,
                buttonValue: this.$t('create_account'),
                error_message: null,
                emplData: {attachedModels:[],teamID:0,groupID:0,positionID:0},
                passwordEditing:false,
                formSubmit: false,
                singleGroup: [],
                emplID: null,
                saved:false,
                noGroups:false,
                noMarkGroups:false,
                groupIndex:null,
                timeNow:null,
                color: null,
                suckerCanvas: null,
                suckerArea: [],
                modelsList:[],
                sm:{pID:-1},
                noarrow_band: this.$props.noarrow?? true,
                permissionsModal:false
            }
        },
        computed: {
            selectedTeams() {
                if (this.emplData.groupID) {
                    const selectedGroup = this.groups.find(group => group.id == this.emplData.groupID);
                    return selectedGroup ? selectedGroup.children : [];
                }
                return [];
            }
        },
        components() {
            employees,
            privilegesPanel    
        },
        methods: {
            checkIcon(condition) {
                return condition ? 'fas fa-check-square' : 'fas fa-square';
            },
            updateSelectedGroup() {
                this.emplData.teamID = 0;  // reset the selected team whenever the group changes
            },
            addModel() {
                if (this.sm.pID > -1) {
                    this.emplData.attachedModels = this.emplData.attachedModels || [];
                    this.emplData.attachedModels.push(this.modelsList[this.sm.pID]);
                    this.modelsList.splice(this.sm.pID,1)
                }
                console.log(this.emplData.attachedModels);
                this.sm.pID = -1;
            },
            remove_from_attached(index) {
                this.modelsList.push(this.emplData.attachedModels[index]);
                this.emplData.attachedModels.splice(index,1);
            },
            positionChange() {
                this.emplData.groupID = null;
                this.emplData.teamID = null;
            },
            changeColor(color) {
                const { r, g, b, a } = color.rgba
                this.emplData.color = `rgba(${r}, ${g}, ${b}, ${a})`
            },
            calcTimeNow() {
                this.timeNow = this.$moment().utcOffset(this.emplData.timeZone*60).format("HH:mm")
            },
            switchView(event, selectedIndex) {
               this.groupIndex = selectedIndex-1;
               this.emplData.teamID = null;
            },
            async validateEmail(email) {
                if (email.length>1) {
                    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if(re.test(String(email).toLowerCase())){
                        this.error_message = null;
                        if (this.$route.query && this.$route.query.e) {
                            return true;
                        } else {
                            const response = await check_if_email_used(email, this.$cookies.get('userToken'), this.$cookies.get('c_id'));
                            if (response.success) {
                                return true;
                            } else {
                                this.error_message = response?.message
                            }
                        }
                    } else {
                        this.error_message = this.$t('invalid_email');
                        return false;
                    }
                } else {
                    return true;
                }
            },
            resetForm() {
                this.emplData = {attachedModels:[],teamID:0,groupID:0,positionID:0};
                this.emplData.groupID = 0;
                this.emplData.color = this.getRandomHexColor();
                this.emplData.timeZone = this.$moment().utcOffset()/60;
                this.timeNow = this.$moment().utcOffset(this.emplData.timeZone*60).format("HH:mm")
            },
            async saveForm() {
                this.formSubmit = true
                this.loading = true
                if (this.emplID) {
                    this.buttonValue = `${this.$t('updating')}...`
                    let b_l = this.$t('update_account');
                    setTimeout(() => (this.loading = false, this.buttonValue = b_l), 1000);
                } else {
                    this.buttonValue = `${this.$t('creating')}...`;
                    let b_l = this.$t('create_account');
                    setTimeout(() => (this.loading = false, this.buttonValue = b_l), 1000);
                }
                if (!((this.emplData.id || (!this.emplData.id && this.emplData.password)) && this.emplData.positionID && this.emplData.name && this.emplData.email && this.validateEmail(this.emplData.email))) {
                    return false;
                }

                const result = await save_user(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.emplData);
                if (result && result.message) {
                    alert(result.message);
                } else {
                    if (!this.emplID) {
                        this.resetForm();
                    }
                    this.formSubmit = false;
                    this.saved = true;
                    setTimeout(() => (this.saved = false),1500);
                    setTimeout(() => (this.$emit('close')), 1500);
                }
                this.loading = false;
            },
            async getTeamsAndGroups() {
                if (this.modelsList.length == 0) {
                    let models = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,null,1,0);
                    let socials = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,null,1,1);
                    this.modelsList = models?.concat(socials);
                }
                this.groups = await get_groups(this.$cookies.get("userToken"),this.$cookies.get('c_id'));
            },
            getRandomHexColor() {
                let getRandomByte = () => Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
                return `#${getRandomByte()}${getRandomByte()}${getRandomByte()}`;
            },
            async getListData(whichData,group,team,mSummary = null,platform = null) {
                return new Promise(async (resolve, reject) => {
                    try {
                      const result = await this.$axios.get(baseURL+"lists/", {
                            params: {
                                build: whichData,
                                groupFilter: group,
                                teamFilter: team,
                                msummary:mSummary,
                                userToken: this.$cookies.get('userToken'),
                                companyID: this.$cookies.get('c_id'),
                            }
                        })
                      resolve(result.data);
                    } catch (error) {
                      reject(error);
                    }
                });
            }
        },
        async created() {
            this.accountTypeList = await this.getListData('userTypes',null,null,null);
            const models = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,null,1,0);
            const socials = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,null,1,1);
            this.modelsList = models.concat(socials);

            if (this.emplID) {
                this.buttonValue = this.$t('update_account');
                this.emplData = await get_users(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,this.emplID,null,null,0);

                this.emplData.color = this.emplData.color ?? this.getRandomHexColor();
                if (this.emplData.timeZone == null) {this.emplData.timeZone = this.$moment().utcOffset()/60}
                this.timeNow = this.$moment().utcOffset(this.emplData.timeZone*60).format("HH:mm")
                await this.getTeamsAndGroups();
            } else {
                // add a new employee
                this.resetForm();
                this.getTeamsAndGroups();
            }

            
        },
        async mounted() {
            this.emplID = await this.$props.employee_id_prop?? this.$route.query.id??null;
        }
    }
</script>

<style scoped>
    .zoom-fade-enter-active {
        transition: all .3s ease;
    }
    .zoom-fade-leave-active {
        transition: all .3s ease;
    }
    .zoom-fade-enter-from, .zoom-fade-leave-to {
        transform: scale(0.9);
        opacity: 0;
    }
</style>