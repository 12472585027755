<template>
    <div v-if="employee && employee.emplData">
        <div v-if="(employee.shifts && employee.shifts.length>0) || selectedEmpl" class="overflow-hidden justify-center border border-sepBorder bg-squares text-contentText m-4 ml-0 w-min rounded" :style="'border-top:5px solid '+employee.emplColor">
            <div class="col-span-12 p-4 py-3 w-200">
                <div class="overflow-hidden relative">
                    <div class="grid grid-cols-3">
                        <div></div>
                        <div class="font-bold mt-0 mb-5 text-center text-contentText w-72 text-center truncate">{{employee.emplData.emplName}} - <span class="font-normal">{{positionFormat(employee.emplData.positionName)}}</span></div>
                        <h2 class="text-contentText text-right">{{employee.emplData.team}}</h2>
                    </div>
                    <slot v-if="layout == 1">
                        <section class="float-left border border-sepBorder py-1.5 px-3 mr-1 rounded font-semibold" ><label class="text-sm font-normal">Hours:</label><br />{{ employee.shifts.reduce((totalHours, shift) => totalHours + shift.hours, 0) }}</section>
                        <section class="float-left border border-sepBorder py-1.5 px-3 mr-1 rounded font-semibold" title="Completed shifts / Planned shifts"><label class="text-sm font-normal">Shifts:</label><br />{{employee.shifts.length}}<span class="text-black">/{{employee.allShifts}}</span></section>
                        <section class="float-left border border-sepBorder py-1.5 px-3 mr-1 rounded font-semibold" ><label class="text-sm font-normal">Earnings:</label><br />$ {{String(parseFloat(employee.shifts.reduce((totalEarnings, shift) => totalEarnings + shift.models.reduce((sum, model) => sum + parseFloat(model.earningsSum), 0), 0)).toLocaleString('en-US')).replace(/,/g,' ')}}</section>
                        <section class="float-left border border-sepBorder py-1.5 px-3 mr-1 rounded font-semibold" ><label class="text-sm font-normal">Salary:</label><br />$ {{String(parseFloat(employee.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0)).toFixed(2).toLocaleString('en-US')).replace(/,/g,' ')}}</section>
                        <section class="float-left border border-sepBorder py-1.5 px-3 mr-1 rounded font-semibold" ><label class="text-sm font-normal">Cost ratio:</label><br />{{((employee.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0)/employee.shifts.reduce((totalEarnings, shift) => totalEarnings + shift.models.reduce((sum, model) => sum + parseFloat(model.earningsSum), 0), 0))*100).toFixed(2)}} %</section>
                        <section class="float-left border border-sepBorder py-1.5 px-3 mr-1 rounded font-semibold" ><label class="text-sm font-normal">Words:</label><br />{{ employee.shifts.reduce((totalWordCount, shift) => totalWordCount + shift.word_count, 0) }}</section>
                        <section class="float-left bg-green-500 py-1.5 px-3 mr-1 text-black rounded font-semibold" title="Bonus is already calculated into salary"><label  class="text-sm text-black font-normal">Bonus: <font-awesome-icon icon="fas fa-circle-question" /></label><br />$ {{employee.shifts.reduce((totalBonus, shift) => totalBonus + shift.bonus, 0).toFixed(2)}}</section>

                        <font-awesome-icon icon="fa-xl fas fa-ellipsis" @click="displayOptions=!displayOptions" class="absolute right-0 top-5 cursor-pointer p-5" />
                        <section v-if="displayOptions" class="bg-tooltipBG text-tooltipText z-10 absolute p-3 px-5 leading-loose rounded-xl right-0 select-none top-16" style="filter: drop-shadow(0px 4px 5px rgba(0,0, 0, 0.4));">
                            <font-awesome-icon icon="fas fa-caret-up" class="text-tooltipBG absolute right-5 fa-xl top-0 -mt-3" />
                            <div v-if="!employee.locked && ($getPermission(permissions,22).is_granted || $getPermission(permissions,23).is_granted)" @click="openPayrollModal()" class="cursor-pointer"><font-awesome-icon icon="fas fa-user-lock" class="mr-1 w-5" /> Payroll</div>
                            <div v-else>Locked <font-awesome-icon icon="fas fa-lock" class="text-white ml-1" /></div>
                            <div v-if="!employee.locked && ($getPermission(permissions,22).is_granted || $getPermission(permissions,23).is_granted)" class="cursor-pointer" @click="recalcShifts(employee.emplData.emplID)">
                                <slot v-if="loading"><font-awesome-icon icon="fas fa-spinner" class="animate-spin mr-1 w-5 text-center" /> Loading</slot>
                                <slot v-else><font-awesome-icon icon="fas fa-arrows-rotate" class="mr-1 w-5 text-center" /> Recalc</slot>
                            </div>
                        </section>
                        
                        <div class="clear-both"></div>

                        <table @click="displayOptions=false" class="text-white w-full space-y-4 text-sm whitespace-nowrap overflow-y-auto h-80 block border-0 rounded mt-3">
                            <thead class="text-tableText bg-tableRow1 sticky -top-0.5 rounded text-center">
                                <tr>
                                    <th class="p-2.5 border border-sepBorder">Date</th>
                                    <th class="border border-sepBorder">Shift</th>
                                    <th class="border border-sepBorder">Models</th>
                                    <th class="px-3 border border-sepBorder" title="Number of words sent to fans">Words</th>
                                    <th class="px-2 border border-sepBorder text-sm leading-none">Words<br />sum</th>
                                    <th class="border border-sepBorder">Free $</th>
                                    <th class="border border-sepBorder">Total $</th>
                                    <th class="border border-sepBorder">SUM $</th>
                                </tr>
                            </thead>
                            <tbody class="border-0 bg-tableBG">
                                <tr class="text-tableText border border-sepBorder mt-1" v-for="(shift,index) in employee.shifts" :key="index">
                                    <td class="p-2 border-0 border-r-1">
                                        <span>{{shift.dayName}}.</span><br />{{shift.date}}
                                    </td>
                                    <td class="p-2 px-3 font-bold border border-sepBorder border-r-1 text-center">
                                        {{formatTime(shift.time_from)}} <br />-<br /> {{formatTime(shift.time_to)}}
                                    </td>
                                    <td class="p-2 leading-6 border border-sepBorder border-r-1">
                                        <div class="font-semibold pl-1 w-32 truncate" v-for="(model,modelInd) in shift.models" :key="modelInd">{{model.models_name}}</div>
                                    </td>
                                    <td class="p-2 text-center">
                                        <div class="truncate py-0.5" v-for="(model,modelInd) in shift.models" :key="modelInd">{{model.word_count}}</div>
                                    </td>
                                    <td class="px-3 text-center border border-sepBorder">
                                        {{ shift.models.reduce((sum, model) => sum + parseInt(model.word_count, 10), 0) }}
                                    </td>
                                    <td class="p-1.5 leading-6 border border-sepBorder border-r-1">
                                        <slot v-for="(model,modelInd) in shift.models" :key="modelInd">
                                            <div v-if="model.earningsFree" class="px-1.5" :class="{'text-red-200':parseFloat(model.earningsFree).toFixed(0)==0}">$ {{parseFloat(model.earningsFree).toFixed(2)}}</div>
                                            <div v-else class="text-red-400 px-1.5 text-center"><font-awesome-icon icon="fas fa-clock-rotate-left" /></div>
                                        </slot>
                                    </td>
                                    <td class="p-1.5 leading-6 font-bold border border-sepBorder border-r-1">
                                        <slot v-for="(model,modelInd) in shift.models" :key="modelInd">
                                            <div v-if="model.earningsSum" class=" px-2" :class="{'text-red-200':parseFloat(model.earningsSum).toFixed(0)==0}">$ {{parseFloat(model.earningsSum).toFixed(2)}}</div>
                                            <div v-else class="text-red-400 px-1.5 text-center"><font-awesome-icon icon="fas fa-clock-rotate-left" /></div>
                                        </slot>
                                    </td>
                                    <td class="p-1.5 leading-6 font-bold border border-sepBorder border-r-1">
                                        <div v-if="shift.shiftSum" class="w-20 px-1.5">$ {{parseFloat(shift.shiftSum).toFixed(2)}}</div>
                                        <div v-else class="w-20 px-1.5">$ 0.00</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </slot>
                    <slot v-else>
                        <div class="text-sm grid grid-cols-7 text-tableText gap-0.5 gap-y-2 mb-3">
                            <section class="bg-tableBG p-2 mr-1 rounded text-center pt-5 pb-4 border border-sepBorder">1. half</section>
                            <section class="bg-tableBG p-2 mr-1 rounded text-center border border-sepBorder"><label>Hours:</label><br /> {{ firstHalfSummary.hours }}</section>
                            <section class="bg-tableBG p-2 mr-1 rounded text-center border border-sepBorder"><label>Shifts:</label><br /> {{ firstHalfSummary.shifts}}</section>
                            <section class="bg-tableBG p-2 mr-1 rounded text-center border border-sepBorder"><label>Earnings:</label><br /> $ {{ firstHalfSummary.earnings.toFixed(2) }}</section>
                            <section class="bg-tableBG p-2 mr-1 rounded text-center border border-sepBorder"><label>Salary:</label><br /> $ {{ firstHalfSummary.salary.toFixed(2) }}</section>
                            <section class="bg-tableBG p-2 mr-1 rounded text-center border border-sepBorder"><label>Words:</label><br /> {{ firstHalfSummary.word_count }}</section>
                            <section class="bg-green-500 p-2 mr-1 text-black rounded text-center border border-sepBorder"><label class="text-black" title="Bonus is already calculated into salary">Bonus: <font-awesome-icon icon="fas fa-circle-question" /></label><br /> $ {{ firstHalfSummary.bonus.toFixed(2) }}</section>
                            
                            <section class="bg-tableBG p-2 mr-1 rounded text-center pt-5 pb-4 border border-sepBorder">2. half </section>    
                            <section class="bg-tableBG p-2 mr-1 rounded text-center border border-sepBorder"><label>Hours:</label><br /> {{ secondHalfSummary.hours }}</section>
                            <section class="bg-tableBG p-2 mr-1 rounded text-center border border-sepBorder"><label>Shifts:</label><br /> {{ secondHalfSummary.shifts }}</section>
                            <section class="bg-tableBG p-2 mr-1 rounded text-center border border-sepBorder"><label>Earnings:</label><br /> $ {{ secondHalfSummary.earnings.toFixed(2) }}</section>
                            <section class="bg-tableBG p-2 mr-1 rounded text-center border border-sepBorder"><label>Salary:</label><br />$ {{ secondHalfSummary.salary.toFixed(2) }}</section>
                            <section class="bg-tableBG p-2 mr-1 rounded text-center border border-sepBorder"><label>Words:</label><br /> {{ secondHalfSummary.word_count }}</section>
                            <section class="bg-green-500 p-2 mr-1 text-black rounded text-center border border-sepBorder"><label class="text-black" title="Bonus is already calculated into salary">Bonus: <font-awesome-icon icon="fas fa-circle-question" /></label><br />$ {{ secondHalfSummary.bonus.toFixed(2) }}</section>
                            
                            <section class="bg-tableRow1 text-tableRow1Text p-2 mr-1 rounded text-center pt-5 pb-4">Together</section>    
                            <section class="bg-tableRow1 text-tableRow1Text p-2 mr-1 rounded text-center"><label>Hours:</label><br /> {{ firstHalfSummary.hours + secondHalfSummary.hours }}</section>
                            <section class="bg-tableRow1 text-tableRow1Text p-2 mr-1 rounded text-center"><label>Shifts:</label><br /> {{ firstHalfSummary.shifts + secondHalfSummary.shifts }}</section>
                            <section class="bg-tableRow1 text-tableRow1Text p-2 mr-1 rounded text-center"><label>Earnings:</label><br /> $ {{ (firstHalfSummary.earnings + secondHalfSummary.earnings).toFixed(2) }}</section>
                            <section class="bg-tableRow1 text-tableRow1Text p-2 mr-1 rounded text-center"><label>Salary:</label><br /> $ {{ (firstHalfSummary.salary + secondHalfSummary.salary).toFixed(2) }}</section>
                            <section class="bg-tableBG p-2 mr-1 rounded text-center border border-sepBorder"><label>Words:</label><br /> {{ firstHalfSummary.word_count + secondHalfSummary.word_count }}</section>
                            <section class="bg-green-800 text-tableRow1 p-2 mr-1 rounded text-center"><label title="Bonus is already calculated into salary">Bonus: <font-awesome-icon icon="fas fa-circle-question" /></label><br /> $ {{ (firstHalfSummary.bonus + secondHalfSummary.bonus).toFixed(2) }}</section>
                        </div>
                    </slot>
                </div>
            </div>
        </div>

        <transition name="zoom-fade">
            <section v-if="modal.display" @click="modal.display = false" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
                <section @click.stop class="bg-white rounded-lg w-full max-h-screen max-w-md mx-auto my-auto p-8 px-6 sm:p-10 z-50 overflow-auto relative" :class="modal.type == 'graph' ? 'sm:max-w-7xl' : 'sm:max-w-5xl'">
                    <font-awesome-icon @click="modal.display = false" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />

                    <div class="grid sm:grid-cols-2 gap-3 gap-x-10">
                        <div class="col-span-2 border-b border-sepBorder pb-3 flex justify-between pr-10">
                            <div>
                                <h2 class="font-semibold text-lg">{{employee.emplData.emplName}}</h2>
                                <span class="font-light">{{employee.emplData.team}}</span>
                            </div>
                            <div>
                                <h2 class="font-semibold text-lg">{{employee.shifts.reduce((totalHours, shift) => totalHours + shift.hours, 0)}}</h2>
                                <span class="font-light">Hours</span>
                            </div>
                            <div>
                                <h2 class="font-semibold text-lg">{{employee.shifts.length}}</h2>
                                <span class="font-light">Shifts</span>
                            </div>
                            <div>
                                <h2 class="font-semibold text-lg">$ {{String(parseFloat(employee.shifts.reduce((totalEarnings, shift) => totalEarnings + shift.models.reduce((sum, model) => sum + parseFloat(model.earningsSum), 0), 0)).toLocaleString('en-US')).replace(/,/g,' ')}}</h2>
                                <span class="font-light">Earnings</span>
                            </div>
                            <div>
                                <h2 class="font-semibold text-lg">$ {{parseFloat(employee.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0)).toFixed(2)}}</h2>
                                <span class="font-light">Salary</span>
                            </div>
                            <div>
                                <h2 class="font-semibold text-lg">{{((employee.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0)/employee.shifts.reduce((totalEarnings, shift) => totalEarnings + shift.models.reduce((sum, model) => sum + parseFloat(model.earningsSum), 0), 0))*100).toFixed(2)}} %</h2>
                                <span class="font-light">Cost ratio</span>
                            </div>
                        </div>
                        <div class="hidden sm:block mt-5">
                            <h3 class="text-2xl font-bold leading-relaxed">Secure Salary Management</h3>
                            <p>This section is crafted to finalize salary adjustments with confidence. You can review the current salary, apply any discretionary soft bonuses, and view the updated total. Once satisfied, locking the record solidifies the salary, preparing it for the payout process.</p>
                            <h3 class="text-xl font-bold leading-relaxed mt-5">Finalize Earnings with Lock-in Feature</h3>
                            <p>Locking the salary ensures that subsequent schedule alterations or recalculations won't affect the settled amount. It's a safeguard for both management and creators, guaranteeing the agreed compensation remains untouched. Should any revisions be necessary, you retain the flexibility to unlock, adjust, and re-lock the salary to reflect any new changes accurately.</p>
                        </div>
                        <div>
                            <h2 class="text-button font-bold text-xl my-2">Lock salary</h2>
                            <div class="mb-2">
                                <label class="mt-2 text-xs">Soft bonus:</label><input type="number" class="w-full py-1 border-b border-sepBorder" placeholder="Soft bonus" v-model="softBonus" /><br />
                            </div>
                            <span class="text-xs">New salary:</span><br /><strong>$ {{softBonus ? (parseFloat(employee.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0))+parseFloat(softBonus)).toFixed(2) : (parseFloat(employee.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0)).toFixed(2))}}</strong><br />
                        </div>
                    </div>
                    <button @click="lockSalary(parseFloat(employee.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0)), softBonus)" class="delay-75 duration-75 transition-all float-right right-10 bottom-10 p-1.5 mt-5 px-7 rounded text-white bg-button"><font-awesome-icon icon="fas fa-lock" /> Lock this salary</button>
                </section>
            </section>
        </transition>

        <section v-if="dialogSalary" class="z-50 w-full top-0 left-0 fixed bg-opacity-0 h-screen">
            <section  class="text-tooltipText bg-tooltipBG p-5 rounded-xl top-60 z-50 w-80 mx-auto h-max pb-10 my-auto relative">
                <font-awesome-icon icon="fas fa-xmark" @click="dialogSalary=false,dialogBG = false" class="absolute p-5 right-0 top-0 cursor-pointer" />
                <div class="h-max clear-both">
                    <div class="text-center mb-2 text-white">
                        <h2 class="font-semibold text-lg">{{employee.emplData.emplName}}</h2>
                        <span class="text-xs">*By locking the salary you'll disable further changes</span>
                    </div>
                    <section class="my-4 mx-auto grid grid-cols-2 w-4/5 cursor-pointer" @click="SalaryLmonthly=!SalaryLmonthly" style="filter: drop-shadow(0px 4px 5px rgba(0,0, 0, 0.4));">
                        <div class="text-center rounded-l p-2" :class="{'bg-button text-buttonText font-bold':SalaryLmonthly,'bg-gray-200 text-black':!SalaryLmonthly}">Monthly</div>
                        <div class="text-center rounded-r p-2" :class="{'bg-button text-buttonText font-bold':!SalaryLmonthly,'bg-gray-200 text-black':SalaryLmonthly}">2 weeks</div>
                    </section>
                    <slot v-if="SalaryLmonthly && unlocked">
                        <span class="text-indigo-300">Current salary:</span> <strong>$ {{String(parseFloat(employee.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0)).toFixed(2).toLocaleString('en-US')).replace(/,/g,' ')}}</strong><br />
                        <span class="mt-2 text-indigo-300">Soft bonus:</span> $ <input type="number" class="w-28 mt-1" placeholder="soft bonus" v-model="softBonus" /><br />
                        <span class="text-indigo-300">New salary:</span> <strong>$ {{softBonus ? (parseFloat(employee.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0))+parseFloat(softBonus)).toFixed(2).toLocaleString('en-US') : parseFloat(employee.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0)).toFixed(2).toLocaleString('en-US')}}</strong><br />
                        <button @click="lockSalary(parseFloat(employee.shifts.reduce((totalSalary, shift) => totalSalary + shift.salary, 0)), softBonus)" class="w-full p-1 mt-2 text-center text-base rounded text-buttonText font-medium bg-button uppercase"><font-awesome-icon icon="fas fa-lock" /> Lock</button>
                    </slot>
                    <slot v-else-if="unlocked">
                        <h2 class="text-lg p-y-1 font-bold">First Half</h2>
                        <span class="text-indigo-300">Current salary:</span> <strong>$ {{firstHalfSummary.salary.toFixed(2)}}</strong><br />
                        <span class="mt-2 text-indigo-300">Soft bonus:</span> $ <input type="number" class="w-20 mt-1" placeholder="soft bonus" v-model="softBonus" /><br />
                        <span class="text-indigo-300">New salary:</span> <strong>$ {{softBonus ? (parseFloat(firstHalfSummary.salary.toFixed(2))+parseFloat(softBonus)).toFixed(2) : firstHalfSummary.salary.toFixed(2)}}</strong><br />
                        <button @click="lockSalary(parseFloat(firstHalfSummary.salary), softBonus)" class="w-full p-1 mt-2 text-center text-base rounded text-buttonText font-medium bg-button uppercase"><font-awesome-icon icon="fas fa-lock" /> Lock 1. half</button>
                        <br />
                        <hr class="my-3 border-0 border-t border-gray-500" />
                        <h2 class="text-lg p-y-1 font-bold">Second Half</h2>
                        <span class="text-indigo-300">Current salary:</span> <strong>$ {{secondHalfSummary.salary.toFixed(2)}}</strong><br />
                        <span class="mt-2 text-indigo-300">Soft bonus:</span> $ <input type="number" class="w-20 mt-1" placeholder="soft bonus" v-model="softBonusS" /><br />
                        <span class="text-indigo-300">New salary:</span> <strong>$ {{softBonusS ? (parseFloat(secondHalfSummary.salary.toFixed(2))+parseFloat(softBonusS)).toFixed(2) : secondHalfSummary.salary.toFixed(2)}}</strong><br />
                        <button @click="lockSalary(parseFloat(secondHalfSummary.salary), softBonusS)" class="w-full p-1 mt-2 text-center text-base rounded text-buttonTextfont-medium bg-button text-buttonText uppercase"><font-awesome-icon icon="fas fa-lock" /> Lock 2. half</button>
                    </slot>
                </div>

            </section>
        </section>
    </div>
</template>

<script setup>
    import niceInput from '@/components/niceInput.vue';
</script>

<script>
    export default {
        components() {'niceInput'},
        props: ['employeeData','layout','selectedEmpl','gIndex','i','groupID','selectedMonth','permissions'],
        data() {
            return {
                modal: {display:false,type:null},
                loading:false,
                employee: {},
                dialog: false,
                dialogBG: false,
                dialogSalary: false,
                dialogData: null,
                softBonus:0,
                softBonusS:0,
                unlocked: true,
                displayOptions:false,
                SalaryLmonthly:true,
                firstHalfSummary: {},
                secondHalfSummary: {},
            }
        },
        computed: {
            displayLock() { 
                return this.$moment().format("YYYY-MM") !== this.$moment(this.selectedMonth).format("YYYY-MM") ? true : false;
            }
        },
        watch: {
            employeeData: function(oldV,newV) {
                this.employee = this.employeeData;
                this.calculateHalfMonthSummaries(this.employee);
            }
        },
        methods: {
            halfMonthReduce(employee, propertyName, firstHalf = true) {
                return employee.shifts.reduce((total, shift) => {
                const day = parseInt(shift.date.split(".")[0], 10); // Assuming the date format is "DD.MM."

                // Determine the date range based on the half of the month
                const isInFirstHalf = day >= 1 && day <= 15;
                const isInSecondHalf = day >= 16 && day <= 31; // Assuming all months have 31 days, adjust if needed

                // Check if the shift date is within the specified half of the month
                if ((firstHalf && isInFirstHalf) || (!firstHalf && isInSecondHalf)) {
                    if (propertyName === "shift") {
                    return total + 1;
                    }
                    return total + (shift[propertyName] || 0);
                }
                return total;
                }, 0);
            },
            calculateHalfMonthSummaries(employee) {
                this.firstHalfSummary = {
                    hours: this.halfMonthReduce(employee, 'hours', true),
                    shifts: this.halfMonthReduce(employee, 'shift', true),
                    earnings: this.halfMonthReduce(employee, 'shiftSum', true),
                    salary: this.halfMonthReduce(employee, 'salary', true),
                    word_count: this.halfMonthReduce(employee, 'word_count', true),
                    bonus: this.halfMonthReduce(employee, 'bonus', true),
                };
                this.secondHalfSummary = {
                    hours: this.halfMonthReduce(employee, 'hours', false),
                    shifts: this.halfMonthReduce(employee, 'shift', false),
                    earnings: this.halfMonthReduce(employee, 'shiftSum', false),
                    salary: this.halfMonthReduce(employee, 'salary', false),
                    word_count: this.halfMonthReduce(employee, 'word_count', false),
                    bonus: this.halfMonthReduce(employee, 'bonus', false),
                };
            },
            formatTime(time) {
                return this.$moment.utc(time, "HH:mm:ss").local().format("HH:mm");
            },
            positionFormat(position) {
                return position.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            },
            openPayrollModal() {
                this.softBonus = null;
                this.modal.display = true;
                this.modal.type = 'salary';                
                this.$axios.get(baseURL+"finance/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        empl_id: this.employee.emplData.emplID,
                        selectedMonth: this.selectedMonth,
                        build:'checkForLocks',
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    this.payrollLocks = result.data;
                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false)
            },
            unlockSalary() {
                if (parseInt(this.employee.costRatio) > -1) {
                    if (confirm("Unlock?")) {
                        this.$axios.delete(baseURL+"finance/", {
                            params: {
                                action: "deleteSalary",
                                userToken: this.$cookies.get('userToken'),
                                companyID: this.$cookies.get('c_id'),
                                selectedMonth: this.selectedMonth,
                                emplID: this.employee.emplData.emplID
                            }
                        }).then((result) => {
                            if (result.status == 200 && result.data.success === true) {
                                this.unlocked = true;
                                this.employee.locked = false;
                            }
                        }, (error) => {
                            console.log(error);
                        });
                    }
                }
            },
            lockSalary(salary, softBonus) {
                this.$axios.post(baseURL+"finance/", {                    
                    action: "lockSalary",
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id'),
                    date: this.selectedMonth,
                    salary: salary,
                    correction: softBonus,
                    data:this.employee,
                }).then((result) => {
                    if (result.data.success === true) {
                        this.modal.display = false;
                        this.unlocked = false;
                        this.employee.locked = true;
                    } else {
                        alert(result.data.error);
                        this.dialogBG = false;
                        this.unlocked = false;
                    }
                }, (error) => {
                    console.log(error);
                });
            },
            recalcShifts(sextar) {
                this.loading = true;
                this.$axios.get(baseURL+"sexting/", {
                    params: {
                        userToken: this.$cookies.get('userToken'),
                        sextarID: sextar,
                        fromDate: this.selectedMonth,
                        build:'recalcShifts',
                        TZoffset: this.$moment().utcOffset(),
                        companyID: this.$cookies.get('c_id')
                    }
                }).then((result) => {
                    if (result.data && result.data.success == true) {
                        this.$axios.get(baseURL+"sexting/", {
                            params: {
                                userToken: this.$cookies.get('userToken'),
                                groupId: this.groupID,
                                emplId: sextar,
                                fromDate: this.selectedMonth,
                                build:'sextingDataRef',
                                companyID: this.$cookies.get('c_id')
                            }
                        }).then((result) => {
                            this.employee = result.data[0];
                        }, (error) => {
                            console.log(error);
                        }).finally()
                    }

                }, (error) => {
                    console.log(error);
                }).finally(() => this.loading = false)
            }
        },
        mounted() {
            this.employee = this.employeeData;
            this.calculateHalfMonthSummaries(this.employee);
        }
    }
</script>