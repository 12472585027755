import axios from 'axios';

export async function get_list_data(auth_token,c_id,whichData,group,team,mSummary = null,platform = null,pageTitle) {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await axios.get(`${baseURL}lists/`, {
                params: {
                    build: whichData,
                    groupFilter: group,
                    teamFilter: team,
                    msummary:mSummary,
                    userToken: auth_token,
                    companyID: c_id,
                    systemLocation: pageTitle
                }
            })
            resolve(result.data);
        } catch (error) {
          reject(error);
        }
    });
}

export async function get_groups(auth_token,c_id) {
    if (auth_token) {
        try {
            let url = `${baseURL}groups/?userToken=${auth_token}`;
            if (c_id !== null) {url += `&companyID=${c_id}`;}
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function get_proxy_list(auth_token,c_id) {
    try {
        let url = `${baseURL}proxy/?userToken=${auth_token}&action=unused_proxy_list`;
        if (c_id !== null) {url += `&companyID=${c_id}`;}
        const response = await axios.get(url);
        return response.data;
    } catch (err) {
        return false;
    }
}

export async function reorder_groups(auth_token,c_id,new_groups) {
    if (auth_token) {
        try {
            const response = await axios.post(`${baseURL}groups/`, {
                action: 'reorder',
                userToken: auth_token,
                companyID: c_id,
                groups: new_groups
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function remove_group(auth_token,c_id,group_id,is_group) {
    if (auth_token) {
        try {
            const response = await axios.delete(`${baseURL}groups/`, {
                params: {
                    userToken: auth_token,
                    companyID: c_id,
                    groupID: group_id,
                    is_group: is_group
                }
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function remove_proxy_from_model(auth_token, c_id, model_id, proxy_id) {
    if (auth_token) {
        try {
            const response = await axios.delete(`${baseURL}proxy/`, {
                params: {
                    action: "disconnect_proxy_from_model",
                    userToken: auth_token,
                    companyID: c_id,
                    model_pair_id: model_id,
                    proxy_id: proxy_id
                }
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function get_models(auth_token,c_id,active,pair_id=null,filter=null,drop=0,social=0,withFinance=0) {
    //in case of social, pair ID is going to be ID
    if (auth_token) {
        try {
            if (pair_id) {
                let url = `${baseURL}models/?userToken=${auth_token}&action=one_model&pair_id=${pair_id}&groupFilter=${filter}&social=${social}&withFinance=${withFinance}`;
                if (c_id !== null) {url += `&companyID=${c_id}`;}
                if (active !== null) {url += `&active=${active}`;}                

                const response = await axios.get(url);
                return response.data;
            } else {
                let url = `${baseURL}models/?userToken=${auth_token}&action=models_list&groupFilter=${filter}&drop=${drop}&social=${social}&withFinance=${withFinance}`;
                if (c_id !== null) {url += `&companyID=${c_id}`;}
                if (active !== null) {url += `&active=${active}`;}
                const response = await axios.get(url);
                return response.data;
            }
        } catch (error) {
            return false;
        }
    }
}

export async function get_top_models(auth_token, c_id, dateFrom, dateTo, limit) {
    if (auth_token) {
        try {
            const response = await axios.get(`${baseURL}models/?userToken=${auth_token}&action=top_models&companyID=${c_id}&dateFrom=${dateFrom}&dateTo=${dateTo}&limit=${limit}`);
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function get_top_employee(auth_token, c_id, dateFrom, dateTo, limit) {
    if (auth_token) {
        try {
            const response = await axios.get(`${baseURL}users/?userToken=${auth_token}&action=top_employee&companyID=${c_id}&dateFrom=${dateFrom}&dateTo=${dateTo}&limit=${limit}`);
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function get_metrics(auth_token, c_id, group, model, dateFrom, dateTo, period, summary = null) {
    if (auth_token) {
        try {
            const response = await axios.get(`${baseURL}metrics/`, {
                params: {
                    p: period,
                    groupId: group,
                    modelId: model,
                    dateFrom: dateFrom,
                    dateTo: dateTo,
                    userToken: auth_token,
                    companyID: c_id,
                    summary: summary
                }
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function verify_token(auth_token, c_id, place) {
    if (auth_token) {
        // Base URL with the userToken parameter
        let url = `${baseURL}opravnenia/userToken.php?userToken=${auth_token}&place=${place}`;
        if (c_id !== null) {url += `&companyID=${c_id}`;}

        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function get_model_graph(auth_token,c_id, column, dateFrom, dateTo, modelID) {
    if (auth_token) {
        try {
            const response = await axios.get(`${baseURL}models/?userToken=${auth_token}&model_id=${modelID}&dateFrom=${dateFrom}&dateTo=${dateTo}&companyID=${c_id}&column=${column}&action=model_graph`);
            return response.data
        } catch (error) {
            return false;
        }
    }
}

export async function get_all_shifts(auth_token,c_id,build,groupId=null,teamId=null,dateFrom,dateTo) {
    if (auth_token) {
        try {
            let url = `${baseURL}shifts/?dateFrom=${dateFrom}&dateTo=${dateTo}&groupId=${groupId}&teamId=${teamId}&build=${build}&userToken=${auth_token}`
            if (c_id !== null) {url += `&companyID=${c_id}`;}
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            return false
        }
    }
}

export async function get_sexting(auth_token,c_id,build,groupId=null,teamId=null,emplId=null, timestamp_from, timestamp_to) {
    const params = new URLSearchParams();
    params.append('timestamp_from', timestamp_from);
    params.append('timestamp_to', timestamp_to);

    if (auth_token) {
        try {
            let url = `${baseURL}sexting/?&build=${build}&userToken=${auth_token}&${params.toString()}`
            if (c_id !== null) {url += `&companyID=${c_id}`;}
            if (teamId !== null) {url += `&teamId=${teamId}`;}
            if (groupId !== null) {url += `&groupId=${groupId}`;}
            if (emplId !== null) {url += `&emplId=${emplId}`;}
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            return false
        }
    }
}

export async function get_users(auth_token,c_id,active,id=null,groupFilter=null,teamFilter=null,sexting=0,companyFilter=null,filter=null,withFinance=0) {
    if (auth_token) {
        try {
            if (id) {
                let url = `${baseURL}users/?userToken=${auth_token}&action=one_user&id=${id}&active=${active}&groupFilter=${groupFilter}&teamFilter=${teamFilter}&withFinance=${withFinance}`
                if (c_id !== null) {url += `&companyID=${c_id}`;}
                if (filter !== null) {url += `&filter=${filter}`;}
                const response = await axios.get(url);
                return response.data;
            } else {
                let url = `${baseURL}users/?userToken=${auth_token}&action=users_list&active=${active}&groupFilter=${groupFilter}&teamFilter=${teamFilter}&sexting=${sexting}&withFinance=${withFinance}`
                console.log(url);
                if (c_id !== null) {url += `&companyID=${c_id}`;}
                if (companyFilter !== null) {url += `&companyFilter=${companyFilter}`;}
                if (filter !== null) {url += `&filter=${filter}`;}
                const response = await axios.get(url);
                return response.data;
            }
        } catch (error) {
            return false;
        }
    }
}

export async function check_if_email_used(email, auth_token, c_id) {
    try {
        const response = await axios.get(`${baseURL}users/`, {
            params: {
                action: 'check_email',
                email: email,
                userToken: auth_token,
                companyID: c_id
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function signup(formData) {
    try {
        const response = await axios.post(`${baseURL}login/`, {
            action: 'register',
            data: formData,
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function verifyEmail(verificationCode) {
    try {
        const response = await axios.post(`${baseURL}login/`, {
            action: 'verify',
            verificationCode: verificationCode,
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function archive_model(auth_token,c_id,archive,pair_id = null,model_id = null, social = 0) {
    // works for both archiving by model ID and by pair_id
    if (auth_token) {
        try {
            const response = await axios.post(`${baseURL}models/`, {
                action: 'archive',
                userToken: auth_token,
                companyID: c_id,
                model_pair_id: pair_id,
                model_id: model_id,
                archive: archive,
                social: social
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function suspend_user(auth_token, c_id, archive, user_id) {
    if (auth_token) {
        try {
            const response = axios.post(`${baseURL}settingspost/`, {                    
                action: "employeeSuspend",
                employeeID: user_id,
                suspended: archive,
                userToken: auth_token,
                companyID: c_id
            });
            return (await response).data;
        } catch (error) {
            return error;
        }
    }
}

export async function save_user(auth_token,c_id,emplObject) {
    if (auth_token) {
        try {
            const response = axios.post(`${baseURL}users/`, {                    
                action: "save",
                emplObject: emplObject,
                userToken: auth_token,
                companyID: c_id
            });
            return response.data;
        } catch (error) {
            return error;
        }
    }
}

export async function save_model(auth_token,c_id,modelData) {
    if (auth_token) {
        try {
            const response = await axios.post(`${baseURL}models/`, {                    
                action: "save",
                modelObject: modelData,
                userToken: auth_token,
                companyID: c_id
            });
            return response.data;
        } catch (error) {
            return error;
        }
    }
}

export async function get_sessions(auth_token, c_id, model_pair_id) {
    if (auth_token) {
        try {
            const response = await axios.get(`${baseURL}settingsget/`, {
                params: {                   
                    action: "sessionManagement",
                    model_pair_id: model_pair_id,
                    userToken: auth_token,
                    companyID: c_id
                }
            });
            return response.data;
        } catch (error) {
            return error;
        }
    }
}

export async function scraped_models(auth_token, c_id, modelID, date_from, date_to) {
    if (auth_token) {
        try {
            const response = await axios.get(`${baseURL}scraping/`, {
                params: {                   
                    action: "scraped",
                    modelID: modelID,
                    dateFrom: date_from,
                    dateTo: date_to,
                    userToken: auth_token,
                    companyID: c_id
                }
            });
            return response.data;
        } catch (error) {
            return error;
        }
    }
}

export async function get_repost_links(auth_token, c_id, modelID) {
    if (auth_token) {
        try {
            const response = await axios.get(`${baseURL}models/`, {
                params: {
                    action: "repost_links",
                    modelID: modelID,
                    userToken: auth_token,
                    companyID: c_id
                }
            });
            return response.data;
        } catch (error) {
            return error;
        }
    }
}

export async function add_repost_link(auth_token, c_id, modelID, url) {
    try {
        const response = await axios.post(`${baseURL}models/`, {
            action: 'add_repost_link',
            modelID: modelID,
            url: url,
            userToken: auth_token,
            companyID: c_id
        });
        return response.data;
    } catch (error) {
        return false;
    }
}

export async function delete_repost_link(auth_token, c_id, model_id, link_id) {
    try {
        const response = await axios.delete(`${baseURL}models/`, {
            params : {
                action: 'delete_repost_link',
                modelID: model_id,
                linkID: link_id,
                userToken: auth_token,
                companyID: c_id
            }
        });
        return response.data;
    } catch (error) {
        return false;
    }
}

export async function scrape_data(auth_token, c_id, modelID, platform, date, filter = 0) {
    if (!auth_token) {return false;}
    try {
        const response = await axios.get(`${baseURL}scraping/`, {
            params: {                   
                action: "scrape",
                modelID: modelID,
                platform: platform,
                date: date,
                filter: filter,
                userToken: auth_token,
                companyID: c_id
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
}

export async function save_social(auth_token,c_id,socialData) {
    if (auth_token) {
        try {
            const response = await axios.post(`${baseURL}models/`, {                    
                action: "saveSocial",
                socialObject: socialData,
                userToken: auth_token,
                companyID: c_id
            });
            return response.data;
        } catch (error) {
            return error;
        }
    }
}

export async function archive_user(auth_token,c_id,archive,user_id) {
    // works for both archiving by model ID and by pair_id
    if (auth_token) {
        try {
            const response = await axios.post(`${baseURL}users/`, {
                action: 'archive',
                userToken: auth_token,
                companyID: c_id,
                user_id: user_id,
                archive: archive
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function analytics_data(auth_token,c_id,date_from,date_to,action,group_id = null, team_id = null, model_id = null, user_id = null,data = null) {
    if (auth_token) {
        try {
            const response = await axios.get(`${baseURL}analytics/`, {
                params: {
                    action: action,
                    userToken: auth_token,
                    companyID: c_id,
                    date_from: date_from,
                    date_to: date_to,
                    group_id: group_id,
                    team_id: team_id,
                    model_id: model_id,
                    user_id: user_id,
                    data:data
                }
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function getTrackingLinks(auth_token,companyID,modelID,offset=0,limit=10){
    if(auth_token){
        try{
            const response = await axios.get(`${baseURL}traffic/index.php?action=traffic_links&modelID=${modelID}&companyID=${companyID}&userToken=${auth_token}&offset=${offset}&limit=${limit}`)
            return response.data;
        }catch(error){
            return false;
        }
    }
}
export async function getTrialLinks(auth_token,companyID,modelID,offset=0,limit=10){
    if(auth_token){
        try{
            const response = await axios.get(`${baseURL}traffic/index.php?action=trial_links&modelID=${modelID}&companyID=${companyID}&userToken=${auth_token}&offset=${offset}&limit=${limit}`)
            return response.data;
        }catch(error){
            return false;
        }
    }
}

export async function getUserClaims(auth_token,companyID,modelID,linkID,offset=0,limit=10){
    if(auth_token){
        try{
            const response = await axios.get(`${baseURL}traffic/index.php?action=traffic_claims&companyID=${companyID}&modelID=${modelID}&offset=${offset}&limit=${limit}&linkID=${linkID}&userToken=${auth_token}`)
            return response.data;
        }catch(error){
            return false;
        }
    }
}

export async function postTrackingLink(auth_token, companyID, modelID, linkName, type) {
    if(auth_token){
        try{
            const response = await axios.post(`${baseURL}traffic/`, {
                action: 'addLink',
                companyID: companyID,
                modelID: modelID,
                userToken: auth_token,
                linkName: linkName,
                type: type
            })
            return response.data;
        }catch(error){
            return false;
        }
    }
}

export async function sendMail(emailData) {
    try {
        const response = await axios.post(`${baseURL}email/`, {
            action: 'send_mail',
            emailData: emailData,
        });
        return response.data;
    } catch(error){
        return false;
    }
}

export async function getChattingScriptsCategories(auth_token,companyID){
    if(auth_token){
        try{
            const response = await axios.get(`${baseURL}chatting_scripts/?action=chatting_script_categories&companyID=${companyID}&userToken=${auth_token}`)
            return response.data;
        }catch(error){
            return false;
        }
    }
}

export async function getChattingScripts(auth_token,companyID,categoryID,offset=0,limit=10){
    if(auth_token){
        try{
            const response = await axios.get(`${baseURL}chatting_scripts/?action=chatting_scripts_list&companyID=${companyID}&category=${categoryID}&userToken=${auth_token}&offset=${offset}&limit=${limit}`)
            return response.data;
        }catch(error){
            return false;
        }
    }
}

export async function postChattingScript(auth_token,companyID,categoryID,scriptText){
    if(auth_token){
        try{
            const response = await axios.post(`${baseURL}chatting_scripts/`,{
                action: 'save_script',
                companyID: companyID,
                category: categoryID,
                userToken: auth_token,
                script: scriptText
            })
            return response;
        }catch(error){
            return false;
        }
    }
}
export async function updateChattingScript(auth_token,companyID,categoryID,scriptID,scriptText){
    if(auth_token){
        try{
            const response = await axios.post(`${baseURL}chatting_scripts/`,{
                action: 'update_script',
                companyID: companyID,
                script_id: scriptID,
                category: categoryID,
                userToken: auth_token,
                script: scriptText
            })
            return response.data;
        }catch(error){
            return false;
        }
    }
}

export async function deleteChattingScript(auth_token,companyID,scriptID){
    if(auth_token){
        try{
            const response = await axios.delete(`${baseURL}chatting_scripts/?script_id=${scriptID}&companyID=${companyID}&userToken=${auth_token}`)
            return response.data;
        }catch(error){
            return false;
        }
    }
}

export async function get_monthly_billing(auth_token,companyID, offset, limit) {
    if(auth_token){
        try{
            const response = await axios.get(`${baseURL}billing/?action=monthly_bills&companyID=${companyID}&userToken=${auth_token}&offset=${offset}&limit=${limit}`)
            return response.data;
        }catch(error){
            return false;
        }
    }
}

export async function models_on_tools(auth_token,c_id, date_from, date_to) {
    if(auth_token) {
        try {
            let url = `${baseURL}billing/?action=models_on_tools&userToken=${auth_token}&date_from=${date_from}&date_to=${date_to}`
            if (c_id !== null) {url += `&companyID=${c_id}`;}
            const response = await axios.get(url)
            return response.data;
        } catch (err) {
            return false;
        }
    }
}

export async function update_tools(auth_token, c_id, toolData) {
    try {
        const response = await axios.post(baseURL+"settingspost/", {                    
            action: "updateTools",
            toolData: toolData,
            userToken: auth_token,
            companyID: c_id
        });
        return response.data;
    } catch (err) {
        return false;
    }
}

export async function api_get_call(url) {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (err) {
        return false;
    }
}

export async function api_post_call(url, data) {
    try {
        const response = await axios.post(url, data);
        return response.data;
    } catch (err) {
        return false;
    }
}