<template>
    <div class="overflow-x-hidden">
        <!-- header -->
        <header-panel />

        <div class="px-2">
            <section class="container mx-auto bg-white rounded-lg shadow-lg p-5 sm:p-10 mt-22">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
                    <div class="max-w-base">
                        <font-awesome-icon icon="fa-solid fa-arrow-left" class="ml-2 text-2xl cursor-pointer" @click="$router.push('/')" />
                        <h1 class="mt-5 text-3xl font-bold">{{ $t('reposting.h1') }}</h1>
                        <h2 class="mt-2 text-xl">{{ $t('reposting.h2') }}</h2>
                        <p class="max-w-md mt-5 text-lg" v-html="$t('reposting.p1')"></p>
                    </div>
                    <div>
                        <img :src="s4s" class="hidden sm:block" alt="Elevate Your OnlyFans, Fansly, and Reddit Strategy" title="Elevate Your OnlyFans, Fansly, and Reddit Strategy" />
                    </div>
                    <div @click="$router.push({ path: '/', query: { open: 'register' } });" class="col-span-full bg-growth w-full mx-auto rounded shadow-lg text-center text-white p-3 font-semibold text-lg cursor-pointer">
                        <font-awesome-icon icon="fa-solid fa-seedling" class="mr-3" /> {{ $t('Try_now') }}
                    </div>

                    <div class="col-span-full text-left relative mt-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 items-center">
                            <div class="sm:px-5">
                                <span class="ml-10 flex text-2xl font-bold text-blueColor"> <img src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="mt-0.5 mr-2 h-7"/> {{ $t('reposting.h3') }}</span>
                                <div class="absolute border border-growth text-growth font-bold p-0.5 px-3 rounded -rotate-12 -mt-14"><font-awesome-icon icon="fa-solid fa-fire" /> {{ $t('new') }}</div>
                                <p class="mt-5 leading-relaxed" v-html="$t('reposting.p2')"></p>
                            </div>
                            <img src="@/assets/images/reposting_image.jpg" title="Reposting screenshot" alt="Reposting screenshot" class="mr-2 w-full rounded shadow"/>
                        </div>
                    </div>

                    <hr class="col-span-full" />

                    <div class="col-span-full">    
                        <div class="flex items-center">
                            <font-awesome-icon icon="fa-solid fa-handshake-simple" class="w-16 hidden sm:block text-5xl px-16 text-growth" />
                            <div class="max-w-2xl">
                                <h3 class="leading-relaxed font-bold text-2xl">{{ $t('reposting.h4') }}</h3>
                                <p>{{ $t('reposting.p3') }}</p>
                            </div>
                        </div>
                        <div class="flex items-center mt-8">
                            <font-awesome-icon icon="fa-solid fa-heart" class="w-16 hidden sm:block text-5xl px-16 text-growth" />
                            <div class="max-w-2xl">
                                <h3 class="leading-relaxed font-bold text-2xl">{{ $t('reposting.h5') }}</h3>
                                <p>{{ $t('reposting.p4') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <footer-panel />
    </div>
</template>

<script setup>
    import headerPanel from "@/components/landing/HeaderLanding.vue"
    import s4s from '@/assets/images/s4s.svg';
    import FooterPanel from "@/components/landing/FooterLanding.vue"
</script>

<script>
    export default {
        components: {headerPanel,FooterPanel},
        data() {
            return {
                menuOpen:false,
            }
        },
    }
</script>