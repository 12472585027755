<template>
  <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
  <header-filter :pageTitle="'Chat Scripts'" />
  <div class="mx-4 mb-24 sm:ml-16 px-4 mt-24">
      <section class="flex flex-wrap gap-3 justify-between ">
        <button type="button" class="mt-2 bg-button rounded cursor-pointer p-1.5 px-7 text-buttonText text-center font-semibold" @click="modal.display=true;modal.type='new_script'">
          <font-awesome-icon icon="fa-solid fa-plus" /> {{ $t('add_new_scripts') }}
        </button>
        <select v-model="categorySelected" @change="setChattingScripts()" class="p-2 border-b border-sepBorder outline-0 bg-transparent">
            <option value="0">{{ $t('select_category') }}</option>
            <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
        </select>
      </section>
      <section class="mt-5">
      <div class="w-full bg-bkg rounded text-center py-60 border border-sepBorder ml-1" v-if="scripts_band=='loading'">
        <h3 class="text-center text-2xl font-semibold text-contentText mt-5"><font-awesome-icon :icon="'fa-solid fa-spinner'" class="animate-spin"/></h3>
      </div>
      <div class="flex flex-col max-w-3xl pr-5 overflow-auto max-h-[800px]" v-else-if="scripts.length>0">
        <div class="w-full mt-2 bg-squares py-3 px-4 rounded flex justify-between" v-for="(script,index) in scripts" :key="index">
          <div>{{ script.content }}</div>
          <div v-if="!script.global" class="flex flex-row flex-nowrap">
            <font-awesome-icon icon="fa-solid fa-pen" class="text-growth text-buttonText p-1 mr-2 px-3 cursor-pointer font-sm" @click="openUpdateModal(script.content,script.id,index)" />
            <font-awesome-icon icon="fa-solid fa-trash-can" class="text-decline text-buttonText p-1 px-3 cursor-pointer font-sm" @click="delChattingScript(script.id,index)" />
          </div>
        </div>
        <div class="grid content-center text-center pt-5 mt-3 place-items-center">
          <slot v-if="hasMore==true">
            <div class="cursor-pointer animate-bounce text-white bg-white p-3 px-4 border-2 border-growth rounded-full"  @click="loadMore()">
              <font-awesome-icon icon="fa-solid fa-angles-down" class="text-growth" />
            </div>
          </slot>
          <slot v-else-if="hasMore=='processing'">
              <font-awesome-icon :icon="'fa-solid fa-spinner'" class="animate-spin"/>
          </slot>
        </div>
        
      </div>
      <div class="w-full bg-bkg rounded text-center py-60 border border-sepBorder ml-1" v-else-if="scripts_band==false && categorySelected>0">
        <h3 class="text-center text-2xl font-semibold text-contentText mt-5">
          {{ $t('no_script_warning') }}
          <span class="text-growth cursor-pointer" @click="modal.display=true;modal.type='new_script'">{{ $t('add_new_script') }}</span>
        </h3>
      </div>
      <div class="w-full bg-bkg rounded text-center py-60 border border-sepBorder ml-1" v-else>
        <h3 class="text-center text-2xl font-semibold text-contentText mt-5">{{ $t('select_chat_category') }}Please select a category to see Chatting Scripts</h3>
      </div>
      </section>
  </div>
  <transition name="zoom-fade">
    <section v-if="modal.display" @click="modal.display = false; showHidetag = false; script_text='';" class="backdrop-blur w-full h-full fixed inset-0 bg-gray-700 bg-opacity-30 z-50 flex justify-center items-center px-4 text-black">
        <section @click.stop class="bg-white rounded-lg w-full max-h-full max-w-md sm:max-w-5xl mx-auto my-auto p-8 sm:p-12 z-50 overflow-auto relative">
            <font-awesome-icon @click="modal.display = false; showHidetag = false; script_text='';" icon="fas fa-xmark" class="transition absolute p-6 top-0 right-0 text-xl cursor-pointer hover:opacity-50" />
            <slot v-if="modal.type=='new_script' || modal.type=='update_script'">
                <h2 class="mt-2 text-2xl font-semibold text-contentText text-center"> 
                  <span v-if="modal.type=='new_script'">{{ $t('add_new') }}</span> 
                  <span v-if="modal.type=='update_script'">{{ $t('update') }}</span>
                  {{ $t('Chat Scripts') }}
                </h2>
                <div class="mt-5 grid sm:grid-cols-2 gap-x-10">
                  <div class="hidden sm:block mt-2">
                    <h3 class="text-2xl font-bold leading-relaxed">{{ $t('chat_h1') }}</h3>
                    <p>{{ $t('chat_p1') }}</p>
                    <h3 class="text-xl font-bold leading-relaxed mt-5">{{ $t('chat_h2') }}</h3>
                    <p>{{ $t('chat_p2') }}</p>
                  </div>
                  <div>
                    <select v-model="categoryForPost" class="mb-2 border-b border-sepBorder py-1.5 outline-0">
                      <option value="0">{{ $t('select_category') }}</option>
                      <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                    </select>
                    <textarea class="w-full max-h-48 h-48 border border-sepBorder p-3 rounded" style="min-height:200px" placeholder="Enter your chatting script here" ref="script_text" v-model="script_text"></textarea>
                    <div class="mt-5 flex flex-wrap justify-between">
                      <div class="mb-2">
                        <button type="button" class="border border-button bg-white text-button font-bold rounded p-1.5 px-2 mr-2 text-sm" @click="showHidetag=!showHidetag"><font-awesome-icon icon="fa-regular fa-face-smile" /></button>
                        <button type="button" class="border border-button bg-white text-button font-bold rounded p-1.5 px-2 text-sm" @click="placeText('{name}')">{{ $t('add_user_name') }}</button>
                        <EmojiPicker :native="true" @select="onSelectEmoji" class="fixed z-50 emojiWindow" v-show="showHidetag"/>
                      </div>
                      <button type="button" v-if="modal.type=='new_script'" class="bg-button rounded cursor-pointer p-1.5 px-7 text-buttonText text-center font-semibold" @click="sendChattingScript()">{{ $t('save') }}</button>
                      <button type="button" v-if="modal.type=='update_script'" class="bg-button rounded cursor-pointer p-1.5 px-7 text-buttonText text-center font-semibold" @click="actChattingScript()">{{ $t('update') }}</button>
                    </div>
                    
                  </div>
                </div>
            </slot>
            <p v-else class="text-center text-xl">{{ $t('comming_soon') }}</p>
        </section>
    </section>
  </transition>
</template>
<script setup>
  import headerFilter from "@/components/headerFilter.vue";
  import leftMenu from "@/components/LeftMenu.vue";
  import { getChattingScriptsCategories,getChattingScripts,postChattingScript,updateChattingScript,deleteChattingScript } from "@/api";
  import EmojiPicker from 'vue3-emoji-picker'
  import 'vue3-emoji-picker/css'
</script>
<script>
export default {
    name: 'ChattingScripts',
    data() {
        return {
            permissions: null,
            companyID: null,
            userToken: null,
            categories: [],
            scripts: [],
            displayedScripts: [],
            categorySelected: 1,
            scripts_band: true,
            offset: 0,
            limit: 10,
            modal: {display: false, type: null},
            script_text: '',
            categoryForPost: 0,
            showHidetag: false,
            updateID:null,
            updatedIndex:null,
            hasMore: false,
        };
    },
    methods: {
        async setChattingCategories() {
            this.categories = await getChattingScriptsCategories(this.userToken,this.companyID);
        },
        async setChattingScripts(){
            this.offset = 0;
            this.limit = 10;
            this.scripts_band = 'loading';
            this.scripts = [];
            let scripts_data = await getChattingScripts(this.userToken,this.companyID,this.categorySelected,this.offset,this.limit);
            this.scripts = scripts_data.data;
            this.scripts_band = true;
            this.categoryForPost = this.categorySelected;
            this.hasMore = scripts_data.hasMore;
            if(this.scripts.length==0){
              this.scripts_band = false;
            }
            
        },
        async sendChattingScript(){
          if(this.selectedCategory==0){ return; }
          let post = await postChattingScript(this.userToken,this.companyID,this.categorySelected,this.script_text);
          if(post.status==200 && this.categorySelected==this.categoryForPost){
            this.scripts.unshift({content: this.script_text, id: post.data.id})
          }
          this.script_text = '';
          this.modal.display = false;
        },
        async actChattingScript(){
            let post = await updateChattingScript(this.userToken,this.companyID,this.categorySelected,this.updateID,this.script_text);
            if(post.success==true && this.categorySelected==this.categoryForPost){
              this.scripts[this.updatedIndex].content = this.script_text;
            }
            this.script_text = '';
            this.modal.display = false;
        },
        async delChattingScript(deleteID,index){
          if(confirm(this.$t('chat_delete_warning'))){
            let post = await deleteChattingScript(this.userToken,this.companyID,deleteID);
            if(post.success==true){
              this.scripts.splice(index,1);
            }
            this.scripts_band = true;
            if(this.scripts.length==0){
              this.scripts_band = false;
            }
            
          }
          
        },
        async loadMore(){
          this.offset = this.limit;
          this.limit += 10;
          let scripts_data = await getChattingScripts(this.userToken,this.companyID,this.categorySelected,this.offset,this.limit);
          this.scripts = this.scripts.concat(scripts_data.data);
          this.hasMore = scripts_data.hasMore;
          if(scripts_data.data.length==0){
            this.scripts_band = false;
          }
        
        },
        placeText(the_text) {
          const textarea = this.$refs.script_text;
          const startPos = textarea.selectionStart;
          const endPos = textarea.selectionEnd;

          const textBefore = textarea.value.substring(0, startPos);
          const textAfter = textarea.value.substring(endPos, textarea.value.length);

          textarea.value = textBefore + the_text + textAfter;
          textarea.setSelectionRange(startPos + 6, startPos + 6); // Place cursor after inserted text

          this.script_text = textarea.value;
        },
        onSelectEmoji(emoji){
          let icon = emoji.i;
          this.placeText(icon);
          this.showHidetag = false;
        },
        openUpdateModal(text,id,updatedIndex){
          this.modal.display=true;
          this.modal.type='update_script';
          this.script_text = text;
          this.updateID = id;
          this.updatedIndex = updatedIndex;
        }
    },
    created(){
      if (this.$cookies.get('c_id')) {
        this.companyID = this.$cookies.get('c_id');
      }
      if(this.$cookies.get('userToken')) {
        this.userToken = this.$cookies.get('userToken');
      }
      this.$axios.get(baseURL+"opravnenia/userToken.php", {
        params: {
          userToken: this.userToken,
          companyID: this.companyID,
          place: 'header'
        }
        }).then((result) => {
          if (result.data.selectBranch) {
            let prev = this.$route.path;
            this.$router.push({name:'Branches', query: { prevRoute: prev } });
          } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
            this.$cookies.set('userToken','');
            window.location.href = '/';
          }
          if (result.data.myself) {this.myself = result.data.myself;}
          if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
          setTimeout(() => {
              this.loaded = true;
          }, 500); 
        }, (error) => {
          console.log(error);
        });
    },
    async mounted() {
        await this.setChattingCategories();
        await this.setChattingScripts();
    },
};
</script>
<style scoped>
.emojiWindow{
  top: 36%;
  left: 29%;
  
}
</style>