<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full mb-4 relative">
      <router-link :to="'../Settings'">
        <font-awesome-icon icon="fas fa-arrow-left" class="float-left text-lg p-2 text-button -mt-2" />
      </router-link>
      <h2 class="mt-2 text-xl text-contentText font-semibold text-center">Edit socials</h2>
    </div>

    <div>
        <table class="text-tableText bg-tableBG rounded">
            <tr>
                <th><div class="px-3">ID</div></th>
                <th class="cursor-pointer" @click="sortTable('platform','ASC')"><div class="px-3">Platform <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'platform'}" /></div></th>
                <th class="cursor-pointer" @click="sortTable('identifier','ASC')" ><div class="px-3">Name &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'name'}" /></div></th>
                <th><div class="px-3">Proxy</div></th>
                <th><div class="px-3">Archive</div></th>
            </tr>
            <tr v-for="(model, index) in socialData" :key="index" class="cursor-pointer hover:bg-sepBorder" :class="{'opacity-50 cursor-default': model.active== 0,'bg-tableRow1':index%2==0}">
                <td @click="navigateToModelForm(model)">{{model.id}}</td>                
                <td @click="navigateToModelForm(model)">{{model.platformName}}</td>
                <td @click="navigateToModelForm(model)"><div class="px-3">{{ model.name }}</div></td>
                <td @click="navigateToModelForm(model)">{{ model.proxy ? 'Yes' : 'No' }}</td>
                <td class="cursor-pointer" @click="modelDeArchive(index)" v-if="model.active == 0">Return from archive <font-awesome-icon icon="fas fa-undo" /></td>
                <td class="cursor-pointer" @click="modelArchive(index)" v-else><font-awesome-icon icon="fas fa-archive" /></td>
            </tr>
        </table>
    </div>
</template>

<script>
    import { get_models, archive_model } from '@/api';
    export default {
        data() {
            return { 
                socialData: [],
                sortColumn: null,
                loading:false,
                dialogWindow:false,
                dK:null,
                dialogData:null,
                willingnessList:[],
                dialogWillingness: []
            }
        },        
        methods: {
            navigateToModelForm(model) {
                if (model.active != 0) {
                    this.$router.push({ name: 'addSocial', query: {e:true, id: model.id} });
                }
            },
            sortTable(column,order) {
                this.sortColumn = column
                if (this.$cookies.get('orderColumnM') != column) {
                    this.$cookies.set('orderColumnM',column);
                }
                const properties = column.split('.');

                this.socialData.sort((a, b) => {
                    const valueA = properties.reduce((obj, key) => obj[key], a);
                    const valueB = properties.reduce((obj, key) => obj[key], b);

                    if (typeof valueA === 'number' && typeof valueB === 'number') {
                        // Both values are numbers. Do numerical comparison.
                        return order === 'ASC'
                            ? valueA - valueB
                            : valueB - valueA;
                    } else {
                        // Convert the values to strings and do string comparison.
                        const stringA = String(valueA);
                        const stringB = String(valueB);
                        return order === 'ASC'
                            ? stringA.localeCompare(stringB, undefined, { sensitivity: 'base' })
                            : stringB.localeCompare(stringA, undefined, { sensitivity: 'base' });
                    }
                });
            },
            async modelArchive(index) {
                if (confirm("Are you sure you want to archive this model?")) { 
                    this.socialData[index].free.active=0 
                    await archive_model(this.$cookies.get('userToken'),this.$cookies.get('c_id'),0,this.socialData[index].pair_id,null,1);
                }
            },
            async modelDeArchive(index) {
                if (confirm("Are you sure you want to return this model from archive?")) { 
                    this.socialData[index].free.active=1 
                    await archive_model(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,this.socialData[index].pair_id,null,1);
                }
            },
        },
        async created() {
            this.loading = true;
            this.socialData = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,null,null,0,1);
            this.loading = false;
            if (this.$cookies.get('orderColumnE')) {
                this.sortTable(this.$cookies.get('orderColumnM'),'ASC')
            }
        }
    }
</script>

<style scoped>
    td,th {
        padding:5px;
        border:1px solid #D1D5DB;
        text-align:center;
    }
</style>