<template>
    <div>
        <div>
            <div class="grid sm:grid-cols-2 gap-x-10">
                <div class="">
                    <h1 class="text-xl font-bold mb-2">Master Your OnlyFans Promotion</h1>
                    <p class="leading-relaxed block">Tracking Success Made Easy with Campaign Feature</p>
                    <br />
                    <h1 class="text-xl font-bold mb-2">Unlock Insights</h1>
                    <p class="leading-relaxed block">OnlyFans Campaigns Offer Simple Tracking for Promotional Efforts</p>
                </div>
                <div>
                    <h3 class="text-xl font-bold mb-4 text-button">Add new Tracking Link</h3>
                    <label for="trackingLinkName" class="block text-sm font-medium text-gray-700">Tracking Link Name</label>
                    <input type="text" id="trackingLinkName" v-model="trackingLinkName" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    <button type="button" @click="submit" class="bg-button text-buttonText rounded p-1 px-3 mt-5 cursor-pointer">Create a link</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { postTrackingLink } from '@/api';
</script>
<script>
export default {
    props: ['modelID'],
    emits: ['newLink'],
    data() {
        return {
            trackingLinkName: ''
        };
    },
    methods: {
        async submit() {
            const response = await postTrackingLink(this.$cookies.get('userToken'), this.$cookies.get('c_id'), this.modelID, this.trackingLinkName, 'tracking');
            console.log(response);
            if (response.success === true) {
                this.$emit('newLink', response.data);
            } else {
                alert("Error: " + response.message);
            }
            /*this.$emit('newLink', {
                name: this.trackingLinkName,
                url: 'https://www.google.com',
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                clicks_count: 0,
                subscribeCount: 0,
                earnings: 0,
                buyers: 0,
            });*/
        }
    },
};
</script>