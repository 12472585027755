<template>
  <transition-group name="fade">
    <slot v-if="$cookies.get('userToken')">
      <div class="rounded-t-3xl hidden sm:block sm:rounded-none bg-leftPanelBG w-full h-20 sm:h-full fixed bottom-0 sm:top-0 pt-4 text-white sm:justify-items-center z-50 smooth-width-transition" 
        @mouseover="bigMenu = true" @mouseleave="bigMenu = false" :class="{'sm:w-48':bigMenu, 'sm:w-14':!bigMenu}" style="box-shadow: rgba(100, 100, 100, 0.25) 5px 0px 5px;">
        
        <div v-if="!bigMenu" class="font-bold px-1 text-center" style="color:#10B981;font-size:28px;">X</div>
        <router-link v-else to="/analytics">
          <logo-component class="mx-auto w-max" />
        </router-link>

        <router-link to="/profile">      
          <section v-if="bigMenu && userName" class="mt-4 text-center w-full whitespace-nowrap pl-2 mb-9 mb-1 cursor-pointer">
            <div class="font-semibold">{{userName}}</div>
            <div class="w-44 truncate">{{branchName}}</div>
          </section>
          <section v-else class="h-16 mt-5 text-center"><font-awesome-icon icon="fa-solid fa-user" class="w-5 text-leftPanelText" /></section>
        </router-link>

        <div v-if="menuItems">
          <div v-for="(item, index) in menuItems" :key="index" class="sm:w-full cursor-pointer px-4 text-lg leading-14 my-5 text-leftPanelText hover:text-white font-semibold">
            <div class="whitespace-nowrap" @click="item.children ? toggleShowChildren(item.name) : false, item.click ? item.click : null">
              <router-link v-if="item.link" :to="'../../' + item.link">
                <font-awesome-icon :icon="item.icon" class="w-5 mr-2.5"/>
                <slot v-if="bigMenu">
                  {{ item.name }}
                  <font-awesome-icon v-if="item.children" icon="fas fa-angle-down" class="mt-1.5 absolute right-3" />
                </slot>
              </router-link>
              <div v-else @click="callMethod(item.method)">
                <font-awesome-icon :icon="item.icon" class="w-5 mr-2.5"/>
                <slot v-if="bigMenu">
                  {{ item.name }}
                  <font-awesome-icon v-if="item.children" icon="fas fa-angle-down" class="mt-1.5 absolute right-3" />
                </slot>
              </div>
            </div>
            <span v-show="item.children && item.showChildren && bigMenu" class="text-tooltipText text-left sm:leading-8 whitespace-nowrap z-50">
              <slot v-for="(child, chI) in item.children" :key="chI">
                <router-link v-if="child?.link" :to="'../../' + child.link">
                  <div class="pl-8 block sm:w-full font-normal cursor-pointer hover:bg-gray-100 hover:text-leftPanelBG rounded">{{ child.name }}</div>
                </router-link>
              </slot>
            </span>
          </div>
        </div>

        <div class="w-max ml-4 -mt-2 text-black" v-show="bigMenu">
          <label class="text-xs text-gray-100">{{ $t('language')}}:</label><br />
          <language-picker />
        </div>

        <div v-if="menuItems" @click="logout()" class="sm:w-full cursor-pointer p-4 text-lg leading-14 bottom-2 absolute text-leftPanelText hover:text-white font-semibold whitespace-nowrap">
            <font-awesome-icon icon="fas fa-arrow-right-from-bracket" class="w-5 mr-2.5"/>
            <slot v-if="bigMenu">{{ $t('logout') }}</slot>
        </div>
      </div>

      <div class="z-40 sm:hidden fixed bottom-0 left-0 bg-leftPanelBG text-white rounded-t-2xl w-full p-3 px-5">
        <font-awesome-icon @click="displayMenu = !displayMenu" icon="fas fa-bars" />
        <font-awesome-icon @click="logout()" icon="fas fa-arrow-right-from-bracket" class="absolute right-4 p-1" />
      </div>

      <section v-if="displayMenu" class="overflow-auto z-50 w-full h-full fixed p-5 top-0 left-0 bg-leftPanelBG text-white">
        <font-awesome-icon @click="displayMenu = false" icon="fas fa-xmark" class="absolute right-0 fa-lg top-1 p-5" />
        <div class="container p-3 pt-5 mx-auto leading-loose">
          <section class="whitespace-nowrap" v-if="userName">
            <span>{{$t('welcome_back')}} {{userName}}</span>
            <div class="mt-1"><strong>{{ $t('branch') }}:</strong> {{branchName}}</div>
          </section>
          <hr class="my-5 border-0 border-t border-sepBorder" />

          <slot v-if="menuItems">
            <slot v-for="(item,index) in menuItems" :key="index">
              <!-- Pridať child options -->
              <router-link @click="displayMenu = false" v-if="item.link" :to="'../../' + item.link">
                <div><font-awesome-icon :icon="item.icon" /> {{item.name}}</div>
              </router-link>
              <span v-else @click="callMethod(item.method)" class="cursor-pointer font-semibold"><font-awesome-icon :icon="item.icon" /> {{item.name}}</span>
              <div v-if="item.children">
                <router-link @click="displayMenu = false" :to="'../../' + child.link" v-for="(child,chI) in item.children" :key="chI">
                  <div class="pl-10"><font-awesome-icon icon="fas fa-circle" class="text-xs mr-2" /> {{child.name}}</div>
                </router-link>
                <hr class="my-1 border-0 border-t border-sepBorder"/>
              </div>
            </slot>
          </slot>
        </div>
      </section>

    </slot>
  </transition-group>
</template>

<script setup>
  import logoComponent from '@/components/logo.vue';
  import languagePicker from '@/components/languagePicker.vue';
</script>

<script>
  import { api_get_call } from '@/api.js';
  export default {
    props: ['permissions', 'myself'],
    components: {logoComponent, languagePicker},
    data() {
      return {
        bigMenu:false,
        menuOptions: [],
        menuOpened: false,
        compSelector:{show:false,over:false},
        userName: null,
        usernameOver:false,
        branchNameOver:false,
        branchName:null,
        backgroundOn:'0',
        displayMenu:false,
        menuItems: []
      }
    },
    methods: {
      toggleShowChildren(itemName) {
        const item = this.menuItems.find(item => item.name === itemName);
        if (item) {
          item.showChildren = !item.showChildren;
        }
      },
      callMethod(methodName) {
        if (methodName) {
          if (typeof this[methodName] === 'function') {
            this[methodName]();
          }
        }
      },
      changeBG() {
        this.backgroundOn = (parseInt(this.backgroundOn)+1) % 2;
        if (this.backgroundOn != '1') {
          document.body.classList.add('no-bg');
        } else {
          document.body.classList.remove('no-bg');
        }
        this.$cookies.set('bgOn',this.backgroundOn);
      },
      openMenu(index) {                
        menuOptions[index].over = true
      },
      closeMenu() {
        this.menuOpened = false;        
      },
      changeComp() {
        this.$cookies.set('c_id','');
        location.reload();
      },
      logout() {
        this.$axios.get(baseURL+"login/", {
          params: {
            action:'logout',
            userToken: this.$cookies.get('userToken')
          }
        }).then((result) => {
          this.$cookies.set('userToken','');
          this.$cookies.set('c_id','');
          window.location.href = '/';
          this.compSelector.show = false;
        }, (error) => {
          console.log(error);
        })
      },
      createMenu() {
        let items = [
          {
            name: this.$t('Creators'),
            icon: "fas fa-users",
            showChildren: false,
            children: [
              this.$getPermission(this.permissions, 20).is_granted || this.$getPermission(this.permissions, 6).is_granted || this.$getPermission(this.permissions, 7).is_granted || this.$getPermission(this.permissions, 8).is_granted || this.$getPermission(this.permissions, 9).is_granted
                ? {name: this.$t('Profiles'), link: "creators"} : null,
              //{name: "Insights", link: "analytics"},
              this.$getPermission(this.permissions, 16).is_granted ? {name: this.$t('Transactions'), link: "transactions"} : null,
            ].filter(child => child !== null) // Filter out the null values
          },
          {
            name: this.$t('Dashboard'),
            icon: "fas fa-chart-column",
            showChildren: false,
            children: [
              this.$getPermission(this.permissions, 1).is_granted ? {name: this.$t('Metrics'), link: "metrics"} : null,
              this.$getPermission(this.permissions, 1).is_granted ? {name: this.$t('Analytics'), link: "analytics"} : null,
            ].filter(child => child !== null) // Filter out the null values
          },
          {name: this.$t('Employees'), icon: "fas fa-users", showChildren: false,
            children: [
              this.$getPermission(this.permissions, 9).is_granted || this.$getPermission(this.permissions, 10).is_granted ? {name: this.$t('Profiles'), link: "employees"}:null,
              this.$getPermission(this.permissions, 4).is_granted || this.$getPermission(this.permissions, 5).is_granted ? {name: this.$t('Shifts'), link: "shifts"}:null,
              this.$getPermission(this.permissions, 4).is_granted || this.$getPermission(this.permissions, 5).is_granted ? {name: this.$t('Shift_earnings'), link: "sexting"}:null,
              this.$getPermission(this.permissions, 9).is_granted || this.$getPermission(this.permissions, 10).is_granted ? {name: this.$t('Chat Scripts'), link: "chattingScripts"}:null,
            ].filter(child => child !== null)
          },
          {name: this.$t('Finance'), icon: "fas fa-coins", showChildren: false,
            children: [
              this.$getPermission(this.permissions, 21).is_granted ? {name: this.$t('Payroll'), link: "payrolls"} : null,
              this.$getPermission(this.permissions, 21).is_granted ? {name: this.$t('Sexting_cost'), link: "sextingCosts"} : null,
              [1,2].includes(this.myself.position) ? {name: this.$t('Billing'), link: "Billing"} : null,
              //this.$getPermission(this.permissions, 21).is_granted ? {name: "Payouts", link: "payouts"} : null,
            ].filter(child => child !== null)
          },
          {name: this.$t('Traffic'), icon: "fas fa-bullhorn", showChildren: false,
            children: [
              this.$getPermission(this.permissions, 17).is_granted ? {name: this.$t('Tracking_Links'), link: "trackingLinks"} : null,
              this.$getPermission(this.permissions, 17).is_granted ? {name: this.$t('Trial_Links'), link: "trialLinks"} : null,
            ].filter(child => child !== null)
          },
          // Continue with other menu items that don't require permission checks or include them as needed
          {name: this.$t('Tools'), icon: "fa-solid fa-screwdriver-wrench", link: "tools", showChildren: false},
            {name: this.$t('Settings'), icon: "fa-solid fa-cogs", link: "settings", showChildren: false},
            (this.myself.multi_branch_access !== null && !this.myself.multi_branch_access) ? null : {name: this.$t('Switch_branch'), icon: "fa-solid fa-shuffle", method: "changeComp"},
          ].filter(item => item !== null && (item.children?.length > 0 || item.link || item.method));
        return items;
      }
    },
    created() {
      if (this.$cookies.get('bgOn') == null) {
        this.$cookies.set('bgOn','1');
        this.backgroundOn = '1';
      } else {
        this.backgroundOn = this.$cookies.get('bgOn');
      }
      if (this.$cookies.get('bgOn')!='1') {
        document.body.classList.add('no-bg');
      } else {
        document.body.classList.remove('no-bg');
      }
    },
    async mounted() {
        if (!(this.$cookies.get('c_id'))) {
            var companyID = null;
        } else {
            var companyID = this.$cookies.get('c_id');
        }
        this.$axios.get(baseURL+"menu/", {
          params: {
            userToken: this.$cookies.get('userToken'),
            companyID: companyID
          }
        }).then((result) => {
          if (result.data?.logout === true) {
            this.logout();
          }
          //this.menuOptions = result.data.list
          this.userName = result.data.username
          this.branchName = result.data.branchName
          if (this.$cookies.get('c_id')) {
            this.compSelector.show = true
          }
        }, (error) => {
          console.log(error);
        });
        if (this.permissions) {
          this.menuItems = this.createMenu();
        }
        
        //check if profile is filled out
        let agency_status = await api_get_call(`${baseURL}users/?action=agency_profile_status&companyID=${this.$cookies.get('c_id')}&userToken=${this.$cookies.get('userToken')}`);
        this.agency_status = agency_status.to_do;
        //condition if exact tasks id=1 and id=2 are done. If not, redirect to profile page
        if (this.agency_status && (this.agency_status[0].done == false || this.agency_status[1].done == false) || agency_status.subscription_active == false) {
          this.$router.push({name: 'Profile'});
        }
    }
  }
</script>

<style scoped>
  .smooth-width-transition {
    transition: width 0.3s ease-in-out; /* Adjust time and easing function as needed */
  }
</style>