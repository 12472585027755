<template>
    <span v-if="1==2">
        <div class="inline absolute ml-1 items-center cursor-pointer" @click="displayLegend = !displayLegend">
            <font-awesome-icon icon="fa-solid fa-filter" class="text-sm"/>
        </div>
        <div v-show="displayLegend" class="ml-10 absolute z-10 mt-2 w-max origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div v-if="filters_d" class="py-1" role="none">
                <div v-for="(filter,index) in filters_d" :key="index">
                    <slot>
                        <label @click="changedFilter(index)" class="text-gray-700 block px-3 py-1.5 text-sm show-columns-checkbox cursor-pointer">
                            <font-awesome-icon v-if="!filter.flag" icon="fa-regular fa-square" /><font-awesome-icon v-else icon="fas fa-square-check" />
                            {{ filter.name }}
                        </label>
                    </slot>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
export default {
    name: 'IconFilteringArraysComponent',
    props: ['filters'],
    emits: ['filteredPlatform'],
    data() {
        return {
            displayLegend:false,
            filters_d: []
        }
    },
    watch: {
        filters: {
            handler(newVal) {
                this.filters_d = [...newVal];
            },
            immediate: true,
            deep: true
        },
        filters_d: {
            handler(newVal) {
                this.$emit('filteredPlatform', newVal);
            },
            deep: true
        }
    },
    methods: {
        changedFilter(index) {
            this.filters_d[index] = { ...this.filters_d[index], flag: !this.filters_d[index].flag };
        }
    }
}
</script>