import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router/index.js'
import '@/assets/css/tailwind.css'
import VueCookies from 'vue3-cookies'
import axios from 'axios';
import moment from 'moment-timezone';
import { createI18n } from 'vue-i18n'
import en from './assets/i18n/en.json'
import es from './assets/i18n/es.json'
// Import and configure Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot, faCaretLeft,  faCaretRight, faPhone, faComments, faInfo, faAngleLeft, faAngleRight, faCaretUp, faServer, faCaretDown, faChartColumn, faSackDollar, faTableList, faAngleDown, faAnglesDown, faTableColumns, faTableCellsLarge, faHandshakeSimple, faCommentDollar, faComment, faCalendarDays, faDollarSign, faShareNodes, faScrewdriverWrench, faCogs, faShuffle, faArrowRightFromBracket, faXmark, faBars, faCircle, faSpinner, faFire, faQuestionCircle, faFilterCircleXmark, faFilter, faPlus, faGrip, faFloppyDisk, faDownload, faChevronDown, faChevronUp, faChevronRight, faUser, faChevronLeft, faUpRightFromSquare, faHeart, faLaptop, faChartBar, faRobot, faUserGroup, faUserShield, faArrowUpRightDots, faSeedling, faUsersCog, faArrowsRotate, faToggleOn, faToggleOff, faBullhorn, faDesktop, faReceipt, faPuzzlePiece, faStar, faStarHalfStroke, faPen, faArrowUp, faCheck, faCheckSquare, faSquare, faCirclePlus, faCircleMinus, faSort, faArrowLeft, faArchive, faUndo, faCirclePlay, faCirclePause, faTrashCan, faExclamation, faLink, faPenToSquare, faArrowUpRightFromSquare, faEye, faEyeSlash, faFileCircleExclamation, faCircleCheck, faFileCircleCheck, faExclamationCircle, faExclamationTriangle, faEllipsis, faUserLock, faLock, faLockOpen, faClockRotateLeft, faStickyNote, faRotateLeft, faRedo, faUserXmark, faRightLeft, faDatabase, faLayerGroup, faCoins, faUserPlus, faUserClock, faClock, faArrowTrendUp, faArrowTrendDown, faUsers, faMessage, faPaste, faArrowPointer, faComputerMouse, faPercent, faTrophy, faPeopleGroup, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare, faCopy as faCopy, faEnvelope as farEnvelope, faSmile } from '@fortawesome/free-regular-svg-icons'
import { faInstagram, faSquareFacebook, faWhatsapp, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const app = createApp(App)

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// Create i18n instance
const i18n = createI18n({
    locale: 'en', // set default locale
    fallbackLocale: 'en', // fallback locale in case the current locale translation is missing
    messages: {
      en,
      es
    }
})

app.config.globalProperties.$getPermission = function (permissions, permission_id) {
    const permission = permissions.find(p => p.permission_id === permission_id);
    return permission 
        ? { is_granted: Boolean(permission.is_granted), scope: permission.scope } 
        : null;
}

app.config.globalProperties.$axios = axios
app.config.globalProperties.$moment = moment;
app.component('FontAwesomeIcon', FontAwesomeIcon)
library.add(faLocationDot, faXTwitter, faInstagram, farEnvelope, faSquareFacebook, faInfo, faWhatsapp, faPhone, faServer, faComments, farSquare, faCaretLeft, faAngleLeft, faAngleRight, faCaretRight, faCaretUp, faCaretDown, faSackDollar, faChartColumn, faHandshakeSimple, faAngleDown, faAnglesDown, faTableList, faTableColumns, faTableCellsLarge, faCommentDollar, faComment, faCalendarDays, faDollarSign, faShareNodes, faScrewdriverWrench, faCogs, faShuffle, faArrowRightFromBracket, faXmark, faBars, faCircle, faSpinner, faFire, faQuestionCircle, faFilterCircleXmark, faFilter, faPlus, faUser, faGrip, faFloppyDisk, faDownload, faChevronUp, faChevronDown, faChevronRight, faChevronLeft, faUpRightFromSquare, faHeart, faToggleOn, faToggleOff, faSeedling, faLaptop, faChartBar, faRobot, faUserGroup, faArrowUpRightDots, faUserShield, faUsersCog, faArrowsRotate, faBullhorn, faDesktop, faReceipt, faPuzzlePiece, faStar, faStarHalfStroke, faPen, faArrowUp, faCheck, faCheckSquare, faSquare, faCirclePlus, faCircleMinus, faSort, faArrowLeft, faArchive, faUndo, faCirclePlay, faCirclePause, faTrashCan, faExclamation, faLink, faPenToSquare, faArrowUpRightFromSquare, faEye, faEyeSlash, faFileCircleExclamation, faCircleCheck, faFileCircleCheck, faExclamationCircle, faExclamationTriangle, faEllipsis, faUserLock, faLock, faLockOpen, faClockRotateLeft, faStickyNote, faRotateLeft, faRedo, faUserXmark, faRightLeft, faDatabase, faLayerGroup, faCoins, faUserPlus, faUserClock, faClock, faArrowTrendUp, faArrowTrendDown, faUsers, faMessage, faPaste, farSquare, faArrowPointer, faComputerMouse, faPercent, faCopy, faSmile, faTrophy, faPeopleGroup, faUserTie);

app.use(i18n).use(router).use(VueCookies)
.component('VueDatePicker', VueDatePicker)
.mount('#app')