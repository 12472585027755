<template>
    <div class="overflow-x-hidden">
        <header-panel />

        <div class="px-2">
            <section class="container mx-auto bg-white rounded-lg shadow-lg p-5 sm:p-10 mt-22">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10">
                    <div class="max-w-base">
                        <font-awesome-icon icon="fa-solid fa-arrow-left" class="ml-2 text-2xl cursor-pointer" @click="$router.push('/')" />
                        <h1 class="mt-5 text-3xl font-bold" v-html="$t('traffic_tracking.h1')"></h1>
                        <p class="max-w-lg mt-3 text-lg" v-html="$t('traffic_tracking.p1')"></p>
                    </div>
                    <div>
                        <img :src="trackingLinks" class="hidden sm:block" alt="Simple traffic tracking" title="Simple traffic tracking" />
                    </div>
                    <div @click="$router.push({ path: '/', query: { open: 'register' } });" class="col-span-full bg-growth w-full mx-auto rounded shadow-lg text-center text-white p-3 font-semibold text-lg cursor-pointer">
                        <font-awesome-icon icon="fa-solid fa-seedling" class="mr-3" /> {{ $t('Try_now') }}
                    </div>

                    <div class="col-span-full text-left relative mt-3">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 items-center">
                            <img src="@/assets/images/traffic_tracking.jpg" title="Simple traffic tracking tool" alt="Simple traffic tracking tool" class="mr-2 w-full rounded shadow"/>
                            <div class="sm:px-5">
                                <span class="ml-10 flex text-2xl font-bold text-blueColor">{{ $t('traffic_tracking.h2') }}</span>
                                <div class="absolute border border-growth text-growth font-bold p-0.5 px-3 rounded -rotate-12 -mt-14"><font-awesome-icon icon="fa-solid fa-fire" /> {{ $t("new") }}</div>
                                <p class="mt-5 leading-relaxed" v-html="$t('traffic_tracking.p2')"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <footer-panel />
    </div>
</template>

<script setup>
    import headerPanel from "@/components/landing/HeaderLanding.vue";
    import trackingLinks from '@/assets/images/tracking_links.svg';
    import FooterPanel from "@/components/landing/FooterLanding.vue"
</script>

<script>
    export default {
        components: {FooterPanel, headerPanel},
        data() {
            return {
                menuOpen:false,
            }
        },
    }
</script>