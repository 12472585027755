<template>
    <div>
        <div class="bg-green-300 rounded-full text-black p-2 fixed z-50 top-5 px-6 left-1/2" v-if="saved">{{ $t('saved') }}</div>
        Base salary/month: <input class="mt-3 w-16 mx-2 text-center" type="number" v-model="baseSalary"> $ for 
        <input class="w-16 mx-2 text-center" type="number" v-model="baseSalaryH"> hours/month
        <button class="ml-5 bg-button text-white rounded p-1 px-3 cursor-pointer" @click="saveCompanySalaryMonthly()"><font-awesome-icon icon="fas fa-floppy-disk" /> Save</button>
    </div>
</template>

<script>
    export default {
        props: ['salary','sHours'],
        data() {
            return {
                baseSalaryH:0,
                baseSalary:0,
                saved:false
            }
        },
        methods: {
            saveCompanySalaryMonthly() {
                if (this.baseSalary>0) {
                    this.$axios.post(baseURL+"settingspost/", {
                        action: 'salaryMonthBase',
                        baseSalary: this.baseSalary,
                        baseSalaryHours: this.baseSalaryH,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        this.saved = true;
                        setTimeout(() => (this.saved = false),500);
                    }, (error) => {
                        console.log(error);
                    })
                }
            }
        },
        created() {
            this.baseSalaryH = this.sHours;
            this.baseSalary = this.salary;
        }
    }
</script>