<template>
    <div class="bg-growth rounded-full text-buttonText p-2 fixed top-5 px-6 left-1/2 z-40" v-if="saved">{{ $t('saved') }}</div>
    <div v-if="socialData" class="w-full mb-4 relative">
        <router-link to="../archivedSocials" v-if="$route.query.routedFrom == 'archived'">
            <font-awesome-icon icon="fas fa-arrow-left" class="float-left text-lg p-2 text-button -mt-2" />
        </router-link>
        <router-link to="../socials" v-else-if="$route.query.e">
            <font-awesome-icon icon="fas fa-arrow-left" class="float-left text-lg p-2 text-button -mt-2" />
        </router-link>
        <router-link to="../../settings" v-else>
            <font-awesome-icon icon="fas fa-arrow-left" class="float-left text-lg p-2 text-button -mt-2" />
        </router-link>
        <h2 class="mt-1 text-xl text-contentText font-semibold text-center" v-if="$route.query.e">Edit an account</h2>
        <h2 class="mt-1 text-xl text-contentText font-semibold text-center" v-else>Add an account</h2>
    </div>
    <div class="text-panelText pb-2">
        <div class="ml-7 w-60" v-if="!$route.query.e">
            <label class="text-sm">Platform:</label><br />
            <select class="border-b rounded border-sepBorder w-full p-1 px-3 outline-0" v-model="socialData.platform">
                <option value="3">Reddit</option>
                <option value="4">X</option>
                <option value="5">Instagram</option>
            </select>
        </div>
        <div v-else class="ml-7">
            <label class="text-sm">Platform:</label><br />
            <strong>{{socialData.platformName}}</strong>
        </div>
        <div class=" p-5 pb-10 px-7 rounded-lg  w-96">
            <form class="leading-none">
                <label class="text-sm">Username</label><br />
                <div class="relative mb-2">
                    <input class="border-b rounded border-sepBorder w-full p-1 px-3" :class="{'bg-red-100' : (submitted && !socialData.email)}" autocomplete="username" type="text" placeholder="Username/email" v-model="socialData.email">
                    <font-awesome-icon icon="fas fa-exclamation" class="text-red-500 absolute ml-3 mt-2" v-if="submitted && !socialData.email" />
                </div>
                <label class="text-sm">Password</label><br />
                <div class="relative mb-2">
                    <input class="border-b rounded border-sepBorder w-full p-1 px-3" :class="{'bg-red-100' : (submitted && !socialData.password)}" autocomplete="new-password" v-bind:type="displayPass.f ? 'text' : 'password'" placeholder="Password" v-model="socialData.password">
                    <font-awesome-icon icon="fas fa-exclamation" class="text-red-500 absolute ml-3 mt-2" v-if="submitted && !socialData.password" />
                    <font-awesome-icon :icon="seePass(displayPass.f)" class="absolute text-gray-700 right-2 mt-1 text-center p-1 cursor-pointer" @click="displayPass.f = !displayPass.f"  /><br /> 
                </div>
                <div class="mt-3">
                    <label class="text-sm text-gray-500">Name (note)</label><br />
                    <input class="border-b rounded border-sepBorder w-full p-1 px-3" type="text" placeholder="Name (optional)" v-model="socialData.name"><br />
                </div>
            </form>
        </div>

        <section class="border-t border-sepBorder p-5 pb-2">
            <label class="text-sm">Proxy (if needed)</label><br />
            <input class="border-b rounded border-sepBorder w-full p-1 px-3 w-96" id="modelProxy" type="text" placeholder="Proxy" v-model="socialData.proxy">
            <span class="text-xs">0.0.0.0:0000:USERNAME:PASSWORD</span>
        </section>
        
        <button v-if="$route.query.routedFrom != 'archived'" @click="saveAccount()" class="delay-75 duration-75 transition-all float-right bg-button rounded cursor-pointer p-2 mb-4 px-5 text-buttonText text-center font-semibold uppercase">
            <font-awesome-icon icon="fas fa-spinner" class="animate-spin" v-if="loading" /> {{buttonValue}}
        </button>
    </div>
    <div class="clear-both"></div>

    <model-access :dialog="dialog" />
</template>

<script setup>
    import modelAccess from "@/components/settings/models/model_access.vue"
</script>

<script>
    import { save_social, get_models } from '@/api';
    export default {
        components: {modelAccess},
        data() {
            return {
                dialog: {type:1,show:false,title:this.$t('tools_text.main_h1'),data:{tool:null,model:null}},
                socialData: {
                    name: null, 
                    email:null, 
                    password:null,
                    platform: '3',
                    proxy:null
                },
                loading: false,
                buttonValue: this.$t('create_account'),
                saved:false,
                submitted:false,
                createdForm:false,
                groupIndex:null,
                displayForm:false,
                displayPass: {f:false,p:false,m:false},
                modelID: this.$route.query.id,
            }
        },
        methods: {
            seePass(condition) {
                return !condition ? 'fas fa-eye' : 'fas fa-eye-slash';
            },
            updateSelectedGroup() {
                this.socialData.team_id = 0;  // reset the selected team whenever the group changes
            },
            openToolsDialog(tp) {
                this.dialog.type = 1;
                this.dialog.title = this.$t('tools_text.main_h1');
                this.dialog.show = true;
                this.dialog.data.tool = this.socialData[tp].tools;
                this.dialog.data.model = this.socialData[tp].id;
            },
            pad(num, size) {
                while (num.length < size) num = "0" + num;
                return num;
            },
            async saveAccount() {
                this.submitted = true;
                
                if (this.socialData.email && this.socialData.password) {
                    this.loading = true
                    if (this.modelID) {
                        this.buttonValue = `${this.$t('updating')}...`;
                        let b_l = this.$t('update');
                        setTimeout(() => (this.loading = false, this.buttonValue = b_l), 1000);
                    }else {
                        this.buttonValue = `${this.$t('creating')}...`
                        let b_l = this.$t('save_changes');
                        setTimeout(() => (this.loading = false, this.buttonValue = b_l), 1000);
                    }
                } else {
                    alert(this.$t('fill_required_fields'));
                    return;
                }
                
                const result = await save_social(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.socialData);
                console.log(result);
                if (result && result.message && result?.success === false) {
                    alert(result.message)
                    return;
                } else {
                    //if(!this.modelID) {this.socialData = {platform:this.socialData.platform, free: {name: null,accountId: {filled:false,value:null}},paid: {name: null,accountId: {filled:false,value:null}},code: null}; this.submitted = false;}
                    this.saved = true;
                    setTimeout(() => (this.saved = false, this.$router.push({name:'socials'}), 1000));
                }
            },
        },
        async created() {
            //get list of countries
            if (this.modelID) {
                this.buttonValue = this.$t('update');
                this.socialData = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),null,this.modelID,null,0,1);
            }
        }
    }
</script>