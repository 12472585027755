<template>
    <div class="text-xl text-contentText font-bold text-center">Searchforadults - Onlyfans directory</div>
    <div class="mt-5 grid grid-cols-1 md:grid-cols-2 gap-10">
        
        <div class="hidden md:block">
            <div class="flex items-center w-full p-2 px-3 rounded bg-gray-100">
                <div class="rounded-full h-16 w-16 block bg-gray-200" :style="{ backgroundImage: `url(${model.avatar})`, backgroundSize: 'cover', backgroundPosition: 'center' }"></div>
                <div class="ml-6">
                    <h2 class="font-bold">{{model.username ? '@' + model.username : ''}}</h2>
                    <div class="my-1">
                        <slot v-if="model.platform==1"><span class="hidden">O</span><img src="@/assets/images/of_logo.png" title="Onlyfans" alt="Onlyfans" class="h-5"/></slot>
                        <slot v-else-if="model.platform==2"><span class="hidden">F</span><img src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="h-5"/></slot>
                    </div>
                </div>
            </div>
            <h3 class="text-lg font-bold mt-5">Want to get featured in our OnlyFans creators directory?</h3>
            <p class="text-sm">
                You’ll only pay a small percentage of the sales generated by the clients we bring to you through revenue sharing.<br />
                We will monitor all conversions using a tracking link that we create in your OnlyFans account.<br />
                You can view these conversions anytime in the <a href="/trackingLinks" target="_blank" class="font-bold underline italic text-semibold">Tracking Links</a> section.<br />
                <a href="https://searchforadults.com" target="_blank" class="font-semibold underline italic text-button">www.searchforadults.com</a>
            </p>
        </div>
        <section>
            <slot v-if="link.linkURL || link.url || loading">
                <h3 class="text-lg font-bold mb-2 text-button">Tracking link</h3>
                <div class="flex justify-between whitespace-nowrap truncate p-1.5 my-1 px-4 border border-sepBorder rounded w-full font-light">
                    <div class="truncate pr-5">{{link.url ? link.url : link.linkURL}}</div>
                </div>
            </slot>
            <slot v-else>
                <div class="truncate pr-5 text-button font-bold text-lg">No tracking link found</div>
                <button @click="generate_new_sfa_link()" class="mt-4 whitespace-nowrap cursor-pointer w-min p-1 px-3 rounded bg-button text-white">
                    <font-awesome-icon v-if="buttonLoading" icon="fas fa-spinner" class="animate-spin mr-1" />
                    <font-awesome-icon v-else icon="fas fa-plus" class="mr-1" /> Generate new link
                </button>
            </slot>
        </section>
    </div>
</template>

<script setup>
    import { api_get_call, api_post_call, postTrackingLink } from '@/api.js';
</script>

<script>
    export default {
        props: ['model'],
        data() {
            return {
                link: {linkURL: '', url: ''},
                posturl: null,
                loading: true,
                buttonLoading: false
            }
        },
        methods: {
            async generate_new_sfa_link() {
                this.buttonLoading = true;
                let result = await postTrackingLink(this.$cookies.get('userToken'), this.$cookies.get('c_id'), this.model.free.id, "Searchforadults", "tracking");
                this.link = {url: result.data.url};
                
                await api_post_call(`${baseURL}models/`, {
                    action: 'SFA_add_tracking_link',
                    model_id: this.model.free.id,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id'),
                    tracking_link: result.data.url
                });

                this.buttonLoading = false;
            }
        },
        async mounted() {
            this.link = await api_get_call(`${baseURL}models/?action=sfa_link&model_id=${this.model.free.id}&userToken=${this.$cookies.get('userToken')}&companyID=${this.$cookies.get('c_id')}`);
            console.log(this.link);
            this.loading = false;
        }
    }
</script>