<template>
    <leftMenu v-if="permissions" :myself="myself" :permissions="permissions" />
    <header-filter :pageTitle="'Billing'" :myself="myself"/>

    <div class="sm:ml-20 relative mt-24 mb-20 max-w-7xl bg-squares text-panelsText sm:p-5 sm:rounded-xl sm:border border-sepBorder">
        <slot v-if="monthly_billing.length>0">
            <div class="bg-gray-100 grid grid-cols-4 sm:grid-cols-6 gap-4 w-full p-3 border border-sepBorder sm:rounded-t-lg text-center font-semibold text-gray-600">
                <div class="pl-2 sm:pl-10 col-span-2 text-left">{{ $t('invoice_for') }}</div>
                <div>{{ $t('amount') }}</div>
                <div class="hidden sm:block">{{ $t('issued') }}</div>
                <div class="hidden sm:block">{{ $t('status') }}</div>
                <div></div>
            </div>
            <div class="w-full col-span-6 grid grid-cols-4 sm:grid-cols-6 gap-4 p-3 py-5 text-center border border-t-0 border-sepBorder text-gray-500" v-for="(bill,index) in monthly_billing" :key="index">
                <div class="pl-2 sm:pl-10 col-span-2 text-left font-semibold text-black text-sm">{{ $moment(bill.date_from).format('DD.MM.') + ' - ' + $moment(bill.date_to).format('DD.MM.YYYY')}}</div>
                <div><span class="hidden sm:contents">USD</span> $ {{bill.total_amount}}</div>
                <div class="hidden sm:block">{{$moment(bill.created_at).format('DD.MM.YYYY')}}</div>
                <div class="hidden sm:block w-max rounded mx-auto p-0.5 px-2 text-sm font-semibold" :class="!bill.paid ? 'bg-decline bg-opacity-10 text-decline' : 'bg-growth bg-opacity-10 text-growth'">
                    <font-awesome-icon v-if="bill.paid" icon="fas fa-check" class="mr-1" />
                    <font-awesome-icon v-else icon="fas fa-times" class="mr-1" />
                    {{bill.paid == true ? $t('paid'): $t('not_paid')}}
                </div>
                <div class="cursor-pointer" @click="openDetail(index)">
                    <font-awesome-icon v-if="bill.open" icon="fa-solid fa-chevron-up" />
                    <font-awesome-icon v-else icon="fa-solid fa-chevron-down" />
                </div>
                <section class="col-span-full text-left leading-loose m-2 mx-1 sm:mx-5 rounded-lg border border-sepBorder" v-show="bill.open">
                    <div class="bg-gray-100 grid grid-cols-3 sm:grid-cols-6 gap-4 w-full p-3 border border-sepBorder sm:rounded-t-lg text-center font-semibold text-gray-600">
                        <div class="pl-2 text-left">{{ $t('creator') }}</div>
                        <div>{{ $t('platform') }}</div>
                        <div class="hidden sm:block">{{ $t('active_days') }}</div>
                        <div class="hidden sm:block">{{ $t('cost_basic') }}</div>
                        <div class="hidden sm:block">{{ $t('add_ons') }}</div>
                        <div>{{ $t('summary') }}</div>
                    </div>
                    <div>
                        <div class="grid grid-cols-3 sm:grid-cols-6 px-3 border-b border-sepBorder py-1" v-for="(detail,index) in bill.data" :key="index">
                            <div>{{detail.name}}</div>
                            <div class="w-20 mx-auto">
                                <slot v-if="detail.platform==1"><img src="@/assets/images/of_logo.png" title="Onlyfans" alt="Onlyfans" class="mt-1.5 h-5 mx-auto"/></slot>
                                <slot v-else-if="detail.platform==2"><img src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="mt-1.5 h-5 mx-auto"/></slot>
                            </div>
                            <div class="hidden sm:block text-center">
                                <span>{{detail.total_days}}</span>
                            </div>
                            <div class="hidden sm:block text-center">
                                <span>$ {{detail.total_cost.toFixed(2)}}</span>
                            </div>
                            <div v-if="detail.tools" class="hidden sm:block text-center">
                                <span>$ {{(detail.tools.reduce((toolsTotal,item) => toolsTotal + item.final_cost, 0)).toFixed(2)}}</span>
                            </div>
                            <div class="text-center">
                                <span>$ {{(detail.total_cost + detail.tools.reduce((toolsTotal,item) => toolsTotal + item.final_cost, 0)).toFixed(2)}}</span>
                            </div>
                        </div>
                        <div v-if="bill?.data" class="col-span-full bg-gray-100 grid grid-cols-3 sm:grid-cols-6 w-full p-1 sm:rounded-b-lg text-center font-semibold text-gray-600">
                            <div class="pl-2 text-left">Summary</div>
                            <div class="hidden sm:block col-start-4">$ {{bill.data.reduce((total, item) => total + item.total_cost, 0).toFixed(2)}}</div>
                            <div class="hidden sm:block">$ {{(bill?.data.reduce((total, detailItem) => total + (detailItem.tools ? detailItem.tools.reduce((toolsTotal, item) => toolsTotal + item.final_cost, 0) : 0), 0)).toFixed(2)}}</div>
                            <div v-if="bill?.data?.length>0" class="pr-5 col-start-3 sm:col-start-auto text-center">$ {{
                                (
                                    bill.data.reduce((total, item) => total + item.total_cost, 0) +
                                    bill.data.reduce((total, detailItem) => 
                                        total + (detailItem.tools ? detailItem.tools.reduce((toolsTotal, item) => toolsTotal + item.final_cost, 0) : 0)
                                    , 0)
                                ).toFixed(2)
                            }}</div>
                        </div>
                    </div>
                </section>
            </div>
        </slot>
        <div class="text-center my-5" v-else>{{ $t('no_invoices_yet') }}</div>
    </div>
</template>

<script setup>
    import headerFilter from "@/components/headerFilter.vue"
    import ofdashButton from '@/components/ofdashButton.vue'
    import leftMenu from "@/components/LeftMenu.vue"
</script>

<script>
    import { get_monthly_billing, models_on_tools, api_get_call } from "@/api"
    export default {
        components() {
            headerFilter,
            ofdashButton,
            leftMenu
        },
        data() {
            return {
                monthly_billing: [],
                loading:false,
                myself:null,
                companyID:null,
                permissions:null
            }
        },
        methods: {
            async openDetail(index) {
                if (this.monthly_billing[index].open == false && this.monthly_billing[index].detail == null) {
                    this.monthly_billing[index].detail = await models_on_tools(this.$cookies.get('userToken'),this.$cookies.get('c_id'), this.monthly_billing[index].date_from, this.monthly_billing[index].date_to);
                }
                this.monthly_billing[index].open = !this.monthly_billing[index].open;
            }
        },
        async created() {
            this.loading = true;
            if (!(this.$cookies.get('c_id'))) {
                var companyID = null;
            } else {
                var companyID = this.$cookies.get('c_id');
            }
            this.$axios.get(baseURL+"opravnenia/userToken.php", {
                params: {
                    userToken: this.$cookies.get('userToken'),
                    companyID: companyID,
                    place: 'header'
                }
            }).then((result) => {
                if (result.data.selectBranch) {
                    let prev = this.$route.path;
                    this.$router.push({name:'Branches', query: { prevRoute: prev } });
                } else if ((result.data.logout) && (this.$router.currentRoute._rawValue.fullPath != "/Login")) {
                    this.$cookies.set('userToken','');
                    window.location.href = '/';
                }
                if (result.data.myself) {this.myself = result.data.myself;}
                if (result.data.blockedColumns) {this.blockedColumns = result.data.blockedColumns;}
                if (result.data.new_permissions) {this.permissions = result.data.new_permissions;}
                if (![1,2].includes(result.data.myself.position)) {
                    //redirect to 404 in case of no permissions
                    this.$router.push({name:'NotFound'});
                }
            }, (error) => {
                console.log(error);
            });
            this.monthly_billing = await get_monthly_billing(this.$cookies.get('userToken'),this.$cookies.get('c_id'), 0, 10);
            this.loading = false;
        }
    }
</script>