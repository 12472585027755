<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>
    
    <div class="clear-both"></div>

    <slot v-if="!refreshing && metricsTable && metricsTable.data && metricsTable.data.length>1">
        <transition name="fade">
            <section :class="{'mt-8': period != 'daily'}">
                <h3 class="text-textOnBkg font-bold text-lg sm:text-2xl m-2 float-left">{{tableTitle}}<small v-if="period == 'daily'" class="text-textOnBkg mt-2 font-light text-sm ml-3">*The earnings difference may be related to referrals.</small></h3>
                <div class="float-right text-textOnBkg m-2">
                    <slot v-if="period == 'daily'">
                        <span class="font-normal">Display: </span>
                        <span class="pl-4 text-sm sm:text-base cursor-pointer" @click="displayComments = !displayComments"><font-awesome-icon class="mr-0.5" :icon="getIconClass(displayComments)" /> Comments</span>
                        <span class="pl-4 text-sm sm:text-base cursor-pointer" v-if="blockedColumns.renewOn == 1" @click="displayRenewOn = !displayRenewOn"><font-awesome-icon class="mr-0.5" :icon="getIconClass(displayRenewOn)" /> Renew</span>
                        <span class="pl-4 text-sm sm:text-base cursor-pointer" v-if="blockedColumns.subsPaid == 1" @click="displaySubs = !displaySubs"><font-awesome-icon class="mr-0.5" :icon="getIconClass(displaySubs)" /> Subs Paid</span>
                    </slot>
                    <button class="bg-button text-buttonText ml-5 border border-sepBorder rounded p-1 px-3 -mt-1 cursor-pointer" @click="exportToCSV"><span ref="exportLoading" class="hidden"><font-awesome-icon icon="fas fa-spinner" class="animate-spin mr-2" /></span>Export to CSV</button>
                </div>
                <div class="clear-both"></div>

                <div class="parent" ref="parent">
                    <div @click="resetComments()" v-if="hideCom && editable == true" class="z-10 absolute w-full h-full bg-black bg-opacity-20"></div>
                    <table ref="tableRef" class="bg-tableBG text-tableText whitespace-nowrap rounded font-normal border border-sepBorder" :style="{ 'max-height': period === 'daily' ? '535px' : '450px' }">
                        <thead ref="theadRef" class="sticky top-0">
                            <tr class="bg-tableBG text-tableText">
                                <th class="date bg-tableBG z-9 border-r border-sepBorder" v-bind:colspan="1"></th>
                                <th class="" v-if="selectedModel == null"></th>
                                <th class="cursor-pointer activeFans border-l border-sepBorder" v-bind:colspan="displayColumns.free == true ? '6' : '4'" @click="displayColumns.free = !displayColumns.free">Fans<slot> free</slot> <font-awesome-icon :icon="plusMinusIcon(displayColumns.free)" class="fas ml-2" /></th>
                                <th class="messagesCount border-l border-sepBorder" colspan="1">Mass DM</th>
                                <th class="transactionsValue border-l border-sepBorder" v-bind:colspan="selectedPlatform == 2 ? '2' : blockedColumns.subsPaid == 1 && displaySubs == 1 ? '3' : '3'">Earnings</th>            
                                <th class="average_fans border-l border-sepBorder" v-bind:class="{hidden: selectedModel != null}" colspan="2"></th>
                            </tr>
                            <tr>
                                <th class="date bg-tableBG z-9 border-r border-sepBorder">Date</th>
                                <th class="models" v-bind:class="{hidden: selectedModel != null}">Models</th>
                                <th class="activeFans border-l border-sepBorder">Active</th>
                                <slot v-if="displayColumns.free == true && selectedPlatform==1">
                                    <th class="expiredFans">Expired</th>
                                    <th>NEW</th>
                                </slot>
                                <th>Change</th>
                                <th>Likes</th>
                                <th v-if="blockedColumns.renewOn == 1 && displayRenewOn == 1">Renew</th>
                                <th class="messagesCount border-l border-sepBorder border-r">MM count</th>
                                <th v-if="blockedColumns.subsPaid == 1 && displaySubs == 1">Subs. Paid</th>
                                <th class="transactionsValue free">Sales</th>
                                <th>Total</th>
                                <th class="average_fans whitespace-normal border-l border-sepBorder" v-bind:class="{hidden: selectedModel != null}">Fans growth/ prospect</th>
                                <th class="average_sales whitespace-normal" v-bind:class="{hidden: selectedModel != null}">Earnings/ prospect</th>
                            </tr>
                        </thead>
                        <tbody ref="tbodyRef" v-if="metricsTable" @scroll="syncScroll">
                            <slot v-for="(metric,index) in metricsTable.data" :key="index">
                                <slot v-if="metric.models.free">
                                    <tr v-if="index>0 || (period == 'daily' && (index == 0 && isDate1BeforeDate2(metric.forDate, dateFrom)))" v-bind:class="{'bg-tableRow1': index % 2 == 0}">
                                        <td :title="metric.dayName" v-bind:class="{'bg-tableRow1': index % 2 == 0,'bg-tableBG': index % 2 == 1}" class="z-9 ">{{metric.forDate}}</td>

                                        <td v-if="metric.models.modelNames" v-bind:class="{hidden: selectedModel != null}" @mouseover="hoverModelsIndex = index" @mouseleave="hoverModelsIndex = null">
                                            {{ addSpace(metric.models?.modelNames?.length) }}
                                            <div v-if="hoverModelsIndex == index" class="absolute max-h-60 overflow-y-scroll z-30 bg-gray-200 text-panelsText -mt-7 ml-20 p-2 px-5 text-sm rounded-lg">
                                                <ol><li v-for="(model, mIndex) in metric.models.modelNames" :key="mIndex">{{model}}</li></ol>
                                            </div>
                                        </td>

                                        <td @contextmenu.capture.prevent="showMetricsOptions('faf'+index,index)" class="relative w-min p-0 border-l border-black"
                                            v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='faf'+index,'border-2 border-decline':displayComments && metric.models.free.comments.fans_active.length}">
                                            <slot v-if="metric.models.free.comments">
                                                <addComment v-if="metric.models.free"
                                                @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                                :editable="editable" :optionPosition="optionPosition" :column="'fans_active'" :dataName="'eFansF'" :mType="'free'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.free.comments.fans_active" 
                                                :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.free.fans_active" :displayData="metric.models.free.fans_active" :ind="index" :showOptions="showOptions" :optionIndex="'faf'+index" />
                                            </slot>
                                        </td>
                                        
                                        <slot v-if="displayColumns.free">
                                            <td v-if="index == 0 && isDate1BeforeDate2(metric.forDate, dateFrom)">0</td>
                                            <td v-else @contextmenu.capture.prevent="showMetricsOptions('fef'+index,index)" class="relative w-min p-0"
                                                v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='fef'+index,'border-2 border-decline':displayComments && metric.models.free.comments && metric.models.free.comments.fans_expired.length,
                                                'text-decline': (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) > 0,'text-growth': (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) < 0}">
                                                    <slot v-if="metric.models.free.comments">
                                                        <addComment v-if="metric.models.free"
                                                        @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                                        :editable="editable" :optionPosition="optionPosition" :column="'fans_expired'" :dataName="'exFansF'" :mType="'free'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.free.comments.fans_expired.length" 
                                                        :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.free.fans_expired" :displayData="metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired" :ind="index" :showOptions="showOptions" :optionIndex="'fef'+index" />
                                                    </slot>
                                            </td>
                                            <td v-if="index == 0 && isDate1BeforeDate2(metric.forDate, dateFrom)">0</td>
                                            <td v-else v-bind:class="{'text-growth': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) > 0,
                                                                'text-decline': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) < 0}">
                                                {{ addSpace(metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) }}
                                            </td>
                                        </slot>

                                        <td v-if="index == 0 && isDate1BeforeDate2(metric.forDate, dateFrom)">0</td>
                                        <td v-else 
                                            v-bind:class="{'text-growth': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) > 0,
                                                            'text-decline': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) < 0}">
                                            {{ addSpace((metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired)) }}
                                        </td>
                                        
                                        <td v-if="index == 0 && isDate1BeforeDate2(metric.forDate, dateFrom)">0</td>
                                        <td v-else>
                                            {{ addSpace(parseInt(metric.models.free.likes)) }}
                                        </td>

                                        <slot v-if="metric.models.free.renewOn">
                                            <td class="relative" @contextmenu.capture.prevent="showMetricsOptions('ren'+index,index)" v-if="blockedColumns && blockedColumns.renewOn == 1 && displayRenewOn"
                                                v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='ren'+index,'border-2 border-decline':displayComments && metric.models.free.comments && metric.models.paid.comments.renewOn.length}">
                                                <slot v-if="metric.models.free.comments">
                                                    {{metric.models.free.renewOn}}
                                                </slot>
                                            </td>
                                        </slot>
                                        <slot v-else>
                                            <td class="relative" @contextmenu.capture.prevent="showMetricsOptions('ren'+index,index)" v-if="blockedColumns && blockedColumns.renewOn == 1 && displayRenewOn"
                                                v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='ren'+index,'border-2 border-decline':displayComments && metric.models.paid.comments && metric.models.paid.comments.renewOn.length}">
                                                <slot v-if="metric.models.paid.comments">
                                                    <addComment v-if="metric.models.paid"
                                                    @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                                    :editable="editable" :optionPosition="optionPosition" :column="'renewOn'" :dataName="'eRen'" :mType="'PAID'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.paid.comments.renewOn" 
                                                    :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.paid.renewOn" :displayData="metric.models.paid.renewOn" :ind="index" :showOptions="showOptions" :optionIndex="'ren'+index" />
                                                </slot>
                                            </td>
                                        </slot>

                                        <td @contextmenu.capture.prevent="showMetricsOptions('mmf'+index,index)" class="relative border-l-2 border-black"
                                            v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='mmf'+index,'border-2 border-decline':displayComments && metric.models.free.comments && metric.models.free.comments.messages[0]}">
                                            <slot v-if="metric.models.free.comments">
                                                <addComment v-if="metric.models.free"
                                                @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                                :editable="editable" :optionPosition="optionPosition" :column="'messages'" :dataName="'eDMF'" :mType="'free'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.free.comments.messages" 
                                                :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.free.messages" :displayData="metric.models.free.messages" :ind="index" :showOptions="showOptions" :optionIndex="'mmf'+index" />
                                            </slot>
                                        </td>


                                        <td @contextmenu.capture.prevent="showMetricsOptions('sbP'+index,index)" v-if="blockedColumns.subsPaid == 1 && displaySubs == 1"
                                            class="relative border-l border-black"
                                            v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='sbP'+index,'border-2 border-decline':displayComments && metric.models.paid.comments && metric.models.paid.comments.subsEarnings[0]}">

                                            <slot v-if="metric.models.paid.comments">
                                                <addComment v-if="metric.models.paid"
                                                @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                                :editable="editable" :optionPosition="optionPosition" :column="'subsEarnings'" :dataName="'sbP'" :mType="'PAID'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.paid.comments.subsEarnings" 
                                                :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.paid.subsEarnings + metric.models.free.subsEarnings" :displayData="'$ '+parseFloat(metric.models.paid.subsEarnings + metric.models.free.subsEarnings).toFixed(2)" :ind="index" :showOptions="showOptions" :optionIndex="'sbP'+index" />
                                            </slot>
                                        </td>

                                        <td @contextmenu.capture.prevent="showMetricsOptions('earnF'+index,index)" class="relative" @click="openReverseAlert(parseFloat(metric.models.free.reverseSum).toFixed(2));"
                                            v-bind:class="{'border-2 border-yellow-200':hideCom && editable == true && showOptions.index=='earnF'+index,'bg-red-500/50':metric.models.free.reverseSum,'border-2 border-decline':displayComments && metric.models.free.comments && metric.models.free.comments.transactionSum[0]}">
                                            <slot v-if="metric.models.free.comments">
                                                <addComment v-if="metric.models.free"
                                                @hideF="hideF = $event" @changedData="changedData = $event" @changedComment="changedComment = $event"
                                                :editable="editable" :optionPosition="optionPosition" :column="'transactionSum'" :dataName="'eEarnF'" :mType="'free'" :selectedModel="selectedModel" :date="metric.forDate" :commentData="metric.models.free.comments.transactionSum" 
                                                :displayComments="displayComments" :hideCom="hideCom" :editData="metric.models.free.transactionSum" :displayData="'$ '+parseFloat(parseFloat(metric.models.free.transactionSum) - parseFloat(metric.models.free.reverseSum)).toFixed(2)" :ind="index" :showOptions="showOptions" :optionIndex="'earnF'+index" />
                                            </slot>
                                        </td>

                                        <td>$ {{ addSpace(parseFloat(metric.models.free.transactionSum-metric.models.free.reverseSum+metric.models.paid.transactionSum-metric.models.paid.reverseSum).toFixed(2)) }}</td>

                                        <td v-if="index == 0 && isDate1BeforeDate2(metric.forDate, dateFrom)" style="border-left: 2px solid black;" v-bind:class="{hidden: selectedModel != null}">0</td>
                                        <td class="border-l border-sepBorder" v-else 
                                            v-bind:class="{hidden: selectedModel != null, 
                                                'text-growth': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) > 0, 
                                                'text-decline': (metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) < 0}">
                                            {{ (((metric.models.free.fans_active - metricsTable.data[index-1].models.free.fans_active + metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired) - (metric.models.free.fans_expired - metricsTable.data[index-1].models.free.fans_expired)) / metric.models.modelNames.length).toFixed(2) }}
                                        </td>

                                        <td v-if="index == 0 && isDate1BeforeDate2(metric.forDate, dateFrom)" :class="{'hidden':selectedModel != null}">0</td>
                                        <td v-else v-bind:class="{hidden: selectedModel != null,
                                            'text-decline':((metric.models.free.transactionSum + metric.models.paid.transactionSum) / metric.models.modelNames.length)
                                                < ((metricsTable.data[index-1].models.free.transactionSum + metricsTable.data[index-1].models.paid.transactionSum) / metricsTable.data[index-1].models.modelNames.length),
                                            'text-growth':((metric.models.free.transactionSum + metric.models.paid.transactionSum) / metric.models.modelNames.length)
                                                > ((metricsTable.data[index-1].models.free.transactionSum + metricsTable.data[index-1].models.paid.transactionSum) / metricsTable.data[index-1].models.modelNames.length)}">
                                            $ {{addSpace(parseFloat((metric.models.free.transactionSum + metric.models.paid.transactionSum) / metric.models.modelNames.length).toFixed(2))}}
                                        </td>
                                    </tr>
                                </slot>
                            </slot>

                            <summary-line v-if="metricsTable && metricsTable.data && metricsTable.data.length>0 && metricsTable.summary" :blockedColumns="blockedColumns" :displaySubs="displaySubs" :displayRenewOn="displayRenewOn" :selectedPlatform="selectedPlatform" :selectedModel="selectedModel" :metricsData="metricsTable.summary" :displayColumns="displayColumns"/>
                            <average-line v-if="metricsTable && metricsTable.data && metricsTable.data.length>0 && metricsTable.summary" :blockedColumns="blockedColumns" :displaySubs="displaySubs" :displayRenewOn="displayRenewOn" :selectedPlatform="selectedPlatform" :selectedModel="selectedModel" :metricsData="metricsTable.average" :displayColumns="displayColumns"/>
                        </tbody>
                    </table>
                </div>

                <div class="absolute right-8" v-if="1==2 && editable == true">
                    <div v-if="selectedModel != null && addNewLine == true" @click="saveNew()" class="bg-gray-300 w-max p-1 px-3 cursor-pointer rounded-lg mt-2 bg-green-600 text-panelsText float-left"><font-awesome-icon icon="fas fa-floppy-disk" /> &nbsp;Save</div>
                    <div v-else-if="selectedModel != null" @click="addNewLine = true" class="bg-gray-300 w-max text-gray-800 p-1 px-3 cursor-pointer rounded-lg mt-2 mr-4 float-left"><font-awesome-icon icon="fas fa-plus" /> &nbsp;Add a new record</div>
                </div>
            </section>
        </transition>
    </slot>

    <slot v-else-if="loading">
        <transition name="fade">
            <section>
                <h3 class="text-textOnBkg font-bold text-lg sm:text-2xl m-2">{{tableTitle}}</h3>
                <div class="w-full bg-bkg rounded text-center py-60 border border-sepBorder ml-1" :class="{'py-60':period == 'daily','py-52': period != 'daily'}"><font-awesome-icon icon="fas fa-spinner" class="animate-spin" /> Loading</div>
            </section>
        </transition>
    </slot>
    <slot v-else>
        <transition name="fade">
            <section>
                <h3 class="text-textOnBkg font-bold text-lg sm:text-2xl m-2">{{tableTitle}}</h3>
                <div class="w-full bg-bkg rounded text-center py-60 border border-sepBorder ml-1" :class="{'py-60':period == 'daily','py-52': period != 'daily'}">No data</div>
            </section>
        </transition>
    </slot>
</template>

<script setup>
    import metricsComment from "@/components/metrics/metricsComment.vue"
    import addComment from "@/components/metrics/metricsAddComment.vue"
    import summaryLine from "@/components/metrics/metricsSummaryLine.vue"
    import averageLine from "@/components/metrics/metricsAverageLine.vue"
    import { get_metrics } from "@/api.js"
</script>

<script>
    export default {
        props: ['refreshing','period', 'tableTitle', 'summary','hideF','changedComment','changedData','selectedModelN','selectedPlatform','selectedGroupN','selectedModel','selectedGroup','dateFrom','dateTo','permissions','blockedColumns'],
        components() {
            metricsComment,
            addComment,
            summaryLine,
            averageLine
        },
        data() {
            return {
                metricsTable: [],
                hideCom:false,
                eFansF: {editing:null,value:null},
                exFansF: {editing:null,value:null},
                eFansP: {editing:null,value:null},
                exFansP: {editing:null,value:null},
                eDMF: {editing:null,value:null},
                eDMP: {editing:null,value:null},
                eEarnF: {editing:null,value:null},
                eEarnP: {editing:null,value:null},
                eRen: {editing:null,value:null},
                sbP: {editing:null,value:null},
                loading: false,
                displayComments:true,
                displayRenewOn: true,
                displaySubs: true,
                editable: false,
                hoverModelsIndex:null,
                hoverCommentIndex:null,
                mounted: false,
                addNewLine: false,
                add: {date: null, free: {fans:null,massDM:null,trans:null}, paid: {fans:null,massDM:null,trans:null}},
                submited: false,
                showOptions:{index:0,options:1},
                optionPosition:{x:null,y:null},
                displayColumns: {free:false,paid:false},
                isLoadingMetircs:false
            }
        },
        watch: { 
            changedData: function() {
                this.metricsTable.data[this.changedData[1]].models[this.changedData[2]][this.changedData[3]] = this.changedData[0];
            },
            changedComment: function() {
                this.metricsTable.data[this.changedComment[1]].models[this.changedComment[2]].comments[this.changedComment[3]] = this.changedComment[0];
            },
            hideF: function() {
                this.hideCom = this.hideF[1];
            },
            refreshing: function (n,o) {
                this.metricsTable = [];
                this.getMetricsData();
            },
            selectedModel: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.metricsTable = [];
                this.getMetricsData();
            },
            dateFrom: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.getMetricsData();
            },
            dateTo: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.getMetricsData();
            },
            selectedGroup: function(newVal, oldVal) { // watch it
                if ((newVal === null && oldVal === undefined) || (newVal === 0 && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.getMetricsData(null);
            },
            selectedPlatform: function(newVal,oldVal) {
                if ((newVal === null && oldVal === undefined) || !this.mounted) {
                    return;
                }
                this.getMetricsData();
            }
        },
        methods: {
            getIconClass(condition) {
                return condition ? 'fas fa-check-square' : 'fas fa-square';
            },
            plusMinusIcon(condition) {
                return condition ? 'fa-circle-minus': 'fa-circle-plus';
            },
            exportToCSV() {
                this.$refs.exportLoading.classList.remove('hidden');

                setTimeout(() => {
                    const table = this.$refs.tableRef;
                    const data = [];

                    const rows = table.querySelectorAll('tr');
                    rows.forEach(row => {
                        const rowData = [];
                        row.querySelectorAll('td, th').forEach(cell => {
                            rowData.push(cell.innerText);
                        });
                        data.push(rowData.join(','));
                    });

                    // Create a CSV content string
                    const csvContent = 'data:text/csv;charset=utf-8,' + data.join('\n');

                    const encodedUri = encodeURI(csvContent);
                    const link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    let filename = this.period + "_metrics_" + this.$moment().format('YYYY-MM-DD') + ".csv";
                    link.setAttribute('download', filename);

                    setTimeout(() => {
                        this.$refs.exportLoading.classList.add('hidden');
                        link.click();
                    }, 1000);
                }, 2000);
            },
            syncScroll() {
                const thead = this.$refs.theadRef;
                const tbody = this.$refs.tbodyRef;
                if (thead && tbody) {
                    thead.scrollLeft = tbody.scrollLeft;
                }
            },
            async getMetricsData() {
                if (this.isLoadingMetircs) {return;}
                this.isLoadingMetircs = true;
                try {
                    this.loading = true;
                    this.metricsTable = await get_metrics(this.$cookies.get('userToken'),this.$cookies.get('c_id'),this.selectedGroup,this.selectedModel,this.$moment(this.dateFrom).format('YYYY-MM-DD'),this.$moment(this.dateTo).format('YYYY-MM-DD'), this.period, this.summary);
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isLoadingMetircs = false;
                    this.loading = false;
                }
            },
            isDate1BeforeDate2(date1, date2) {
                let formattedDate1 = new Date(date1.split('.').reverse().join('-'));
                let formattedDate2 = new Date(date2);

                formattedDate1.setHours(0, 0, 0, 0);
                formattedDate2.setHours(0, 0, 0, 0);

                return formattedDate1.getTime() >= formattedDate2.getTime();
            },
            openReverseAlert(alertMessage) {
                if (alertMessage>0) {
                    alert("Reverse summary for this day: $ " + alertMessage);
                }
            },
            resetComments() {
                this.hideCom = false;
            },
            showMetricsOptions(ind,index) {
                if (this.selectedModel != null && index!=this.metricsTable.data.length-1) {
                    this.hideCom = true;
                    this.showOptions.options = 1;
                    //this.hideF = true;
                    this.showOptions.index = ind
                    this.optionPosition.x = pointerX+50;
                }
            },
            saveNew() {
                this.submited = true
                if (this.add.date)  {
                    let dateVar = this.$moment(this.add.date).format('YYYY-MM-DD');
                    this.$axios.post(baseURL+"metricsUpdate/", {                    
                        action: "addMetrics",
                        Model_pair_id: this.selectedModel,
                        addData: this.add,
                        dataDate: dateVar,
                        userToken: this.$cookies.get('userToken'),
                        companyID: this.$cookies.get('c_id')
                    }).then((result) => {
                        if (result.data != 'duplicate') {
                            this.addNewLine = false;
                            this.metricsTable.push({
                                date: this.$moment(this.add.date).format('DD.MM.YYYY'), 
                                free: {
                                    fans_active: result.data.free.fans,
                                    fans_expired: 0,
                                    messages: result.data.free.massDM,
                                    transactionSum: parseFloat(result.data.free.trans.replace(",",".")),
                                },
                                paid: {
                                    fans_active: result.data.paid.fans,
                                    fans_expired: 0,
                                    messages: result.data.paid.massDM,
                                    transactionSum: parseFloat(result.data.paid.trans.replace(",",".")),
                                }
                            });
                            this.add = {date: null, free: {fans:null,massDM:null,trans:null}, paid: {fans:null,massDM:null,trans:null}};
                            this.submited = false
                        } else {
                            alert("Data for this date already exists");
                        }
                    }, (error) => {
                        console.log(error);
                    });
                }
            },
            addSpace(nStr) {
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + " " + '$2');
                }
                return x1 + x2;
            },
            updateMetrics(which,index,newVal) {
                newVal = parseFloat(newVal.replace(",","."));
                this.$axios.post(baseURL+"metricsUpdate/", {                    
                    action: "updateMetrics",
                    Model_pair_id: this.selectedModel,
                    newValue: newVal,
                    column: which,
                    date: this.metricsTable[index].date,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                }, (error) => {
                    console.log(error);
                });
            }
        },
        mounted() {
            if (this.permissions && this.$getPermission(this.permissions,2) && this.$getPermission(this.permissions,2).is_granted) {
                this.editable = true;
            }
            this.mounted = true;
            if (!(this.metricsTable.data && this.metricsTable.data.length>1)) { 
                setTimeout(() => {
                    this.getMetricsData();
                }, 500);
            }
        }
    }
</script>

<style scoped >
    @import "@/assets/css/metrics.css";
    td {
        padding:5px;
        border:1px solid #D1D5DB;
        text-align:center;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s ease;
    }
    .fade-enter, .fade-leave-to /* Starting and ending state for fade-out */ {
        opacity: 0;
    }
</style>