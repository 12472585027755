<template>
    <div v-if="loading" class="state-changed w-full h-20 fixed border-0 top-0 left-0 z-50"></div>   

    <div class="w-full mb-4 relative">
      <router-link :to="'../Settings'">
        <font-awesome-icon icon="fas fa-arrow-left" class="float-left text-lg p-2 text-button -mt-2" />
      </router-link>
      <h2 class="mt-2 text-xl text-contentText font-semibold text-center">Edit a model</h2>
    </div>

    <div>
        <table class="text-tableText bg-tableBG rounded">
            <tr>
                <th></th>
                <th class="cursor-pointer w-1/4" @click="sortTable('free.name','ASC')" >Name &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'free.name'}" /></th>
                <th class="w-24 cursor-pointer" @click="sortTable('platform','ASC')">Platform <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'platform'}" /></th>
                <th class="w-18">ID</th>
                <th class="cursor-pointer" @click="sortTable('code','ASC')" >Code &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'code'}" /></th>
                <th class="cursor-pointer w-1/6" @click="sortTable('identifier','ASC')" >Group &nbsp; <font-awesome-icon icon="fas fa-sort" v-bind:class="{'text-indigo-300':sortColumn == 'identifier'}" /></th>
                <th class="w-28">Willingness</th>
                <th class="w-24">Archive</th>
            </tr>
            <tr v-for="(model, index) in modelData" :key="index" class="cursor-pointer hover:bg-sepBorder" :class="{'opacity-50 cursor-default': model.free.active== 0,'bg-tableRow1':index%2==0}">
                <td>
                    <template v-if="model.avatar">
                        <div class="rounded-full h-10 w-10" :style="{ backgroundImage: `url(${model.avatar})`, backgroundSize: 'cover', backgroundPosition: 'center' }"></div>
                    </template>
                    <template v-else>
                        <div class="bg-gray-200 rounded-full h-10 w-10"></div>
                    </template>
                    <div class="absolute -mt-11 ml-8 bg-decline rounded-full w-3.5 h-3.5 block border border-white" v-if="model.free.scraped == 0" title="Probably wrong credentials" ></div>
                </td>
                <td @click="navigateToModelForm(model)">{{ model.free.name }}</td>
                <td @click="navigateToModelForm(model)">
                    <slot v-if="model.platform==1"><span class="hidden">O</span><img src="@/assets/images/of_logo.png" title="Onlyfans" alt="Onlyfans" class="h-5 mx-auto"/></slot>
                    <slot v-else-if="model.platform==2"><span class="hidden">F</span><img src="@/assets/images/fansly_logo.png" title="Fansly" alt="Fansly" class="h-5 mx-auto"/></slot>
                </td>
                <td @click="navigateToModelForm(model)">{{model.free.id}}</td>
                <td @click="navigateToModelForm(model)">{{ model.code }}</td>
                <td @click="navigateToModelForm(model)">{{ model.identifier }}</td>                
                <td @click="openWillingness(index)" class="cursor-pointer">
                    <font-awesome-icon icon="fas fa-file-circle-check" v-if="model.willingness" class="text-green-500" />
                    <font-awesome-icon icon="fas fa-file-circle-exclamation" v-else class="text-button" />
                </td>
                <td class="cursor-pointer" @click="modelDeArchive(index)" v-if="model.free.active == 0">Return from archive <font-awesome-icon icon="fas fa-undo" /></td>
                <td class="cursor-pointer" @click="modelArchive(index)" v-else><font-awesome-icon icon="fas fa-archive" /></td>
            </tr>
        </table>
    </div>

    <slot v-if="dialogWindow">
        <div class="fixed w-full h-full left-0 top-0 bg-black bg-opacity-60 block z-40" @click="dialogWindow = false"></div>
        <section class="absolute sm:inset-x-1/4 block p-10 bg-tooltipBG text-tooltipText z-50 rounded-lg w-full sm:w-144 mx-auto h-auto top-0">
            <font-awesome-icon icon="fas fa-xmark" @click="dialogWindow = false" class="cursor-pointer absolute right-3 top-3 p-2" />
            <slot v-if="dialogData">
                <h2 class="text-center mb-2">Willingness list for <strong>{{dialogData.free.name}}</strong></h2>
                <section class="overflow-auto">
                    <slot v-for="(v,i) in willingnessList" :key="i">
                        <div @click="dialogWillingness[i] = (parseInt(dialogWillingness[i])-1)*(-1)" class="leading-looser cursor-pointer">
                            <font-awesome-icon v-if="dialogWillingness && dialogWillingness[i] == 0" class="mr-2" icon="fas fa-square" /><font-awesome-icon v-else class="mr-2 text-green-600" icon="fas fa-square-check" /> {{v.name}}
                        </div>
                    </slot>
                </section>
                <button @click="saveWillingness(dialogData.pair_id)" class="mt-5 delay-75 duration-75 transition-all bg-button rounded cursor-pointer p-2 px-7 text-buttonText float-right text-center font-semibold uppercase">
                    <font-awesome-icon icon="fas fa-spinner" class="animate-spin" v-if="loading" /> Save
                </button>
            </slot>
        </section>
    </slot>
</template>

<script>
    import { get_models, archive_model, get_list_data } from '@/api';
    export default {
        data() {
            return { 
                modelData: [],
                sortColumn: null,
                loading:false,
                dialogWindow:false,
                dK:null,
                dialogData:null,
                willingnessList:[],
                dialogWillingness: []
            }
        },        
        methods: {
            navigateToModelForm(model) {
                if (model.free.active != 0) {
                    this.$router.push({ name: 'modelsForm', query: {e:true, id: model.pair_id} });
                }
            },
            saveWillingness(pair_id,index) {
                var w = this.dialogWillingness.join("");
                this.$axios.post(baseURL+"settingspost/", {                    
                    action: "saveWillingness",
                    modelPairID: pair_id,
                    willingnessString: w,
                    userToken: this.$cookies.get('userToken'),
                    companyID: this.$cookies.get('c_id')
                }).then((result) => {
                    this.dialogWindow = false;
                    this.modelData[this.dK].willingness = w;
                    this.dK = null;
                }, (error) => {
                    console.log(error);
                });
            },
            openWillingness(key) {
                this.dialogWindow = true;
                this.dialogData = this.modelData[key];
                this.dK = key;
                this.dialogWillingness = this.dialogData.willingness ? this.dialogData.willingness.split("") : ('1111111111111111111111111').split("");
            },
            sortTable(column,order) {
                this.sortColumn = column
                if (this.$cookies.get('orderColumnM') != column) {
                    this.$cookies.set('orderColumnM',column);
                }
                const properties = column.split('.');

                this.modelData.sort((a, b) => {
                    const valueA = properties.reduce((obj, key) => obj[key], a);
                    const valueB = properties.reduce((obj, key) => obj[key], b);

                    if (typeof valueA === 'number' && typeof valueB === 'number') {
                        // Both values are numbers. Do numerical comparison.
                        return order === 'ASC'
                            ? valueA - valueB
                            : valueB - valueA;
                    } else {
                        // Convert the values to strings and do string comparison.
                        const stringA = String(valueA);
                        const stringB = String(valueB);
                        return order === 'ASC'
                            ? stringA.localeCompare(stringB, undefined, { sensitivity: 'base' })
                            : stringB.localeCompare(stringA, undefined, { sensitivity: 'base' });
                    }
                });
            },
            async modelArchive(index) {
                if (confirm("Are you sure you want to archive this model?")) { 
                    this.modelData[index].free.active=0 
                    await archive_model(this.$cookies.get('userToken'),this.$cookies.get('c_id'),0,this.modelData[index].pair_id,null);
                }
            },
            async modelDeArchive(index) {
                if (confirm("Are you sure you want to return this model from archive?")) { 
                    this.modelData[index].free.active=1 
                    await archive_model(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1,this.modelData[index].pair_id,null);
                }
            },
        },
        async created() {
            this.willingnessList = await get_list_data(this.$cookies.get('userToken'),this.$cookies.get('c_id'),'modelWillingness',null,null,null,null,null);
            this.loading = true;
            this.modelData = await get_models(this.$cookies.get('userToken'),this.$cookies.get('c_id'),1);
            this.loading = false;
            if (this.$cookies.get('orderColumnE')) {
                this.sortTable(this.$cookies.get('orderColumnM'),'ASC')
            }
        }
    }
</script>

<style scoped>
    td,th {
        padding:5px;
        border:1px solid #D1D5DB;
        text-align:center;
    }
</style>